var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import StyledReactModal, { BaseModalBackground, } from "styled-react-modal";
import { COLORS } from "theme";
import { IoCloseOutline } from "react-icons/io5";
import { IconButton } from "./IconButton";
import { Row } from "./Row";
import { Button } from "./Button";
var StyledModal = StyledReactModal.styled(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  opacity: ", ";\n  transition: all 0.3s ease-in-out;\n"], ["\n  opacity: ", ";\n  transition: all 0.3s ease-in-out;\n"])), function (props) { return props.opacity; });
var ContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 26px;\n"], ["\n  background-color: ", ";\n  border-radius: 16px;\n  padding: 26px;\n"])), COLORS.WHITE);
/**
 * Background component for universal modal
 */
export var FadingBackground = styled(BaseModalBackground)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  opacity: ", ";\n  transition: all 0.3s ease-in-out;\n  z-index: 10000;\n  padding: 60px;\n  overflow-y: ", ";\n"], ["\n  opacity: ", ";\n  transition: all 0.3s ease-in-out;\n  z-index: 10000;\n  padding: 60px;\n  overflow-y: ", ";\n"])), function (props) {
    // @ts-expect-error styled-react-modal doesn't have the greatest ts defs
    return props.opacity;
}, function (props) {
    var _a;
    // @ts-expect-error styled-react-modal doesn't have the greatest ts defs
    return (_a = props.overflowY) !== null && _a !== void 0 ? _a : "auto";
});
/**
 * Reusable modal / dialog component used throughout the app
 */
export var Modal = function (_a) {
    var _b;
    var children = _a.children, isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, _c = _a.wrapper, wrapper = _c === void 0 ? true : _c, wrapperStyle = _a.wrapperStyle, backgroundProps = _a.backgroundProps, title = _a.title, id = _a.id, backProps = _a.backProps, nextProps = _a.nextProps, rest = __rest(_a, ["children", "isOpen", "setIsOpen", "wrapper", "wrapperStyle", "backgroundProps", "title", "id", "backProps", "nextProps"]);
    var _d = __read(useState(0), 2), opacity = _d[0], setOpacity = _d[1];
    function toggleModal() {
        setOpacity(0);
        setIsOpen(!isOpen);
    }
    function afterOpen() {
        setTimeout(function () {
            setOpacity(1);
        }, 100);
    }
    function beforeClose() {
        return new Promise(function (resolve) {
            setOpacity(0);
            setTimeout(resolve, 300);
        });
    }
    var contents = (_jsxs(_Fragment, { children: [title && (_jsxs(Row, __assign({ alignItems: "flex-start" }, { children: [_jsx("h2", __assign({ className: "blue", style: { margin: "0 0 12px 0" } }, { children: title })), _jsx(IconButton, __assign({ size: 40, onClick: function () { return setIsOpen(false); }, style: { marginTop: "-8px" } }, { children: _jsx(IoCloseOutline, {}) }))] }))), children, (backProps || nextProps) && (_jsxs(Row, __assign({ margin: "20px 0 0 0" }, { children: [backProps && (_jsx(Button, __assign({ plump: true, loading: backProps.loading, id: "".concat(id, "-back-button"), onClick: (_b = backProps.onClick) !== null && _b !== void 0 ? _b : (function () { return setIsOpen(false); }) }, backProps, { style: __assign({ justifySelf: "flex-start", flex: nextProps && 0.5, maxWidth: nextProps && "50%", width: !nextProps && "100%", marginRight: nextProps && "5px" }, backProps.style) }, { children: backProps.text }))), nextProps && (_jsx(Button, __assign({ plump: true, loading: nextProps.loading, id: "".concat(id, "-proceed-button"), outline: "filled" }, nextProps, { style: __assign({ justifySelf: "flex-end", flex: backProps && 0.5, maxWidth: backProps && "50%", width: !backProps && "100%", marginLeft: backProps && "5px" }, nextProps.style) }, { children: nextProps.text })))] })))] }));
    return (_jsx(StyledModal, __assign({ isOpen: isOpen }, rest, { afterOpen: afterOpen, beforeClose: beforeClose, onBackgroundClick: toggleModal, 
        // @ts-expect-error styled-react-modal doesn't have complete ts
        opacity: opacity, backgroundProps: __assign({ opacity: opacity }, backgroundProps), id: id }, { children: wrapper ? (_jsx(ContentWrapper, __assign({ style: wrapperStyle }, { children: contents }))) : (contents) })));
};
var templateObject_1, templateObject_2, templateObject_3;

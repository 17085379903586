var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme, List as MuiList, ListItem, Typography, Stack } from "@mui/material";
import { shadeColor } from "utils";
import { unitLabels } from "../../../../../constants";
var List = function (_a) {
    var items = _a.items, _b = _a.nameVariant, nameVariant = _b === void 0 ? "caption" : _b, _c = _a.valueVariant, valueVariant = _c === void 0 ? "caption" : _c;
    var theme = useTheme();
    var shadePercent = -10;
    return (_jsx(MuiList, __assign({ sx: { width: "100%" } }, { children: items.map(function (item, index) {
            var _a;
            var valueBackgroundColor = item.backgroundColor || "transparent";
            return (_jsxs(ListItem, __assign({ dense: true, sx: {
                    width: "100%",
                    alignItems: "center",
                    backgroundColor: index % 2 ? theme.palette.secondary[700] : undefined,
                    borderRadius: "4px",
                    display: "flex",
                    height: "32px",
                    justifyContent: "space-between",
                } }, { children: [_jsxs("span", { children: [_jsx(Typography, __assign({ style: {
                                    display: "inline",
                                    color: theme.palette.secondary[300],
                                }, variant: nameVariant }, { children: item.name })), item.alert && (_jsx(Typography, __assign({ style: {
                                    color: theme.palette.warning["400"],
                                    display: "inline",
                                    fontStyle: "italic",
                                    marginLeft: "10px",
                                }, variant: "overline" }, { children: "".concat(item.alert) })))] }), _jsx(Typography, __assign({ style: {
                            color: item.color,
                            display: "inline",
                            fontStyle: "italic",
                        }, variant: "caption" }, { children: item.midValue })), _jsxs(Stack, __assign({ flexDirection: "row", alignItems: "center", gap: "27px", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ style: {
                                    backgroundColor: valueBackgroundColor,
                                    borderRadius: "2px",
                                    color: item.color
                                        ? shadeColor(item.color, shadePercent)
                                        : undefined,
                                    display: "inline",
                                    minWidth: "40px",
                                    padding: "2px 4px",
                                    textAlign: "end",
                                }, variant: valueVariant }, { children: (_a = item.value) !== null && _a !== void 0 ? _a : "N/A" })), _jsx(Typography, __assign({ style: {
                                    color: theme.palette.secondary[400],
                                    minWidth: "40px",
                                    textAlign: "end",
                                }, variant: "caption" }, { children: unitLabels(item.unit) }))] }))] }), item.name + index));
        }) })));
};
export default List;

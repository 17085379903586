var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { COLORS } from "theme";
import { Button } from "./Button";
var ButtonWrapper = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 5px 12px 5px 0;\n  height: unset;\n  color: ", ";\n  padding: 8px 16px;\n  border-radius: 24px;\n  border: ", ";\n  background: transparent;\n  cursor: ", ";\n\n  &:hover {\n    box-shadow: none;\n  }\n\n  &.selected {\n    color: ", ";\n    background: ", ";\n  }\n"], ["\n  margin: 5px 12px 5px 0;\n  height: unset;\n  color: ", ";\n  padding: 8px 16px;\n  border-radius: 24px;\n  border: ", ";\n  background: transparent;\n  cursor: ", ";\n\n  &:hover {\n    box-shadow: none;\n  }\n\n  &.selected {\n    color: ", ";\n    background: ", ";\n  }\n"])), function (props) { return (props.disabled ? COLORS.GREY : COLORS.BLACK); }, function (props) { return "1px solid ".concat(props.disabled ? COLORS : COLORS.BLUE); }, function (props) { return (props.disabled ? "auto" : "cursor"); }, COLORS.WHITE, COLORS.BLUE);
/**
 * Reusable, pill-looking button
 */
export var PillButton = function (props) {
    var selected = props.selected, rest = __rest(props, ["selected"]);
    return _jsx(ButtonWrapper, __assign({ plump: true, className: selected && "selected" }, rest));
};
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { FaUserCircle } from "react-icons/fa";
import { BsQuestionCircle } from "react-icons/bs";
import styled from "styled-components";
import { Row, IconButton, Pipe, Button } from "components";
import { COLORS } from "theme";
import { VERSION } from "version";
var Header = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\theight: 58px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\twidth: 100%;\n\tpadding: 0 1em;\n"], ["\n\theight: 58px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: space-between;\n\twidth: 100%;\n\tpadding: 0 1em;\n"])));
var RightButtons = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tfont-size: 14px;\n\tfont-weight: lighter;\n"], ["\n\tfont-size: 14px;\n\tfont-weight: lighter;\n"])));
/**
 * Primary header for the frame of the application
 */
export var NavHeader = function () {
    var _a, _b, _c, _d, _e, _f;
    var userInfo = useSelector(function (state) { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.info; });
    var navigate = useNavigate();
    var location = useLocation();
    return (_jsx(_Fragment, { children: _jsxs(Header, { children: [_jsx("h1", { children: "Persium Dashboard" }), _jsxs(RightButtons, { children: [_jsx(IconButton, __assign({ style: { marginRight: "8px" }, onClick: function () { return navigate("help"); } }, { children: _jsx(BsQuestionCircle, { size: 24, color: location.pathname.split("/")[1] === "help"
                                    ? COLORS.BLUE
                                    : COLORS.DARKGREY }) })), _jsxs(Button, __assign({ outline: "bare", style: {
                                color: COLORS.DARKGREY,
                                fontSize: "12px",
                            }, onClick: function () { return navigate("changelog"); } }, { children: ["PE Dashboard, Version ", VERSION] })), ((_b = (_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.data) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : (_c = userInfo === null || userInfo === void 0 ? void 0 : userInfo.data) === null || _c === void 0 ? void 0 : _c.username) && (_jsxs(_Fragment, { children: [_jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsx(Pipe, {}), _jsxs("span", __assign({ style: {
                                                color: COLORS.BLUE,
                                                marginRight: "4px",
                                                marginLeft: "6px",
                                            } }, { children: ["Hello,", " "] })), " ", (_e = (_d = userInfo === null || userInfo === void 0 ? void 0 : userInfo.data) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : (_f = userInfo === null || userInfo === void 0 ? void 0 : userInfo.data) === null || _f === void 0 ? void 0 : _f.username] })), _jsx(IconButton, __assign({ style: { pointerEvents: "none" } }, { children: _jsx(FaUserCircle, { size: 30 }) }))] }))] })] }) }));
};
var templateObject_1, templateObject_2;

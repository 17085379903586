var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { ActionTypes, ActionCreators, Maps, getMapSlice, } from "store";
import { boundsToParams } from "utils/geo";
import { normalizeBoundsData } from "utils";
import { API_BASE } from "../../../constants";
import { searchSagas } from "./search";
/**
 * Util saga to make an api call to get all data for map in certain bounds
 */
export function getMapDataInBounds(options) {
    var params, heatmap, result, result, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = options.params, heatmap = options.heatmap;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                if (!heatmap) return [3 /*break*/, 3];
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/v2/heatmap"), {
                        params: params,
                    })];
            case 2:
                result = (_a.sent());
                return [2 /*return*/, result];
            case 3: return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/v2/station"), {
                    params: params,
                })];
            case 4:
                result = (_a.sent());
                return [2 /*return*/, result];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_1 = _a.sent();
                // Need error handling in this application!
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request the station data for all stations currently in map bounds
 */
function handleGetStationsInBounds(action) {
    var _a, boundsFromPayload, _b, saveTo, triggerGetSampleData, reduxLocation_1, view, showHeatmap, showIsobar, boundsFromStore, filters, heatmap, pollutants, bounds, pollutantFilterParams, corners, result, normalizedResponse, pods_1, sample, podsWithoutSummary, e_2;
    var _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _g.trys.push([0, 13, , 14]);
                _a = action.payload, boundsFromPayload = _a.bounds, _b = _a.saveTo, saveTo = _b === void 0 ? "mapdata" : _b, triggerGetSampleData = _a.triggerGetSampleData;
                reduxLocation_1 = action.meta;
                return [4 /*yield*/, select(function (state) { return getMapSlice(state, reduxLocation_1).view; })];
            case 1:
                view = _g.sent();
                showHeatmap = view.showHeatmap;
                showIsobar = view.showIsobar;
                boundsFromStore = view.bounds;
                filters = view.filters;
                heatmap = showHeatmap || showIsobar;
                pollutants = (_c = filters === null || filters === void 0 ? void 0 : filters.pollutants) === null || _c === void 0 ? void 0 : _c.filter(function (filter) { return filter !== "aqi"; });
                bounds = boundsFromPayload || boundsFromStore;
                pollutantFilterParams = __assign(__assign(__assign({}, (((_d = filters === null || filters === void 0 ? void 0 : filters.pollutants) === null || _d === void 0 ? void 0 : _d.length)
                    ? {
                        "filters[pollutants]": pollutants.join(","),
                        pollutant_code: pollutants.join(","),
                    }
                    : {})), (((_e = filters === null || filters === void 0 ? void 0 : filters.atmospherics) === null || _e === void 0 ? void 0 : _e.length)
                    ? { "filters[atmospherics]": filters.atmospherics.join(",") }
                    : {})), (((_f = filters === null || filters === void 0 ? void 0 : filters.engineerings) === null || _f === void 0 ? void 0 : _f.length)
                    ? { "filters[engineerings]": filters.engineerings.join(",") }
                    : {}));
                corners = boundsToParams(bounds);
                return [4 /*yield*/, call(getMapDataInBounds, {
                        heatmap: heatmap,
                        params: __assign(__assign({ include_location: 1, include_status: 1 }, corners), pollutantFilterParams),
                    })];
            case 2:
                result = (_g.sent());
                if (!heatmap) return [3 /*break*/, 4];
                return [4 /*yield*/, put(ActionCreators.setHeatmapInBounds(result.data, action.meta))];
            case 3:
                _g.sent();
                return [2 /*return*/];
            case 4:
                if (!(saveTo === "mapdata")) return [3 /*break*/, 10];
                normalizedResponse = normalizeBoundsData(result.data);
                /* If setting results as map layer data, save entire object, including heatmap, isobars, etc */
                return [4 /*yield*/, put(ActionCreators.setAllStationsInBounds(normalizedResponse, action.meta))];
            case 5:
                /* If setting results as map layer data, save entire object, including heatmap, isobars, etc */
                _g.sent();
                if (!triggerGetSampleData) return [3 /*break*/, 9];
                return [4 /*yield*/, select(function (state) { return state.pods.byId; })];
            case 6:
                pods_1 = _g.sent();
                return [4 /*yield*/, select(function (state) {
                        return state.user.info.data.settings.Dashboard.dashboard.sample;
                    })];
            case 7:
                sample = _g.sent();
                podsWithoutSummary = sample.filter(function (pod) { return !(pods_1 === null || pods_1 === void 0 ? void 0 : pods_1[pod.uuid]); });
                if (!podsWithoutSummary.length) return [3 /*break*/, 9];
                return [4 /*yield*/, put(ActionCreators.requestPodSummaries(podsWithoutSummary.map(function (pod) { return pod.uuid; })))];
            case 8:
                _g.sent();
                _g.label = 9;
            case 9: return [3 /*break*/, 12];
            case 10:
                if (!(saveTo === "podsearch")) return [3 /*break*/, 12];
                /* If saving to pod search results, just save the pod marker items themselves, which is the .data object */
                return [4 /*yield*/, put(ActionCreators.setFoundPods(result.data.data, action.meta))];
            case 11:
                /* If saving to pod search results, just save the pod marker items themselves, which is the .data object */
                _g.sent();
                _g.label = 12;
            case 12: return [3 /*break*/, 14];
            case 13:
                e_2 = _g.sent();
                // Need error handling in this application!
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
/**
 * Saga to call API to retrieve wind data for current map bounds
 */
function handleGetWindData(action) {
    var reduxLocation_2, showWind, bounds, lat1, lng1, lat2, lng2, lat3, lng3, lat4, lng4, response, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                reduxLocation_2 = action.meta;
                return [4 /*yield*/, select(function (state) {
                        return getMapSlice(state, reduxLocation_2).view.showWind;
                    })];
            case 1:
                showWind = _a.sent();
                if (!showWind)
                    return [2 /*return*/];
                return [4 /*yield*/, select(function (state) {
                        return getMapSlice(state, reduxLocation_2).view.bounds;
                    })];
            case 2:
                bounds = _a.sent();
                lat1 = bounds.getNorthEast().lat;
                lng1 = bounds.getNorthEast().lng;
                lat2 = bounds.getSouthWest().lat;
                lng2 = bounds.getNorthEast().lng;
                lat3 = bounds.getSouthWest().lat;
                lng3 = bounds.getSouthWest().lng;
                lat4 = bounds.getNorthEast().lat;
                lng4 = bounds.getSouthWest().lng;
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/wind/map"), {
                        params: {
                            lat1: lat1,
                            lat2: lat2,
                            lat3: lat3,
                            lat4: lat4,
                            lng1: lng1,
                            lng2: lng2,
                            lng3: lng3,
                            lng4: lng4,
                        },
                    })];
            case 3:
                response = (_a.sent());
                /* Save retrieved wind data to store */
                return [4 /*yield*/, put(ActionCreators.setWindInBounds(response.data.data, action.meta))];
            case 4:
                /* Save retrieved wind data to store */
                _a.sent();
                return [3 /*break*/, 6];
            case 5:
                e_3 = _a.sent();
                // Need error handling in this application!
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
/**
 * Util saga to trigger re-fetching of map data within map bounds if any of
 * the following actions are called
 */
function triggerGetStationInBounds(action) {
    var bounds;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return getMapSlice(state, action.meta).view.bounds; })];
            case 1:
                bounds = _b.sent();
                if (!(action.meta.path !== Maps.HASwipeMapWidget &&
                    (bounds || action.payload.bounds))) return [3 /*break*/, 3];
                return [4 /*yield*/, put(ActionCreators.requestStationsInBounds({ triggerGetSampleData: !!((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.triggerGetSampleData) }, action.meta))];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}
function watchTriggerGetStationInBounds() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery([
                    ActionTypes.SET_MAP_BOUNDS,
                    ActionTypes.SET_ISOBAR,
                    ActionTypes.SET_HEATMAP,
                    ActionTypes.SET_VIEW,
                ], triggerGetStationInBounds)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetStationsInBounds() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_ALL_STATIONS_IN_BOUNDS, handleGetStationsInBounds)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetWindData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.SET_WIND, handleGetWindData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function mapSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    fork(watchTriggerGetStationInBounds),
                    fork(watchHandleGetStationsInBounds),
                    fork(watchHandleGetWindData),
                    fork(searchSagas),
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

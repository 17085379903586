var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactTooltip from "react-tooltip";
import * as ReactDOM from "react-dom/client";
import * as L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { COLORS } from "theme";
/**
 * Class abstraction wrapper around geoman, so that we can create an instance
 * that is an extension of L.Control, so that react-leaflet can call all
 * L.PM methods using the expected L.Control lifecycle event handlers
 */
var GeomanControl = L.Control.extend({
    initialize: function (options) {
        var _a;
        L.PM.setOptIn((_a = options.optIn) !== null && _a !== void 0 ? _a : false);
        L.setOptions(this, options);
    },
    addTo: function (map) {
        var _a = this
            .options, globalOptions = _a.globalOptions, toolbarOptions = _a.toolbarOptions, languageOptions = _a.languageOptions, events = _a.events;
        // This should never happen, but its better than crashing the page
        if (!map.pm)
            return;
        map.pm.addControls(toolbarOptions);
        if (languageOptions) {
            map.pm.setLang(languageOptions.lang, languageOptions.customTranslations, languageOptions.fallbackLanguage);
        }
        map.pm.setGlobalOptions(__assign({ snappable: false }, globalOptions));
        /**
         * Attach tooltips object ot keep track of which buttons already have react-tooltip's built
         */
        // @ts-expect-error extending beyond TS defs for geoman
        map.pm.Toolbar.tooltips = {};
        Object.entries(events !== null && events !== void 0 ? events : {}).forEach(function (_a) {
            var _b = __read(_a, 2), eventName = _b[0], handler = _b[1];
            // @ts-expect-error TODO fix this TS issue
            map.on(eventName, handler);
        });
        /**
         * The following lines of code (until the end of `addTo`) are written to create custom
         * tooltips on the Geoman control buttons, to be consistent with the rest of the UX
         * in the application.  The code digs into the internals of Geoman to extract the DOM
         * that makes up its controls.  Then react-tooltip's are applied imperatively using
         * some additional calls to ReadtDOM.render.  The glue that links the geoman-rendered
         * control DOM and the react-tooltip DOM is the data-for attribute injected into geoman's
         * control buttons, which match the IDs of the tooltips applied.
         */
        // @ts-expect-error Geoman TS definitions not comprehensive enough, so flying blind here
        var controls = map.pm.Toolbar.buttons;
        Object.entries(controls).forEach(function (_a) {
            var _b;
            var _c = __read(_a, 2), name = _c[0], control = _c[1];
            if (control.buttonsDomNode) {
                control.buttonsDomNode.setAttribute("data-tip", "");
                control.buttonsDomNode.setAttribute("data-for", "".concat(name, "-button"));
                // @ts-expect-error extending beyond TS defs for geoman
                if (!map.pm.Toolbar.tooltips[name]) {
                    var tooltipContainer = document.createElement("div");
                    control.buttonsDomNode.appendChild(tooltipContainer);
                    var recipient = ReactDOM.createRoot(tooltipContainer);
                    recipient.render(_jsx(ReactTooltip, __assign({ backgroundColor: COLORS.BLACKISH, textColor: COLORS.WHITE, id: "".concat(name, "-button"), place: "left", effect: "solid" }, { children: (_b = control === null || control === void 0 ? void 0 : control._button) === null || _b === void 0 ? void 0 : _b.title })));
                    // @ts-expect-error extending beyond TS defs for geoman
                    map.pm.Toolbar.tooltips[name] = true;
                }
            }
        });
    },
});
var createControl = function (props) {
    return new GeomanControl(props);
};
/**
 * React-leaflet implementation of leaflet-geoman/free
 */
export var Geoman = createControlComponent(createControl);

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { COLORS } from "theme";
/**
 * Simple styled <hr> component reused throughout the app
 */
export var Divider = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: none;\n  border-bottom: ", ";\n  margin: ", ";\n  width: 100%;\n"], ["\n  border: none;\n  border-bottom: ", ";\n  margin: ", ";\n  width: 100%;\n"])), function (props) { var _a; return "1px solid ".concat((_a = props === null || props === void 0 ? void 0 : props.color) !== null && _a !== void 0 ? _a : COLORS.DARKGREY); }, function (props) { var _a; return (_a = props.margin) !== null && _a !== void 0 ? _a : "6px 0 16px 0"; });
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Select from "react-select";
import { PuffLoader } from "react-spinners";
import { COLORS } from "theme";
import { Checkbox } from "./Checkbox";
import { Row } from "./Row";
export var ALL = "Select All";
/**
 * Util function to inject a "Select All" option as the top option of the dropdown,
 * whether using a grouped or non-grouped set of options
 */
var prependSelectAll = function (options) {
    var _a;
    if (!options.length)
        return options;
    var copy = __spreadArray([], __read(options), false);
    if ((_a = options === null || options === void 0 ? void 0 : options[0]) === null || _a === void 0 ? void 0 : _a.options) {
        copy.unshift({
            label: ALL,
            options: [{ label: ALL, value: ALL }],
        });
    }
    else {
        copy.unshift({
            label: ALL,
            value: ALL,
        });
    }
    return copy;
};
/**
 * Default props passed to the generic Dropdown
 */
var defaultSelectProps = {
    isSearchable: true,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    isClearable: false,
};
var customStyles = {
    control: function (p, state) { return (__assign(__assign({}, p), { borderColor: (state.selectProps.isMulti &&
            state.selectProps.value.length) ||
            (!state.selectProps.isMulti && state.selectProps.value)
            ? COLORS.BLUE
            : COLORS.GREY1, display: "flex" })); },
    placeholder: function (p, state) { return (__assign(__assign({}, p), { color: state.isDisabled ? "".concat(COLORS.BLACK, "80") : COLORS.BLACK, position: "absolute", top: "8px", left: "8px", opacity: state.selectProps.menuIsOpen ? 0.5 : 1, fontStyle: state.selectProps.menuIsOpen && "italic" })); },
    indicatorSeparator: function () { return ({
        display: "none",
    }); },
    menuList: function (p) { return (__assign(__assign({}, p), { paddingTop: 0, paddingBottom: 0 })); },
    groupHeading: function (p) { return (__assign(__assign({}, p), { marginBottom: "0.5em", color: COLORS.BLUE, fontWeight: "bold" })); },
    option: function (p, state) { return (__assign(__assign({}, p), { cursor: "pointer", backgroundColor: state.isSelected ? COLORS.LIGHTBLUE : "#FFFFFF", color: COLORS.BLACK, "&:hover": {
            backgroundColor: state.isSelected ? COLORS.LIGHTBLUE : COLORS.LIGHTGREY,
        } })); },
    menu: function (p) { return (__assign(__assign({}, p), { minWidth: "100%", width: "max-content" })); },
    multiValue: function (p) { return (__assign(__assign({}, p), { overflow: "hidden", margin: "0 0 0 8px", backgroundColor: "transparent" })); },
    valueContainer: function (p, state) {
        return state.selectProps.isMulti
            ? {
                flex: 1,
                overflow: "hidden",
                position: state.selectProps.value.length
                    ? "relative"
                    : "static",
                "& .custom-multivalue": {
                    display: state.selectProps.menuIsOpen && "none",
                },
            }
            : p;
    },
    input: function (p, state) { return (__assign(__assign({}, p), { position: !state.selectProps.menuIsOpen ? "absolute" : "static", opcity: !state.selectProps.menuIsOpen ? 0 : 1, top: 0, right: 0, left: 0, marginLeft: "8px" })); },
};
/**
 * Customized react-select dropdown menu which provides built-in options like
 * checkboxes and select-all options
 */
export var Dropdown = function (props) {
    var _a, _b;
    var selectAll = props.selectAll, onChange = props.onChange, originalOptions = props.options, styles = props.styles, placeholder = props.placeholder, loading = props.loading, components = props.components, rest = __rest(props, ["selectAll", "onChange", "options", "styles", "placeholder", "loading", "components"]);
    var selected = props.value;
    var _c = __read(useState(false), 2), open = _c[0], setOpen = _c[1];
    if (loading) {
        components.DropdownIndicator = function DropdownIndicator() {
            return (_jsx("div", __assign({ style: { marginRight: "8px" } }, { children: _jsx(PuffLoader, { size: 20, color: COLORS.GREY1 }) })));
        };
    }
    /**
     * Potentially flatten options, if they were grouped
     */
    var flattenedOptions = ((_a = originalOptions === null || originalOptions === void 0 ? void 0 : originalOptions[0]) === null || _a === void 0 ? void 0 : _a.options)
        ? originalOptions.map(function (o) { return o.options; }).flat()
        : originalOptions;
    /**
     * Whether or not all options are already selected
     */
    var allAlreadySelected = ((_b = originalOptions === null || originalOptions === void 0 ? void 0 : originalOptions[0]) === null || _b === void 0 ? void 0 : _b.options)
        ? selected.length === flattenedOptions.length
        : selected.length === originalOptions.length;
    var options = selectAll
        ? prependSelectAll(originalOptions)
        : originalOptions;
    /**
     * Callback to handle change events.  If using a simple dropdown, will
     * use the onChange callback provided.  If using isMulti and selectAll,
     * will override and append select all behaviors
     */
    var handleChange = props.isMulti && selectAll
        ? function (e, actionMeta) {
            var option = actionMeta.option;
            if ((option === null || option === void 0 ? void 0 : option.value) === ALL) {
                if (allAlreadySelected) {
                    onChange([], actionMeta);
                }
                else {
                    onChange(flattenedOptions, actionMeta);
                }
            }
            else {
                onChange(e, actionMeta);
            }
        }
        : onChange;
    return (_jsx(Select
    // The order of props here is crucial, careful when moving them around!
    , __assign({}, defaultSelectProps, { formatGroupLabel: function (_a) {
            var label = _a.label;
            if (label === ALL) {
                return "";
            }
            return label;
        }, formatOptionLabel: selectAll
            ? function (_a) {
                var _b;
                var value = _a.value, label = _a.label, disabled = _a.disabled, omitCheckbox = _a.omitCheckbox;
                var checked = label === ALL
                    ? allAlreadySelected
                    : !!selected.find(function (o) { return o.value === value; });
                return (_jsxs(Row, __assign({ id: "option-".concat(((_b = value) !== null && _b !== void 0 ? _b : "").replaceAll(" ", "-")), justifyContent: "flex-start", alignItems: "center", style: disabled && {
                        opacity: 0.5,
                        pointerEvents: "none",
                        cursor: "auto",
                    } }, { children: [!omitCheckbox && (_jsx(Checkbox, { className: "dropdown-checkbox", checked: checked, size: 16, style: { marginRight: "4px" } })), label] })));
            }
            : undefined, options: options, onChange: handleChange, styles: customStyles, onMenuOpen: function () { return setOpen(true); }, onMenuClose: function () { return setOpen(false); }, placeholder: open ? "Search ".concat(placeholder) : placeholder, components: components }, rest)));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { COLORS } from "theme";
// Create root level element for react-tooltips
var domNode = document.createElement("div");
document.body.appendChild(domNode);
// Wrapper component to portal react-tooltips
function BodyPortal(_a) {
    var children = _a.children;
    return ReactDOM.createPortal(children, domNode);
}
var ReactTooltipStyled = styled(ReactTooltip)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 300px;\n  white-space: pre-wrap;\n\n  &.show {\n    opacity: 1 !important;\n  }\n"], ["\n  max-width: 300px;\n  white-space: pre-wrap;\n\n  &.show {\n    opacity: 1 !important;\n  }\n"])));
/**
 * Persium-branded tooltip, based on react-tooltip.  Renders react-tooltip in react portal
 * by default, which helps with positioning and overflow issues, but this can be opted-out
 */
export var Tooltip = function (props) {
    var children = props.children, contents = props.contents, id = props.id, disabled = props.disabled, usePortal = props.usePortal, rest = __rest(props, ["children", "contents", "id", "disabled", "usePortal"]);
    var tooltip = (_jsx(ReactTooltipStyled, __assign({ backgroundColor: COLORS.BLACKISH, textColor: COLORS.WHITE, effect: "solid", id: "tooltip-".concat(id), multiline: true }, rest, { children: contents })));
    var renderable = usePortal ? _jsx(BodyPortal, { children: tooltip }) : tooltip;
    return (_jsxs(_Fragment, { children: [!disabled && renderable, React.cloneElement(children, {
                // @ts-expect-error TS not accept data- prop?
                "data-tip": true,
                "data-for": "tooltip-".concat(id),
            })] }));
};
var templateObject_1;

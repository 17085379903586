var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "components/atoms";
import { IoIosEye, IoMdEyeOff } from "react-icons/io";
import styled from "styled-components";
import { COLORS } from "theme";
var Wrapper = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 5px 16px 5px 12px;\n  background: ", ";\n  border: none;\n  border-radius: 16px;\n  margin-bottom: 5px;\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);\n  cursor: pointer;\n  font-size: 10px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 5px 16px 5px 12px;\n  background: ", ";\n  border: none;\n  border-radius: 16px;\n  margin-bottom: 5px;\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);\n  cursor: pointer;\n  font-size: 10px;\n"])), COLORS.BLACK);
var Label = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  margin-left: 8px;\n"], ["\n  color: ", ";\n  margin-left: 8px;\n"])), COLORS.WHITE);
/**
 * Button component to toggle layer on map
 */
export var ToggleButton = function (_a) {
    var layerName = _a.layerName, visible = _a.visible, text = _a.text, icon = _a.icon, rest = __rest(_a, ["layerName", "visible", "text", "icon"]);
    var Icon = visible ? IoIosEye : IoMdEyeOff;
    return (_jsxs(Wrapper, __assign({}, rest, { id: "map-layer-toggle-".concat(layerName.toLowerCase()) }, { children: [icon !== null && icon !== void 0 ? icon : _jsx(Icon, { color: COLORS.GREY, size: 20 }), _jsx(Label, { children: text !== null && text !== void 0 ? text : "".concat(visible ? "Hide" : "Show", " ").concat(layerName) })] })));
};
var templateObject_1, templateObject_2;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router";
import { Button, Column, Row } from "components/atoms";
import styled from "styled-components";
import { MdKeyboardBackspace } from "react-icons/md";
import { PinIcon } from "assets/images";
var Title = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 50px;\n  text-align: center;\n  font-family: \"Roboto\", serif monospace;\n"], ["\n  font-size: 50px;\n  text-align: center;\n  font-family: \"Roboto\", serif monospace;\n"])));
var IconWrapper = styled(Column)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  margin-right: 20px;\n"], ["\n  position: relative;\n  align-items: center;\n  justify-content: center;\n  margin-right: 20px;\n"])));
/**
 * General 404 page
 */
export var Error404 = function () {
    var navigate = useNavigate();
    return (_jsx(Column, __assign({ alignItems: "center", margin: "10% 0 0 0" }, { children: _jsxs(Column, { children: [_jsxs(Row, { children: [_jsxs(IconWrapper, { children: [_jsx("img", { src: PinIcon, style: { height: "180px", width: "180px" } }), _jsx(Title, __assign({ className: "blue", style: {
                                        position: "absolute",
                                        top: "20px",
                                        fontFamily: "'Roboto Mono', monospace",
                                    } }, { children: "404" }))] }), _jsxs(Column, { children: [_jsx(Title, __assign({ className: "blue" }, { children: "Oops!" })), _jsx("h1", { children: "We're sorry - we can't find the page you're looking for." })] })] }), _jsx(Button, __assign({ plump: true, outline: "bare", onClick: function () { return navigate("/"); }, style: { width: "180px", marginTop: "60px" } }, { children: _jsx("h4", { children: _jsxs(Row, __assign({ alignItems: "center" }, { children: [_jsx(MdKeyboardBackspace, { size: 28, style: { marginRight: "10px" } }), " ", _jsx("span", { children: "Go Home" })] })) }) }))] }) })));
};
var templateObject_1, templateObject_2;

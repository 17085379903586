var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import L from "leaflet";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { TimeRanges, } from "models";
import { ActionCreators, ActionTypes, Maps } from "store";
import { deriveBoundsFromPoints, deriveCenterAndZoomFromBounds, nowUTC, } from "utils";
import { API_BASE, CC_SUBSCRIPTION_URL, defaultDashboardSettings, defaultUserFilters, timerangeOptions, } from "../../../constants";
import { userAuthSagas } from "./auth";
import { userAccountSagas } from "./account";
/**
 * Utility saga to recieve user info, and based on user's settings
 * make appropriate api calls to hydrate store with necessary data
 */
function handleRequestUserRelatedData(settings) {
    var dashboard, sample, selectedTimerange, timeframe, bounds, _a, zoom, center, pods_1, podsWithoutSummary;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                dashboard = (_b = settings === null || settings === void 0 ? void 0 : settings.Dashboard) === null || _b === void 0 ? void 0 : _b.dashboard;
                sample = dashboard === null || dashboard === void 0 ? void 0 : dashboard.sample;
                selectedTimerange = dashboard === null || dashboard === void 0 ? void 0 : dashboard.timerange;
                timeframe = Array.isArray(selectedTimerange)
                    ? selectedTimerange
                    : [
                        (_c = timerangeOptions
                            .find(function (t) { var _a; return (_a = t.value === selectedTimerange) !== null && _a !== void 0 ? _a : t.value === TimeRanges.PAST_MONTH; })) === null || _c === void 0 ? void 0 : _c.getTime(),
                        nowUTC(),
                    ];
                if (!(sample && (sample === null || sample === void 0 ? void 0 : sample.length))) return [3 /*break*/, 7];
                /**
                 * Step 2: Call to get statistics for these pods
                 */
                return [4 /*yield*/, put(ActionCreators.requestTimeframeStats({ pods: sample, timeframe: timeframe }))];
            case 1:
                /**
                 * Step 2: Call to get statistics for these pods
                 */
                _d.sent();
                bounds = deriveBoundsFromPoints(sample.map(function (pod) {
                    return L.latLng({ lat: pod.location.latitude, lng: pod.location.longitude });
                }));
                _a = deriveCenterAndZoomFromBounds({
                    bounds: bounds,
                    height: 400,
                    width: 800,
                }), zoom = _a.zoom, center = _a.center;
                /**
                 * Register the user's pollutant filter selection in the main dashboard map
                 */
                return [4 /*yield*/, put(ActionCreators.setMapView({
                        filters: settings.filters,
                    }, {
                        path: Maps.Dashboard,
                    }))];
            case 2:
                /**
                 * Register the user's pollutant filter selection in the main dashboard map
                 */
                _d.sent();
                return [4 /*yield*/, put(ActionCreators.setMapBounds({
                        bounds: bounds,
                        center: center,
                        triggerGetSampleData: true,
                        zoom: zoom,
                    }, {
                        path: Maps.PodSelect,
                    }))];
            case 3:
                _d.sent();
                return [4 /*yield*/, put(ActionCreators.setMapBounds({
                        bounds: bounds,
                        center: center,
                        zoom: zoom,
                    }, {
                        path: Maps.Dashboard,
                    }))];
            case 4:
                _d.sent();
                return [4 /*yield*/, select(function (state) { return state.pods.byId; })];
            case 5:
                pods_1 = _d.sent();
                podsWithoutSummary = sample.filter(function (pod) { return !(pods_1 === null || pods_1 === void 0 ? void 0 : pods_1[pod.uuid]); });
                if (!podsWithoutSummary.length) return [3 /*break*/, 7];
                return [4 /*yield*/, put(ActionCreators.requestPodSummaries(podsWithoutSummary.map(function (pod) { return pod.uuid; })))];
            case 6:
                _d.sent();
                _d.label = 7;
            case 7: return [4 /*yield*/, put(ActionCreators.requestPreferenceOptions())];
            case 8:
                _d.sent();
                return [4 /*yield*/, put(ActionCreators.requestFavPods())];
            case 9:
                _d.sent();
                return [4 /*yield*/, put(ActionCreators.requestOwnedPods())];
            case 10:
                _d.sent();
                return [4 /*yield*/, put(ActionCreators.requestSubscribedPods())];
            case 11:
                _d.sent();
                return [4 /*yield*/, put(ActionCreators.requestAllFilters())];
            case 12:
                _d.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * Saga to request user information from API
 */
function handleRequestUserInfo() {
    var userResponse, userInfo, sanitizedSettings, e_1;
    var _a, _b, _c, _d, _e, _f, _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _h.trys.push([0, 4, , 5]);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/user/info"))];
            case 1:
                userResponse = (_h.sent());
                userInfo = userResponse.data.data;
                /**
                 * If this is the first time the user accesses the dashboard, they may not have a `.Dashboard`
                 * property on their settings object.  In that case, create an empty one so the app
                 * doesn't crash:
                 */
                if (!userInfo.settings.Dashboard) {
                    userInfo.settings.Dashboard = defaultDashboardSettings;
                }
                sanitizedSettings = __assign(__assign({}, userInfo.settings), { 
                    /**
                     * Sanitize the filters property - sometimes new users get created from the BE with a value of
                     * filters: [], which causes problems!
                     */
                    filters: Array.isArray(userInfo.settings.filters) || !userInfo.settings.filters
                        ? defaultUserFilters
                        : userInfo.settings.filters, Dashboard: __assign(__assign({}, userInfo.settings.Dashboard), { dashboard: __assign(__assign({}, userInfo.settings.Dashboard.dashboard), { sample: userInfo.settings.Dashboard.dashboard.sample.filter(function (pod) { return !!pod.location; }), graphs: (_a = userInfo.settings.Dashboard.dashboard.graphs) !== null && _a !== void 0 ? _a : null }), analytics: __assign(__assign({}, userInfo.settings.Dashboard.analytics), { widgets: (_d = (_c = (_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.settings) === null || _b === void 0 ? void 0 : _b.Dashboard) === null || _c === void 0 ? void 0 : _c.analytics.widgets) !== null && _d !== void 0 ? _d : [], layout: (_g = (_f = (_e = userInfo === null || userInfo === void 0 ? void 0 : userInfo.settings) === null || _e === void 0 ? void 0 : _e.Dashboard) === null || _f === void 0 ? void 0 : _f.analytics.layout) !== null && _g !== void 0 ? _g : [] }) }) });
                userInfo.settings = __assign(__assign({}, sanitizedSettings), { loading: false });
                /* Save user info to redux store, recall for map data */
                return [4 /*yield*/, put(ActionCreators.setUserInfo(userInfo))];
            case 2:
                /* Save user info to redux store, recall for map data */
                _h.sent();
                return [4 /*yield*/, call(handleRequestUserRelatedData, sanitizedSettings)];
            case 3:
                _h.sent();
                return [3 /*break*/, 5];
            case 4:
                e_1 = _h.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Saga to send payment form information when registering for paid plan
 * (different from udpating card information)
 */
function handleSubmitPaymentForm(action) {
    var e_2;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 4, , 6]);
                /* Call to post credit card form */
                return [4 /*yield*/, call(axios.post, CC_SUBSCRIPTION_URL, action.payload)];
            case 1:
                /* Call to post credit card form */
                _d.sent();
                /* Register payment success in store */
                return [4 /*yield*/, put(ActionCreators.paymentFormSuccess("You've unlocked Persium Pro Tools"))];
            case 2:
                /* Register payment success in store */
                _d.sent();
                /* Now that user has paid, their info should be updated, so request again */
                return [4 /*yield*/, put(ActionCreators.requestUserInfo())];
            case 3:
                /* Now that user has paid, their info should be updated, so request again */
                _d.sent();
                return [3 /*break*/, 6];
            case 4:
                e_2 = _d.sent();
                return [4 /*yield*/, put(ActionCreators.paymentFormError((_c = (_b = (_a = e_2 === null || e_2 === void 0 ? void 0 : e_2.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : "Something went wrong while we were processing your information.  Contact customer support if your issue persists."))];
            case 5:
                _d.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function watchHandleRequestUserInfo() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_USER_INFO, handleRequestUserInfo)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleSubmitPaymentForm() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_SUBMIT_PAYMENT_FORM, handleSubmitPaymentForm)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function userSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    fork(userAuthSagas),
                    fork(userAccountSagas),
                    fork(watchHandleRequestUserInfo),
                    fork(watchHandleSubmitPaymentForm),
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HealthAdvice from "./healthAdvice";
import LevelBar from "./levelBar";
import Points from "./points";
import Recommendation from "./recommendation";
import Title from "./title";
import { Stack } from "@mui/material";
export var StationSummary = function (_a) {
    var aqiMax = _a.aqiMax, AQIType = _a.AQIType, station = _a.station, statusColor = _a.statusColor;
    var stationAQI = station[AQIType];
    return (_jsxs(Stack, __assign({ width: "100%", gap: "12px" }, { children: [_jsx(Title, { station: station }), stationAQI.value > -1 && (_jsx(Points, { aqiMax: aqiMax, AQIType: AQIType, station: station, statusColor: statusColor })), stationAQI.value > -1 && (_jsx(LevelBar, { aqi: stationAQI.value, AQIType: AQIType, statusColor: statusColor })), stationAQI.value > -1 && (_jsx(HealthAdvice, { healthAdvice: station.health_advice, statusColor: statusColor })), _jsx(Recommendation, { aqi: stationAQI.value, recommendation: station.recommendation })] })));
};

import { ActionTypes } from "store/types";
import { createAction } from "typesafe-actions";
export var ReportsActionCreators = {
    /**
     * Redux action to request a pdf report to be sent to the users email
     */
    requestPdfReport: createAction(ActionTypes.REQ_PDF_REPORT, function (payload) { return payload; })(),
    /**
     * Redux action to register that pdf was sent successfully
     */
    pdfReportSuccess: createAction(ActionTypes.PDF_REPORT_SUCESS, function () { return undefined; })(),
    /**
     * Redux action to register pdf request error
     */
    pdfReportError: createAction(ActionTypes.PDF_REPORT_ERROR, function (payload) { return payload; })(),
    exportData: createAction(ActionTypes.EXPORT_DATA, function (payload) { return payload; })(),
    /**
     * Redux action to clear pdf request state in store
     */
    clearPdfReport: createAction(ActionTypes.CLEAR_PDF_REPORT, function () { return undefined; })(),
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import ReactTooltip from "react-tooltip";
import styled, { css } from "styled-components";
import { Button } from "components";
import { COLORS } from "theme";
import { clsx } from "clsx";
import { NavWrapper } from "./NavMenu";
/** Navigation react-router wrapped Link component */
var NavLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  all: unset;\n  display: flex;\n  width: 248px;\n"], ["\n  all: unset;\n  display: flex;\n  width: 248px;\n"])));
/** Navigation Button component */
export var NavButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  margin-bottom: 10px;\n  justify-content: flex-start;\n  border: none;\n  height: 42px;\n  width: 248px;\n  flex: 1;\n  padding-left: 6px;\n  transition: color 150ms, background-color 150ms, padding-left 350ms;\n\n  ", ".compact & {\n    padding-left: 20px;\n    border-radius: 0px;\n    transition: color 150ms, background-color 150ms, padding-left 350ms;\n  }\n\n  &.selected {\n    color: ", ";\n  }\n\n  & svg {\n    transition: color 150ms, background-color 150ms;\n  }\n\n  &:hover:not(.selected),\n  &.highlighted {\n    background-color: ", ";\n    color: ", ";\n    transition: color 150ms, background-color 150ms;\n  }\n"], ["\n  color: ", ";\n  margin-bottom: 10px;\n  justify-content: flex-start;\n  border: none;\n  height: 42px;\n  width: 248px;\n  flex: 1;\n  padding-left: 6px;\n  transition: color 150ms, background-color 150ms, padding-left 350ms;\n\n  ", ".compact & {\n    padding-left: 20px;\n    border-radius: 0px;\n    transition: color 150ms, background-color 150ms, padding-left 350ms;\n  }\n\n  &.selected {\n    color: ", ";\n  }\n\n  & svg {\n    transition: color 150ms, background-color 150ms;\n  }\n\n  &:hover:not(.selected),\n  &.highlighted {\n    background-color: ", ";\n    color: ", ";\n    transition: color 150ms, background-color 150ms;\n  }\n"])), COLORS.GREY1, NavWrapper, COLORS.BLUE, COLORS.DARKBLUE, COLORS.WHITE);
/** Text in a nav button */
export var NavButtonText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  opacity: 1;\n  transition: color 150ms, background-color 150ms, opacity 350ms;\n  pointer-events: none;\n\n  ", ".compact & {\n    opacity: 0;\n    transition: color 150ms, background-color 150ms, opacity 350ms;\n  }\n"], ["\n  opacity: 1;\n  transition: color 150ms, background-color 150ms, opacity 350ms;\n  pointer-events: none;\n\n  ", ".compact & {\n    opacity: 0;\n    transition: color 150ms, background-color 150ms, opacity 350ms;\n  }\n"])), NavWrapper);
var Bullet = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 20px;\n  margin: -3px 10px 0 30px;\n  line-height: 20px;\n"], ["\n  font-size: 20px;\n  margin: -3px 10px 0 30px;\n  line-height: 20px;\n"])));
/**
 * The UL component for the expandable menu that opens when you click a menu item
 * that has submenu items
 */
var SubnavMenu = styled.ul(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-height: 350px;\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n\n  ", "\n"], ["\n  max-height: 350px;\n  margin: 0;\n  padding: 0;\n  overflow: hidden;\n\n  ", "\n"])), function (props) {
    return !props.expanded && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      max-height: 0px;\n    "], ["\n      max-height: 0px;\n    "])));
});
export var NavItem = function (_a) {
    var _b, _d;
    var link = _a.link, Icon = _a.icon, title = _a.title, subitems = _a.subitems, menuOpen = _a.menuOpen;
    var location = useLocation();
    var tooltip = useRef();
    var _e = __read(useState(false), 2), expanded = _e[0], setExpanded = _e[1];
    var _f = __read(useState(false), 2), highlighted = _f[0], setHighlighted = _f[1];
    var selected = (_d = (_b = location.pathname.split("/")) === null || _b === void 0 ? void 0 : _b[1]) === null || _d === void 0 ? void 0 : _d.includes(link);
    useEffect(function () {
        var _a;
        if (menuOpen && selected) {
            setExpanded(true);
        }
        else {
            setExpanded(false);
        }
        /**
         * Next block of code is a bit of a hack to get the subnav tooltip to always
         * open in the correct place
         */
        // @ts-expect-error due to lack of typine for react tooltip ref
        var tooltipRef = (_a = tooltip.current) === null || _a === void 0 ? void 0 : _a.tooltipRef;
        if (tooltipRef) {
            var parentButton = document.querySelector("#nav-button-".concat(link));
            var top_1 = parentButton.getBoundingClientRect().y;
            tooltipRef.style = "left: 62px; top: ".concat(top_1 + 32, "px; margin-left: 0; padding: 0;");
        }
    }, [menuOpen]);
    if (subitems && subitems.length) {
        return (_jsxs(_Fragment, { children: [_jsxs(NavButton, __assign({ id: "nav-button-".concat(link), className: clsx({ selected: selected, highlighted: highlighted }), style: { flex: "unset" }, onClick: function () { return setExpanded(!expanded); }, "data-tip": true, "data-for": "tooltip-subnav-".concat(link) }, { children: [_jsx(Icon, { size: 20, style: {
                                marginRight: "10px",
                                marginTop: "-1px",
                                pointerEvents: "none",
                            } }), _jsx(NavButtonText, { children: title })] })), _jsx(SubnavMenu, __assign({ expanded: expanded && menuOpen }, { children: subitems.map(function (subitem) {
                        var _a, _b;
                        var selected = (_b = (_a = location.pathname
                            .split("/")) === null || _a === void 0 ? void 0 : _a[2]) === null || _b === void 0 ? void 0 : _b.includes(subitem.link);
                        return (_jsx(NavLink, __assign({ to: "".concat(link, "/").concat(subitem.link), id: "nav-item-".concat(link, "-").concat(subitem.link) }, { children: _jsxs(NavButton, __assign({ className: clsx({ selected: selected }) }, { children: [_jsx(Bullet, { children: "\u2022" }), _jsx(NavButtonText, { children: subitem.text })] })) }), "nav-item-".concat(link, "-").concat(subitem.link)));
                    }) })), !menuOpen && (_jsx(ReactTooltip, __assign({ ref: tooltip, id: "tooltip-subnav-".concat(link), effect: "solid", multiline: true, clickable: true, place: "right", arrowColor: "transparent", offset: { left: 196 }, delayHide: 100, className: "nav-tooltip stay-visible-on-hover", backgroundColor: COLORS.BLACKISH, textColor: COLORS.WHITE, overridePosition: function (_p, _c, currentTarget) {
                        return {
                            left: 62,
                            top: currentTarget.getBoundingClientRect().top,
                        };
                    }, afterShow: function () { return setHighlighted(true); }, afterHide: function () { return setHighlighted(false); } }, { children: _jsx(SubnavMenu, __assign({ expanded: true, onClick: function (e) { return e.stopPropagation(); } }, { children: subitems.map(function (subitem) {
                            var _a, _b;
                            var selected = (_b = (_a = location.pathname
                                .split("/")) === null || _a === void 0 ? void 0 : _a[2]) === null || _b === void 0 ? void 0 : _b.includes(subitem.link);
                            return (_jsx(NavLink, __assign({ to: "".concat(link, "/").concat(subitem.link), id: "nav-item-".concat(link, "-").concat(subitem.link) }, { children: _jsx(NavButton, __assign({ className: clsx({ selected: selected }), style: { marginBottom: 0, height: "39px" }, onClick: function () {
                                        var current = tooltip.current;
                                        if (current.tooltipRef) {
                                            current.tooltipRef = null;
                                            ReactTooltip.hide();
                                        }
                                    } }, { children: _jsx(NavButtonText, __assign({ style: { opacity: 1 } }, { children: subitem.text })) })) }), "nav-item-".concat(link, "-").concat(subitem.link)));
                        }) })) })))] }));
    }
    return (_jsx(NavLink, __assign({ to: link }, { children: _jsxs(NavButton, __assign({ className: clsx({
                selected: location.pathname.split("/")[1] === link,
            }), id: "nav-button-".concat(link) }, { children: [_jsx(Icon, { size: 20, style: {
                        marginRight: "10px",
                        marginTop: "-1px",
                        pointerEvents: "none",
                    } }), _jsx(NavButtonText, { children: title })] })) }), "nav-".concat(link)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;

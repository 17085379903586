var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { COLORS } from "theme";
/**
 * Small box to display AQI trend number
 */
export var AQITrend = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  border: 1px solid\n    ", ";\n  border-radius: 3px;\n  padding: 3px 8px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: ", ";\n  border: 1px solid\n    ", ";\n  border-radius: 3px;\n  padding: 3px 8px;\n"])), function (props) {
    return !props.trend ? COLORS.GREY1 : props.trend > 0 ? COLORS.GREEN : COLORS.RED;
}, function (props) {
    return !props.trend
        ? COLORS.GREY1
        : props.trend > 0
            ? COLORS.GREEN
            : COLORS.RED;
});
var templateObject_1;

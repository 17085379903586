var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, useTheme } from "@mui/material";
import List from "../list";
import { useSelector } from "react-redux";
var Pollutants = function (_a) {
    var stationLatest = _a.stationLatest;
    var theme = useTheme();
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var aqiType = userInfo.settings.aqi_type;
    var dewPoint = stationLatest.atmospherics.find(function (item) { return item.code === "dewPoint"; });
    var temperature = stationLatest.atmospherics.find(function (item) { return item.code === "temperature"; });
    var wind = stationLatest.atmospherics.find(function (item) { return item.code === "windHoriz"; });
    var isThereProbabilityOfFogFormation = temperature &&
        dewPoint &&
        wind &&
        (temperature === null || temperature === void 0 ? void 0 : temperature.value) === (dewPoint === null || dewPoint === void 0 ? void 0 : dewPoint.value) &&
        wind.value &&
        wind.value < 2;
    var items = __spreadArray([
        {
            backgroundColor: stationLatest.color,
            color: theme.palette.secondary["50"],
            name: "Air Quality",
            unit: aqiType.toUpperCase(),
            value: stationLatest.aqi,
        }
    ], __read(stationLatest.pollutants), false);
    var finalItems = !isThereProbabilityOfFogFormation
        ? items
        : items.map(function (item) {
            if ((["PM1", "PM10", "PM2.5"]).includes(item.name)) {
                return __assign(__assign({}, item), { alert: "High Probability of Fog Formation" });
            }
            return item;
        });
    return (_jsxs(Stack, __assign({ width: "100%" }, { children: [_jsx(Typography, __assign({ variant: "body1" }, { children: "Pollutants" })), _jsx(List, { items: finalItems })] })));
};
export default Pollutants;

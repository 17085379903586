var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { MapContainer, Pane, TileLayer, useMap, } from "react-leaflet";
import * as L from "leaflet";
import { useDispatch, useSelector } from "react-redux";
import "leaflet/dist/leaflet.css";
import { ActionCreators, getMapSlice, } from "store";
import { PodPreview } from "components/atoms";
import { MAPBOX_TOKEN, MAPBOX_BASELAYER, MAPBOX_LABELS, } from "../../../constants";
import { MapEvents } from "./MapEvents";
import { Search } from "./controls";
import { useMultiViewStore } from "store/multi-view/store";
var DEFAULT_LOCATION = { lat: 51.98823, lng: -0.30448 };
/**
 * Reusable, batteries-included persium MapView component.  Accepts all props of a react-leaflet
 * `MapView` component, though some props are already defaulted and not required.  Props defined in
 * a `MapView` will add to and override default props.  The `style` prop will be merged into a
 * default `MapContainer` style.
 *
 * Most crucially, a `MapView` is assocaited with a slice of the redux store, based on the `reduxLocation`
 * prop.  That prop guides the redux logic in making api calls, and managing the state of a map's
 * view properties and current data-in-view.
 */
var GetCorners = function () {
    var leafletMap = useMap();
    var handleMapCorners = useMultiViewStore().handleMapCorners;
    useEffect(function () {
        var updateCorners = function () {
            var bounds = leafletMap.getBounds();
            var northWest = L.latLng(bounds.getNorth(), bounds.getWest());
            var northEast = L.latLng(bounds.getNorth(), bounds.getEast());
            var southEast = L.latLng(bounds.getSouth(), bounds.getEast());
            var southWest = L.latLng(bounds.getSouth(), bounds.getWest());
            var corners = [
                [northWest.lat, northWest.lng],
                [northEast.lat, northEast.lng],
                [southEast.lat, southEast.lng],
                [southWest.lat, southWest.lng],
            ];
            handleMapCorners(corners); // Update corners only after move ends
        };
        // Set up listener for "moveend" event, which fires after panning/zooming ends
        leafletMap.on('moveend', updateCorners);
        // Run once on mount in case the map already has bounds set
        updateCorners();
        // Clean up the event listener when component unmounts
        return function () {
            leafletMap.off('moveend', updateCorners);
        };
    }, [leafletMap, handleMapCorners]);
    return null;
};
export var MapView = React.forwardRef(function (props, ref) {
    var _a, _b, _c, _d;
    var children = props.children, reduxLocation = props.reduxLocation, events = props.events, style = props.style, useGestureHandling = props.useGestureHandling, _e = props.search, search = _e === void 0 ? true : _e, rest = __rest(props, ["children", "reduxLocation", "events", "style", "useGestureHandling", "search"]);
    var view = useSelector(function (state) { return getMapSlice(state, reduxLocation).view; });
    var dispatch = useDispatch();
    var _f = __read(useState(null), 2), map = _f[0], setMap = _f[1];
    /**
     * Effect that runs on map load, must be defined here because there is a known issue
     * with trying to use the `load` event in the `useMapEvents` hook:
     * https://github.com/PaulLeCam/react-leaflet/issues/105#issuecomment-160580884
     */
    useEffect(function () {
        if (map) {
            if (useGestureHandling) {
                // @ts-expect-error Leaflet.GestureHandling does not have TS defs
                map.gestureHandling.enable();
            }
            dispatch(ActionCreators.setMapBounds({
                bounds: map.getBounds(),
                center: map.getCenter(),
                zoom: map.getZoom(),
            }, reduxLocation));
        }
    }, [map]);
    return (_jsxs(MapContainer, __assign({ ref: function (r) {
            if (ref) {
                // @ts-expect-error typescript getting strange here
                ref.current = r;
            }
            setMap(r);
        }, id: "".concat(reduxLocation.path, "-").concat(((_a = reduxLocation.widget) === null || _a === void 0 ? void 0 : _a.id) ? "".concat((_b = reduxLocation.widget) === null || _b === void 0 ? void 0 : _b.id, "-") : "", "leaflet-map"), center: (_c = view.center) !== null && _c !== void 0 ? _c : DEFAULT_LOCATION, zoom: (_d = view === null || view === void 0 ? void 0 : view.zoom) !== null && _d !== void 0 ? _d : 11, minZoom: 3, maxZoom: 22, preferCanvas: true, className: "markercluster-map", attributionControl: false, style: __assign({ borderRadius: "8px" }, style) }, rest, { children: [React.Children.map(children, function (child) {
                var _a;
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, __assign({ 
                        // If reduxLocation was passed explicitly to component, use it.  Otherwise, clone from MapView component
                        reduxLocation: (_a = child.props.reduxLocation) !== null && _a !== void 0 ? _a : reduxLocation }, child.props));
                }
                return child;
            }), search && _jsx(Search, { reduxLocation: reduxLocation }), _jsx(TileLayer, { url: "".concat(MAPBOX_BASELAYER, "?access_token=").concat(MAPBOX_TOKEN) }), _jsx(Pane, __assign({ name: "labels", style: { zIndex: 410 } }, { children: _jsx(TileLayer, { url: "".concat(MAPBOX_LABELS, "?access_token=").concat(MAPBOX_TOKEN) }) })), _jsx(MapEvents, { reduxLocation: reduxLocation, events: events }), _jsx(PodPreview, { pod: view.podPreview }), _jsx(GetCorners, {})] })));
});
MapView.displayName = "MapView";

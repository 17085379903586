var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { toast } from "react-toastify";
import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "store/types";
import { ActionCreators } from "store";
import { API_BASE } from "../../constants";
/**
 * Saga to request PDF report be sent to user via API
 */
function handleRequestPdfReport(action) {
    var e_1, errorMessage;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 3, 5, 6]);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/station/export-pdf"), {
                        params: action.payload,
                    })];
            case 1:
                _d.sent();
                toast("You will receive your requested reports at ".concat(action.payload.email, " shortly."), {
                    position: "bottom-center",
                    type: "success",
                });
                return [4 /*yield*/, put(ActionCreators.pdfReportSuccess())];
            case 2:
                _d.sent();
                return [3 /*break*/, 6];
            case 3:
                e_1 = _d.sent();
                errorMessage = (_c = (_b = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "There was an error requesting your pdf report.  Please try again.";
                return [4 /*yield*/, put(ActionCreators.pdfReportError(errorMessage))];
            case 4:
                _d.sent();
                toast(errorMessage, {
                    position: "bottom-center",
                    type: "error",
                });
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 6];
            case 5: return [7 /*endfinally*/];
            case 6: return [2 /*return*/];
        }
    });
}
function handleExportData(action) {
    var _a, email, params, e_2, errorMessage;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 3, 5, 6]);
                _a = action.payload, email = _a.email, params = __rest(_a, ["email"]);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/v3/data"), {
                        params: params,
                    })];
            case 1:
                _e.sent();
                toast("You will receive your requested data at ".concat(email, " shortly."), {
                    position: "bottom-center",
                    type: "success",
                });
                return [4 /*yield*/, put(ActionCreators.pdfReportSuccess())];
            case 2:
                _e.sent();
                return [3 /*break*/, 6];
            case 3:
                e_2 = _e.sent();
                errorMessage = (_d = (_c = (_b = e_2 === null || e_2 === void 0 ? void 0 : e_2.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : "There was an error requesting your pdf report.  Please try again.";
                return [4 /*yield*/, put(ActionCreators.pdfReportError(errorMessage))];
            case 4:
                _e.sent();
                toast(errorMessage, {
                    position: "bottom-center",
                    type: "error",
                });
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 6];
            case 5: return [7 /*endfinally*/];
            case 6: return [2 /*return*/];
        }
    });
}
function watchHandleRequestPdfReport() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_PDF_REPORT, handleRequestPdfReport)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleExportData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.EXPORT_DATA, handleExportData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function reportsSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([fork(watchHandleRequestPdfReport)])];
            case 1:
                _a.sent();
                return [4 /*yield*/, all([fork(watchHandleExportData)])];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

import { combineReducers } from "redux";
import { filterReducer } from "./filters";
import { dashboardStatsReducer } from "./statistics";
import { graphsReducer } from "./graphs";
import { mapReducer } from "./map";
/**
 * The central reducer for all state related to the dashboard page
 */
export var dashboardReducer = combineReducers({
    filters: filterReducer,
    statistics: dashboardStatsReducer,
    graphs: graphsReducer,
    map: mapReducer,
});

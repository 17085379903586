var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button, ErrorPrompt, PasswordInput, TextInput, } from "components/atoms";
import { ActionCreators } from "store";
import { Inner } from ".";
/**
 * Login content for login screen
 */
export var Login = function () {
    var dispatch = useDispatch();
    var _a = __read(useState(""), 2), email = _a[0], setEmail = _a[1];
    var _b = __read(useState(""), 2), password = _b[0], setPassword = _b[1];
    var navigate = useNavigate();
    var loading = useSelector(function (state) { return state.user.auth.loading; });
    var unauthorized = useSelector(function (state) { return state.user.auth.error; });
    return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ style: { textAlign: "center" }, id: "login-page-header" }, { children: "Persium Dashboard" })), _jsxs(Inner, { children: [_jsx(TextInput, { id: "email-input", type: "email", placeholder: "Email", value: email, onChange: function (e) { return setEmail(e.target.value); }, error: !!unauthorized }), _jsx(PasswordInput, { id: "password-input", enableVisiblePassword: true, placeholder: "Password", value: password, onChange: function (e) { return setPassword(e.target.value); }, error: !!unauthorized }), _jsx(ErrorPrompt, __assign({ visible: !!unauthorized }, { children: "The credentials you entered are incorrect." })), _jsx(Button, __assign({ plump: true, id: "sign-in-button", outline: "filled", loading: loading, onClick: function (e) {
                            e.preventDefault();
                            dispatch(ActionCreators.requestLogin({ email: email, password: password }));
                        }, disabled: !email || !password }, { children: "Sign In" })), _jsx(Button, __assign({ plump: true, id: "forgot-password-button", outline: "bare", style: { margin: "20px 0" }, onClick: function (e) {
                            e.preventDefault();
                            navigate("/forgot");
                        } }, { children: "Forgot Password?" }))] })] }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from "store/types";
var initialState = {
    request: {},
};
export var reportsReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.REQ_PDF_REPORT:
            return __assign(__assign({}, state), { request: {
                    loading: true,
                } });
        case ActionTypes.PDF_REPORT_SUCESS:
            return __assign(__assign({}, state), { request: {
                    loading: false,
                    success: true,
                } });
        case ActionTypes.PDF_REPORT_ERROR:
            return __assign(__assign({}, state), { request: {
                    loading: false,
                    error: action.payload,
                } });
        case ActionTypes.CLEAR_PDF_REPORT:
            return __assign(__assign({}, state), { request: {} });
        default:
            return state;
    }
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from "react-select";
import { AiOutlineDownload, AiOutlineFile } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { COLORS } from "theme";
import { Row } from "components";
var customStyles = {
    container: function (p) { return (__assign(__assign({}, p), { ".HALF &": {
            width: "28px",
            height: "28px",
            borderRadius: "14px",
        } })); },
    control: function (p, state) { return (__assign(__assign({}, p), { backgroundColor: COLORS.BLUE, color: COLORS.WHITE, border: "none", width: "90px", cursor: "pointer", opacity: state.selectProps.isDisabled ? 0.5 : 1, ".HALF &": {
            width: "28px",
            height: "28px",
            minHeight: "28px",
            borderRadius: "14px",
            overflow: "hidden",
            svg: {
                marginTop: "-2px",
                marginLeft: "2px",
            },
        } })); },
    valueContainer: function (p) { return (__assign(__assign({}, p), { ".HALF &": {
            padding: 0,
        } })); },
    placeholder: function (p) { return (__assign(__assign({}, p), { color: COLORS.WHITE, fontFamily: "Inter", fontSize: "14px" })); },
    menu: function (p) { return (__assign(__assign({}, p), { width: "120px", marginLeft: "-30px", ".HALF &": {
            marginLeft: "-92px",
        } })); },
    menuList: function (p) { return (__assign(__assign({}, p), { paddingTop: 0, paddingBottom: 0 })); },
    option: function (p) { return (__assign(__assign({}, p), { backgroundColor: COLORS.WHITE, color: COLORS.BLUE, borderColor: COLORS.BLUE, cursor: "pointer" })); },
};
/**
 * Select dropdown used to let user export a graph in various ways.  Based on a react-select,
 * but acts more like an expandable menu
 */
export var ExportDropdown = function (_a) {
    var onPng = _a.onPng, onCsv = _a.onCsv, disabled = _a.disabled;
    return (_jsx(Select, { isDisabled: disabled, placeholder: _jsxs(Row, { children: [_jsx(AiOutlineDownload, { size: 20, color: COLORS.WHITE, style: { marginRight: "8px" } }), " ", "Export"] }), value: [], options: [
            {
                label: (_jsxs(Row, __assign({ onClick: onPng }, { children: [_jsx(BsImage, { size: 16, color: COLORS.BLUE, style: { marginRight: "8px" } }), " ", "PNG"] }))),
                value: "PNG",
            },
            {
                label: (_jsxs(Row, __assign({ onClick: onCsv }, { children: [_jsx(AiOutlineFile, { size: 18, color: COLORS.BLUE, style: { marginRight: "8px" } }), " ", "CSV"] }))),
                value: "CSV",
            },
        ], isSearchable: false, components: {
            IndicatorSeparator: null,
            DropdownIndicator: null,
        }, styles: customStyles }));
};

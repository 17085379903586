var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { COLORS } from "theme";
import { useSettings } from "hooks";
import { Row } from "components/atoms";
import { AqiScaleRanges } from "../../../../constants";
var Wrapper = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 40px;\n  height: 20px;\n  color: ", ";\n  z-index: 401;\n  width: 100%;\n  justify-content: center;\n  font-size: 14px;\n  pointer-events: none;\n"], ["\n  position: absolute;\n  bottom: 40px;\n  height: 20px;\n  color: ", ";\n  z-index: 401;\n  width: 100%;\n  justify-content: center;\n  font-size: 14px;\n  pointer-events: none;\n"])), COLORS.WHITE);
var Pill = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 54px;\n  justify-content: center;\n  padding: 3px 0;\n  &:first-child {\n    border-radius: 4px 0 0 4px;\n  }\n  &:last-child {\n    border-radius: 0 4px 4px 0;\n  }\n"], ["\n  width: 54px;\n  justify-content: center;\n  padding: 3px 0;\n  &:first-child {\n    border-radius: 4px 0 0 4px;\n  }\n  &:last-child {\n    border-radius: 0 4px 4px 0;\n  }\n"])));
var Label = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: unset;\n\n  @media (max-width: 512px) {\n    display: none;\n  }\n"], ["\n  width: unset;\n\n  @media (max-width: 512px) {\n    display: none;\n  }\n"])));
/**
 * UI component showing stylized AQI scale bar on bottom center of map
 */
export var ScaleControl = function () {
    var aqi_type = useSettings().aqi_type;
    var scale = AqiScaleRanges[aqi_type];
    return (_jsxs(Wrapper, __assign({ id: "aqi-scale" }, { children: [_jsx(Label, __assign({ style: { paddingRight: "16px" } }, { children: "Good" })), scale.brackets.map(function (bracket) { return (_jsx(Pill, __assign({ style: {
                    backgroundColor: bracket.color,
                    width: scale.pillWidth,
                } }, { children: bracket.text }), bracket.text)); }), _jsx(Label, __assign({ style: { paddingLeft: "16px" } }, { children: "Very High" }))] })));
};
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { COLORS } from "theme";
/**
 * Colored circle to show a pod's status
 */
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", "px;\n  width: ", "px;\n  border-radius: 50%;\n  background-color: ", "4D;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 2px;\n\n  & .core {\n    background-color: ", ";\n    border-radius: 50%;\n    height: 100%;\n    width: 100%;\n  }\n\n  &.stale {\n    background-color: ", "4D;\n\n    & .core {\n      background-color: ", ";\n    }\n  }\n\n  &.offline {\n    background-color: transparent;\n    border: 1px solid ", ";\n\n    & .core {\n      background-color: transparent;\n    }\n  }\n"], ["\n  height: ", "px;\n  width: ", "px;\n  border-radius: 50%;\n  background-color: ", "4D;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 2px;\n\n  & .core {\n    background-color: ", ";\n    border-radius: 50%;\n    height: 100%;\n    width: 100%;\n  }\n\n  &.stale {\n    background-color: ", "4D;\n\n    & .core {\n      background-color: ", ";\n    }\n  }\n\n  &.offline {\n    background-color: transparent;\n    border: 1px solid ", ";\n\n    & .core {\n      background-color: transparent;\n    }\n  }\n"])), function (props) { var _a; return (_a = props.size) !== null && _a !== void 0 ? _a : 24; }, function (props) { var _a; return (_a = props.size) !== null && _a !== void 0 ? _a : 24; }, COLORS.BLUE, COLORS.BLUE, COLORS.GREY1, COLORS.GREY1, COLORS.BLACKISH);
export var PodStatusCircle = function (_a) {
    var size = _a.size, status = _a.status;
    return (_jsx(Wrapper, __assign({ className: status, size: size }, { children: _jsx("div", { className: "core" }) })));
};
var templateObject_1;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as L from "leaflet";
import { createLayerComponent, } from "@react-leaflet/core";
import "leaflet-velocity";
import isEqual from "lodash.isequal";
var createLayer = function (props, context) {
    var instance = L.velocityLayer(props);
    return { instance: instance, context: context };
};
var updateLayer = function (instance, props, prevProps) {
    var prevData = prevProps.data, prevOptions = __rest(prevProps, ["data"]);
    var newData = props.data, newOptions = __rest(props, ["data"]);
    if (!isEqual(prevOptions, newOptions)) {
        instance.setOptions(newOptions);
    }
};
/**
 * React-leaflet implementation of [leaflet-velocity](https://github.com/onaci/leaflet-velocity)
 */
export var LeafletVelocity = createLayerComponent(createLayer, updateLayer);

/**
 * Constants relating to application defaults
 */
import { TimeRanges } from "models";
/**
 * Set of defaults to use for unit/languange preferences for non-logged in users,
 * keyed by locale string
 */
export var DefaultSettingsByLocale = {
    /** Defaults for UK users */
    en: {
        language: "English",
        aqi_type: "daqi",
        aq_standard: "UK",
        timezone: "local",
        get_notifications: false,
        email_notification: false,
        phone_notification: false,
        thresholds: {
            aqi: [],
            pollutants: [],
            atmospherics: [],
            engineerings: [],
        },
        filters: {
            pollutants: [],
            atmospherics: [],
            engineerings: [],
        },
        unit: {
            pollutants: [],
            atmospherics: [],
            engineerings: [],
        },
    },
    /** Defaults for US users */
    us: {
        language: "English",
        aqi_type: "daqi",
        aq_standard: "WHO",
        timezone: "local",
        get_notifications: false,
        email_notification: false,
        phone_notification: false,
        thresholds: {
            aqi: [],
            pollutants: [],
            atmospherics: [],
            engineerings: [],
        },
        filters: {
            pollutants: [],
            atmospherics: [],
            engineerings: [],
        },
        unit: {
            pollutants: [],
            atmospherics: [],
            engineerings: [],
        },
    },
};
/**
 * Placeholder filters object that we use in case filters have not yet come in
 * from back end, or user is signed out and has never set filters before
 */
export var defaultUserFilters = {
    pollutants: [],
    atmospherics: [],
    engineerings: [],
};
/**
 * Default dashboard settings to use if user doesn't already have a `.Dashboard` property
 * on their settings, i.e. in the case of first logins
 */
export var defaultDashboardSettings = {
    dashboard: {
        timerange: TimeRanges.PAST_QUARTER,
        sample: [],
    },
    analytics: {
        widgets: [],
        layout: [],
    },
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { COLORS, FONTS } from "theme";
export var TextInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  border-radius: ", ";\n  border-color: ", ";\n  border-width: ", ";\n  resize: none;\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  font-family: ", ";\n  margin-bottom: 10px;\n  transition: all 200ms;\n  cursor: auto;\n\n  &::placeholder {\n    opacity: 0.5;\n    font-style: italic;\n  }\n\n  ", "\n\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n"], ["\n  width: 100%;\n  border-radius: ", ";\n  border-color: ", ";\n  border-width: ", ";\n  resize: none;\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  font-family: ", ";\n  margin-bottom: 10px;\n  transition: all 200ms;\n  cursor: auto;\n\n  &::placeholder {\n    opacity: 0.5;\n    font-style: italic;\n  }\n\n  ", "\n\n  &:focus {\n    outline: none;\n    border-color: ", ";\n  }\n"])), function (props) {
    return props.variant === "underline-on-focus" ? 0 : "5px";
}, function (props) {
    return props.variant === "underline-on-focus" ? "transparent" : COLORS.GREY1;
}, function (props) {
    return props.variant === "underline-on-focus" ? "0 0 1px 0" : "1px";
}, FONTS.ROBOTO, function (props) {
    return props.error && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border: 1px solid #ff5b5b;\n      transition: all 200ms;\n    "], ["\n      border: 1px solid #ff5b5b;\n      transition: all 200ms;\n    "])));
}, COLORS.GREY1);
var templateObject_1, templateObject_2;

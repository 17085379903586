var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from "store/types";
var initialState = {
    timeframe: {
        loading: true, // initialize to true
    },
};
/**
 * The reducer for the slice of state pertaining to
 * the Dashboard statistics
 */
export function dashboardStatsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.REQ_TIMEFRAME_STATS:
            return __assign(__assign({}, state), { timeframe: __assign(__assign({}, state.timeframe), { loading: true }) });
        case ActionTypes.SET_TIMEFRAME_STATS:
            return __assign(__assign({}, state), { timeframe: __assign(__assign({}, state.timeframe), { loading: false, data: action.payload }) });
        default:
            return state;
    }
}

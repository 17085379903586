var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from "store/types";
export var initialMapState = {
    view: {
        bounds: undefined,
        center: undefined,
        showHeatmap: false,
        showIsobar: false,
        showWind: false,
        podPreview: null,
        zoom: 11,
    },
    data: {
        heatmap: [],
        wind: undefined,
        meta: {
            aqi_type: "daqi", // use as default
        },
    },
    search: {
        query: "",
        loading: false,
        results: [],
        pods: [],
    },
};
/**
 * Reducer for processing data to be shown in various layers on map
 */
export default function generateMapReducer(meta) {
    return function (state, action) {
        var _a;
        if (state === void 0) { state = initialMapState; }
        if (meta === ((_a = action.meta) === null || _a === void 0 ? void 0 : _a.path)) {
            switch (action.type) {
                /**
                 * Map view reducers:
                 */
                case ActionTypes.SET_VIEW:
                    return __assign(__assign({}, state), { view: __assign(__assign({}, state.view), action.payload) });
                case ActionTypes.SET_MAP_BOUNDS:
                    return __assign(__assign({}, state), { view: __assign(__assign({}, state.view), action.payload) });
                case ActionTypes.SET_HEATMAP:
                    return __assign(__assign({}, state), { view: __assign(__assign({}, state.view), { showHeatmap: !state.view.showHeatmap }) });
                case ActionTypes.SET_ISOBAR:
                    return __assign(__assign({}, state), { view: __assign(__assign({}, state.view), { showIsobar: !state.view.showIsobar }) });
                case ActionTypes.SET_WIND:
                    return __assign(__assign({}, state), { view: __assign(__assign({}, state.view), { showWind: !state.view.showWind }) });
                case ActionTypes.SET_POD_PREVIEW:
                    return __assign(__assign({}, state), { view: __assign(__assign({}, state.view), { podPreview: action.payload }) });
                /**
                 * Map Data reducers:
                 */
                case ActionTypes.SET_HEATMAPS_IN_BOUNDS: {
                    var _b = action.payload, data = _b.data, meta_1 = _b.meta;
                    return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { heatmap: data, meta: meta_1 }) });
                }
                case ActionTypes.SET_WIND_IN_BOUNDS:
                    return __assign(__assign({}, state), { data: __assign(__assign({}, state.data), { wind: action.payload }) });
                /**
                 * Map search reducers:
                 */
                case ActionTypes.REQ_SEARCH_FOR_PODS:
                    return __assign(__assign({}, state), { search: __assign(__assign({}, state.search), { loading: true }) });
                case ActionTypes.SET_SEARCH:
                    return __assign(__assign({}, state), { search: __assign(__assign({}, state.search), { query: action.payload }) });
                case ActionTypes.SET_GEOCODE_RESULTS: {
                    return __assign(__assign({}, state), { search: __assign(__assign({}, state.search), { results: action.payload }) });
                }
                case ActionTypes.SET_FOUND_PODS: {
                    return __assign(__assign({}, state), { search: __assign(__assign({}, state.search), { pods: action.payload, loading: false }) });
                }
                default:
                    return state;
            }
        }
        else {
            return state;
        }
    };
}

import { create } from "zustand";
export var useMultiViewStore = create(function (set) { return ({
    views: [],
    sections: [],
    selectedSectionUuid: "",
    mapCorners: [],
    handleSections: function (sections) { return set({ sections: sections }); },
    handleViews: function (views) { return set({ views: views }); },
    handleSelectedSectionUuid: function (selectedSectionUuid) {
        return set({ selectedSectionUuid: selectedSectionUuid });
    },
    handleMapCorners: function (mapCorners) { return set({ mapCorners: mapCorners }); },
}); });

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Column } from "components/atoms";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 1000;\n  pointer-events: none;\n\n  & .leaflet-control * {\n    pointer-events: auto;\n  }\n"], ["\n  position: absolute;\n  z-index: 1000;\n  pointer-events: none;\n\n  & .leaflet-control * {\n    pointer-events: auto;\n  }\n"])));
var classNameMap = {
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
};
var justifyMap = {
    topleft: "flex-start",
    topright: "flex-end",
    bottomleft: "flex-start",
    bottomright: "flex-end",
};
/**
 * Convenient wrapper to wrap any pure-react components that are spoofing leaflet-native components.
 * Injects leaflet classes that help position the children the same way that leaflet would
 * position vanilla-leaflet control components.  Will have no affect on react-leaflet
 * control components that use the true react-leaflet methods for creating a control
 */
export var LeafletControls = function (_a) {
    var children = _a.children, position = _a.position;
    return (_jsx(Wrapper, __assign({ className: classNameMap[position] }, { children: _jsx(Column, __assign({ alignItems: justifyMap[position], className: "leaflet-control" }, { children: children })) })));
};
var templateObject_1;

/**
 * Persium homepage URL (not API)
 */
export var MAIN_URL = "https://persium.co.uk";
/**
 * Base API url
 */
// export const API_BASE =
//   process.env.NODE_ENV === "development"
//     ? "https://dev-api.persium.co.uk/api"
//     : "https://api.persium.co.uk/api";
export var API_BASE = process.env.API_BASE;
/**
 * API endpoint to register a new user
 */
export var SIGNUP_URL = "".concat(API_BASE, "/user/register");
/**
 * Api route to log user in
 */
export var SIGNIN_URL = "".concat(API_BASE, "/user/login");
/**
 * The API url to get the filters for which pollutant data we are requesting
 */
export var FILTER_URL = "".concat(API_BASE, "/filter");
/**
 * API endpoint to like or add or remove a pod from the user's like list
 */
export var FAV_PODS = "".concat(API_BASE, "/station/like");
/**
 * API endpoint which returns generic wiki information for the wiki section
 */
export var API_WIKI = "".concat(API_BASE, "/wiki");
/**
 * API endpoint to user's favorite liked station
 */
export var API_FAV = "".concat(API_BASE, "/station/like");
/**
 * API endpoint to list of stations user is subscribed to
 */
export var SUBSCRIBED_STATIONS_LIST = "".concat(API_BASE, "/station/subscribe");
/**
 * Mapbox tile layer URL for map tiles with no labels
 */
export var MAPBOX_BASELAYER = "https://api.mapbox.com/styles/v1/alexyousefian/cl3nlyqzu000415ny5rci995h/tiles/256/{z}/{x}/{y}@2x";
/**
 * Mapbox tile layer URL for map tiles with labels only
 */
export var MAPBOX_LABELS = "https://api.mapbox.com/styles/v1/alexyousefian/cl3nm0ab5001j15rjxeda0nzm/tiles/256/{z}/{x}/{y}@2x";
/**
 * Mapbox API token to be used with any mapbox related calls
 */
export var MAPBOX_TOKEN = "pk.eyJ1IjoiYWxleHlvdXNlZmlhbiIsImEiOiJjbDBod3MxeWowYjFwM2pwNTgzNmZheGd5In0.IcoiTPi2qbE1YxjC-LD-cw";
/**
 * API endpoint which returns generic list of current possible subscription packages
 */
export var API_PACKAGE = "".concat(API_BASE, "/package");
/**
 * Paypal ID for the application
 */
export var PAYPAL_ID = "AWd7fGR2uF0FgYy1ULovHf9Mizjwz6Fic6AH41Fc2ZoVpyHsLDmdH1hsSgtWF7-orTV21jWGTQIx7rHn";
/**
 * Paypal URL to subscribe to the application
 */
export var PAYPAL_URL = "https://www.paypal.com/sdk/js?client-id=".concat(PAYPAL_ID, "&vault=true&intent=subscription&disable-funding=credit,card");
/**
 * Direct credit card subscription URL
 */
export var CC_SUBSCRIPTION_URL = "".concat(API_BASE, "/payment/subscribe");
/**
 * App ID in facebook's app registry, used with facebook OAuth
 */
export var FB_APP_ID = "469564650951869";

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { COLORS } from "theme";
/**
 * Persium-branded react-textarea-autosize
 */
export var TextAreaAutosize = styled(TextareaAutosize)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  border-radius: 15px;\n  border: none;\n  resize: none;\n  padding: 10px;\n  border: 1px solid ", ";\n  display: flex;\n  font-family: \"Inter\", sans-serif;\n  align-items: center;\n  margin-bottom: 10px;\n\n  &:focus {\n    outline: none;\n  }\n"], ["\n  width: 100%;\n  border-radius: 15px;\n  border: none;\n  resize: none;\n  padding: 10px;\n  border: 1px solid ", ";\n  display: flex;\n  font-family: \"Inter\", sans-serif;\n  align-items: center;\n  margin-bottom: 10px;\n\n  &:focus {\n    outline: none;\n  }\n"])), COLORS.GREY);
var templateObject_1;

import jwtDecode from "jwt-decode";
var localStorage;
if (typeof window !== "undefined") {
    localStorage = window.localStorage;
}
export var PermissionsMap = {
    /**
     * Special permissions class, for users who for some reason have no `name` field in
     * their user.info.package object
     */
    Debug: 1,
    Standard: 1,
    Pro: 2,
    Enterprise: 3,
};
/**
 * Utility hook for determining permission levels for user.  Pulls user info from store and
 * grades user's permission level according to the name of their subscription package.  If
 * there is no user info or subscription package, defaults to 0 permissions (for not logged in user)
 */
export var usePermissions = function () {
    var _a, _b;
    if (!localStorage)
        return 0; // This is just to make SSR happy, never occurs in real browser env
    var jwt = localStorage.getItem("token");
    if (!jwt)
        return 0;
    var jwtDecoded = jwtDecode(jwt);
    var userPackage = (_b = (_a = jwtDecoded === null || jwtDecoded === void 0 ? void 0 : jwtDecoded.package) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : PermissionsMap.Debug;
    return PermissionsMap[userPackage];
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PuffLoader } from "react-spinners";
import styled, { css } from "styled-components";
import { Divider } from "components";
import { COLORS } from "theme";
import clsx from "clsx";
import { MemoizedGraph } from "./MemoizedGraph";
/**
 * SVG-type line dash styles that can be used in highcharts graphs
 */
export var DashStyles;
(function (DashStyles) {
    DashStyles["Solid"] = "Solid";
    DashStyles["ShortDash"] = "ShortDash";
    DashStyles["ShortDot"] = "ShortDot";
    DashStyles["ShortDashDot"] = "ShortDashDot";
    DashStyles["ShortDashDotDot"] = "ShortDashDotDot";
    DashStyles["Dot"] = "Dot";
    DashStyles["Dash"] = "Dash";
    DashStyles["LongDash"] = "LongDash";
    DashStyles["DashDot"] = "DashDot";
    DashStyles["LongDashDot"] = "LongDashDot";
    DashStyles["LongDashDotDot"] = "LongDashDotDot";
})(DashStyles || (DashStyles = {}));
export var SpeciesLineStyles = {
    "aqi": "ShortDashDot",
    "pm10": "ShortDash",
    "pm2.5": "ShortDot",
    "no2": "Solid",
    "no": "ShortDashDotDot",
    "nox": "Dot",
    "o3": "Dash",
    "pm1": "LongDash",
    "temperature": "DashDot",
    "pressure": "LongDashDot",
    "humidity": "LongDashDotDot",
    "dewpoint": "Solid",
    "windchill": "ShortDash",
    "heatindex": "ShortDot",
    "windu": "ShortDashDot",
    "windv": "ShortDashDotDot",
    "windw": "Dot",
    "windhoriz": "Dash",
    "windhorizd": "LongDash",
    "battery": "DashDot",
    "rssi": "LongDashDot",
    "heading": "LongDashDotDot",
    "pitch": "Solid",
    "roll": "ShortDash",
};
/**
 * Mapping of Highcharts-recognized dash style names to their resultant `stroke-dasharray` values
 */
export var DashArrayMap = {
    Solid: "none",
    ShortDash: "6,2",
    ShortDot: "2,2",
    ShortDashDot: "6,2,2,2",
    ShortDashDotDot: "6,2,2,2,2,2",
    Dot: "2,6",
    Dash: "8,6",
    LongDash: "16,6",
    DashDot: "8,6,2,6",
    LongDashDot: "16,6,2,6",
    LongDashDotDot: "16,6,2,6,2,6",
};
/**
 * SVG-type line dash styles that can be used in highcharts graphs
 */
export var DASH_STYLES = Object.keys(DashStyles);
export * from "./MemoizedGraph";
var GraphWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: ", ";\n\n  &.accomodate-navigator {\n    margin-right: 0px;\n  }\n\n  .highcharts-navigator-outline {\n    border-color: black;\n    border-width: 1px 0 1px 0;\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  height: ", ";\n\n  &.accomodate-navigator {\n    margin-right: 0px;\n  }\n\n  .highcharts-navigator-outline {\n    border-color: black;\n    border-width: 1px 0 1px 0;\n  }\n"])), function (props) { return "".concat(props.height + 24, "px"); });
var FrameContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 4px;\n  bottom: 30px;\n  right: 0;\n  left: 50px;\n\n  .accomodate-navigator & {\n    bottom: 30px;\n  }\n\n  .range-selector & {\n    top: 40px;\n  }\n"], ["\n  position: absolute;\n  top: 4px;\n  bottom: 30px;\n  right: 0;\n  left: 50px;\n\n  .accomodate-navigator & {\n    bottom: 30px;\n  }\n\n  .range-selector & {\n    top: 40px;\n  }\n"])));
var FrameInner = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  position: relative;\n  /* border: 2px solid rgba(0, 210, 210, 0.3); */\n"], ["\n  height: 100%;\n  width: 100%;\n  position: relative;\n  /* border: 2px solid rgba(0, 210, 210, 0.3); */\n"])));
var GraphLines = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  top: 0;\n  bottom: 30px;\n  right: 0;\n  left: 0;\n  position: absolute;\n  border: solid #8f96a0;\n  border-width: 0 0 1px 1px;\n  pointer-events: none;\n\n  .accomodate-navigator & {\n    bottom: 102px;\n  }\n"], ["\n  top: 0;\n  bottom: 30px;\n  right: 0;\n  left: 0;\n  position: absolute;\n  border: solid #8f96a0;\n  border-width: 0 0 1px 1px;\n  pointer-events: none;\n\n  .accomodate-navigator & {\n    bottom: 102px;\n  }\n"])));
var Arrow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  border: solid #8f96a0;\n  border-width: 0 1px 1px 0;\n  display: inline-block;\n  padding: 3px;\n"], ["\n  position: absolute;\n  border: solid #8f96a0;\n  border-width: 0 1px 1px 0;\n  display: inline-block;\n  padding: 3px;\n"])));
var ArrowUp = styled(Arrow)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  left: -4px;\n  top: 0;\n  transform: rotate(-135deg);\n"], ["\n  left: -4px;\n  top: 0;\n  transform: rotate(-135deg);\n"])));
var ArrowRight = styled(Arrow)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  right: 0;\n  bottom: -4px;\n  transform: rotate(-45deg);\n"], ["\n  right: 0;\n  bottom: -4px;\n  transform: rotate(-45deg);\n"])));
var XLabels = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  color: #8f96a0;\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 10px;\n  line-height: 12px;\n  left: 0;\n  right: 0;\n\n  &.accomodate-navigator {\n    position: absolute;\n    bottom: 62px;\n  }\n"], ["\n  display: flex;\n  justify-content: space-between;\n  color: #8f96a0;\n  font-family: Roboto;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 10px;\n  line-height: 12px;\n  left: 0;\n  right: 0;\n\n  &.accomodate-navigator {\n    position: absolute;\n    bottom: 62px;\n  }\n"])));
var NoDataMessage = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  top: 45%;\n  width: 100%;\n  text-align: center;\n  font-size: 12px;\n  color: #8f96a0;\n  margin: auto;\n  z-index: 10;\n"], ["\n  position: absolute;\n  top: 45%;\n  width: 100%;\n  text-align: center;\n  font-size: 12px;\n  color: #8f96a0;\n  margin: auto;\n  z-index: 10;\n"])));
var LoadingWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background-color: #ffffff80;\n  opacity: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  position: absolute;\n  z-index: 10;\n  transition: all 100ms;\n  pointer-events: none;\n\n  &.hidden {\n    opacity: 1;\n    pointer-events: auto;\n    transition: all 100ms;\n  }\n"], ["\n  background-color: #ffffff80;\n  opacity: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  position: absolute;\n  z-index: 10;\n  transition: all 100ms;\n  pointer-events: none;\n\n  &.hidden {\n    opacity: 1;\n    pointer-events: auto;\n    transition: all 100ms;\n  }\n"])));
var NavigatorBorder = styled(Divider)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  right: 0;\n  width: auto;\n  margin: 0;\n"], ["\n  position: absolute;\n  left: 0;\n  right: 0;\n  width: auto;\n  margin: 0;\n"])));
/**
 * Additional CSS helpful in controlling visibiity of plot band colors,
 * average lines, etc
 */
export var ControlledGraphCSS = css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  .highcharts-plot-bands-0 {\n    transition: opacity 200ms;\n  }\n\n  &.hideAqiColors .highcharts-plot-bands-0 {\n    opacity: 0;\n    transition: opacity 200ms;\n  }\n"], ["\n  .highcharts-plot-bands-0 {\n    transition: opacity 200ms;\n  }\n\n  &.hideAqiColors .highcharts-plot-bands-0 {\n    opacity: 0;\n    transition: opacity 200ms;\n  }\n"])));
/**
 * Reusable, persium-branded Highcharts line graph component
 */
export var LineGraph = function (_a) {
    var _b, _c;
    var graphOptions = _a.graphOptions, xLabels = _a.xLabels, loading = _a.loading, noDataMessage = _a.noDataMessage, navigator = _a.navigator, rangeSelector = _a.rangeSelector, style = _a.style;
    var height = (_c = (_b = graphOptions === null || graphOptions === void 0 ? void 0 : graphOptions.chart) === null || _b === void 0 ? void 0 : _b.height) !== null && _c !== void 0 ? _c : 230;
    return (_jsxs(GraphWrapper, __assign({ height: height, style: style, className: clsx("line-graph-wrapper", {
            "accomodate-navigator": navigator,
            "range-selector": rangeSelector,
        }) }, { children: [_jsx(FrameContainer, __assign({ className: "line-graph-frame-container" }, { children: _jsxs(FrameInner, { children: [_jsxs(GraphLines, __assign({ className: "line-graph-graph-lines" }, { children: [_jsx(ArrowUp, {}), _jsx(ArrowRight, {}), noDataMessage && !loading && (_jsx(NoDataMessage, { children: noDataMessage }))] })), _jsx(XLabels, __assign({ className: clsx("x-labels", { "accomodate-navigator": navigator }) }, { children: xLabels.map(function (_a) {
                                var text = _a.text, style = _a.style;
                                return (_jsx("span", __assign({ style: style }, { children: text }), text));
                            }) })), (navigator === null || navigator === void 0 ? void 0 : navigator.xLabels) && (_jsxs(_Fragment, { children: [_jsx(NavigatorBorder, { style: { bottom: "60px" } }), _jsx(NavigatorBorder, { style: { bottom: "8px" } }), _jsx(XLabels, __assign({ style: { bottom: "-8px", position: "absolute" } }, { children: navigator.xLabels.map(function (_a) {
                                        var text = _a.text, style = _a.style;
                                        return (_jsx("span", __assign({ style: style }, { children: text }), text));
                                    }) }))] }))] }) })), _jsx(MemoizedGraph, { graphOptions: graphOptions, navigator: navigator }), _jsx(LoadingWrapper, __assign({ className: clsx({
                    hidden: loading,
                    "range-selector": rangeSelector,
                }) }, { children: _jsx("div", __assign({ style: { marginTop: navigator && "-12%" } }, { children: _jsx(PuffLoader, { color: COLORS.BLUE, size: 120 }) })) }))] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;

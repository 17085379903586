var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from "../types";
var initialState = {
    filters: {
        availableStations: [],
        selectedStation: {
            name: "",
            uuid: "",
        },
        startDate: "",
        endDate: "",
    },
    statistics: [],
};
export var atmosphereReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.ATMOSPHERE_SET_AVAILABLE_STATIONS:
            return __assign(__assign({}, state), { filters: __assign(__assign({}, state.filters), { availableStations: action.payload }) });
        case ActionTypes.ATMOSPHERE_SET_SELECTED_STATION:
            return __assign(__assign({}, state), { filters: __assign(__assign({}, state.filters), { selectedStation: action.payload }) });
        case ActionTypes.ATMOSPHERE_SET_START_DATE:
            return __assign(__assign({}, state), { filters: __assign(__assign({}, state.filters), { startDate: action.payload }) });
        case ActionTypes.ATMOSPHERE_SET_END_DATE:
            return __assign(__assign({}, state), { filters: __assign(__assign({}, state.filters), { endDate: action.payload }) });
        case ActionTypes.ATMOSPHERE_SET_STATISTICS:
            return __assign(__assign({}, state), { statistics: action.payload });
        default:
            return state;
    }
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "@visx/axis";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Arc, Circle, Line } from "@visx/shape";
import ChartLayout from "./chartLayout";
var directions = Array.from({ length: 36 }, function (_, i) { return i * 10; });
var cardinalDirections = [0, 90, 180, 270];
var directionLabels = {
    0: "N",
    90: "E",
    180: "S",
    270: "W",
};
var Container = function (_a) {
    var data = _a.data, color = _a.color, gridColor = _a.gridColor, textColor = _a.textColor, strokeWidth = _a.strokeWidth, fontSize = _a.fontSize, legendGap = _a.legendGap, height = _a.height, width = _a.width;
    var minSize = Math.min(height, width);
    var radius = minSize / 3;
    var angleScale = scaleBand({
        domain: directions.map(String),
        range: [0, Math.PI * 2],
    });
    var dataArray = Object.entries(data).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return ({
            direction: parseInt(key, 10) * 10,
            speed: value,
        });
    });
    var maxSpeed = Math.max.apply(Math, __spreadArray([], __read(dataArray.map(function (d) { return d.speed; })), false));
    var normalizedData = dataArray.map(function (d) { return (__assign(__assign({}, d), { speed: (d.speed / maxSpeed) * 100 })); });
    var radiusScale = scaleLinear({
        domain: [0, 100],
        range: [0, radius],
    });
    var colorScale = scaleOrdinal({
        domain: normalizedData.map(function (item) { return item.speed.toString(); }),
        range: [color],
    });
    var radialAxes = directions.map(function (dir) {
        var _a;
        var angleValue = (_a = angleScale(dir.toString())) !== null && _a !== void 0 ? _a : 0;
        var x = radius * Math.sin(angleValue);
        var y = -radius * Math.cos(angleValue);
        return { angle: dir, x: x, y: y };
    });
    var speeds = normalizedData.map(function (d) { return d.speed; });
    var minSpeed = Math.min.apply(Math, __spreadArray([], __read(speeds), false));
    var maxSpeed2 = Math.max.apply(Math, __spreadArray([], __read(speeds), false));
    var meanSpeed = speeds.reduce(function (acc, val) { return acc + val; }, 0) / speeds.length;
    var roundedMean = Math.ceil(meanSpeed);
    var roundedMin = Math.ceil(minSpeed);
    var roundedMax = Math.ceil(maxSpeed2);
    var intermediateValues1 = Math.ceil((roundedMin + roundedMean) / 2);
    var intermediateValues2 = Math.ceil((roundedMean + roundedMax) / 2);
    var scaleValues = [
        roundedMin,
        intermediateValues1,
        roundedMean,
        intermediateValues2,
        roundedMax,
    ];
    var gridRadii = scaleValues.map(function (value) { return radiusScale(value); });
    return (_jsx("svg", __assign({ height: height, preserveAspectRatio: "xMidYMid meet", style: {
            fontSize: "".concat(fontSize, "px"),
            fontWeight: "bold",
            fontFamily: "monospace",
        }, viewBox: "0 0 ".concat(width, " ").concat(height), width: width }, { children: _jsxs(Group, __assign({ left: width / 2, top: height / 2 }, { children: [_jsx(Circle, { fill: "none", r: radius, stroke: gridColor, strokeWidth: strokeWidth }), gridRadii.map(function (radius, index) { return (_jsx(Circle, { fill: "none", r: radius, stroke: gridColor, strokeWidth: strokeWidth }, index)); }), radialAxes.map(function (d, i) { return (_jsx(Line, { stroke: gridColor, strokeWidth: strokeWidth, x1: 0, x2: d.x, y1: 0, y2: d.y }, i)); }), normalizedData.map(function (item, i) {
                    var _a, _b, _c, _d;
                    return (_jsx(Arc, { data: item, endAngle: ((_a = angleScale.bandwidth()) !== null && _a !== void 0 ? _a : 0) +
                            ((_b = angleScale(item.direction.toString())) !== null && _b !== void 0 ? _b : 0), fill: (_c = colorScale(item.speed.toString())) !== null && _c !== void 0 ? _c : "red", innerRadius: 0, outerRadius: radiusScale(item.speed), startAngle: (_d = angleScale(item.direction.toString())) !== null && _d !== void 0 ? _d : 0, stroke: textColor, strokeWidth: 0 }, "arc-".concat(i)));
                }), cardinalDirections.map(function (dir, i) {
                    var _a;
                    var angleValue = (_a = angleScale(dir.toString())) !== null && _a !== void 0 ? _a : 0;
                    var x = (radius + legendGap + 10) * Math.sin(angleValue);
                    var y = -(radius + legendGap + 10) * Math.cos(angleValue);
                    var label = directionLabels[dir];
                    return (_jsx("text", __assign({ fill: label === "N" ? "red" : textColor, style: {
                            textAnchor: "middle",
                            dominantBaseline: "middle",
                        }, x: x, y: y }, { children: label }), i));
                })] })) })));
};
export var PollutionRose = function (props) {
    console.log("data: ", props.data);
    return _jsx(ChartLayout, { Component: Container, componentProps: props });
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "theme";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 25px;\n  width: 25px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"], ["\n  height: 25px;\n  width: 25px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n"])));
var HiddenRadioButton = styled.input.attrs({
    type: "radio",
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  cursor: pointer;\n  position: absolute;\n  opacity: 0;\n"], ["\n  width: 16px;\n  height: 16px;\n  cursor: pointer;\n  position: absolute;\n  opacity: 0;\n"])));
export var Donut = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-color: transparent;\n  pointer-events: none;\n  border: 1px solid ", ";\n  transition: all 100ms;\n"], ["\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-color: transparent;\n  pointer-events: none;\n  border: 1px solid ", ";\n  transition: all 100ms;\n"])), function (props) { return (props.checked ? "#00a7e1" : COLORS.GREY); });
var Handle = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  border-radius: 50%;\n  height: 0;\n  width: 0;\n  background-color: #00a7e1;\n  pointer-events: none;\n  transition: all 100ms;\n  &.checked {\n    height: 10px;\n    width: 10px;\n    transition: all 100ms;\n  }\n"], ["\n  position: absolute;\n  border-radius: 50%;\n  height: 0;\n  width: 0;\n  background-color: #00a7e1;\n  pointer-events: none;\n  transition: all 100ms;\n  &.checked {\n    height: 10px;\n    width: 10px;\n    transition: all 100ms;\n  }\n"])));
/**
 * Radiobutton component styled for persium FE
 */
export var RadioButton = function (_a) {
    var checkedFromProps = _a.checked, onClick = _a.onClick;
    var _b = __read(useState(false), 2), internalChecked = _b[0], setInternalChecked = _b[1];
    var checked = checkedFromProps !== null && checkedFromProps !== void 0 ? checkedFromProps : internalChecked;
    var changeHandler = onClick !== null && onClick !== void 0 ? onClick : (function () { return setInternalChecked(function (checked) { return !checked; }); });
    return (_jsxs(Wrapper, { children: [_jsx(HiddenRadioButton, { checked: checked, onClick: changeHandler }), _jsx(Donut, { checked: checked }), _jsx(Handle, { checked: checked, className: checked && "checked" })] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

/**
 * The different types of widgets a user can create in Historics & Analytics
 */
export var HAWidgetTypes;
(function (HAWidgetTypes) {
    HAWidgetTypes["GRAPH"] = "GRAPH";
    HAWidgetTypes["SWIPE_MAP"] = "SWIPE_MAP";
    HAWidgetTypes["SPECIES_MAP"] = "SPECIES_MAP";
    HAWidgetTypes["ROSE_CHART"] = "ROSE_CHART";
})(HAWidgetTypes || (HAWidgetTypes = {}));
export var HAWidgetSizes;
(function (HAWidgetSizes) {
    /**
     * Takes up the full width of the page
     */
    HAWidgetSizes["FULL"] = "FULL";
    /**
     * Takes up half the width of the page
     */
    HAWidgetSizes["HALF"] = "HALF";
})(HAWidgetSizes || (HAWidgetSizes = {}));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import { store } from "store";
import { ModalProvider } from "styled-react-modal";
import { FadingBackground } from "components";
import { ArrivalPage } from "./ArrivalPage";
import { ErrorBoundary } from "./ErrorBoundary";
import "react-toastify/dist/ReactToastify.css";
export * from "./routes";
export * from "./frame";
/**
 * Central app rendered for the application.  `App` component is a high level
 * component, responsible for wrapper application in various providers, does / should
 * not contain any renderable content
 */
export var App = function () {
    return (_jsxs(Provider, __assign({ store: store }, { children: [_jsx(BrowserRouter, { children: _jsx(ReactErrorBoundary, __assign({ FallbackComponent: ErrorBoundary }, { children: _jsx(ModalProvider, __assign({ backgroundComponent: FadingBackground }, { children: _jsx(ArrivalPage, {}) })) })) }), _jsx(ToastContainer, { style: { zIndex: 10000 } })] })));
};

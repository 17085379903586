var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { LayerGroup } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { HeatmapLayer, IsobarsLayer, MapView, } from "components/organisms";
import { getMapSlice, Maps } from "store/map";
import { Row, Swipe } from "components";
import { useDidMountEffect } from "hooks";
import { ActionCreators } from "store";
import { HAWidgetSizes, TimeRanges } from "models";
/**
 * Swipe Map widget for H&A page which allows side-by-side comparison
 * of two different points in time
 */
export var SwipeMap = function (_a) {
    var id = _a.id, species = _a.species, start = _a.start, end = _a.end, size = _a.size, MapViewProps = _a.MapViewProps;
    var dispatch = useDispatch();
    var mapRef = useRef();
    var bounds = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.analytics.maps.swipeMaps[id]) === null || _a === void 0 ? void 0 : _a.left) === null || _b === void 0 ? void 0 : _b.view) === null || _c === void 0 ? void 0 : _c.bounds; });
    var _b = __read(useState(), 2), leftLayers = _b[0], setLeftLayers = _b[1];
    var _c = __read(useState(), 2), rightLayers = _c[0], setRightLayers = _c[1];
    var startDateString = new Date(start.getTime()).toLocaleDateString();
    var endDateString = new Date(end.getTime()).toLocaleDateString();
    useEffect(function () {
        dispatch(ActionCreators.createSplitMapSlice(id, {
            path: Maps.HASwipeMapWidget,
            widget: { id: id },
        }));
        /**
         * After widget loads, and the entry animation is done, invalidate the size to make
         * sure map components are rendering properly
         */
        setTimeout(function () {
            var _a;
            (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.invalidateSize();
        }, 500);
    }, []);
    /**
     * Effect that mimics the `triggerGetStationInBounds` saga, but with customized
     * logic for a split map
     */
    useDidMountEffect(function () {
        dispatch(ActionCreators.requestSplitMapData({
            left: __assign({ time: start.getTime() }, (species && species !== "aqi"
                ? { pollutant_code: species, "filters[pollutants]": species }
                : { pollutant_code: "", "filters[pollutants]": "" })),
            right: __assign({ time: end.value === TimeRanges.NOW ? null : end.getTime() }, (species && species !== "aqi"
                ? { pollutant_code: species, "filters[pollutants]": species }
                : { pollutant_code: "", "filters[pollutants]": "" })),
        }, {
            path: Maps.HASwipeMapWidget,
            widget: { id: id },
        }));
    }, [bounds, start.value, end.value]);
    var mapLeftState = useSelector(function (state) {
        return getMapSlice(state, {
            path: Maps.HASwipeMapWidget,
            widget: { id: id, side: "left" },
        });
    });
    var mapRightState = useSelector(function (state) {
        return getMapSlice(state, {
            path: Maps.HASwipeMapWidget,
            widget: { id: id, side: "left" },
        });
    });
    if (!mapLeftState || !mapRightState)
        return null;
    return (_jsxs(MapView, __assign({ ref: mapRef, reduxLocation: {
            path: Maps.HASwipeMapWidget,
            widget: { id: id, side: "left" },
        }, search: false, style: { width: "100%", height: "100%", position: "relative" } }, MapViewProps, { children: [_jsxs(LayerGroup, __assign({ ref: function (r) { return setLeftLayers(r); } }, { children: [_jsx(HeatmapLayer, { pane: "left-heatmap", reduxLocation: {
                            path: Maps.HASwipeMapWidget,
                            widget: { id: id, side: "left" },
                        } }), _jsx(IsobarsLayer, { pane: "left-isobars", reduxLocation: {
                            path: Maps.HASwipeMapWidget,
                            widget: { id: id, side: "left" },
                        } })] })), _jsxs(LayerGroup, __assign({ ref: function (r) { return setRightLayers(r); } }, { children: [_jsx(HeatmapLayer, { pane: "right-heatmap", reduxLocation: {
                            path: Maps.HASwipeMapWidget,
                            widget: { id: id, side: "right" },
                        } }), _jsx(IsobarsLayer, { pane: "right-isobars", reduxLocation: {
                            path: Maps.HASwipeMapWidget,
                            widget: { id: id, side: "right" },
                        } })] })), _jsx(Swipe, { leftLayers: leftLayers !== null && leftLayers !== void 0 ? leftLayers : [], rightLayers: rightLayers !== null && rightLayers !== void 0 ? rightLayers : [] }), _jsxs(Row, __assign({ style: {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    justifyContent: "space-between",
                    zIndex: 100000,
                } }, { children: [_jsx("svg", __assign({ width: "350", height: "75", viewBox: "0 0 350 75" }, { children: _jsx("g", __assign({ style: {
                                overflow: "hidden",
                                fontSize: "16",
                                fontFamily: "Inter",
                                fontWeight: "bold",
                            } }, { children: _jsx("text", __assign({ x: "20", y: "55", style: { fill: "white", stroke: "black", strokeWidth: 0.5 } }, { children: startDateString })) })) })), _jsx("svg", __assign({ width: "350", height: "75", viewBox: "0 0 350 75" }, { children: _jsx("g", __assign({ style: {
                                overflow: "hidden",
                                fontSize: "16",
                                fontFamily: "Inter",
                                textAnchor: "end",
                                fontWeight: "bold",
                            } }, { children: _jsx("text", __assign({ x: size === HAWidgetSizes.HALF ? "270" : "330", y: "55", style: { fill: "white", stroke: "black", strokeWidth: 0.5 } }, { children: endDateString })) })) }))] }))] })));
};

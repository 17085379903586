var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Tooltip, useTheme } from "@mui/material";
import { CSSTransition } from "react-transition-group";
import { ToggleButtonGroup } from "./toggleGroup";
var GroupButton = function (_a) {
    var onButtonGroupChange = _a.onButtonGroupChange, selectedToggleButton = _a.selectedToggleButton;
    var nodeRef = useRef(null);
    var theme = useTheme();
    return (_jsxs(Stack, __assign({ width: "100%", alignItems: "center", gap: "10px" }, { children: [_jsx(ToggleButtonGroup, { items: [
                    {
                        text: "Show Units",
                        value: "showUnits",
                    },
                    {
                        text: "Show %",
                        value: "showPercent",
                    },
                    {
                        text: "Show Graphs",
                        value: "showGraphs",
                    },
                ], value: selectedToggleButton, onChange: onButtonGroupChange }), _jsx(CSSTransition, __assign({ unmountOnExit: true, classNames: "percentages-hint-icon", in: selectedToggleButton === "showPercent", nodeRef: nodeRef, timeout: 100 }, { children: _jsx("div", __assign({ className: "percentages-hint-icon", ref: nodeRef }, { children: _jsx(Tooltip, __assign({ style: {
                            color: theme.palette.secondary["800"],
                        }, title: "These percentages represent real-time pollutant concentration\n\t\t\t\t\t\t\tlevels against current national air quality objectives." }, { children: _jsx(Stack, __assign({ flexDirection: "row", alignItems: "center" }, { children: _jsx(InfoOutlinedIcon, { color: "primary" }) })) })) })) }))] })));
};
export default GroupButton;

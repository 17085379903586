/**
 * Util function for converting species code names into subscripted text strings
 */
export var speciesLabels = function (code) {
    switch (code) {
        case "aqi":
            return "AQI";
        case "NO2":
            return "NO₂";
        case "CO2":
            return "CO₂";
        case "O3":
            return "O₃";
        case "NH3":
            return "NH₃";
        case "H2S":
            return "H₂S";
        case "SO2":
            return "SO₂";
        case "VOCS":
            return "VOCₛ";
        default:
            return code;
    }
};
/**
 * Util function for converting unit labels into abbreviated strings for display
 */
export var unitLabels = function (code) {
    switch (code) {
        case "degrees":
            return "°";
        case "C":
            return "°C";
        case "hPascals":
            return "hPa";
        case "ug/m3":
        case "ugm3":
        case "ugm³":
        case "µgm3":
            return "µg/m³";
        case "mg/m3":
            return "mg/m³";
        default:
            return code;
    }
};
/**
 * Util function for converting unit labels into abbreviated strings for display
 */
export var unitMap = function (code) {
    switch (code) {
        case "µg/m3":
        case "ug/m3":
            return "ugm3";
        case "ppm":
            return "ppm";
        default:
            return code;
    }
};
/**
 * Util function to shorten a number to a given amount of decimal points
 */
export var round = function (number, decimals) {
    var _a, _b;
    if (decimals === void 0) { decimals = 2; }
    var decimal = (_b = (_a = number === null || number === void 0 ? void 0 : number.toString()) === null || _a === void 0 ? void 0 : _a.split(".")) === null || _b === void 0 ? void 0 : _b[1];
    if ((decimal === null || decimal === void 0 ? void 0 : decimal.length) > decimals) {
        var asNumber = Number(number);
        return asNumber.toFixed(decimals);
    }
    return number;
};
/**
 * Util function to round up to nearest interval
 *
 * @example
 * roundUpToNearest(3, 10) // result is 10
 *
 * @example
 * roundUpToNearest(12, 10) // result is 20
 *
 * @example
 * roundUpToNearest(44, 25) // result is 50
 */
export var roundUpToNearest = function (value, ceiling) {
    return Math.ceil(value / ceiling) * ceiling;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import { Button, Row, Column } from "components/atoms";
import { BuildGraphSteps } from "./index";
import "react-day-picker/dist/style.css";
var SelectDate = function (_a) {
    var editMode = _a.editMode, onSubmit = _a.onSubmit, selectedSpecies = _a.selectedSpecies, setSelectedDate = _a.setSelectedDate, setStep = _a.setStep;
    var _b = __read(useState(undefined), 2), from = _b[0], setFrom = _b[1];
    var _c = __read(useState(undefined), 2), to = _c[0], setTo = _c[1];
    useEffect(function () {
        if (from && to && from <= to) {
            setSelectedDate({ from: from, to: to });
        }
    }, [from, to]);
    return (_jsxs(Column, __assign({ gap: "10px", style: { width: "100%" } }, { children: [_jsxs(Row, __assign({ justifyContent: "space-between", style: { width: "100%" } }, { children: [_jsxs(Column, __assign({ gap: "10px" }, { children: [_jsx("p", { children: "Select Start Date:" }), _jsx(DayPicker, { mode: "single", selected: from, onSelect: function (date) { return setFrom(date); } })] })), _jsxs(Column, __assign({ gap: "10px" }, { children: [_jsx("p", { children: "Select End Date:" }), _jsx(DayPicker, { mode: "single", selected: to, onSelect: function (date) { return setTo(date); }, disabled: !from ? [{ before: new Date() }] : [{ before: from }] })] }))] })), _jsxs(Row, __assign({ justifyContent: "center", style: { margin: "40px 0 0 0", width: "100%" } }, { children: [_jsx(Button, __assign({ id: "back-button", style: { width: "50%", borderRadius: "18px", marginRight: "30px" }, plump: true, onClick: function () { return setStep(BuildGraphSteps.SelectPods); } }, { children: "Back" })), _jsx(Button, __assign({ id: "continue-button", style: { width: "50%", borderRadius: "18px" }, plump: true, outline: "filled", disabled: !selectedSpecies.length, onClick: onSubmit }, { children: editMode ? "Save Changes" : "Create Graph" }))] }))] })));
};
export default SelectDate;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
/**
 * Customized react-intl-tel-input to address the bug described here:
 * https://github.com/patw0929/react-intl-tel-input/issues/260#issuecomment-629763763
 */
export var IntlPhoneInput = function (props) {
    var setCaretPosition = function (caretPos) {
        var elem = document.getElementsByClassName("intl-tel-input-input")[0];
        if (elem) {
            elem.focus();
            setTimeout(function () {
                elem.setSelectionRange(caretPos, caretPos);
            }, 0);
        }
    };
    return (_jsx(IntlTelInput, __assign({}, props, { inputClassName: props.inputClassName + " intl-tel-input-input", onPhoneNumberChange: function (isValid, value, selectedCountryData, fullNumber, extension) {
            if (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(fullNumber) ||
                fullNumber === "") {
                setCaretPosition(fullNumber.length);
                props.onPhoneNumberChange(isValid, value, selectedCountryData, fullNumber, extension);
            }
        } })));
};

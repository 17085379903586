import { useEffect, useRef } from "react";
/**
 * Custom util hook to get reference to the previous value of a prop before it changed (similar)
 * to prevProps in componentDidUpdate
 */
export var usePrevious = function (value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import BarChart from "./barChart";
import Footer from "./footer";
import GroupButton from "./groupButton";
import { GraphGroup } from "./lineChart/graphGroup";
import ReadingTitle from "./readingTitle";
import StationLatestUpdateDate from "./stationLatestUpdateDate";
import { useSelector, useDispatch } from "react-redux";
import { StationReadings } from "../stationReadings";
import { PodActions } from "store/pods";
import { StationSummary } from "../stationSummary";
import { PersiumLoading } from "components/pages/historics_analytics/widgets/graphs/RoseBlock/persium";
export var StationDetails = function (_a) {
    var _b, _c, _d;
    var podUuid = _a.podUuid;
    var dispatch = useDispatch();
    var stationLatest = useSelector(function (state) { return state.pods.detail.latest; });
    var _f = __read(useState("showUnits"), 2), selectedToggleButton = _f[0], setSelectedToggleButton = _f[1];
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var stationByUUID = useSelector(function (state) { return state.pods.byId; })[podUuid];
    useEffect(function () {
        dispatch(PodActions.requestLatestPodData({ uuid: podUuid, saveto: "current" }));
    }, [podUuid]);
    var aqiType = userInfo.settings.aqi_type;
    var handleButtonGroupChange = function (_e, value) {
        console.log("value: ", value);
        if (value !== selectedToggleButton && value)
            setSelectedToggleButton(function () { return value; });
    };
    // const shouldShowLoading = isGetStationByUUIDLoading || isGetStationLatestLoading;
    var shouldShowLoading = false;
    var AQIs = {
        caqi: 100,
        daqi: 10,
        usaqi: 400, // india_aqi: 500,
    };
    // if (shouldShowLoading) return <PersiumLoading />;
    if (!stationLatest || podUuid !== (stationLatest === null || stationLatest === void 0 ? void 0 : stationLatest.station_uuid))
        return _jsx(PersiumLoading, {});
    return (_jsxs(Stack, __assign({ height: "80vh", overflow: "auto", width: "100%", gap: "8px", justifyContent: "space-between" }, { children: [_jsx(StationSummary, { aqiMax: AQIs[aqiType], AQIType: aqiType, station: stationByUUID, statusColor: stationLatest.color }), _jsxs(Stack, __assign({ gap: ".5rem" }, { children: [_jsx(ReadingTitle, { station: stationByUUID }), _jsx(StationLatestUpdateDate, { stationLatest: stationLatest })] })), _jsx(Stack, { style: {
                    marginTop: "8px",
                } }), _jsx(GroupButton, { selectedToggleButton: selectedToggleButton, onButtonGroupChange: handleButtonGroupChange }), selectedToggleButton === "showUnits" && (_jsx(StationReadings, { stationLatest: stationLatest })), selectedToggleButton === "showGraphs" && (_jsxs(Stack, __assign({ width: "100%", gap: "10px" }, { children: [_jsx(GraphGroup, { availableParams: __spreadArray([
                            {
                                code: "AQI",
                                name: "AQI",
                            }
                        ], __read((stationByUUID.available_parameters.pollutants || [])), false), hasRoseChart: !!((_b = stationByUUID.dev_config) === null || _b === void 0 ? void 0 : _b.is_measuring_wind), speciesGroup: "pollutants", stationUUID: stationByUUID.uuid }), !!((_c = stationByUUID.available_parameters.atmospherics) === null || _c === void 0 ? void 0 : _c.length) && (_jsx(GraphGroup, { availableParams: stationByUUID.available_parameters.atmospherics, hasRoseChart: false, speciesGroup: "atmospherics", stationUUID: stationByUUID.uuid })), !!((_d = stationByUUID.available_parameters.engineerings) === null || _d === void 0 ? void 0 : _d.length) && (_jsx(GraphGroup, { availableParams: stationByUUID.available_parameters.engineerings, hasRoseChart: false, speciesGroup: "engineerings", stationUUID: stationByUUID.uuid }))] }))), selectedToggleButton === "showPercent" && (_jsx(BarChart, { station: stationByUUID, pollutants: stationLatest.pollutants })), _jsx(Footer, { station: stationByUUID })] })));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row } from "components/atoms";
import { DashArrayMap } from "components/organisms";
import styled from "styled-components";
import { speciesLabels } from "../../constants";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(\n    auto-fill,\n    ", "px\n  );\n  position: absolute;\n  left: 24px;\n  flex-wrap: wrap;\n  transition: all 200ms;\n  right: 120px;\n\n  .HALF & {\n    top: 6px;\n    left: 90px;\n    transition: all 200ms;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(\n    auto-fill,\n    ", "px\n  );\n  position: absolute;\n  left: 24px;\n  flex-wrap: wrap;\n  transition: all 200ms;\n  right: 120px;\n\n  .HALF & {\n    top: 6px;\n    left: 90px;\n    transition: all 200ms;\n  }\n"])), function (props) { var _a; return (_a = props.colWidth) !== null && _a !== void 0 ? _a : 320; });
/**
 * Legend component used at the top of graphs to mark what lines mean, in a compact
 * and horizontal fashion.  Similar in spirit but different in execution from the
 * other `Legend.tsx` file in the codebase
 */
export var HorizontalLegend = function (_a) {
    var lines = _a.lines, style = _a.style, colWidth = _a.colWidth;
    if (!lines.length)
        return null;
    return (_jsx(Wrapper, __assign({ className: "horizontal-legend", style: style, colWidth: colWidth }, { children: lines.map(function (line) {
            return (_jsxs(Row, __assign({ margin: "0 20px 0 0", justifyContent: "flex-start" }, { children: [_jsx("svg", __assign({ width: 60, height: line.lineWidth + 2 }, { children: _jsx("line", { x1: "0", y1: "0", x2: "100%", y2: "0", stroke: line.color, strokeWidth: line.lineWidth + 2, strokeDasharray: DashArrayMap[line.dashStyle] }) })), _jsx("span", __assign({ style: { paddingLeft: "4px", fontSize: "12px" } }, { children: speciesLabels(line.title) }))] }), "species-key" + line.title));
        }) })));
};
var templateObject_1;

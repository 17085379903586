var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GraphType } from "store/pods";
import { ActionTypes } from "store/types";
var initialState = null;
/**
 * Reducer to handle all data and view state surrounding the graphs on the
 * dashboard page
 */
export function graphsReducer(state, action) {
    var _a, _b, _c, _d;
    var _e, _f, _g, _h, _j, _k, _l, _m;
    if (state === void 0) { state = initialState; }
    switch (action.meta) {
        case GraphType.Dashboard:
            switch (action.type) {
                case ActionTypes.REQ_POD_PARAM_DATA_MULTI: {
                    var _o = action.payload, species_code = _o.species_code, species_name = _o.species_name;
                    return __assign(__assign({}, state), (_a = {}, _a[species_code] = __assign(__assign({}, ((_e = state === null || state === void 0 ? void 0 : state[species_code]) !== null && _e !== void 0 ? _e : {})), { name: species_name, loading: true }), _a));
                }
                case ActionTypes.SET_POD_PARAM_DATA_MULTI: {
                    var _p = action.payload, species_code = _p.species_code, data = _p.data;
                    return __assign(__assign({}, state), (_b = {}, _b[species_code] = __assign(__assign({}, ((_f = state === null || state === void 0 ? void 0 : state[species_code]) !== null && _f !== void 0 ? _f : {})), { loading: false, pods: data }), _b));
                }
                case ActionTypes.REQ_POD_PARAM_DATA_AVERAGE: {
                    var _q = action.payload, species_code = _q.species_code, species_name = _q.species_name;
                    return __assign(__assign({}, state), (_c = {}, _c[species_code] = __assign(__assign({}, ((_g = state === null || state === void 0 ? void 0 : state[species_code]) !== null && _g !== void 0 ? _g : {})), { name: species_name, average: __assign(__assign({}, ((_j = (_h = state === null || state === void 0 ? void 0 : state[species_code]) === null || _h === void 0 ? void 0 : _h.average) !== null && _j !== void 0 ? _j : {})), { loading: true }) }), _c));
                }
                case ActionTypes.SET_POD_PARAM_DATA_AVERAGE: {
                    var _r = action.payload, species_code = _r.species_code, data = _r.data;
                    return __assign(__assign({}, state), (_d = {}, _d[species_code] = __assign(__assign({}, ((_k = state === null || state === void 0 ? void 0 : state[species_code]) !== null && _k !== void 0 ? _k : {})), { average: __assign(__assign({}, ((_m = (_l = state === null || state === void 0 ? void 0 : state[species_code]) === null || _l === void 0 ? void 0 : _l.average) !== null && _m !== void 0 ? _m : {})), { loading: false, data: data }) }), _d));
                }
                default:
                    return state;
            }
        default:
            switch (action.type) {
                default:
                    return state;
            }
    }
}

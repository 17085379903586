/**
 * Dashboard related actions
 */
import { ActionTypes } from "store/types";
import { createAction } from "typesafe-actions";
export var DashboardActionCreators = {
    /**
     * Action to select multiple pods in the user's selection.  Reducer will automatically
     * remove duplicates
     */
    selectPods: createAction(ActionTypes.SELECT_PODS, function (payload) { return payload; })(),
    /**
     * Action to toggle whether or not a pod is currently selected in the user's selection
     */
    togglePodSelect: createAction(ActionTypes.TOGGLE_SELECT_POD, function (payload) { return payload; })(),
    /**
     * Action to clear all selected pods in the filter
     */
    clearSelectedPods: createAction(ActionTypes.CLEAR_SELECTED_PODS, function () { return undefined; })(),
    /**
     * Action to request a list of locations
     */
    requestLocationList: createAction(ActionTypes.REQ_LOCATION_LIST, function (payload) { return payload; })(),
    /**
     * Action to set list of locations in the store
     */
    setLocationList: createAction(ActionTypes.SET_LOCATION_LIST, function (payload) { return payload; })(),
    /**
     * Action to request a list of pods to be shown in the filters dropdowns
     */
    requestPodList: createAction(ActionTypes.REQ_POD_LIST, function (payload) { return payload; })(),
    /**
     * Action to save list of pods to be shown in the filters dropdowns
     */
    setPodList: createAction(ActionTypes.SET_POD_LIST, function (payload) { return payload; })(),
    /**
     * Action to save timeframe as selected by user in calendar picker
     */
    setTimeframe: createAction(ActionTypes.SET_TIME_FRAME, function (payload) { return payload; })(),
    /**
     * Redux action to request statistics for the user's selection of stations and timeline
     */
    requestTimeframeStats: createAction(ActionTypes.REQ_TIMEFRAME_STATS, function (payload) { return payload; })(),
    /**
     * Redux action to request statistics for the user's selection of stations and timeline
     */
    setTimeframeStats: createAction(ActionTypes.SET_TIMEFRAME_STATS, function (payload) { return payload; })(),
};

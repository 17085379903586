var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled, { keyframes } from "styled-components";
import { FONTS, COLORS } from "theme";
import { shift } from "utils";
import { LoadingSpinner } from "./LoadingSpinner";
var ANIMATION_MS = 700;
var CIRCLE_SIZE = 50;
var ripple = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    opacity: 0.1;\n    transform: scale(0.5);\n  }\n\n  50% {\n    transform: scale(8);\n  }\n\n  80% {\n    opacity: 0;\n  }\n"], ["\n  0% {\n    opacity: 0.1;\n    transform: scale(0.5);\n  }\n\n  50% {\n    transform: scale(8);\n  }\n\n  80% {\n    opacity: 0;\n  }\n"])));
var ButtonWrapper = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: ", ";\n  min-height: ", ";\n  cursor: ", ";\n  overflow: hidden;\n  letter-spacing: 1px;\n  border-radius: 5px;\n  font-family: ", ";\n  transition: all 0.2s cubic-bezier(0.06, 0.67, 0.37, 0.99);\n  color: ", ";\n  background-color: ", ";\n  border: ", ";\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0);\n  transition: all 300ms;\n\n  &:hover {\n    box-shadow: ", ";\n    transition: all 300ms;\n  }\n\n  * {\n    pointer-events: none;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: ", ";\n  min-height: ", ";\n  cursor: ", ";\n  overflow: hidden;\n  letter-spacing: 1px;\n  border-radius: 5px;\n  font-family: ", ";\n  transition: all 0.2s cubic-bezier(0.06, 0.67, 0.37, 0.99);\n  color: ", ";\n  background-color: ", ";\n  border: ", ";\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0);\n  transition: all 300ms;\n\n  &:hover {\n    box-shadow: ", ";\n    transition: all 300ms;\n  }\n\n  * {\n    pointer-events: none;\n  }\n"])), function (props) { return (props.plump ? "36px" : "26px"); }, function (props) { return (props.plump ? "36px" : "26px"); }, function (props) { return (props.disabled ? "auto" : "pointer"); }, FONTS.INTER, function (props) {
    return props.disabled
        ? COLORS.LIGHTGREY
        : props.outline === "bare"
            ? "".concat(props.variant === "error" ? "#FF5B5B" : "#00a7e1")
            : "".concat(props.outline === "filled" ? "#ffffff" : "#00a7e1");
}, function (props) {
    return props.outline === "filled"
        ? props.disabled
            ? COLORS.GREY1
            : "#00a7e1"
        : "transparent";
}, function (props) {
    return props.outline === "bare"
        ? "none"
        : "1px solid ".concat(props.disabled ? COLORS.GREY1 : "#00a7e1");
}, function (props) {
    return props.outline === "bare"
        ? "0px 0px 8px rgba(0, 0, 0, 0)"
        : "0px 0px 8px rgba(0, 0, 0, 0.2)";
});
var ButtonRipple = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: ", "px;\n  left: ", "px;\n  height: ", "px;\n  width: ", "px;\n  background: currentColor;\n  border-radius: 50%;\n  opacity: 0.3;\n  animation-name: ", ";\n  animation-duration: ", "ms;\n  animation-iteration-count: 1;\n  animation-timing-function: ease;\n  pointer-events: none;\n"], ["\n  position: absolute;\n  top: ", "px;\n  left: ", "px;\n  height: ", "px;\n  width: ", "px;\n  background: currentColor;\n  border-radius: 50%;\n  opacity: 0.3;\n  animation-name: ", ";\n  animation-duration: ", "ms;\n  animation-iteration-count: 1;\n  animation-timing-function: ease;\n  pointer-events: none;\n"])), function (props) { return props.y; }, function (props) { return props.x; }, CIRCLE_SIZE, CIRCLE_SIZE, ripple, ANIMATION_MS * 2);
/**
 * Reusable, Persium-Dashboard branded button.  Defaults to use ripple effect, can
 * set `ripple={false}` in props to remove this behavior.  Have various variants
 * and outline options, all of which can be overridden with standard `style` props,
 * or by re-styling with styled components
 *
 * Written as a good ol' class component to keep ripples state working properly
 */
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            ripples: [],
        };
        _this.id = 0;
        /**
         * Callback to create a ripple effects when clicking the button
         */
        _this.handleRipple = function (e) {
            var box = e.target.getBoundingClientRect();
            var centerRipple = _this.props.centerRipple;
            var id = ++_this.id;
            _this.setState({
                ripples: __spreadArray(__spreadArray([], __read(_this.state.ripples), false), [
                    {
                        id: id,
                        x: centerRipple
                            ? box.width / 2 - CIRCLE_SIZE / 2
                            : e.clientX - box.left - CIRCLE_SIZE / 2,
                        y: centerRipple
                            ? box.height / 2 - CIRCLE_SIZE / 2
                            : e.clientY - box.top - CIRCLE_SIZE / 2,
                    },
                ], false),
            });
            setTimeout(function () {
                _this.setState({
                    ripples: shift(_this.state.ripples),
                });
            }, ANIMATION_MS);
        };
        _this.handleClick = function (e) {
            var _a = _this.props, _b = _a.ripple, ripple = _b === void 0 ? true : _b, onClick = _a.onClick;
            if (onClick) {
                onClick(e);
            }
            if (ripple) {
                _this.handleRipple(e);
            }
        };
        return _this;
    }
    Button.prototype.render = function () {
        var _a = this.props, ripple = _a.ripple, centerRipple = _a.centerRipple, children = _a.children, onClick = _a.onClick, loading = _a.loading, rest = __rest(_a, ["ripple", "centerRipple", "children", "onClick", "loading"]);
        return (_jsxs(ButtonWrapper, __assign({ onClick: this.handleClick }, rest, { children: [loading ? _jsx(LoadingSpinner, { style: { height: "100%" } }) : children, this.state.ripples.map(function (ripple) { return (_jsx(ButtonRipple, { x: ripple.x, y: ripple.y }, ripple.id)); })] })));
    };
    return Button;
}(React.Component));
export { Button };
var templateObject_1, templateObject_2, templateObject_3;

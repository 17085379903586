var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from "axios";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "store/types";
import { ActionCreators } from "store";
import { formatUTC, sensibleInterval } from "utils";
import { POD_COMPARISON_COLORS } from "theme";
import { DASH_STYLES } from "components";
import { API_BASE, API_FAV, SUBSCRIBED_STATIONS_LIST } from "../../constants";
import { GraphType } from "./actions";
/**
 * The following variables are declared at the module level to act as
 * constant reference once created, without polluting react/redux state -
 * may need to be moved into a proper state context if causes bugs
 */
/**
 * Utility objects defined at the module-level so help coordinate colors of
 * graph lines used across various H&A widgets, so that same pods have same colors
 * across widgets
 */
export var podLineColorMap = {};
/**
 * Utility objects defined at the module-level so help coordinate dash styles of
 * graph lines used across various H&A widgets, so that same species have same dash types
 * across widgets.
 */
export var speciesDashStyleMap = {};
var lineColorIndex = 0;
var dashStyleIndex = 0;
/**
 * Utility saga for calling pod parameter route and returning the data from the API,
 * calls for a single parameter on a single pod, returns a single `ParamDataResponse`
 */
function getPodParamData(requestParams) {
    var uuid, species_code, start, end, minutesFromPayload, page, widgetUuid, minutes, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                uuid = requestParams.uuid, species_code = requestParams.species_code, start = requestParams.start, end = requestParams.end, minutesFromPayload = requestParams.minutes, page = requestParams.page, widgetUuid = requestParams.widgetUuid;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                minutes = minutesFromPayload !== null && minutesFromPayload !== void 0 ? minutesFromPayload : sensibleInterval(start, end);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/v2/station/").concat(uuid, "/parameter/").concat(species_code, "/data"), {
                        params: __assign({ minutes: minutes, start: start, end: end }, (page ? { page: page } : {})),
                    })];
            case 2:
                response = (_a.sent());
                return [4 /*yield*/, put(ActionCreators.updateError({ uuid: widgetUuid, errorMessage: undefined }))];
            case 3:
                _a.sent();
                return [2 /*return*/, { data: response.data.data }];
            case 4:
                e_1 = _a.sent();
                return [4 /*yield*/, put(ActionCreators.updateError({ uuid: widgetUuid, errorMessage: "Error" }))];
            case 5:
                _a.sent();
                return [2 /*return*/, { error: e_1 }];
            case 6: return [2 /*return*/];
        }
    });
}
/**
 * Utiliy saga for getting pod parameter data for all pods for an array of pod uuids
 * for a single parameter. Returns data as an array of `ParamDataResponse` objects
 */
function getAllPodsForParam(instructions) {
    var pods, species_code, start, end, minutes, widgetUuid, allParamsData, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                pods = instructions.pods, species_code = instructions.species_code, start = instructions.start, end = instructions.end, minutes = instructions.minutes, widgetUuid = instructions.widgetUuid;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, all(pods.map(function (pod) {
                        return call(getPodParamData, {
                            uuid: pod.uuid,
                            species_code: species_code,
                            start: start,
                            end: end,
                            minutes: minutes,
                            widgetUuid: widgetUuid,
                        });
                    }))];
            case 2:
                allParamsData = (_a.sent());
                return [2 /*return*/, allParamsData];
            case 3:
                e_2 = _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Utility saga for calling pod summary route and retrieving pod summary for a single pod
 */
function getPodSummary(uuid) {
    var response, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/v2/station/").concat(uuid))];
            case 1:
                response = (_a.sent());
                return [2 /*return*/, response.data.data];
            case 2:
                e_3 = _a.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}
/**
 * Saga to get latest pod data for a given pod
 */
function handleGetLatestPodData(action) {
    var response, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/v2/station/").concat(action.payload.uuid, "/latest"))];
            case 1:
                response = (_a.sent());
                return [4 /*yield*/, put(ActionCreators.setLatestPodData({
                        data: response.data.data,
                        saveto: action.payload.saveto,
                    }))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_4 = _a.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to get pod param data for a single pod and single param, given the uuid
 * and parameter code.  Used to get pollutant data for currently viewed pod
 * and pods being compared
 */
function handleGetPodParamData(action) {
    var _a, uuid, species_code, speciesGroup, start, end, minutes, page, widgetUuid, yesterday, tomorrow, startParam, endParam, response, e_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, uuid = _a.uuid, species_code = _a.species_code, speciesGroup = _a.speciesGroup, start = _a.start, end = _a.end, minutes = _a.minutes, page = _a.page, widgetUuid = _a.widgetUuid;
                yesterday = new Date();
                tomorrow = new Date();
                yesterday.setHours(yesterday.getHours() - 24);
                tomorrow.setHours(tomorrow.getHours() + 24);
                startParam = start !== null && start !== void 0 ? start : formatUTC(yesterday);
                endParam = end !== null && end !== void 0 ? end : formatUTC(tomorrow);
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 5]);
                return [4 /*yield*/, call(getPodParamData, {
                        uuid: uuid,
                        species_code: species_code,
                        start: startParam,
                        end: endParam,
                        minutes: minutes,
                        page: page,
                        widgetUuid: widgetUuid,
                    })];
            case 2:
                response = _b.sent();
                return [4 /*yield*/, put(ActionCreators.setPodParameterData({
                        uuid: uuid,
                        species_code: species_code,
                        speciesGroup: speciesGroup,
                        data: response.data,
                    }, GraphType.PodDetail))];
            case 3:
                _b.sent();
                return [3 /*break*/, 5];
            case 4:
                e_5 = _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Utility saga to call to get pod parameter data for a single parameter averaged across an array of pods
 */
function getAveragePodsParamData(_a) {
    var sample, timeframeFromStore, station_uuids, start, end, minutes, result, e_6;
    var uuids = _a.uuids, species_code = _a.species_code, minutesFromPayload = _a.minutes, startFromPayload = _a.start, endFromPayload = _a.end;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                return [4 /*yield*/, select(function (state) {
                        return state.user.info.data.settings.Dashboard.dashboard.sample;
                    })];
            case 1:
                sample = _b.sent();
                return [4 /*yield*/, select(function (state) { return state.dashboard.filters.timeframe; })];
            case 2:
                timeframeFromStore = _b.sent();
                station_uuids = uuids !== null && uuids !== void 0 ? uuids : sample.map(function (pod) { return pod.uuid; });
                start = startFromPayload !== null && startFromPayload !== void 0 ? startFromPayload : timeframeFromStore[0];
                end = endFromPayload !== null && endFromPayload !== void 0 ? endFromPayload : timeframeFromStore[1];
                minutes = minutesFromPayload !== null && minutesFromPayload !== void 0 ? minutesFromPayload : sensibleInterval(start, end);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/v2/parameter/").concat(species_code, "/data"), {
                        params: {
                            station_uuids: station_uuids,
                            start: start,
                            end: end,
                            minutes: minutes,
                        },
                    })];
            case 3:
                result = (_b.sent());
                return [2 /*return*/, result.data.data];
            case 4:
                e_6 = _b.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [2 /*return*/, { error: e_6, data: { items: [], page: 1, total: 10 } }];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Saga to handle calling to get pod summaries for multiple pods
 */
function handleGetPodSummaries(action) {
    var result, normalized_1, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, all(action.payload.map(function (uuid) {
                        return call(getPodSummary, uuid);
                    }))];
            case 1:
                result = _a.sent();
                normalized_1 = {};
                result.forEach(function (podSummary) {
                    normalized_1[podSummary.uuid] = podSummary;
                });
                return [4 /*yield*/, put(ActionCreators.setPodSummaries(normalized_1))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_7 = _a.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request list of user's favorited pods
 */
function handleGetFavorites() {
    var response, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(axios.get, API_FAV)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(ActionCreators.setFavPods(response.data.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_8 = _a.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request pod gets added to users favorite list
 */
function handleAddFavorite(action) {
    var e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                /* Request pod gets added to favorites */
                return [4 /*yield*/, call(axios.post, "".concat(API_BASE, "/station/").concat(action.payload, "/like"))];
            case 1:
                /* Request pod gets added to favorites */
                _a.sent();
                /* Call for list again */
                return [4 /*yield*/, put(ActionCreators.requestFavPods())];
            case 2:
                /* Call for list again */
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_9 = _a.sent();
                // Need error handling in this application!
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request pod gets deleted from users favorite list
 */
function handleDeleteFavorite(action) {
    var e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                /* Request pod gets delete from favorites */
                return [4 /*yield*/, call(axios.delete, "".concat(API_BASE, "/station/").concat(action.payload, "/like"))];
            case 1:
                /* Request pod gets delete from favorites */
                _a.sent();
                /* Call for list again */
                return [4 /*yield*/, put(ActionCreators.requestFavPods())];
            case 2:
                /* Call for list again */
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_10 = _a.sent();
                // Need error handling in this application!
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request list of user's owned pods
 */
function handleGetOwnedPods() {
    var response, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/station/owned"))];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(ActionCreators.setOwnedPods(response.data.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_11 = _a.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request list of user's favorited pods
 */
function handleGetSubscribed() {
    var response, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(axios.get, SUBSCRIBED_STATIONS_LIST)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, put(ActionCreators.setSubscribedPods(response.data.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_12 = _a.sent();
                // Need error handling in this application!
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request pod gets added to users subscribed list
 */
function handleAddSuscribedPod(action) {
    var e_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                /* Request pod gets added to subscribed */
                return [4 /*yield*/, call(axios.post, "".concat(API_BASE, "/station/").concat(action.payload.uuid, "/subscribe"), action.payload.body)];
            case 1:
                /* Request pod gets added to subscribed */
                _a.sent();
                /* Call for list again */
                return [4 /*yield*/, put(ActionCreators.requestSubscribedPods())];
            case 2:
                /* Call for list again */
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_13 = _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request pod gets deleted from users subscribed list
 */
function handleDeleteSuscribedPod(action) {
    var e_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                /* Request pod gets deleted from subscribed */
                return [4 /*yield*/, call(axios.delete, "".concat(API_BASE, "/station/").concat(action.payload, "/subscribe"))];
            case 1:
                /* Request pod gets deleted from subscribed */
                _a.sent();
                /* Call for list again */
                return [4 /*yield*/, put(ActionCreators.requestSubscribedPods())];
            case 2:
                /* Call for list again */
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_14 = _a.sent();
                // Need error handling in this application!
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to handle calling to get param data for multiple pods at once
 */
function handleGetMultiplePodsParamData(action) {
    var _a, uuids_1, species_code_1, startFromPayload, endFromPayload, minutes_1, widgetUuid_1, sample_1, timeframeFromStore, start_1, end_1, dataResponses, results, e_15;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 6]);
                _a = action.payload, uuids_1 = _a.uuids, species_code_1 = _a.species_code, startFromPayload = _a.start, endFromPayload = _a.end, minutes_1 = _a.minutes, widgetUuid_1 = _a.widgetUuid;
                return [4 /*yield*/, select(function (state) {
                        return state.user.info.data.settings.Dashboard.dashboard.sample;
                    })];
            case 1:
                sample_1 = _b.sent();
                return [4 /*yield*/, select(function (state) { return state.dashboard.filters.timeframe; })];
            case 2:
                timeframeFromStore = _b.sent();
                start_1 = startFromPayload !== null && startFromPayload !== void 0 ? startFromPayload : timeframeFromStore[0];
                end_1 = endFromPayload !== null && endFromPayload !== void 0 ? endFromPayload : timeframeFromStore[1];
                dataResponses = [];
                return [4 /*yield*/, all(uuids_1.map(function (uuid) {
                        return call(getPodParamData, {
                            uuid: uuid,
                            species_code: species_code_1,
                            start: start_1,
                            end: end_1,
                            minutes: minutes_1,
                            widgetUuid: widgetUuid_1,
                        });
                    }))];
            case 3:
                /** Parameter data for the timeframe specified by the user */
                dataResponses = _b.sent();
                results = dataResponses.reduce(function (prev, curr, idx) {
                    var _a;
                    var uuid = uuids_1[idx];
                    var name = sample_1.find(function (pod) { return pod.uuid === uuid; }).name;
                    return __assign(__assign({}, prev), (_a = {}, _a[uuid] = __assign({ name: name }, curr), _a));
                }, {});
                return [4 /*yield*/, put(ActionCreators.setMultiplePodsParameterData({ species_code: species_code_1, data: results }, action.meta))];
            case 4:
                _b.sent();
                return [3 /*break*/, 6];
            case 5:
                e_15 = _b.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
/**
 * Handles getting pollutant data for all selected pollutants for all selected pods
 *
 * This fairly complex saga formulates requests
 * to retrieve data for each pod involved, for all pollutant parameters available for that pod.
 * It runs 2 layers of parallel requests.  The first parallel layer is all params being queried in
 * parallel (`getAllPodsForParam` mapped over all params).  Within each param, we run parallel
 * requests for each pod of interest (`getPodParamData` mapped over all selected
 * pod, which happens *within* `getAllPodsForParam`).  The result is an object whose keys are the
 * parameter codes being requested, and whose values are arrays of data.  The array has as many
 * entries as pods being queried for data (1 or multiple for comparison reports).  Each pod array
 * item has either a data or error property.
 */
function handleGet2DPodsParamData(action) {
    var _a, dateRange, endFromPayload, minutes, pods, species, startFromPayload, widgetId, widgetUuid, start_2, end_2, dataResponses, individualLines, averagesDataResponses, averageLines, lines, e_16;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, dateRange = _a.dateRange, endFromPayload = _a.end, minutes = _a.minutes, pods = _a.pods, species = _a.species, startFromPayload = _a.start, widgetId = _a.widgetId, widgetUuid = _a.widgetUuid;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 7]);
                start_2 = (dateRange === null || dateRange === void 0 ? void 0 : dateRange.start) || formatUTC(new Date(startFromPayload));
                end_2 = (dateRange === null || dateRange === void 0 ? void 0 : dateRange.end) || formatUTC(endFromPayload);
                return [4 /*yield*/, all(species.map(function (_a) {
                        var species_code = _a.species_code;
                        return call(getAllPodsForParam, {
                            pods: pods,
                            species_code: species_code,
                            start: start_2,
                            end: end_2,
                            minutes: minutes,
                            widgetId: widgetId,
                            widgetUuid: widgetUuid,
                        });
                    }))];
            case 2:
                dataResponses = _b.sent();
                individualLines = dataResponses
                    .map(function (speciesGroup, i) {
                    // Set species dash style
                    if (!speciesDashStyleMap[species[i].species_code]) {
                        var freshest = DASH_STYLES[dashStyleIndex % (DASH_STYLES.length - 1)];
                        dashStyleIndex++;
                        speciesDashStyleMap[species[i].species_code] = freshest;
                    }
                    return speciesGroup.map(function (podDataResponse, j) {
                        // Set pod color
                        if (!podLineColorMap[pods[j].uuid]) {
                            var freshest = POD_COMPARISON_COLORS[lineColorIndex % (POD_COMPARISON_COLORS.length - 1)].primary;
                            lineColorIndex++;
                            podLineColorMap[pods[j].uuid] = freshest;
                        }
                        return {
                            pod: pods[j],
                            species: species[i],
                            data: podDataResponse,
                            color: podLineColorMap[pods[j].uuid],
                            dash: speciesDashStyleMap[species[i].species_code],
                        };
                    });
                })
                    .flat();
                averagesDataResponses = [];
                if (!(pods.length > 1)) return [3 /*break*/, 4];
                return [4 /*yield*/, all(species.map(function (_a) {
                        var species_code = _a.species_code, species_name = _a.species_name;
                        return call(getAveragePodsParamData, {
                            uuids: pods.map(function (p) { return p.uuid; }),
                            species_code: species_code,
                            species_name: species_name,
                            start: start_2,
                            minutes: minutes,
                            end: end_2,
                        });
                    }))];
            case 3:
                averagesDataResponses = _b.sent();
                _b.label = 4;
            case 4:
                averageLines = averagesDataResponses.map(function (speciesAverageResponse, i) { return ({
                    pod: {
                        name: "Average",
                        uuid: "AVERAGE",
                    },
                    species: species[i],
                    data: speciesAverageResponse.error
                        ? speciesAverageResponse
                        : { data: speciesAverageResponse },
                    color: POD_COMPARISON_COLORS[0].primary,
                    dash: speciesDashStyleMap[species[i].species_code],
                }); });
                lines = __spreadArray(__spreadArray([], __read(individualLines), false), __read(averageLines), false);
                return [4 /*yield*/, put(ActionCreators.set2DParameterData({ widgetId: widgetId, lines: lines }, action.meta))];
            case 5:
                _b.sent();
                return [3 /*break*/, 7];
            case 6:
                e_16 = _b.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
/**
 * Saga to handle calling for pod parameter data averaged across an array of pods
 */
function handleGetAveragePodsParamData(action) {
    var species_code, results, e_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                species_code = action.payload.species_code;
                return [4 /*yield*/, getAveragePodsParamData(action.payload)];
            case 1:
                results = _a.sent();
                return [4 /*yield*/, put(ActionCreators.setAveragePodParameterData({ species_code: species_code, data: results }, action.meta))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_17 = _a.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function watchHandleGetPodSummaries() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_POD_SUMMARIES, handleGetPodSummaries)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetLatestPodData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_POD_LATEST_DATA, handleGetLatestPodData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetFavorites() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_FAV_PODS, handleGetFavorites)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleAddFavorite() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_ADD_FAV_POD, handleAddFavorite)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleDeleteFavorite() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_DELETE_FAV_POD, handleDeleteFavorite)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetOwnedPods() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_OWNED_PODS, handleGetOwnedPods)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetSubscribed() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_SUBSCRIBED_PODS, handleGetSubscribed)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleAddSuscribedPod() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_ADD_SUBSCRIBED_POD, handleAddSuscribedPod)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleDeleteSuscribedPod() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_DELETE_SUBSCRIBED_POD, handleDeleteSuscribedPod)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetPodParamData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_POD_PARAM_DATA, handleGetPodParamData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetMultiplePodsParamData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_POD_PARAM_DATA_MULTI, handleGetMultiplePodsParamData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetAveragePodsParamData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_POD_PARAM_DATA_AVERAGE, handleGetAveragePodsParamData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGet2DPodsParamData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_POD_PARAM_DATA_2D, handleGet2DPodsParamData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function podSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    fork(watchHandleGetPodSummaries),
                    fork(watchHandleGetLatestPodData),
                    fork(watchHandleGetFavorites),
                    fork(watchHandleAddFavorite),
                    fork(watchHandleDeleteFavorite),
                    fork(watchHandleGetOwnedPods),
                    fork(watchHandleGetSubscribed),
                    fork(watchHandleAddSuscribedPod),
                    fork(watchHandleDeleteSuscribedPod),
                    fork(watchHandleGetPodParamData),
                    fork(watchHandleGetMultiplePodsParamData),
                    fork(watchHandleGet2DPodsParamData),
                    fork(watchHandleGetAveragePodsParamData),
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

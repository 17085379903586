var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, IconButton, Modal, TextInput } from "components/atoms";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { useMultiViewStore } from "store/multi-view/store";
import { createView, deleteView, fetchViews, updateView, } from "store/multi-view/api";
import { useViewId } from "./widgets";
export var ManageViews = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen;
    var _b = useMultiViewStore(), views = _b.views, handleViews = _b.handleViews;
    var _c = __read(useState(""), 2), view = _c[0], setView = _c[1];
    var _d = __read(useState(""), 2), editUuid = _d[0], setEditUuid = _d[1];
    var _e = __read(useState("add"), 2), mode = _e[0], setMode = _e[1];
    var _f = useViewId(), selectedView = _f.selectedView, setSelectedView = _f.setSelectedView;
    var refetchViews = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetchViews()];
                case 1:
                    response = _b.sent();
                    handleViews((_a = response.data.data) !== null && _a !== void 0 ? _a : []);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(mode === "add" && view)) return [3 /*break*/, 2];
                    return [4 /*yield*/, createView({ body: { name: view } })];
                case 1:
                    _a.sent();
                    setEditUuid("");
                    setView("");
                    refetchViews();
                    return [3 /*break*/, 4];
                case 2:
                    if (!(mode === "edit" && view)) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateView({ uuid: editUuid, body: { name: view } })];
                case 3:
                    _a.sent();
                    setView("");
                    setEditUuid("");
                    refetchViews();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteView = function (uuid) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (uuid === selectedView.uuid) {
                        setSelectedView("");
                    }
                    return [4 /*yield*/, deleteView({ uuid: uuid })];
                case 1:
                    _a.sent();
                    refetchViews();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Modal, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, id: "build-ha-graph-modal", title: "Manage Views", wrapperStyle: {
            minWidth: "400px",
            maxWidth: "600px",
            minHeight: "400px",
            maxHeight: "600px",
        } }, { children: _jsxs("div", __assign({ style: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "24px",
                flexDirection: "column",
                height: "100%",
            } }, { children: [_jsxs("div", __assign({ style: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "24px",
                        width: "100%",
                    } }, { children: [_jsx(TextInput, { placeholder: "view name", value: view, onChange: function (e) { return setView(e.target.value); }, style: { margin: 0 } }), _jsx(Button, __assign({ style: { height: "34px", padding: "0 16px" }, disabled: !view, onClick: handleSubmit }, { children: mode === "add" ? "Add" : "Edit" }))] })), _jsx("div", __assign({ style: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "24px",
                        flexDirection: "column",
                        width: "100%",
                        overflowY: "auto",
                        maxHeight: "400px",
                    } }, { children: views.map(function (view) { return (_jsxs("div", __assign({ style: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid #ccc",
                            width: "100%",
                        } }, { children: [_jsx("p", __assign({ style: { fontWeight: 600 } }, { children: view.name })), _jsxs("div", __assign({ style: { display: "flex", gap: "8px" } }, { children: [_jsx(IconButton, __assign({ onClick: function () { return handleDeleteView(view.uuid); } }, { children: _jsx(FiTrash2, { size: "24px", color: "red" }) })), _jsx(IconButton, __assign({ onClick: function () {
                                            setView(view.name);
                                            setMode("edit");
                                            setEditUuid(view.uuid);
                                        } }, { children: _jsx(FiEdit3, { size: "24px" }) }))] }))] }), view.uuid)); }) }))] })) })));
};

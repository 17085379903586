import { ActionTypes } from "store/types";
import { createAction } from "typesafe-actions";
export var AtmosphereActionCreators = {
    fetchAvailableAtmosphereStations: createAction(ActionTypes.ATMOSPHERE_FETCH_AVAILABLE_STATIONS, function () { })(),
    setAvailableAtmosphereStations: createAction(ActionTypes.ATMOSPHERE_SET_AVAILABLE_STATIONS, function (payload) { return payload; })(),
    /**
     * Action to set the selected station in the filters
     */
    setSelectedStation: createAction(ActionTypes.ATMOSPHERE_SET_SELECTED_STATION, function (payload) { return payload; })(),
    /**
     * Action to set the start date in the filters
     */
    setAtmosphereFilterStartDate: createAction(ActionTypes.ATMOSPHERE_SET_START_DATE, function (payload) { return payload; })(),
    /**
     * Action to set the end date in the filters
     */
    setAtmosphereFilterEndDate: createAction(ActionTypes.ATMOSPHERE_SET_END_DATE, function (payload) { return payload; })(),
    fetchAvailableStations: createAction(ActionTypes.ATMOSPHERE_FETCH_AVAILABLE_STATIONS, function (payload) { return payload; })(),
    fetchAtmosphereStatistics: createAction(ActionTypes.ATMOSPHERE_FETCH_STATISTICS, function (payload) { return payload; })(),
    setAtmosphereStatistics: createAction(ActionTypes.ATMOSPHERE_SET_STATISTICS, function (payload) { return payload; })(),
};

import { ActionTypes } from "store/types";
/**
 * Actions related to Historics and Analytics
 */
export var AnalyticsActionCreators = {
    /**
     * Redux action to create a slice of state for a splitmap, which needs to happen
     * before taking trying to store any data for that splitmap
     */
    createSplitMapSlice: function (payload, meta) { return ({
        type: ActionTypes.CREATE_SPLITMAP_SLICE,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action -> saga to call API to request stations data within current map bounds
     */
    requestSplitMapData: function (payload, meta) { return ({
        type: ActionTypes.REQ_SPLIT_MAP_DATA,
        payload: payload,
        meta: meta,
    }); },
};

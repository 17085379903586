var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { useMap } from "react-leaflet";
// Using the @changey version because the regular version does not work with react-leaflet V4
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import "leaflet.markercluster";
import L from "leaflet";
import { COLORS } from "theme";
import { PodMarker } from "../components/PodMarker";
import "@changey/react-leaflet-markercluster/dist/styles.min.css";
/**
 * Map layer containing all the pod markers, as well as their self-contained event handlers
 *
 * Wrapper component for mapping data => `<PodMarker />` components
 */
export var PodMarkers = function (_a) {
    var eventHandlers = _a.eventHandlers, getHaloColor = _a.getHaloColor, filtered = _a.filtered, aqi_type = _a.aqi_type;
    var map = useMap();
    var zoom = map.getZoom();
    var podsById = useSelector(function (state) { return state.pods.byId; });
    var activeFilters = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.user.info) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.settings) === null || _c === void 0 ? void 0 : _c.filters; });
    var activeFilterCodes = Object.values(activeFilters).flat();
    var podSummaries = Object.values(podsById);
    var pods = activeFilterCodes.length && filtered
        ? podSummaries.filter(function (pod) {
            var podFilterCodes = Object.values(pod.available_parameters)
                .flat()
                .map(function (species) { return species.code; });
            return activeFilterCodes.every(function (selectedFilterCode) {
                return podFilterCodes.includes(selectedFilterCode);
            });
        })
        : podSummaries;
    /** If there's no pod marker data in the store, this component will not return anything */
    if (!podSummaries || !podSummaries.length) {
        return null;
    }
    var podMarkers = pods.map(function (podSummary) { return (_jsx(PodMarker, { summary: podSummary, eventHandlers: eventHandlers, getHaloColor: getHaloColor, aqi_type: aqi_type }, Math.random())); });
    // return <>{podMarkers}</>;
    return zoom < 12 ? (_jsx(_Fragment, { children: podMarkers })) : (_jsx(MarkerClusterGroup, __assign({ showCoverageOnHover: false, zoomToBoundsOnClick: false, spiderfyOnEveryZoom: true, maxClusterRadius: 50, spiderLegPolylineOptions: { color: COLORS.LIGHTGREY, weight: 0.75 }, iconCreateFunction: function (cluster) {
            var markers = cluster.getAllChildMarkers();
            return L.divIcon({
                html: markers.length,
                className: "marker-cluster",
                iconSize: L.point(40, 40),
            });
        } }, { children: podMarkers })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSelector } from "react-redux";
import merge from "lodash.merge";
import { mergeArrays } from "utils";
import { DefaultSettingsByLocale } from "../constants";
/**
 * Convenience function to extract settings shorthands used in the Preferences UI
 * from the more complex and not user-fiendly settings API object
 */
export var extractSettingsShorthands = function (settings) {
    var _a, _b, _c, _d, _e, _f;
    var unit = settings.unit, timezone = settings.timezone;
    var pollutant_unit = (_b = (_a = unit === null || unit === void 0 ? void 0 : unit.pollutants) === null || _a === void 0 ? void 0 : _a.find(function (p) { return p.code === "all"; })) === null || _b === void 0 ? void 0 : _b.value;
    var wind_speed_unit = (_d = (_c = unit === null || unit === void 0 ? void 0 : unit.atmospherics) === null || _c === void 0 ? void 0 : _c.find(function (a) { return a.code === "windHoriz"; })) === null || _d === void 0 ? void 0 : _d.value;
    var temperature_unit = (_f = (_e = unit === null || unit === void 0 ? void 0 : unit.atmospherics) === null || _e === void 0 ? void 0 : _e.find(function (a) { return a.code === "temperature"; })) === null || _f === void 0 ? void 0 : _f.value;
    return {
        language: settings.language,
        aqi_type: settings.aqi_type,
        aq_standard: settings.aq_standard,
        timezone: timezone,
        pollutant_unit: pollutant_unit,
        wind_speed_unit: wind_speed_unit,
        temperature_unit: temperature_unit,
    };
};
/**
 * Convenience function to transform proper settings object from API (and default) into
 * data structure that is more friendly to use throughout the frontend.  Teases out specific pollutant
 * and atmospherics settings from their respective settings arrays, and puts them as properties of
 * the useSettings object for easy use and DRYer code.
 */
var appendSettingsShorthands = function (settings) {
    var shorthands = extractSettingsShorthands(settings);
    return __assign(__assign({}, settings), shorthands);
};
/**
 * Convenience function for folding shorthand properties back into their respective places within
 * the real settings object
 */
export var settingsShorthandsToSettings = function (settings, shorthands) {
    var pollutant_unit = shorthands.pollutant_unit, wind_speed_unit = shorthands.wind_speed_unit, temperature_unit = shorthands.temperature_unit, timezone = shorthands.timezone, language = shorthands.language, aqi_type = shorthands.aqi_type, aq_standard = shorthands.aq_standard;
    var pollutantUnitSetting = {
        name: "All",
        code: "all",
        value: pollutant_unit,
    };
    var windspeedUnitSetting = {
        name: "Wind",
        code: "windHoriz",
        value: wind_speed_unit,
    };
    var termpatureUnitSetting = {
        name: "Temperature",
        code: "temperature",
        value: temperature_unit,
    };
    var pollutants = mergeArrays(settings.unit.pollutants, [pollutantUnitSetting], "code");
    var atmospherics = mergeArrays(settings.unit.atmospherics, [windspeedUnitSetting, termpatureUnitSetting], "code");
    return {
        aqi_type: aqi_type,
        aq_standard: aq_standard,
        language: language,
        timezone: timezone,
        unit: __assign(__assign({}, settings.unit), { pollutants: pollutants, atmospherics: atmospherics }),
    };
};
/**
 * Utility hook to choose user settings / preferences, based on whether or not they are logged in.
 * If user is logged in, settings are pulled from `state.user.info.settings`, which are retrieved
 * from the back end.  If user is not logged in, default settings will be used
 */
export var useSettings = function () {
    var _a, _b, _c;
    var userInfo = useSelector(function (state) { return state.user.info; });
    var userLocale = useSelector(function (state) { return state.user.locale; });
    var defaults = (_a = DefaultSettingsByLocale[userLocale]) !== null && _a !== void 0 ? _a : DefaultSettingsByLocale.en;
    var settings = merge({}, appendSettingsShorthands(defaults), ((_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.data) === null || _b === void 0 ? void 0 : _b.settings)
        ? appendSettingsShorthands((_c = userInfo === null || userInfo === void 0 ? void 0 : userInfo.data) === null || _c === void 0 ? void 0 : _c.settings)
        : {});
    return settings;
};
/**
 * Util function for retrieving dashboard settings (without also grabbing the rest of the user object)
 */
export var useDashboardSettings = function () {
    var dashboardSettings = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.user.info) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.settings) === null || _c === void 0 ? void 0 : _c.Dashboard; });
    return dashboardSettings;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useSelector } from "react-redux";
import { Button, Row, Prompt, PillButton } from "components/atoms";
import uniqBy from "lodash.uniqby";
import { backGround } from "utils";
import { BuildGraphSteps } from ".";
import { speciesLabels } from "../../../../../../constants";
/**
 * The select parameters step of the build graph modal for the H&A section
 */
export var SelectSpecies = function (_a) {
    var selectedPods = _a.selectedPods, selectedSpecies = _a.selectedSpecies, setSelectedSpecies = _a.setSelectedSpecies, setStep = _a.setStep;
    var podsById = useSelector(function (state) { return state.pods.byId; });
    var pods = selectedPods.map(function (pod) { return podsById === null || podsById === void 0 ? void 0 : podsById[pod === null || pod === void 0 ? void 0 : pod.uuid]; });
    var groups = ["pollutants"];
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx("h2", __assign({ className: "blue", style: { margin: "0 0 12px 0" }, id: "build-graph-select-species-title" }, { children: "Select Data Species" })) }), _jsx(Prompt, { children: "Select which types of data you want to graph for your selected stations." }), groups.map(function (group) {
                var availableSpecies = uniqBy(pods.map(function (pod) { var _a, _b; return (_b = (_a = pod === null || pod === void 0 ? void 0 : pod.available_parameters) === null || _a === void 0 ? void 0 : _a[group]) !== null && _b !== void 0 ? _b : []; }).flat(), "code").map(function (_a) {
                    var code = _a.code, name = _a.name;
                    return ({
                        species_code: code,
                        species_name: name,
                        aqi_colors: backGround(name),
                        show_as_average: true,
                    });
                });
                if (!availableSpecies.length)
                    return null;
                return (_jsxs(React.Fragment, { children: [_jsx("h4", __assign({ style: {
                                textTransform: "capitalize",
                                marginTop: "16px",
                                marginBottom: "4px",
                                fontWeight: "normal",
                            } }, { children: group })), _jsx(Row, __assign({ justifyContent: "flex-start", style: { flexWrap: "wrap" } }, { children: availableSpecies.map(function (specie) {
                                return (_jsx(PillButton, __assign({ id: "species-button-".concat(specie.species_code), onClick: function () {
                                        setSelectedSpecies([specie]);
                                    }, selected: selectedSpecies
                                        .map(function (s) { return s.species_code; })
                                        .includes(specie.species_code) }, { children: speciesLabels(specie.species_name) }), specie.species_code));
                            }) }))] }, group));
            }), _jsx("div", { style: { flex: 1 } }), _jsxs(Row, __assign({ justifyContent: "center", style: { margin: "40px 0 0 0" } }, { children: [_jsx(Button, __assign({ id: "back-button", style: { width: "50%", borderRadius: "18px", marginRight: "30px" }, plump: true, onClick: function () { return setStep(BuildGraphSteps.SelectPods); } }, { children: "Back" })), _jsx(Button, __assign({ id: "continue-button", style: { width: "50%", borderRadius: "18px" }, plump: true, outline: "filled", disabled: !selectedSpecies.length, onClick: function () { return setStep(BuildGraphSteps.SelectDate); } }, { children: "Continue" }))] }))] }));
};

import { Maps } from "store/map";
import generateMapReducer, { initialMapState, } from "store/map/reducer";
export var dashboardMapReducer = generateMapReducer(Maps.Dashboard);
/**
 * The reducer to handle all interactions of the primary map
 * on the dashboard page
 */
export function mapReducer(state, action) {
    var _a;
    if (state === void 0) { state = initialMapState; }
    switch ((_a = action.meta) === null || _a === void 0 ? void 0 : _a.path) {
        case Maps.Dashboard:
            return dashboardMapReducer(state, action);
        default:
            return state;
    }
}

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import StockChart from "highcharts/highstock";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import annotations from "highcharts/modules/annotations";
import merge from "lodash.merge";
import isEqual from "lodash.isequal";
exporting(StockChart);
exporting(Highcharts);
exportData(StockChart);
exportData(Highcharts);
annotations(StockChart);
annotations(Highcharts);
/**
 * Default graphing options used as a base to branch off from for all
 * line graphs.  `graphOptions` props gets spread into this object, allowing
 * for customization on a per-use basis of `LineGraph`, but also providing
 * a consistent starting point across all instances of `LineGraph`
 */
var defaultOptions = {
    chart: {
        type: "spline",
        backgroundColor: "none",
        height: 230,
        margin: [6, 0, 8, 30],
    },
    title: {
        text: "",
    },
    credits: {
        enabled: false,
    },
    xAxis: {
        type: "datetime",
        labels: {
            enabled: false,
        },
        lineWidth: 0,
        tickLength: 0,
    },
    yAxis: {
        title: {
            text: "",
        },
        gridLineWidth: 0,
        softMax: 1,
        labels: {
            enabled: true,
            x: -5,
        },
    },
    tooltip: {
        shared: true,
    },
    plotOptions: {
        spline: {
            lineWidth: 2.5,
            showInLegend: false,
            color: "#00A7E1",
            marker: {
                enabled: false,
            },
        },
    },
    navigation: {
        buttonOptions: {
            enabled: false,
        },
    },
};
/**
 * The central graph component of a LineGraph, used commonly throughout the application.  Built
 * on top of HighChartsJS.  Renders a line graph based on specific instructions given to it through
 * the `graphOptions` and `navigator` props.  See more in the `LineGraph` file.
 */
var Graph = function (_a) {
    var graphOptionsFromProps = _a.graphOptions, navigator = _a.navigator;
    var graphOptions = merge({}, defaultOptions, graphOptionsFromProps, {
        navigator: navigator,
    });
    var highcharts = navigator ? StockChart : Highcharts;
    return (_jsx(HighchartsReact, { highcharts: highcharts, options: graphOptions, constructorType: navigator ? "stockChart" : undefined }));
};
/**
 * The central graph component of a LineGraph, Memoized.
 */
export var MemoizedGraph = React.memo(Graph, function (prevProps, nextProps) {
    return isEqual(prevProps, nextProps);
});
MemoizedGraph.displayName = "MemoizedGraph";

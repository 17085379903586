var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BiLogOut, BiCog, BiCalendar, BiDownload } from "react-icons/bi";
import { MdDashboard, MdMenuOpen, MdMenu } from "react-icons/md";
import { BsFillFileEarmarkBarGraphFill } from "react-icons/bs";
import styled from "styled-components";
import { ActionCreators } from "store";
import { Column, IconButton } from "components";
import { COLORS } from "theme";
import { BlueSquarePath, PersiumLogoTextPath, UserImpactIcon as UserImpactIconSvgPath, } from "assets/images";
import { clsx } from "clsx";
import { NavButton, NavButtonText, NavItem } from "./NavItem";
/**
 * Absolutely ridiculous workaround because we chose a non-react-icons icon for this (ノÒ益Ó)ノ彡▔▔▏
 */
var UserImpactIcon = styled.img.attrs(function () { return ({
    src: UserImpactIconSvgPath,
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmargin-right: 10px;\n\tmargin-top: -2px;\n\tpointer-events: none;\n\tfilter: invert(75%) sepia(16%) saturate(244%) hue-rotate(191deg)\n\t\tbrightness(87%) contrast(86%);\n\ttransition: all 150ms;\n\t-webkit-transition: -webkit-filter 150ms linear;\n\n\tbutton:hover & {\n\t\tfilter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%)\n\t\t\thue-rotate(300deg) brightness(122%) contrast(112%);\n\t\ttransition: all 150ms;\n\t\t-webkit-transition: -webkit-filter 150ms linear;\n\t}\n\n\tbutton.selected:hover & {\n\t\tfilter: invert(55%) sepia(79%) saturate(5118%) hue-rotate(168deg)\n\t\t\tbrightness(105%) contrast(103%);\n\t\ttransition: all 150ms;\n\t\t-webkit-transition: -webkit-filter 150ms linear;\n\t}\n\n\t.selected & {\n\t\ttransition: all 150ms;\n\t\t-webkit-transition: -webkit-filter 150ms linear;\n\t\tfilter: invert(55%) sepia(79%) saturate(5118%) hue-rotate(168deg)\n\t\t\tbrightness(105%) contrast(103%);\n\t}\n"], ["\n\tmargin-right: 10px;\n\tmargin-top: -2px;\n\tpointer-events: none;\n\tfilter: invert(75%) sepia(16%) saturate(244%) hue-rotate(191deg)\n\t\tbrightness(87%) contrast(86%);\n\ttransition: all 150ms;\n\t-webkit-transition: -webkit-filter 150ms linear;\n\n\tbutton:hover & {\n\t\tfilter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%)\n\t\t\thue-rotate(300deg) brightness(122%) contrast(112%);\n\t\ttransition: all 150ms;\n\t\t-webkit-transition: -webkit-filter 150ms linear;\n\t}\n\n\tbutton.selected:hover & {\n\t\tfilter: invert(55%) sepia(79%) saturate(5118%) hue-rotate(168deg)\n\t\t\tbrightness(105%) contrast(103%);\n\t\ttransition: all 150ms;\n\t\t-webkit-transition: -webkit-filter 150ms linear;\n\t}\n\n\t.selected & {\n\t\ttransition: all 150ms;\n\t\t-webkit-transition: -webkit-filter 150ms linear;\n\t\tfilter: invert(55%) sepia(79%) saturate(5118%) hue-rotate(168deg)\n\t\t\tbrightness(105%) contrast(103%);\n\t}\n"])));
/**
 * Central configuration / definition for how navigation items should be rendered
 */
var privateNavItems = [
    {
        link: "",
        title: "Dashboard",
        icon: MdDashboard,
    },
    {
        link: "reports",
        title: "AQ Report Generator",
        icon: BsFillFileEarmarkBarGraphFill,
    },
    {
        link: "historics",
        title: "Historics and Analytics",
        icon: BiCalendar,
    },
    {
        link: "downloadData",
        title: "Download",
        icon: BiDownload,
    },
    {
        link: "impact",
        title: "Impact Tracker",
        icon: function () { return _jsx(UserImpactIcon, {}); },
    },
    {
        link: "settings",
        title: "Settings",
        icon: BiCog,
    },
];
var telecomNavItems = [
    {
        link: "",
        title: "Dashboard",
        icon: BiCog,
    },
];
export var NavWrapper = styled.nav(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tposition: relative;\n\tbackground-color: ", ";\n\tdisplay: flex;\n\tflex-direction: column;\n\tmin-width: 264px;\n\twidth: 264px;\n\tpadding: 8px;\n\toverflow: hidden;\n\ttransition: all 350ms;\n\n\t& * {\n\t\ttransition: all 350ms;\n\t}\n\n\t&.compact {\n\t\tmin-width: 62px;\n\t\twidth: 62px;\n\t\tpadding: 8px 0;\n\t\ttransition: all 350ms;\n\n\t\t& * {\n\t\t\ttransition: all 350ms;\n\t\t}\n\t}\n"], ["\n\tposition: relative;\n\tbackground-color: ", ";\n\tdisplay: flex;\n\tflex-direction: column;\n\tmin-width: 264px;\n\twidth: 264px;\n\tpadding: 8px;\n\toverflow: hidden;\n\ttransition: all 350ms;\n\n\t& * {\n\t\ttransition: all 350ms;\n\t}\n\n\t&.compact {\n\t\tmin-width: 62px;\n\t\twidth: 62px;\n\t\tpadding: 8px 0;\n\t\ttransition: all 350ms;\n\n\t\t& * {\n\t\t\ttransition: all 350ms;\n\t\t}\n\t}\n"])), COLORS.BLACKISH);
var Logo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\twidth: 86px;\n\theight: 42px;\n\t/* border: 1px solid red; */\n\tposition: relative;\n\tmargin: 6px;\n\n\t", ".compact & {\n\t\twidth: 42px;\n\t\ttransition: all 350ms;\n\t\tmargin: 6px 14px;\n\t}\n"], ["\n\twidth: 86px;\n\theight: 42px;\n\t/* border: 1px solid red; */\n\tposition: relative;\n\tmargin: 6px;\n\n\t", ".compact & {\n\t\twidth: 42px;\n\t\ttransition: all 350ms;\n\t\tmargin: 6px 14px;\n\t}\n"])), NavWrapper);
var BlueSquare = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\theight: 36px;\n\twidth: 36px;\n\tposition: absolute;\n\tleft: 40px;\n\n\t", ".compact & {\n\t\tleft: 0px;\n\t}\n"], ["\n\theight: 36px;\n\twidth: 36px;\n\tposition: absolute;\n\tleft: 40px;\n\n\t", ".compact & {\n\t\tleft: 0px;\n\t}\n"])), NavWrapper);
var PersiumTextWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tposition: absolute;\n\twidth: 86px;\n\tbottom: 4px;\n\tleft: 0px;\n\toverflow: hidden;\n\n\t", ".compact & {\n\t\tleft: 5px;\n\t\tbottom: 9px;\n\t\twidth: 26px;\n\t}\n"], ["\n\tposition: absolute;\n\twidth: 86px;\n\tbottom: 4px;\n\tleft: 0px;\n\toverflow: hidden;\n\n\t", ".compact & {\n\t\tleft: 5px;\n\t\tbottom: 9px;\n\t\twidth: 26px;\n\t}\n"])), NavWrapper);
var MenuButtonWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tposition: absolute;\n\ttop: 16px;\n\tright: 12px;\n\n\t", ".compact & {\n\t\ttop: 60px;\n\t}\n"], ["\n\tposition: absolute;\n\ttop: 16px;\n\tright: 12px;\n\n\t", ".compact & {\n\t\ttop: 60px;\n\t}\n"])), NavWrapper);
var ListWrapper = styled(Column)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tflex: 1;\n\tmargin-top: 8px;\n\n\t", ".compact & {\n\t\tmargin-top: 40px;\n\t}\n"], ["\n\tflex: 1;\n\tmargin-top: 8px;\n\n\t", ".compact & {\n\t\tmargin-top: 40px;\n\t}\n"])), NavWrapper);
/**
 * Lefthand Menu component in app frame
 */
export var NavMenu = function () {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var open = useSelector(function (state) { return state.general.menuOpen; });
    var MenuIcon = open ? MdMenuOpen : MdMenu;
    var onToggle = function () {
        dispatch(ActionCreators.toggleMenuOpen());
        setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
        }, 500);
    };
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var userRoles = userInfo.roles;
    var isTelecomUser = userRoles.some(function (obj) { return obj.name === "Telecom"; });
    var navitems = privateNavItems;
    if (isTelecomUser) {
        navitems = telecomNavItems;
    }
    return (_jsxs(NavWrapper, __assign({ className: clsx({ compact: !open }) }, { children: [_jsxs(Logo, { children: [_jsx(BlueSquare, { src: BlueSquarePath }), _jsx(PersiumTextWrapper, { children: _jsx("img", { src: PersiumLogoTextPath }) })] }), _jsx(MenuButtonWrapper, { children: _jsx(IconButton, __assign({ onClick: onToggle, size: 40 }, { children: _jsx(MenuIcon, { color: COLORS.GREY1 }) })) }), _jsx(ListWrapper, __assign({ alignItems: "stretch" }, { children: navitems.map(function (navitem) {
                    return _jsx(NavItem, __assign({ menuOpen: open }, navitem), navitem.link);
                }) })), _jsxs(NavButton, __assign({ style: { flex: "unset" }, onClick: function () {
                    dispatch(ActionCreators.logOut());
                    navigate("");
                } }, { children: [_jsx(BiLogOut, { size: 20, style: { marginRight: "10px", marginTop: "-1px" } }), _jsx(NavButtonText, { children: "Sign Out" })] }))] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// import * as L from "leaflet";
import axios from "axios";
import { all, call, debounce, fork, put, takeEvery } from "redux-saga/effects";
import { ActionTypes, ActionCreators } from "store";
var L;
if (typeof window !== "undefined") {
    L = require("leaflet");
}
/**
 * Utility saga to geocode a placename
 */
function geocode(searchterm) {
    var response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, call(axios.get, "https://nominatim.openstreetmap.org/?addressdetails=1&q=".concat(searchterm, "&format=json&limit=3"), {
                        params: {
                            addressdetails: 1,
                            q: searchterm,
                            format: "json",
                            limit: 3,
                        },
                        /* Remove auth token, or nominatim will reject the call */
                        transformRequest: function (data, headers) {
                            delete headers.common["Authorization"];
                            return data;
                        },
                    })];
            case 1:
                response = (_a.sent());
                /* Return response from nominatim */
                return [2 /*return*/, response.data];
            case 2:
                e_1 = _a.sent();
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request geocode results from nominatim and save results into store
 */
function handleGeocode(action) {
    var geocodeResults, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!window) return [3 /*break*/, 5];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, call(geocode, action.payload)];
            case 2:
                geocodeResults = (_a.sent());
                /* Save results into store.general.search.results */
                return [4 /*yield*/, put(ActionCreators.setGeocodeResults(geocodeResults, action.meta))];
            case 3:
                /* Save results into store.general.search.results */
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_2 = _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Saga to search for pods close to a place name.  First calls the geocode saga to get
 * a latlng from a search term name.  If there is one, it then constructs a box that is 1 degree
 * lat and lng in height and width, then searches for pods in that box.  Saves results to state.pods.foundFromSearch
 */
function handleSearchForPodsByName(action) {
    var geocodeResults, _a, lat, lng, bounds, e_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 8, , 9]);
                return [4 /*yield*/, call(geocode, action.payload)];
            case 1:
                geocodeResults = (_b.sent());
                if (!(geocodeResults && geocodeResults.length)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(ActionCreators.setGeocodeResults(geocodeResults, action.meta))];
            case 2:
                _b.sent();
                _a = geocodeResults[0], lat = _a.lat, lng = _a.lon;
                bounds = L.latLngBounds([
                    [Number(lat) - 0.5, Number(lng) - 0.5],
                    [Number(lat) + 0.5, Number(lng) + 0.5],
                ]);
                /* Call saga to call API to request pods within bounds surrounding the searched location */
                return [4 /*yield*/, put(ActionCreators.requestStationsInBounds({
                        bounds: bounds,
                        saveTo: "podsearch",
                    }, action.meta))];
            case 3:
                /* Call saga to call API to request pods within bounds surrounding the searched location */
                _b.sent();
                return [3 /*break*/, 7];
            case 4: return [4 /*yield*/, put(ActionCreators.setGeocodeResults([], action.meta))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(ActionCreators.setFoundPods([], action.meta))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 9];
            case 8:
                e_3 = _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function watchHandleGeocode() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_GEOCODE, handleGeocode)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleSearchForPodsByName() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, debounce(350, ActionTypes.REQ_SEARCH_FOR_PODS, handleSearchForPodsByName)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function searchSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([fork(watchHandleGeocode), fork(watchHandleSearchForPodsByName)])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

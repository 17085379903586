var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { parseDateString } from "utils";
var Footer = function (_a) {
    var station = _a.station;
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var theme = useTheme();
    var stationInstallDate = station.installdate
        ? parseDateString(station.installdate, userInfo.settings.timezone)
            .fullWithTimezone
        : "N/A";
    var fixedDate = stationInstallDate.slice(0, stationInstallDate.indexOf(",") + 6);
    // const SourceIcon = getStationSourceIcon(station.source as SourceName);
    return (_jsxs(Stack, __assign({ height: "100%", width: "100%", gap: "8px", style: {
            border: "1px solid ".concat(theme.palette.primary.main),
            borderRadius: "5px",
            marginBottom: "20px",
            marginTop: "30px",
            maxHeight: "630px",
            maxWidth: "500px",
            padding: "10px",
        } }, { children: [_jsx(Stack, __assign({ width: "100%", flexDirection: "row", gap: "8px", justifyContent: "space-between" }, { children: _jsxs(Stack, __assign({ gap: "8px" }, { children: [_jsx(Typography, __assign({ variant: "body1" }, { children: station.address || station.name })), _jsxs(Typography, __assign({ variant: "caption" }, { children: ["Source:", " ", _jsx("span", __assign({ style: { color: theme.palette.primary.main } }, { children: station.source }))] })), _jsxs(Typography, __assign({ variant: "caption" }, { children: ["Source ID:", " ", _jsx("span", __assign({ style: { color: theme.palette.primary.main } }, { children: station.source_id }))] })), _jsxs(Typography, __assign({ color: theme.palette.secondary["500"], variant: "caption" }, { children: ["Installed ", fixedDate] }))] })) })), station.photo && (
            // CLEANME: Replace with Box.Img
            _jsx("img", { alt: "stationPhoto", src: station.photo, style: {
                    alignSelf: "center",
                    borderRadius: "5px",
                    maxHeight: "460px",
                    maxWidth: "460px",
                } }))] })));
};
export default Footer;

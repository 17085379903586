var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { initialMapState, Maps } from "store/map";
import generateMapReducer from "store/map/reducer";
import { ActionTypes } from "store/types";
var now = new Date();
var yesterday = new Date();
yesterday.setMonth(yesterday.getMonth() - 1);
var initialState = {
    selected: [],
    selectPodsMap: initialMapState,
    timeframe: [yesterday.toISOString(), now.toISOString()],
    available: {
        country: {
            loading: false,
            data: [],
        },
        state: {
            loading: false,
            data: [],
        },
        city: {
            loading: false,
            data: [],
        },
        district: {
            loading: false,
            data: [],
        },
        pods: {
            loading: false,
            data: [],
        },
    },
};
var podSelectMapReducer = generateMapReducer(Maps.PodSelect);
/**
 * The reducer for the slice of state pertaining to the pod and time
 * selection filters on the dashboard page
 */
export function filterReducer(state, action) {
    var _a, _b;
    var _c;
    if (state === void 0) { state = initialState; }
    switch ((_c = action.meta) === null || _c === void 0 ? void 0 : _c.path) {
        case Maps.PodSelect:
            return __assign(__assign({}, state), { selectPodsMap: podSelectMapReducer(state.selectPodsMap, action) });
        default:
            switch (action.type) {
                case ActionTypes.SELECT_PODS: {
                    var selected_1 = __spreadArray([], __read(state.selected), false);
                    var newSelections = action.payload.filter(function (pod) { return !selected_1.map(function (p) { return p.uuid; }).includes(pod.uuid); });
                    var newSelection = __spreadArray(__spreadArray([], __read(selected_1), false), __read(newSelections), false);
                    return __assign(__assign({}, state), { selected: newSelection });
                }
                case ActionTypes.TOGGLE_SELECT_POD: {
                    var selected = __spreadArray([], __read(state.selected), false);
                    var alreadySelected = state.selected
                        .map(function (p) { return p.uuid; })
                        .includes(action.payload.uuid);
                    if (alreadySelected) {
                        selected = selected.filter(function (pod) { return pod.uuid !== action.payload.uuid; });
                    }
                    else {
                        selected.push(action.payload);
                    }
                    return __assign(__assign({}, state), { selected: selected });
                }
                case ActionTypes.CLEAR_SELECTED_PODS:
                    return __assign(__assign({}, state), { selected: [] });
                case ActionTypes.REQ_LOCATION_LIST:
                    return __assign(__assign({}, state), { available: __assign(__assign({}, state.available), (_a = {}, _a[action.payload.level] = __assign(__assign({}, state.available[action.payload.level]), { loading: true }), _a)) });
                case ActionTypes.SET_LOCATION_LIST:
                    return __assign(__assign({}, state), { available: __assign(__assign({}, state.available), (_b = {}, _b[action.payload.level] = {
                            loading: false,
                            data: action.payload.list,
                        }, _b)) });
                case ActionTypes.REQ_POD_LIST:
                    return __assign(__assign({}, state), { available: __assign(__assign({}, state.available), { pods: __assign(__assign({}, state.available.pods), { loading: true }) }) });
                case ActionTypes.SET_POD_LIST:
                    return __assign(__assign({}, state), { available: __assign(__assign({}, state.available), { pods: {
                                loading: false,
                                data: action.payload,
                            } }) });
                case ActionTypes.SET_TIME_FRAME:
                    return __assign(__assign({}, state), { timeframe: action.payload });
                default:
                    return state;
            }
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { Modal, Row as RowBase, RangeSlider, Prompt, Button, } from "components/atoms";
import { formatMinutes } from "utils";
/**
 * Modal component where user can set the smoothing settings of the simple moving average
 * line of a graph widget on H&A page
 */
export var SMAConfig = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var graphState = _a.graphState, isOpen = _a.isOpen, movingAverageInterval = _a.movingAverageInterval, setIsOpen = _a.setIsOpen, setMovingAverageInterval = _a.setMovingAverageInterval;
    var _s = __read(useState(movingAverageInterval), 2), value = _s[0], setValue = _s[1];
    /*
     * Extract first and second point from first line to get a baseline
     * on the current time resolution of the datasets
     *
     * NOTE: This logic depends on all datasets having the same time resolution
     * which currently seems to be automatic from the API
     */
    var firstPoint = (_f = (_e = (_d = (_c = (_b = graphState === null || graphState === void 0 ? void 0 : graphState.lines[0]) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e[1]) === null || _f === void 0 ? void 0 : _f.date;
    var secondPoint = (_l = (_k = (_j = (_h = (_g = graphState === null || graphState === void 0 ? void 0 : graphState.lines[0]) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.items) === null || _k === void 0 ? void 0 : _k[2]) === null || _l === void 0 ? void 0 : _l.date;
    /*
     * Transform to unix timestamp
     */
    var firstUnix = new Date(firstPoint !== null && firstPoint !== void 0 ? firstPoint : +new Date()).getTime();
    var secondUnix = new Date(secondPoint !== null && secondPoint !== void 0 ? secondPoint : +new Date() + 1000 * 60 * 60 * 24).getTime();
    /**
     * Time resolution of the data in minutes
     */
    var dataTimeResolution = Math.floor((secondUnix - firstUnix) / (60 * 1000));
    var max = Math.floor(((_r = (_q = (_p = (_o = (_m = graphState === null || graphState === void 0 ? void 0 : graphState.lines[0]) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.items) === null || _q === void 0 ? void 0 : _q.length) !== null && _r !== void 0 ? _r : 90) / 6);
    if (!graphState || graphState.loading)
        return null;
    return (_jsxs(Modal, __assign({ id: "sma-smoothing-modal", isOpen: isOpen, setIsOpen: setIsOpen, wrapperStyle: { maxWidth: "480px" }, afterClose: function () { return setValue(movingAverageInterval); } }, { children: [_jsx("h3", { children: "Choose Smoothing Interval" }), _jsx(Prompt, { children: "Choose the level of smoothing you want to apply to the graph lines." }), _jsxs(Prompt, __assign({ style: { margin: "12px 0 24px 0" } }, { children: [_jsx(BsInfoCircle, { size: 20, style: { margin: "0 4px -4px 0" } }), "Moving averages are created by mapping every reading to the average of the previous readings. The larger the number of readings used (called the ", _jsx("i", { children: "period" }), "), the smoother the line."] })), _jsx(RangeSlider, { value: value, setValue: setValue, min: 1, max: max, step: 0.01 }), _jsx(RowBase, __assign({ style: { marginTop: "20px" } }, { children: _jsxs("span", { children: ["Period: ", _jsx("span", __assign({ style: { width: "40px" } }, { children: Math.floor(value) }))] }) })), _jsxs(RowBase, { children: ["Corresponding time interval:", " ", formatMinutes(dataTimeResolution * ((Math.floor(value) - 1) * 2))] }), _jsxs(RowBase, __assign({ style: { marginTop: "40px" } }, { children: [_jsx(Button, __assign({ plump: true, onClick: function () {
                            setIsOpen(false);
                            setValue(movingAverageInterval);
                        }, style: { flex: 1, marginRight: "60px" } }, { children: "Cancel" })), _jsx(Button, __assign({ plump: true, outline: "filled", onClick: function () {
                            setIsOpen(false);
                            setMovingAverageInterval(value);
                        }, style: { flex: 1 } }, { children: "Save" }))] }))] })));
};

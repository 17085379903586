var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Column, Prompt, Row } from "components/atoms";
import { HAWidgetTypes } from "models";
import { WiTime8, WiTime4 } from "react-icons/wi";
import { MapTypeIconSpecies, MapTypeIconSpeciesInverted, MapTypeIconSwipe, MapTypeIconSwipeInverted, } from "assets/images";
import styled from "styled-components";
import { COLORS } from "theme";
import clsx from "clsx";
import { NewWidgetButton } from "../../../BuildWidgetButtons";
import { BuildMapSteps } from ".";
var IconWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: 140px;\n  width: 190px;\n  display: flex;\n  align-items: center;\n  color: ", ";\n\n  &.selected {\n    color: ", ";\n    transition: all 200ms;\n  }\n"], ["\n  position: relative;\n  height: 140px;\n  width: 190px;\n  display: flex;\n  align-items: center;\n  color: ", ";\n\n  &.selected {\n    color: ", ";\n    transition: all 200ms;\n  }\n"])), COLORS.BLUE, COLORS.WHITE);
var IsobarsIcon = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 2px solid ", ";\n  border-radius: 5px;\n  margin-bottom: 12px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  opacity: 1;\n  transition: all 200ms;\n\n  &.invisible {\n    opacity: 0;\n    transition: all 200ms;\n  }\n"], ["\n  border: 2px solid ", ";\n  border-radius: 5px;\n  margin-bottom: 12px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 100%;\n  opacity: 1;\n  transition: all 200ms;\n\n  &.invisible {\n    opacity: 0;\n    transition: all 200ms;\n  }\n"])), COLORS.BLUE);
/**
 * The select map type step of the create map modal
 */
export var SelectMapType = function (_a) {
    var setStep = _a.setStep, mapType = _a.mapType, setMapType = _a.setMapType;
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "What kind of map would you like to create?" }), _jsxs(Row, __assign({ style: { width: "60vw", minWidth: "812px", marginTop: "20px" } }, { children: [_jsx(NewWidgetButton, __assign({ onClick: function () { return setMapType(HAWidgetTypes.SPECIES_MAP); }, selected: mapType === HAWidgetTypes.SPECIES_MAP, style: { padding: "12px 0" } }, { children: _jsxs(Column, __assign({ alignItems: "center" }, { children: [_jsx("h4", { children: "Data Species Heatmap" }), _jsxs(IconWrapper, { children: [_jsx(IsobarsIcon, { src: MapTypeIconSpecies }), _jsx(IsobarsIcon, { src: MapTypeIconSpeciesInverted, className: clsx({
                                                invisible: mapType !== HAWidgetTypes.SPECIES_MAP,
                                            }), style: { border: "2px solid white" } })] }), _jsx(Prompt, __assign({ style: { margin: "20px 0" } }, { children: "View heatmaps for a specific pollutant" }))] })) })), _jsx(NewWidgetButton, __assign({ onClick: function () { return setMapType(HAWidgetTypes.SWIPE_MAP); }, selected: mapType === HAWidgetTypes.SWIPE_MAP, style: { padding: "12px 0" } }, { children: _jsxs(Column, __assign({ alignItems: "center" }, { children: [_jsx("h4", { children: "Historical Comparison Map" }), _jsxs(IconWrapper, __assign({ className: clsx({
                                        selected: mapType === HAWidgetTypes.SWIPE_MAP,
                                    }) }, { children: [_jsx(WiTime8, { size: 36, style: { position: "absolute", left: "-50px" } }), _jsx(IsobarsIcon, { src: MapTypeIconSwipe }), _jsx(IsobarsIcon, { src: MapTypeIconSwipeInverted, className: clsx({
                                                invisible: mapType !== HAWidgetTypes.SWIPE_MAP,
                                            }), style: { border: "2px solid white" } }), _jsx(WiTime4, { size: 36, style: { position: "absolute", right: "-50px" } })] })), _jsx(Prompt, __assign({ style: { margin: "20px 0" } }, { children: "Compare two points in time on a map" }))] })) }))] })), _jsx(Row, __assign({ justifyContent: "center", style: { margin: "40px 0 0 0" } }, { children: _jsx(Button, __assign({ style: { width: "100%", borderRadius: "18px" }, plump: true, outline: "filled", disabled: !mapType, onClick: function () { return setStep(BuildMapSteps.SELECT_POLLUTANT); } }, { children: "Continue" })) }))] }));
};
var templateObject_1, templateObject_2;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionTypes } from "store/types";
var initialState = {
    menuOpen: true,
    allFilters: {
        pollutants: [],
        atmospherics: [],
        engineerings: [],
    },
};
export var generalReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.TOGGLE_MENU_OPEN:
            return __assign(__assign({}, state), { menuOpen: !state.menuOpen });
        case ActionTypes.SET_ALL_FILTERS:
            return __assign(__assign({}, state), { allFilters: action.payload });
        case ActionTypes.SET_PREFERENCE_OPTIONS:
            return __assign(__assign({}, state), { preferenceOptions: action.payload });
        case ActionTypes.REQ_CONTACT_US:
            return __assign(__assign({}, state), { contact: {
                    loading: true,
                } });
        case ActionTypes.CONTACT_US_SUCCESS:
            return __assign(__assign({}, state), { contact: {
                    loading: false,
                    success: true,
                } });
        case ActionTypes.CONTACT_US_ERROR:
            return __assign(__assign({}, state), { contact: {
                    loading: false,
                    error: action.payload,
                } });
        case ActionTypes.CONTACT_US_CLEAR:
            return __assign(__assign({}, state), { contact: null });
        default:
            return state;
    }
};

/**
 * Names of the different instances of a map use throughout the application
 */
export var Maps;
(function (Maps) {
    Maps["Dashboard"] = "Dashboard";
    Maps["PodSelect"] = "PodSelect";
    Maps["HASpeciesMapWidget"] = "HASpeciesMapWidget";
    Maps["HASwipeMapWidget"] = "HASwipeMapWidget";
})(Maps || (Maps = {}));
/**
 * Utility selector to select the slice of state in the store corresponding to a map
 * in the application, specified by the second argument, which is the map name,
 * as defined by the `Maps` enum
 */
function getMapSlice(state, reduxLocation) {
    var _a;
    var widget = reduxLocation.widget;
    var id = widget === null || widget === void 0 ? void 0 : widget.id;
    var side = widget === null || widget === void 0 ? void 0 : widget.side;
    switch (reduxLocation.path) {
        case Maps.Dashboard:
            return state.dashboard.map;
        case Maps.PodSelect:
            return state.dashboard.filters.selectPodsMap;
        case Maps.HASpeciesMapWidget:
            return state.analytics.maps.speciesMaps[id];
        case Maps.HASwipeMapWidget:
            return (_a = state.analytics.maps.swipeMaps[id]) === null || _a === void 0 ? void 0 : _a[side];
        default:
            return state.dashboard.map;
    }
}
export { getMapSlice };

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Row, Prompt, RadioButton, TextInput } from "components/atoms";
import { TimeRanges } from "models";
import styled from "styled-components";
import { BuildGraphSteps } from ".";
import { timerangeOptions } from "../../../../../../constants";
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 6px 6px 6px 0;\n\tmargin-right: 6px;\n\tcursor: pointer;\n\n\t& span {\n\t\tmargin-left: 6px;\n\t}\n"], ["\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: 6px 6px 6px 0;\n\tmargin-right: 6px;\n\tcursor: pointer;\n\n\t& span {\n\t\tmargin-left: 6px;\n\t}\n"])));
/**
 * The select timeframe step of the build graph modal for the H&A section
 */
export var SelectTimeframe = function (_a) {
    var customFrameValue = _a.customFrameValue, editMode = _a.editMode, onSubmit = _a.onSubmit, selectedTimeframe = _a.selectedTimeframe, setCustomFrameValue = _a.setCustomFrameValue, setSelectedTimeframe = _a.setSelectedTimeframe, setStep = _a.setStep;
    return (_jsxs(_Fragment, { children: [_jsx(Prompt, { children: "Select the timeframe for the data you want to graph." }), _jsxs(Row, __assign({ style: { marginTop: "20px", flexWrap: "wrap" } }, { children: [timerangeOptions.map(function (timerange) {
                        return (_jsxs(Label, __assign({ id: "timerange-".concat(timerange.value, "-button"), onClick: function () {
                                setSelectedTimeframe(timerange.value);
                            } }, { children: [_jsx(RadioButton, { checked: selectedTimeframe === timerange.value }), _jsx("span", { children: timerange.label })] }), timerange.value));
                    }), selectedTimeframe === TimeRanges.CUSTOM && (_jsxs("div", __assign({ style: { display: "flex", gap: "8px", alignItems: "center" } }, { children: [_jsx("span", { children: "Enter the number of days you want: " }), _jsx(TextInput, { type: "number", value: customFrameValue, onChange: function (_a) {
                                    var value = _a.target.value;
                                    setCustomFrameValue(value.replace(".", ""));
                                } })] })))] })), _jsx("div", { style: { flex: 1 } }), _jsxs(Row, __assign({ justifyContent: "center", style: { margin: "40px 0 0 0" } }, { children: [_jsx(Button, __assign({ id: "back-button", style: { width: "50%", borderRadius: "18px", marginRight: "30px" }, plump: true, onClick: function () { return setStep(BuildGraphSteps.SelectParams); } }, { children: "Back" })), _jsx(Button, __assign({ id: "continue-button", style: { width: "50%", borderRadius: "18px" }, plump: true, outline: "filled", disabled: !selectedTimeframe, onClick: onSubmit }, { children: editMode ? "Save Changes" : "Create Pollution Rose" }))] }))] }));
};
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector, useDispatch } from "react-redux";
import { Row, Button, IconButton, Modal, Column } from "components";
import { IoCloseOutline } from "react-icons/io5";
import styled from "styled-components";
import { ActionCreators } from "store";
import { useDashboardSettings } from "hooks";
import { MapSelectMap } from "./MapSelectMap";
var ListWrapper = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  width: 100%;\n"], ["\n  margin: 0;\n  padding: 0;\n  width: 100%;\n"])));
var PodItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  list-style: none;\n  padding: 12px 0;\n\n  &:first-child {\n    padding-top: 0;\n  }\n\n  &:last-child {\n    padding-bottom: 0;\n  }\n"], ["\n  list-style: none;\n  padding: 12px 0;\n\n  &:first-child {\n    padding-top: 0;\n  }\n\n  &:last-child {\n    padding-bottom: 0;\n  }\n"])));
/**
 * Modal component that allows user to select pods via a map
 */
export var MapSelect = function (_a) {
    var mapSelectOpen = _a.mapSelectOpen, setMapSelectOpen = _a.setMapSelectOpen, getLocationsFromSample = _a.getLocationsFromSample;
    var Dashboard = useDashboardSettings();
    var dispatch = useDispatch();
    var selectedPods = useSelector(function (state) { return state.dashboard.filters.selected; });
    return (_jsxs(Modal, __assign({ isOpen: mapSelectOpen, setIsOpen: setMapSelectOpen, backgroundProps: { overflowY: "hidden" }, id: "select-sensors-map-modal" }, { children: [_jsxs(Row, __assign({ alignItems: "flex-start" }, { children: [_jsx("h2", __assign({ className: "blue", style: { margin: "0 0 30px 0" } }, { children: "Select Sensors" })), _jsxs(Row, { children: [_jsx(Button, __assign({ onClick: function () {
                                    setMapSelectOpen(false);
                                    getLocationsFromSample(selectedPods, true);
                                }, outline: "filled", style: {
                                    alignSelf: "stretch",
                                    height: "unset",
                                    padding: "0 12px",
                                    margin: "0 10px",
                                }, id: "save-changes-button" }, { children: "Save" })), _jsx(Button, __assign({ outline: "filled", style: {
                                    alignSelf: "stretch",
                                    height: "unset",
                                    padding: "0 12px",
                                    margin: "0 40px 0 10px",
                                }, onClick: function () { return dispatch(ActionCreators.clearSelectedPods()); }, id: "clear-map-button" }, { children: "Clear" })), _jsx(IconButton, __assign({ size: 40, onClick: function () {
                                    var _a, _b;
                                    dispatch(ActionCreators.clearSelectedPods());
                                    dispatch(ActionCreators.selectPods((_b = (_a = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _a === void 0 ? void 0 : _a.sample) !== null && _b !== void 0 ? _b : []));
                                    setMapSelectOpen(false);
                                } }, { children: _jsx(IoCloseOutline, {}) }))] })] })), _jsxs(Row, __assign({ alignItems: "flex-start" }, { children: [_jsxs(Column, __assign({ style: {
                            marginRight: "16px",
                            width: "380px",
                            overflow: "auto",
                            height: "80vh",
                        } }, { children: [selectedPods.length === 0 && "No sensors selected", _jsx(ListWrapper, { children: selectedPods.map(function (pod) { return (_jsx(PodItem, __assign({ id: "selected-pod-list-view-".concat(pod.uuid) }, { children: _jsxs(Row, { children: [pod.name, _jsx(IconButton, __assign({ style: { minWidth: "32px" }, className: "remove-pod-list-button", onClick: function () {
                                                    return dispatch(ActionCreators.togglePodSelect(pod));
                                                } }, { children: _jsx(IoCloseOutline, { size: 16 }) }))] }) }), "selected-pod-list-view-".concat(pod.uuid))); }) })] })), _jsx(MapSelectMap, {})] }))] })));
};
var templateObject_1, templateObject_2;

/**
 * Redux actions related to the user and user state
 */
import { createAction } from "typesafe-actions";
import { ActionTypes } from "store/types";
/**
 * Redux actions related to user login, logout, account actions, etc
 */
export var UserActionCreators = {
    /**
     * Redux action to log user in
     */
    requestLogin: createAction(ActionTypes.REQ_LOGIN, function (payload) { return payload; })(),
    /**
     * Redux action to set user as Authenticated
     * @param {AuthPayload} payload status and JWT of authenticated user
     */
    setAuthenticated: createAction(ActionTypes.SET_AUTHENTICATED, function (payload) { return payload; })(),
    /**
     * Redux action to log out
     */
    logOut: createAction(ActionTypes.LOG_OUT, function () { return undefined; })(),
    /**
     * Register authentication error in the redux store
     */
    setAuthError: createAction(ActionTypes.SET_AUTH_ERROR, function (payload) { return payload; })(),
    /**
     * Redux action to request refresh jwt token and register it in application
     */
    requestRefreshToken: createAction(ActionTypes.REQ_REFRESH_TOKEN, function () { return undefined; })(),
    /**
     * Redux action to request user data from API
     */
    requestUserInfo: createAction(ActionTypes.REQ_USER_INFO, function () { return undefined; })(),
    /**
     * Redux action to set user data in store.  Accepts a whole or partial
     * UserInfo object, and shallow-merges the object into the existing user.info
     * @param {UserInfo} payload User data as defined by API
     */
    setUserInfo: createAction(ActionTypes.SET_USER_INFO, function (payload) { return payload; })(),
    /**
     * Redux action to send user's payment information when signing up for a new plan
     * @param {CreditCardInfo} payload
     */
    requestSubmitPaymentForm: createAction(ActionTypes.REQ_SUBMIT_PAYMENT_FORM, function (payload) { return payload; })(),
    /**
     * Redux action to register success when user's card form is accepted
     */
    paymentFormSuccess: createAction(ActionTypes.PAYMENT_FORM_SUCCESS, function (payload) { return payload; })(),
    /**
     * Redux action to register error when user's card form is rejected
     */
    paymentFormError: createAction(ActionTypes.PAYMENT_FORM_ERROR, function (payload) { return payload; })(),
    /**
     * Redux action to clear payment form results
     */
    clearPaymentForm: createAction(ActionTypes.CLEAR_PAYMENT_FORM, function () { return undefined; })(),
    /**
     * Redux action to send user's new payment information when updating payment information in settings
     * @param {CreditCardInfo} payload
     */
    requestUpdatePaymentMethod: createAction(ActionTypes.REQ_UPDATE_PAYMENT_METHOD, function (payload) { return payload; })(),
    /**
     * Redux action to request resetting password
     */
    requestResetPasswordInit: createAction(ActionTypes.REQ_RESET_PASSWORD_INIT, function (payload) { return payload; })(),
    /**
     * Redux action to register error when initiate reset password call fails
     */
    resetPasswordInitSuccess: createAction(ActionTypes.RESET_PASSWORD_INIT_SUCCESS, function () { return undefined; })(),
    /**
     * Redux action to register error when initiate reset password call fails
     */
    resetPasswordInitFailure: createAction(ActionTypes.RESET_PASSWORD_INIT_ERROR, function (payload) { return payload; })(),
    /**
     * Redux action to request change password form POST
     */
    requestResetPasswordFinish: createAction(ActionTypes.REQ_RESET_PASSWORD_FINISH, function (payload) { return payload; })(),
    requestResetForgotPasswordFinish: createAction(ActionTypes.REQ_FORGOT_RESET_PASSWORD_FINISH, function (payload) { return payload; })(),
    /**
     * Redux action to register error when finish reset password call fails
     */
    resetPasswordFinishSuccess: createAction(ActionTypes.RESET_PASSWORD_FINISH_SUCCESS, function () { return undefined; })(),
    /**
     * Redux action to register error when finish reset password call fails
     */
    resetPasswordFinishFailure: createAction(ActionTypes.RESET_PASSWORD_FINISH_ERROR, function () { return undefined; })(),
    /**
     * Redux action to clear reset password results
     */
    clearResetPasswordInit: createAction(ActionTypes.RESET_PASSWORD_CLEAR, function () { return undefined; })(),
    /**
     * Redux action to request the user's subscription be cancelled
     */
    requestCancelSubscription: createAction(ActionTypes.REQ_CANCEL_SUBSCRIPTION, function (payload) { return payload; })(),
    /**
     * Redux action to rregister that user's cancel request was successful
     */
    cancelSubscriptionResponse: createAction(ActionTypes.CANCEL_SUBSCRIPTION_RESPONSE, function (payload) { return payload; })(),
    /**
     * Redux action to clear cancel form status
     */
    clearCancelSubscription: createAction(ActionTypes.CLEAR_CANCEL_SUBSCRIPTION, function () { return undefined; })(),
    /**
     * Redux action to send the api call to initiate a phone number / email update
     */
    requestUpdateContactInitialize: createAction(ActionTypes.REQ_UPDATE_CONTACT_INIT, function (payload) { return payload; })(),
    /**
     * Redux action to register that initializing phone number / email update was successful
     */
    updateContactInitializeSuccess: createAction(ActionTypes.UPDATE_CONTACT_INIT_SUCCESS, function (payload) { return payload; })(),
    /**
     * Redux action to register that initializing phone number / email update errored
     */
    updateContactInitializeError: createAction(ActionTypes.UPDATE_CONTACT_INIT_ERROR, function (payload) { return payload; })(),
    /**
     * Redux action to clear response status of initializing phone update / email
     */
    clearUpdateContactInitialize: createAction(ActionTypes.CLEAR_UPDATE_CONTACT_INIT, function () { return undefined; })(),
    /**
     * Redux action to send the api call to verify a phone number / email update
     */
    requestUpdateContactVerify: createAction(ActionTypes.REQ_UPDATE_CONTACT_VERIFY, function (payload) { return payload; })(),
    /**
     * Redux action to register that verifying phone number / email update was successful
     */
    updateContactVerifySuccess: createAction(ActionTypes.UPDATE_CONTACT_VERIFY_SUCCESS, function (payload) { return payload; })(),
    /**
     * Redux action to register that verifying phone number / email update errored
     */
    updateContactVerifyError: createAction(ActionTypes.UPDATE_CONTACT_VERIFY_ERROR, function (payload) { return payload; })(),
    /**
     * Redux action to clear response status of verifying phone update / email
     */
    clearUpdateContactVerify: createAction(ActionTypes.CLEAR_UPDATE_CONTACT_VERIFY, function () { return undefined; })(),
};

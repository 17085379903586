var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
/**
 * Reusable error prompt that appears and disappears with the presence of a .show class
 */
export var ErrorPrompt = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0;\n  opacity: 0;\n  max-height: 0;\n  color: #ff5b5b;\n  font-size: 14px;\n  line-height: 20px;\n  transition: all 0.2s ease-in-out;\n  pointer-events: none;\n  padding-left: 5px;\n\n  ", "\n"], ["\n  margin: 0;\n  opacity: 0;\n  max-height: 0;\n  color: #ff5b5b;\n  font-size: 14px;\n  line-height: 20px;\n  transition: all 0.2s ease-in-out;\n  pointer-events: none;\n  padding-left: 5px;\n\n  ", "\n"])), function (props) {
    return props.visible && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      max-height: 40px;\n      margin: -7px 0 7px;\n      opacity: 1;\n      transition: all 0.2s ease-in-out;\n    "], ["\n      max-height: 40px;\n      margin: -7px 0 7px;\n      opacity: 1;\n      transition: all 0.2s ease-in-out;\n    "])));
});
var templateObject_1, templateObject_2;

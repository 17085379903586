var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";
import { Button, Column, ErrorPrompt, PasswordInput, PasswordSafetyBox, } from "components/atoms";
import { ActionCreators } from "store";
import { COLORS } from "theme";
import { checkPasswordSafety } from "utils";
import { Inner } from ".";
/**
 * Forgot password content for login screen
 */
export var ResetPassword = function () {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _a = __read(useState(""), 2), password = _a[0], setPassword = _a[1];
    var _b = __read(useState(false), 2), passwordTouched = _b[0], setPasswordTouched = _b[1];
    var _c = __read(useState("weak"), 2), passwordSafety = _c[0], setPasswordSafety = _c[1];
    var _d = __read(useState(""), 2), passwordConfirm = _d[0], setPasswordConfirm = _d[1];
    var _e = __read(useState(false), 2), passwordConfirmTouched = _e[0], setPasswordConfirmTouched = _e[1];
    var _f = __read(useSearchParams(), 1), searchParams = _f[0];
    var restoreToken = searchParams.get("hash_key");
    // Remove the token from the URL for shallow security
    window.history.replaceState(null, null, window.location.pathname);
    var resetPassword = useSelector(function (state) { return state.user.resetPasswordFinish; });
    var loading = resetPassword === null || resetPassword === void 0 ? void 0 : resetPassword.loading;
    var success = resetPassword === null || resetPassword === void 0 ? void 0 : resetPassword.success;
    var error = resetPassword === null || resetPassword === void 0 ? void 0 : resetPassword.error;
    useEffect(function () {
        return function () {
            dispatch(ActionCreators.clearResetPasswordInit());
        };
    }, []);
    return (_jsxs(_Fragment, { children: [success ? (_jsxs(Column, __assign({ margin: "80px 0 120px", alignItems: "center" }, { children: [_jsx(BsCheckLg, { color: COLORS.BLUE, size: 40 }), _jsx("h2", __assign({ style: { textAlign: "center", marginTop: "40px" } }, { children: "Your password has been reset" })), _jsx(Button, __assign({ plump: true, outline: "filled", loading: loading, onClick: function (e) {
                            e.preventDefault();
                            navigate("/");
                        }, disabled: !password || !passwordConfirm, style: { marginTop: "20px", width: "320px" } }, { children: "Log In" })), " "] }))) : (_jsxs(Column, __assign({ margin: "40px 0 90px", alignItems: "center", style: { width: "100%" } }, { children: [_jsx("h1", __assign({ style: { textAlign: "center", width: "60%" } }, { children: "Reset your password" })), _jsxs(Inner, { children: [_jsx(PasswordInput, { id: "password-input", placeholder: "Enter Password", value: password, onChange: function (e) {
                                    setPasswordSafety(checkPasswordSafety(e.target.value));
                                    setPassword(e.target.value);
                                }, error: !!error || (passwordTouched && passwordSafety === "weak"), onBlur: function () { return setPasswordTouched(true); } }), _jsx(ErrorPrompt, __assign({ visible: !!error ||
                                    (passwordTouched && !password) ||
                                    (passwordTouched && passwordSafety === "weak") }, { children: (error !== null && error !== void 0 ? error : !password)
                                    ? "Required"
                                    : "Must meet password requirements" })), _jsx(PasswordInput, { id: "password-confirm-input", placeholder: "Confirm Password", value: passwordConfirm, onChange: function (e) { return setPasswordConfirm(e.target.value); }, onBlur: function () { return setPasswordConfirmTouched(true); }, error: !!error || (passwordConfirmTouched && !passwordConfirm) }), _jsx(ErrorPrompt, __assign({ visible: !!error ||
                                    (passwordConfirmTouched && !passwordConfirm) ||
                                    (passwordConfirmTouched && password !== passwordConfirm) }, { children: (error !== null && error !== void 0 ? error : !passwordConfirm)
                                    ? "Required"
                                    : "Passwords do not match" })), _jsx(PasswordSafetyBox, { password: password }), _jsx(Button, __assign({ plump: true, outline: "filled", loading: loading, onClick: function (e) {
                                    e.preventDefault();
                                    dispatch(ActionCreators.requestResetForgotPasswordFinish({
                                        password: password,
                                        restoreToken: restoreToken,
                                    }));
                                }, disabled: !password ||
                                    !passwordConfirm ||
                                    passwordSafety === "weak" ||
                                    password !== passwordConfirm }, { children: "Continue" }))] })] }))), _jsx(Button, __assign({ plump: true, outline: "bare", loading: loading, onClick: function (e) {
                    e.preventDefault();
                    navigate("/");
                }, style: { position: "absolute", left: "10px", bottom: "10px" } }, { children: "Back to Login" }))] }));
};
/**
 * Component used when user is redirected to "/reset-password" route while logged in
 * will log the user out so they can continue the reset password flow
 */
export var LoggedInResetPassword = function () {
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(ActionCreators.logOut());
    }, []);
    return null;
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Card, Row as RowBase, Button } from "components/atoms";
import { COLORS } from "theme";
import uniqBy from "lodash.uniqby";
import styled from "styled-components";
import { DashArrayMap } from "components/organisms";
import clsx from "clsx";
import { speciesLabels } from "../../../../../../constants";
var ColorPill = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\theight: 24px;\n\tmin-width: 40px;\n\tborder-radius: 3px;\n\tbackground: ", ";\n"], ["\n\theight: 24px;\n\tmin-width: 40px;\n\tborder-radius: 3px;\n\tbackground: ", ";\n"])), function (props) { return props.color; });
var Row = styled(RowBase)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tmargin-bottom: 6px;\n"], ["\n\tmargin-bottom: 6px;\n"])));
var ExpandableCard = styled(Card)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tposition: relative;\n\tmargin-top: 20px;\n\twidth: 100%;\n\tpadding: 0;\n\toverflow: hidden;\n\tflex: 1;\n\tmin-height: 0;\n\tz-index: 10;\n\ttransition: all 200ms;\n\tmargin-bottom: 1px;\n\n\t.no-box-shadows & {\n\t\tbox-shadow: none important!;\n\t\tborder: none !important;\n\t}\n\n\t&.mini {\n\t\tmargin-top: 0 !important;\n\t}\n"], ["\n\tposition: relative;\n\tmargin-top: 20px;\n\twidth: 100%;\n\tpadding: 0;\n\toverflow: hidden;\n\tflex: 1;\n\tmin-height: 0;\n\tz-index: 10;\n\ttransition: all 200ms;\n\tmargin-bottom: 1px;\n\n\t.no-box-shadows & {\n\t\tbox-shadow: none important!;\n\t\tborder: none !important;\n\t}\n\n\t&.mini {\n\t\tmargin-top: 0 !important;\n\t}\n"])));
var SpeciesRow = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t&:last-child {\n\t\tmargin-bottom: ", ";\n\t}\n"], ["\n\t&:last-child {\n\t\tmargin-bottom: ", ";\n\t}\n"])), function (props) { return (props.mini ? 0 : "32px"); });
var SeeMoreButton = styled(Button)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tposition: absolute;\n\tbottom: 0;\n\twidth: 100%;\n\tbackground-color: ", ";\n\tcolor: ", ";\n\tborder: none;\n\tbox-shadow: 0px -14px 12px ", ";\n\tpadding-bottom: 2px;\n\n\t&:hover {\n\t\tbox-shadow: 0px -14px 12px ", ";\n\t}\n"], ["\n\tposition: absolute;\n\tbottom: 0;\n\twidth: 100%;\n\tbackground-color: ", ";\n\tcolor: ", ";\n\tborder: none;\n\tbox-shadow: 0px -14px 12px ", ";\n\tpadding-bottom: 2px;\n\n\t&:hover {\n\t\tbox-shadow: 0px -14px 12px ", ";\n\t}\n"])), COLORS.WHITE, COLORS.BLACK, COLORS.WHITE, COLORS.WHITE);
/**
 * Legend which shows what colors are what pods, and what lines are what params,
 * for an H&A graph widget
 */
export var Legend = function (_a) {
    var graphState = _a.graphState, mini = _a.mini;
    var outerRef = useRef();
    var innerRef = useRef();
    var pods = uniqBy(graphState.lines || [], "pod.uuid").filter(function (pod) { return pod.pod.uuid !== "AVERAGE"; });
    var species = uniqBy(graphState.lines || [], "species.species_code");
    var _b = __read(useState(), 2), overflow = _b[0], setOverflow = _b[1];
    var _c = __read(useState(false), 2), expanded = _c[0], setExpanded = _c[1];
    /**
     * When all is loaded, check if the list of items in the legend are so large
     * that they are overflowing - if so, add "See More" button and logic to expand legend
     */
    useEffect(function () {
        if (graphState && !graphState.loading) {
            var overflow_1 = innerRef.current.scrollHeight - outerRef.current.clientHeight;
            setOverflow(overflow_1);
        }
    }, [graphState]);
    return (_jsxs(ExpandableCard, __assign({ flex: 1, ref: outerRef, elevation: 2, style: { marginTop: expanded ? "-".concat(overflow - 20, "px") : undefined }, className: clsx("graph-widget-legend", { mini: mini }) }, { children: [_jsxs(Card, __assign({ ref: innerRef, elevation: 2, flex: 1, style: { width: "100%" }, loading: !graphState || (graphState === null || graphState === void 0 ? void 0 : graphState.loading), loadingProps: {
                    color: COLORS.BLUE,
                } }, { children: [pods.map(function (pod) { return (_jsxs(Row, { children: [_jsx(ColorPill, { color: pod.color }), _jsx("span", __assign({ className: "one-line-ellipsis", style: { marginLeft: "20px" } }, { children: pod.pod.name }))] }, "pod-key" + pod.pod.uuid)); }), _jsx("div", { style: { height: "10px" } }), species.map(function (param) {
                        return (_jsxs(SpeciesRow, __assign({ mini: mini }, { children: [_jsx("span", __assign({ style: { paddingLeft: "4px" } }, { children: speciesLabels(param.species.species_name) })), _jsx("svg", __assign({ width: 160, height: 2 }, { children: _jsx("line", { x1: "0", y1: "0", x2: "100%", y2: "0", stroke: "black", strokeWidth: 2, strokeDasharray: DashArrayMap[param.dash] }) }))] }), "species-key" + param.species.species_code));
                    })] })), !!overflow && !(!graphState || (graphState === null || graphState === void 0 ? void 0 : graphState.loading)) && !mini && (_jsx(SeeMoreButton, __assign({ plump: true, onClick: function () { return setExpanded(!expanded); } }, { children: expanded ? "Hide" : "See All" })))] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

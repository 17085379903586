var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, useTheme } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo } from "react";
import { useSelector } from "react-redux";
var RightSide = function (_a) {
    var pollutants = _a.pollutants, selectedStation = _a.selectedStation;
    var theme = useTheme();
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var aqiType = userInfo.settings.aqi_type;
    var aqiSpec = selectedStation[aqiType];
    var barOptions = useMemo(function () {
        return {
            chart: {
                backgroundColor: "none",
                height: "".concat(pollutants.length * 32 + 6, "px"),
                type: "bar",
                width: 280,
            },
            exporting: {
                enabled: false,
            },
            xAxis: {
                title: { text: "" },
                labels: {
                    enabled: false,
                },
                lineWidth: 0,
                maxPadding: 0,
                categories: pollutants
                    .map(function (item, index) {
                    var _a;
                    var idx = pollutants === null || pollutants === void 0 ? void 0 : pollutants.findIndex(function (elem) { return elem.code === item.code; });
                    if (index === 0) {
                        return (aqiSpec.value === -1 ? 10 : aqiSpec.value * 10).toString();
                    }
                    else if (index > 0 && idx >= 0) {
                        return (_a = pollutants[idx]) === null || _a === void 0 ? void 0 : _a.percent.toString();
                    }
                })
                    .filter(Boolean)
                    .splice(0, 13),
            },
            yAxis: {
                title: {
                    text: "",
                },
                gridLineDashStyle: "ShortDash",
                gridLineWidth: 1,
                labels: {
                    enabled: false,
                },
                lineColor: theme.palette.success.main,
                max: Math.max(100, Math.max.apply(Math, __spreadArray([], __read(pollutants.map(function (item) { return Number(item.percent); })), false))),
                maxPadding: 0,
                min: 0,
            },
            plotOptions: {
                bar: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: false,
                },
            },
            title: {
                text: "",
            },
            credits: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            series: [
                {
                    type: "bar",
                    data: pollutants.map(function (i) { return Number(i.percent); }),
                    pointInterval: 3,
                    pointWidth: 8,
                    colorByPoint: true,
                    colors: pollutants.map(function (item) { return item.color; }),
                },
            ],
        };
    }, [aqiSpec.value, pollutants, theme.palette.success.main]);
    return (_jsx(Stack, __assign({ style: { height: "".concat(pollutants.length * 32, "px") } }, { children: _jsx(HighchartsReact, { highcharts: Highcharts, options: barOptions }) })));
};
export default RightSide;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button, Row, SelectTimeframeDropdown } from "components";
import { useDashboardSettings } from "hooks";
import { TimeRanges } from "models";
import { SelectWrapper } from "components/pages/dashboard/PodSelect";
import { BuildMapSteps } from ".";
import { timerangeOptionsWithNow, } from "../../../../../../constants";
import { SwipeMap } from "../SwipeMap";
/**
 * The select timeframe and map preview step of the create map modal for H&A
 */
export var SelectTimeframe = function (_a) {
    var _b;
    var species = _a.species, setTimeframe = _a.setTimeframe, setStep = _a.setStep, onSubmit = _a.onSubmit, editMode = _a.editMode;
    var Dashboard = useDashboardSettings();
    var _c = __read(useState((_b = timerangeOptionsWithNow.find(function (range) { var _a; return range.value === ((_a = Dashboard === null || Dashboard === void 0 ? void 0 : Dashboard.dashboard) === null || _a === void 0 ? void 0 : _a.timerange); })) !== null && _b !== void 0 ? _b : timerangeOptionsWithNow.find(function (range) { return range.value === TimeRanges.PAST_MONTH; })), 2), start = _c[0], setStart = _c[1];
    var _d = __read(useState(timerangeOptionsWithNow.find(function (range) { return range.value === TimeRanges.NOW; })), 2), end = _d[0], setEnd = _d[1];
    useEffect(function () {
        setTimeframe({
            start: start.value,
            end: end.value,
        });
    }, [start.value, end.value]);
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "Select the points in time which you'd like to compare:" }), _jsxs(Row, __assign({ margin: "20px 0 12px 0", justifyContent: "space-between" }, { children: [_jsx(SelectWrapper, __assign({ style: { zIndex: 5000, maxWidth: "200px" } }, { children: _jsx(SelectTimeframeDropdown, { options: timerangeOptionsWithNow, value: __assign(__assign({}, start), { label: start.agoLabel }), onChange: function (e) { return setStart(e); }, label: "agoLabel" }) })), _jsx("h3", { children: "Preview" }), _jsx(SelectWrapper, __assign({ style: { zIndex: 5000, maxWidth: "200px" } }, { children: _jsx(SelectTimeframeDropdown, { options: timerangeOptionsWithNow, value: __assign(__assign({}, end), { label: end.agoLabel }), onChange: function (e) { return setEnd(e); }, label: "agoLabel" }) }))] })), _jsx("div", __assign({ style: { width: "70vw", height: "65vh" } }, { children: _jsx(SwipeMap, { id: "preview", start: start, end: end, species: species, MapViewProps: {
                        zoomControl: false,
                    } }) })), _jsxs(Row, __assign({ justifyContent: "center", style: { margin: "20px 0 0 0" } }, { children: [_jsx(Button, __assign({ style: { width: "50%", borderRadius: "18px", marginRight: "30px" }, plump: true, onClick: function () { return setStep(BuildMapSteps.SELECT_POLLUTANT); } }, { children: "Back" })), _jsx(Button, __assign({ style: { width: "50%", borderRadius: "18px" }, plump: true, outline: "filled", onClick: onSubmit }, { children: editMode ? "Save Changes" : "Create Map" }))] }))] }));
};

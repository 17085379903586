var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Atmospherics from "./atmospherics";
import Engineering from "./engineering";
import Pollutants from "./pollutants";
import { Stack } from "@mui/material";
export var StationReadings = function (_a) {
    var _b;
    var stationLatest = _a.stationLatest;
    return (_jsxs(Stack, __assign({ width: "100%", gap: "8px", mt: "24px" }, { children: [stationLatest.pollutants.length > 0 && (_jsx(Pollutants, { stationLatest: stationLatest })), stationLatest.atmospherics.length > 0 && (_jsx(Atmospherics, { atmospherics: stationLatest.atmospherics })), stationLatest.engineerings && ((_b = stationLatest.engineerings) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsx(Engineering, { engineerings: stationLatest.engineerings, orientations: stationLatest === null || stationLatest === void 0 ? void 0 : stationLatest.orientations }))] })));
};

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
/**
 * Helpful media query hook, borrowed from https://usehooks-ts.com/react-hook/use-media-query
 *
 * @example
 * export default function Component() {
 * const matches = useMediaQuery('(min-width: 768px)')
 *
 *    return (
 *      <div>
 *        {`The view port is ${matches ? 'at least' : 'less than'} 768 pixels wide`}
 *      </div>
 *    )
 *  }
 */
export function useMediaQuery(query) {
    var getMatches = function (query) {
        // Prevents SSR issues
        if (typeof window !== "undefined") {
            return window.matchMedia(query).matches;
        }
        return false;
    };
    var _a = __read(useState(getMatches(query)), 2), matches = _a[0], setMatches = _a[1];
    function handleChange() {
        setMatches(getMatches(query));
    }
    useEffect(function () {
        var matchMedia = window.matchMedia(query);
        // Triggered at the first client-side load and if query changes
        handleChange();
        // Listen matchMedia
        matchMedia.addEventListener("change", handleChange);
        return function () {
            matchMedia.removeEventListener("change", handleChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);
    return matches;
}

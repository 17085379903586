var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapView, PodMarkers, LeafletControls, Geoman, FitMapToSelection, } from "components";
import { Maps, ActionCreators } from "store";
import { COLORS, POD_COMPARISON_COLORS } from "theme";
import { isPointInCircle, isPointInPolygon } from "utils";
/**
 * Map component used in the dashboard page to allow users to select pods from which
 * dashboard data is generated. Lives within modal, uses the state.filters.selectPodsMap
 * slice of state
 */
export var MapSelectMap = function () {
    var dispatch = useDispatch();
    var pods = useSelector(function (state) {
        return Object.values(state.pods.byId);
    });
    var selectedPods = useSelector(function (state) { return state.dashboard.filters.selected; });
    var podPreview = useSelector(function (state) {
        return state.dashboard.filters.selectPodsMap.view.podPreview;
    });
    var aqi_type = useSelector(function (state) {
        return state.dashboard.filters.selectPodsMap.data.meta.aqi_type;
    });
    var mapRef = useRef();
    /**
     * When user creates a circle, rectangle, or polygon, hook into the event.
     * Get all pods within the shape, and add to selected pods, and then remove
     * the shape from the map
     */
    var onShapeComplete = React.useCallback(function (e) {
        var _a, _b;
        var podsInShape = [];
        if (e.shape === "Circle") {
            podsInShape = pods
                .filter(function (pod) {
                return isPointInCircle({ lat: pod.latitude, lng: pod.longitude }, e.layer);
            })
                .map(function (_a) {
                var uuid = _a.uuid, name = _a.name, location = _a.location, address = _a.address;
                return ({
                    uuid: uuid,
                    name: name,
                    location: location,
                    address: address,
                });
            });
        }
        else {
            podsInShape = pods
                .filter(function (pod) {
                return isPointInPolygon({ lat: pod.latitude, lng: pod.longitude }, e.layer);
            })
                .map(function (_a) {
                var uuid = _a.uuid, name = _a.name, location = _a.location, address = _a.address;
                return ({
                    uuid: uuid,
                    name: name,
                    location: location,
                    address: address,
                });
            });
        }
        /** Add pods within shape to selected pods */
        dispatch(ActionCreators.selectPods(podsInShape));
        /** Disable draw mode and remove the shape */
        (_b = (_a = mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) === null || _a === void 0 ? void 0 : _a.pm) === null || _b === void 0 ? void 0 : _b.disableDraw();
        e.layer.remove();
    }, [pods]);
    /**
     * To understand why we are applying classNames specific to currently selected pods to
     * the map's wrapper div, see the notes in src/components/organisms/MapView/components/PodHaloCSS.tsx
     */
    var classNames = function () {
        if (selectedPods.length) {
            return selectedPods
                .map(function (pod) {
                return "pod-halo-".concat(pod.uuid, "-").concat(COLORS.BLUE.replace("#", "").toUpperCase());
            })
                .join(" ");
        }
        return "";
    };
    return (_jsx("div", __assign({ style: { height: "100%", width: "100%" }, className: classNames() }, { children: _jsxs(MapView, __assign({ ref: mapRef, style: { width: "64vw", height: "80vh" }, reduxLocation: { path: Maps.PodSelect }, zoomControl: false, id: "map-select-wrapper" }, { children: [_jsx(PodMarkers, { aqi_type: aqi_type, getHaloColor: function (pod) {
                        return selectedPods.map(function (p) { return p.uuid; }).includes(pod.uuid)
                            ? POD_COMPARISON_COLORS[0].primary
                            : undefined;
                    }, eventHandlers: {
                        click: function (e, pod) {
                            dispatch(ActionCreators.togglePodSelect({
                                name: pod.name,
                                uuid: pod.uuid,
                                location: pod.location,
                            }));
                        },
                        mouseover: function (e, pod) {
                            if (!podPreview) {
                                dispatch(ActionCreators.setPodPreview(pod, { path: Maps.PodSelect }));
                            }
                        },
                        mouseout: function () {
                            return dispatch(ActionCreators.setPodPreview(null, { path: Maps.PodSelect }));
                        },
                    } }), _jsx(Geoman
                // Need to use key to force event handlers to update when pods udpate
                , { toolbarOptions: {
                        position: "topright",
                        drawMarker: false,
                        drawCircleMarker: false,
                        drawPolyline: false,
                        drawText: false,
                        editControls: false,
                        oneBlock: true,
                    }, events: {
                        "pm:create": onShapeComplete,
                    }, languageOptions: {
                        lang: "en",
                        customTranslations: {
                            buttonTitles: {
                                drawRectButton: "Select sensors within a rectangle",
                                drawPolyButton: "Select sensors within a polygon",
                                drawCircleButton: "Select sensors within a circle",
                            },
                        },
                    } }, pods.length), _jsx(LeafletControls, __assign({ position: "bottomright" }, { children: _jsx(FitMapToSelection, { id: "select-pods-map-fit-selection-button" }) }))] })) })));
};

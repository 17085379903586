var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo } from "react";
import { COLORS } from "theme";
import { backGround, leastSquaresFit, plotBand, simpleMovingAverage, } from "utils";
import { useSettings } from "hooks";
import { round, speciesLabels, unitLabels } from "../../../../../../constants";
var gasses = ["NO2", "NO", "SO2", "CO", "O3", "PM10", "PM1", "PM2.5"];
var dashLineSvgMarkup = "\n  <svg \n    width=\"60\" \n    height=\"9\" \n    style=\"\n      position: absolute; \n      left: 0; \n      right: 0;\n      width: 100%;\n    \"\n  >\n    <path\n      d=\"M0 5 L292 5\"\n      stroke=\"black\"\n      stroke-width=\"0.5\"\n    ></path>\n  </svg>  \n";
export var useSeries = function (_a) {
    var speciesInConfig = _a.species, graphState = _a.graphState, showAsAverage = _a.showAsAverage, showMovingAverage = _a.showMovingAverage, movingAverageInterval = _a.movingAverageInterval, showMinMaxHighlights = _a.showMinMaxHighlights, showMinMaxAnnotations = _a.showMinMaxAnnotations, showTrendLines = _a.showTrendLines;
    var _b = useSettings(), aqi_type = _b.aqi_type, unitsSettngs = _b.unit, pollutant_unit = _b.pollutant_unit;
    var aqiColorToggleMakesSense = speciesInConfig.filter(function (species) { return backGround(species.species_name); })
        .length === 1;
    var yAxis = useMemo(function () {
        var axes = [];
        var gasAxesAlreadyCreated = false;
        var gassesBeingPlotted = speciesInConfig.filter(function (species) {
            return gasses.includes(species.species_code);
        });
        var otherSpeciesBeingPlotted = speciesInConfig.filter(function (species) { return !gasses.includes(species.species_code); });
        var uniqueAxes = otherSpeciesBeingPlotted.length + (gassesBeingPlotted.length ? 1 : 0);
        var gasAxisNamePrefix = gassesBeingPlotted
            .map(function (species) { return speciesLabels(species.species_name); })
            .join(", ");
        var speciesContainsAqi = speciesInConfig
            .map(function (s) { return s.species_code; })
            .includes("aqi");
        speciesInConfig.forEach(function (species, i) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            var speciesUnit = species.species_code === "aqi"
                ? aqi_type.toUpperCase()
                : (_b = (_a = Object.values(unitsSettngs)
                    .flat()
                    .find(function (unit) { return unit.code === species.species_code; })) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : (_h = (_g = (_f = (_e = (_d = (_c = graphState === null || graphState === void 0 ? void 0 : graphState.lines) === null || _c === void 0 ? void 0 : _c.find(function (line) { var _a; return ((_a = line === null || line === void 0 ? void 0 : line.species) === null || _a === void 0 ? void 0 : _a.species_code) === (species === null || species === void 0 ? void 0 : species.species_code); })) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.items) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.unit;
            var plotBands = aqiColorToggleMakesSense &&
                plotBand((_k = (_j = speciesInConfig.filter(function (s) { return backGround(s.species_name); })) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.species_name, aqi_type);
            var opposite = true;
            if (speciesContainsAqi) {
                if (species.species_code === "aqi") {
                    opposite = false;
                }
            }
            else if (i === 0) {
                opposite = false;
            }
            var baseAxis = {
                gridLineWidth: 0,
                plotBands: plotBands,
                opposite: opposite,
                className: "highcharts-custom-y-axis-".concat(species.species_code),
                labels: {
                    reserveSpace: true,
                },
            };
            if (gasses.includes(species.species_code)) {
                if (!gasAxesAlreadyCreated) {
                    axes.push(__assign(__assign({}, baseAxis), { id: "gasses", title: {
                            text: "\n                <div \n                  style=\"\n                    width: 292px; \n                    position: relative; \n                    display: flex; \n                    justify-content: center; \n                    align-items: center;\n                  \"\n                >\n                  ".concat(uniqueAxes > 1
                                ? gassesBeingPlotted.map(function () { return dashLineSvgMarkup; }).join("")
                                : "", "\n                  <span style=\"background: white; z-index: 1; padding: 0px 5px;\">\n                    ").concat(gasAxisNamePrefix, ": ").concat(unitLabels(pollutant_unit), "\n                  </span>\n                </div>\n              "),
                            useHTML: true,
                        } }));
                    gasAxesAlreadyCreated = true;
                }
            }
            else {
                axes.push(__assign(__assign({}, baseAxis), { id: species.species_code, title: {
                        text: "\n              <div \n                style=\"\n                  width: 292px; \n                  position: relative; \n                  display: flex; \n                  justify-content: center; \n                  align-items: center;\n                \"\n              >\n                ".concat(uniqueAxes > 1 ? dashLineSvgMarkup : "", "\n                <span style=\"background: white; z-index: 1; padding: 0px 5px;\">\n                  ").concat(species.species_name, ": ").concat(unitLabels(speciesUnit), "\n                </span>\n              </div>\n            "),
                        useHTML: true,
                    } }));
            }
        });
        return axes;
    }, [graphState]);
    // }, [graphState, zoomedTimeframe]);
    var series = useMemo(function () {
        var _a, _b, _c;
        var trendLineLegendItems = [];
        // const min = 0
        // const max = 18000000000000
        // const min = new Date(zoomedTimeframe[0]).getTime()
        // const max = new Date(zoomedTimeframe[1]).getTime()
        var lines = ((_a = graphState === null || graphState === void 0 ? void 0 : graphState.lines) === null || _a === void 0 ? void 0 : _a.length)
            ? (_c = (_b = graphState === null || graphState === void 0 ? void 0 : graphState.lines) === null || _b === void 0 ? void 0 : _b.filter(function (line) {
                return showAsAverage
                    ? line.pod.uuid === "AVERAGE"
                    : line.pod.uuid !== "AVERAGE";
            })) === null || _c === void 0 ? void 0 : _c.map(function (line) {
                var _a, _b, _c, _d, _e;
                return ({
                    type: "spline",
                    name: ((_a = line === null || line === void 0 ? void 0 : line.pod) === null || _a === void 0 ? void 0 : _a.name) +
                        ": " +
                        speciesLabels((_b = line === null || line === void 0 ? void 0 : line.species) === null || _b === void 0 ? void 0 : _b.species_name),
                    color: line.color,
                    dashStyle: line.dash,
                    lineWidth: 2,
                    opacity: 1,
                    marker: { enabled: false },
                    stickyTracking: false,
                    enableMouseTracking: true,
                    className: line.pod.uuid === "AVERAGE" ? "average-line" : "pod-line",
                    yAxis: (function () {
                        var namedAxis = gasses.includes(line.species.species_code)
                            ? "gasses"
                            : line.species.species_code;
                        return yAxis.map(function (axis) { return axis.id; }).includes(namedAxis)
                            ? namedAxis
                            : 0;
                    })(),
                    data: (_e = (_d = (_c = line === null || line === void 0 ? void 0 : line.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e.map(function (item) { return [
                        +new Date(item.date),
                        +item.value,
                    ]; }),
                });
            })
            : // .filter(item => item.data >= min && point.x <= max)
                [];
        // if (zoomedTimeframe) {
        //   lines = [{...lines[0], data: lines[0]?.data?.filter(item => item[0] >= min && item[0] <= max)}]
        // }
        if (showMovingAverage) {
            var smaLines = lines.map(function (line) { return (__assign(__assign({}, line), { data: simpleMovingAverage(line.data, Math.floor(movingAverageInterval)), lineWidth: 8, opacity: 0.5, name: line.name + " SMA", className: line.className + " sma-line", marker: { enabled: false } })); });
            lines = __spreadArray(__spreadArray([], __read(lines), false), __read(smaLines), false);
        }
        if (showTrendLines) {
            var trendLines = lines.map(function (line) {
                var fit = leastSquaresFit(line.data);
                var sign = Math.abs(fit.m) < 1e-100 ? "" : fit.m > 0 ? "+" : "-";
                trendLineLegendItems.push({
                    color: line.color,
                    dashStyle: line.dashStyle,
                    lineWidth: line.lineWidth,
                    title: "R\u00B2: ".concat(round(fit.R2), ", Trend: ").concat(sign).concat((Math.abs(fit.m) * 100).toFixed(2), "%"),
                });
                return __assign(__assign({}, line), { data: fit.results, className: line.className + " trend-line", enableMouseTracking: false });
            });
            lines = __spreadArray(__spreadArray([], __read(lines), false), __read(trendLines), false);
        }
        if (showMinMaxHighlights) {
            var maximums = lines
                .filter(function (l) { return !l.className.includes("trend-line"); })
                .map(function (line) {
                var max = line.data.reduce(function (prev, curr) { return (prev[1] > curr[1] ? prev : curr); }, [-100, -100]);
                var allMaxes = line.data.filter(function (point) { return point[1] === max[1]; });
                return allMaxes;
            });
            var minimums = lines
                .filter(function (l) { return !l.className.includes("trend-line"); })
                .map(function (line) {
                var min = line.data.reduce(function (prev, curr) { return (prev[1] < curr[1] ? prev : curr); }, [10e100, 10e100]);
                var allMins = line.data.filter(function (point) { return point[1] === min[1]; });
                return allMins;
            });
            var maxSeries = maximums.map(function (points, i) {
                var _a, _b, _c;
                return ({
                    data: points,
                    lineWidth: 0,
                    name: ((_b = (_a = graphState.lines) === null || _a === void 0 ? void 0 : _a[i]) === null || _b === void 0 ? void 0 : _b.pod.name) ||
                        "Unknown Pod" +
                            " " +
                            speciesLabels((_c = graphState.lines[i]) === null || _c === void 0 ? void 0 : _c.species.species_name) +
                            " " +
                            "Max",
                    className: "dot-marker",
                    marker: {
                        enabled: true,
                        symbol: "circle",
                        radius: 4,
                        fillColor: COLORS.RED,
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                        },
                    },
                });
            });
            var minSeries = minimums.map(function (points, i) {
                var _a, _b;
                return ({
                    data: points,
                    lineWidth: 0,
                    name: ((_a = graphState.lines[i]) === null || _a === void 0 ? void 0 : _a.pod.name) ||
                        "Unknown Pod" +
                            " " +
                            speciesLabels((_b = graphState.lines[i]) === null || _b === void 0 ? void 0 : _b.species.species_name) +
                            " " +
                            "Min",
                    className: "dot-marker",
                    marker: {
                        enabled: true,
                        symbol: "circle",
                        radius: 4,
                        fillColor: COLORS.GREEN,
                    },
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                        },
                    },
                });
            });
            lines = lines.map(function (l) { return (__assign(__assign({}, l), { enableMouseTracking: false })); });
            // @ts-ignore not all objects have to be the same for highcharts
            lines = __spreadArray(__spreadArray(__spreadArray([], __read(lines), false), __read(maxSeries), false), __read(minSeries), false);
        }
        return { lines: lines, trendLineLegendItems: trendLineLegendItems };
    }, [
        graphState,
        showAsAverage,
        showMovingAverage,
        movingAverageInterval,
        showMinMaxHighlights,
        showTrendLines,
        // zoomedTimeframe
    ]);
    var annotations = useMemo(function () {
        if (!showMinMaxHighlights || !showMinMaxAnnotations)
            return [];
        var maximums = series.lines
            .filter(function (s) {
            return s.className !== "dot-marker" && !s.className.includes("trend-line");
        })
            .map(function (line) {
            return line.data.reduce(function (prev, curr) { return (prev[1] > curr[1] ? prev : curr); }, [-100, -100]);
        })
            .map(function (max, i) {
            var _a, _b, _c, _d, _e, _f;
            return ({
                x: max[0],
                y: max[1],
                pod: (_c = (_b = (_a = graphState.lines) === null || _a === void 0 ? void 0 : _a[i]) === null || _b === void 0 ? void 0 : _b.pod) === null || _c === void 0 ? void 0 : _c.name,
                species: (_f = (_e = (_d = graphState.lines) === null || _d === void 0 ? void 0 : _d[i]) === null || _e === void 0 ? void 0 : _e.species) === null || _f === void 0 ? void 0 : _f.species_name,
            });
        });
        var minimums = series.lines
            .filter(function (s) {
            return s.className !== "dot-marker" && !s.className.includes("trend-line");
        })
            .map(function (line) {
            return line.data.reduce(function (prev, curr) { return (prev[1] < curr[1] ? prev : curr); }, [10e100, 10e100]);
        })
            .map(function (min, i) {
            var _a, _b, _c, _d, _e, _f;
            return ({
                x: min[0],
                y: min[1],
                pod: (_c = (_b = (_a = graphState.lines) === null || _a === void 0 ? void 0 : _a[i]) === null || _b === void 0 ? void 0 : _b.pod) === null || _c === void 0 ? void 0 : _c.name,
                species: (_f = (_e = (_d = graphState.lines) === null || _d === void 0 ? void 0 : _d[i]) === null || _e === void 0 ? void 0 : _e.species) === null || _f === void 0 ? void 0 : _f.species_name,
            });
        });
        var annotations = __spreadArray(__spreadArray([], __read(maximums.map(function (m) { return ({
            draggable: "xy",
            labelOptions: {
                allowOverlap: true,
                shape: "connector",
            },
            labels: [
                {
                    point: { x: m.x, y: m.y, xAxis: 0, yAxis: 0 },
                    text: "Max ".concat(speciesLabels(m.species), ": ").concat(m.y, " at ").concat(new Date(m.x).toLocaleString()),
                },
            ],
        }); })), false), __read(minimums.map(function (m) { return ({
            draggable: "xy",
            labelOptions: {
                allowOverlap: true,
                shape: "connector",
            },
            labels: [
                {
                    point: { x: m.x, y: m.y, xAxis: 0, yAxis: 0 },
                    text: "Min ".concat(speciesLabels(m.species), ": ").concat(m.y, " at ").concat(new Date(m.x).toLocaleString()),
                },
            ],
        }); })), false);
        return annotations;
    }, [graphState, showMinMaxHighlights, showMinMaxAnnotations, series]);
    return {
        series: series.lines,
        annotations: annotations,
        trendLineLegendItems: series.trendLineLegendItems,
        yAxis: yAxis,
    };
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Row, NavMenu, privateRoutes, telecomRoutes, Column, NavHeader, LoadingSpinnerBig, PodHaloCSS, } from "components";
import { Error404, LoginPage, LoggedInResetPassword } from "components/pages";
import { useAuthentication } from "hooks";
import { COLORS } from "theme";
var AppWrapper = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\theight: 100%;\n\talign-items: stretch;\n"], ["\n\theight: 100%;\n\talign-items: stretch;\n"])));
var Main = styled.main(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tflex: 1;\n\tbackground-color: ", ";\n\twidth: 100%;\n\tpadding: 32px;\n\toverflow-y: auto;\n\toverflow-x: hidden;\n"], ["\n\tflex: 1;\n\tbackground-color: ", ";\n\twidth: 100%;\n\tpadding: 32px;\n\toverflow-y: auto;\n\toverflow-x: hidden;\n"])), COLORS.BG);
/**
 * The rendering content of the application - where the user arrives to when visiting the
 * page, what they *see*.
 */
export var ArrivalPage = function () {
    var _a;
    var authCheckComplete = useAuthentication();
    // const dispatch = useDispatch();
    // useEffect(() => {
    // 	dispatch(ActionCreators.updateSettings(userInfoData as any));
    // }, []);
    var authenticated = useSelector(function (state) { return state.user.auth.authenticated; });
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    if (!authCheckComplete)
        return null;
    if (!authenticated) {
        return _jsx(LoginPage, {});
    }
    if (!userInfo)
        return _jsx(LoadingSpinnerBig, {});
    var isTelecom = (_a = userInfo.roles) === null || _a === void 0 ? void 0 : _a.some(function (obj) { return obj.name === "Telecom"; });
    var routes = isTelecom ? telecomRoutes : privateRoutes;
    return (_jsxs(AppWrapper, { children: [_jsx(NavMenu, {}), _jsxs(Column, __assign({ style: { flex: 1, minWidth: "1136px" }, justifyContent: "stretch" }, { children: [_jsx(NavHeader, {}), _jsx(Main, __assign({ style: { flex: 1 } }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "*", element: _jsx(Error404, {}) }), _jsx(Route, { path: "reset-password", element: _jsx(LoggedInResetPassword, {}) }), routes.map(function (route) { return (_jsx(Route, { path: route.path, element: _jsx(Suspense, __assign({ fallback: _jsx(LoadingSpinnerBig, {}) }, { children: userInfo ? _jsx(route.component, {}) : _jsx(LoadingSpinnerBig, {}) })) }, route.path)); })] }) }))] })), _jsx(PodHaloCSS, {})] }));
};
var templateObject_1, templateObject_2;

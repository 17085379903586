var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
// @ts-expect-error
import CHANGELOG from "../../../../CHANGELOG.md";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  h2 ~ *:not(h2):not(hr) {\n    margin-left: 40px;\n  }\n  h2 {\n    border-top: 1px solid grey;\n    padding-top: 20px;\n    margin-top: 40px;\n  }\n\n  h4 + h2 {\n    border-top: none;\n    padding-top: 0;\n    margin-top: 0;\n  }\n"], ["\n  h2 ~ *:not(h2):not(hr) {\n    margin-left: 40px;\n  }\n  h2 {\n    border-top: 1px solid grey;\n    padding-top: 20px;\n    margin-top: 40px;\n  }\n\n  h4 + h2 {\n    border-top: none;\n    padding-top: 0;\n    margin-top: 0;\n  }\n"])));
/**
 * Modal component showing changelog information
 */
export var Changelog = function () {
    return (_jsxs(Wrapper, __assign({ id: "changelog" }, { children: [_jsx("h4", __assign({ className: "blue", style: { marginTop: 0, letterSpacing: "3px", fontWeight: "normal" } }, { children: "CHANGELOG" })), _jsx(ReactMarkdown, { children: CHANGELOG })] })));
};
var templateObject_1;

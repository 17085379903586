export var avgOptions = [
    { label: "2 Minutes", value: 2 },
    { label: "15 Minutes", value: 15 },
    { label: "30 Minutes", value: 30 },
    { label: "1 Hours", value: 60 },
    { label: "8 Hours", value: 480 },
    { label: "24 Hours", value: 1440 },
];
export var timeSelectorOptions = [
    { label: "Fixed Frame", value: "frame" },
    { label: "Calendar Range", value: "range" },
];
export * from "./apis";
export * from "./defaults";
export * from "./definitions";
export * from "./text";

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import List from "../list";
import { Stack, Typography } from "@mui/material";
// TODO: Move the logic to the BE
var SubHeading = function (_a) {
    var items = _a.items, title = _a.title;
    return (_jsxs(Stack, __assign({ width: "100%", style: {
            paddingLeft: "10px",
            paddingTop: "10px",
        } }, { children: [_jsx(Typography, __assign({ variant: "body1" }, { children: title })), _jsx(List, { items: items, nameVariant: "caption", valueVariant: "caption" })] })));
};
export default SubHeading;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { TimeRanges } from "models";
import { COLORS } from "theme";
import { formatUTC, nowUTC } from "utils";
/**
 * List of ranges for various aqi index types, as well as their color schemes
 */
export var AqiScaleRanges = {
    daqi: {
        min: 1,
        max: 10,
        minHide: 2,
        maxHide: 9,
        brackets: [
            {
                range: [1, 3],
                text: "1 - 3",
                name: "Low",
                color: COLORS.GREEN,
                flexGrow: 3,
            },
            {
                range: [4, 6],
                text: "4 - 6",
                name: "Moderate",
                color: COLORS.YELLOW,
                flexGrow: 3,
            },
            {
                range: [7, 9],
                text: "7 - 9",
                name: "High",
                color: COLORS.RED,
                flexGrow: 3,
            },
            {
                range: [9, 10],
                text: "10",
                name: "Very High",
                color: COLORS.PURPLE,
                flexGrow: 1,
            },
        ],
        pollutantIntervals: {
            AQI: [0, 3, 6, 9, 10],
            O3: [0, 100, 160, 240, 1000],
            NO2: [0, 200, 400, 600, 2000],
            SO2: [0, 266, 532, 1064, 2000],
            "PM2.5": [0, 35, 53, 70, 500],
            PM10: [0, 50, 75, 100, 500],
        },
        pillWidth: "54px",
    },
    caqi: {
        min: 0,
        max: 100,
        minHide: 5,
        maxHide: 90,
        brackets: [
            {
                range: [0, 25],
                text: "0 - 25",
                name: "Very Low",
                color: COLORS.GREEN,
                flexGrow: 25,
            },
            {
                range: [25, 50],
                text: "25 - 50",
                name: "Low",
                color: COLORS.GREEN2,
                flexGrow: 25,
            },
            {
                range: [50, 75],
                text: "50 - 75",
                name: "Medium",
                color: COLORS.YELLOW,
                flexGrow: 25,
            },
            {
                range: [75, 99],
                text: "75 - 100",
                name: "High",
                color: COLORS.ORANGE,
                flexGrow: 25,
            },
            {
                range: [99, 100],
                text: "100+",
                name: "Very High",
                color: COLORS.PURPLE,
                flexGrow: 2,
            },
        ],
        pollutantIntervals: {
            AQI: [0, 25, 50, 75, 100, 1000],
            O3: [0, 60, 120, 180, 240, 2000],
            NO2: [0, 50, 100, 200, 400, 2000],
            SO2: [0, 50, 100, 350, 500, 5000],
            "PM2.5": [0, 15, 30, 55, 110, 300],
            PM10: [0, 25, 50, 90, 180, 300],
        },
        pillWidth: "64px",
    },
    usaqi: {
        min: 0,
        max: 500,
        minHide: 30,
        maxHide: 440,
        brackets: [
            {
                range: [0, 50],
                text: "0 - 50",
                name: "Good",
                color: "#00e400",
                flexGrow: 50,
            },
            {
                range: [51, 100],
                text: "50 - 100",
                name: "Moderate",
                color: "#ffbc0f",
                flexGrow: 50,
            },
            {
                range: [101, 150],
                text: "100 - 150",
                name: "Caution",
                color: "#ff7e00",
                flexGrow: 50,
            },
            {
                range: [151, 200],
                text: "150 - 200",
                name: "Unhealthy",
                color: "#ff0000",
                flexGrow: 50,
            },
            {
                range: [201, 300],
                text: "200 - 300",
                name: "Very Unhealthy",
                color: "#8f3f97",
                flexGrow: 100,
            },
            {
                range: [301, 500],
                text: "300 - 500",
                name: "Hazardous",
                color: "#7e0019",
                flexGrow: 200,
            },
        ],
        pollutantIntervals: {
            AQI: [0, 50, 100, 150, 200, 300, 99999],
            O3: [0, 54, 70, 85, 105, 200, 99999],
            NO2: [0, 53, 100, 360, 650, 1250, 2050],
            SO2: [0, 35, 76, 186, 305, 605, 99999],
            "PM2.5": [0, 12, 35.5, 55.5, 150.5, 250.5, 99999],
            PM10: [0, 55, 155, 255, 355, 425, 99999],
        },
        pillWidth: "84px",
    },
};
/**
 * Different time presets the user can choose
 */
export var timerangeOptions = [
    {
        label: "Past 24 Hours",
        agoLabel: "24 Hours Ago",
        shortname: "1D",
        value: TimeRanges.PAST_DAY,
        getTime: function () {
            var start = new Date();
            start.setHours(start.getHours() - 24);
            return formatUTC(start);
        },
    },
    {
        label: "Past 7 Days",
        agoLabel: "7 Days Ago",
        shortname: "7D",
        value: TimeRanges.PAST_WEEK,
        getTime: function () {
            var start = new Date();
            start.setHours(start.getHours() - 7 * 24);
            return formatUTC(start);
        },
    },
    {
        label: "Past 30 Days",
        agoLabel: "30 Days Ago",
        shortname: "1M",
        value: TimeRanges.PAST_MONTH,
        getTime: function () {
            var start = new Date();
            start.setMonth(start.getMonth() - 1);
            return formatUTC(start);
        },
    },
    {
        label: "Past 90 Days",
        agoLabel: "90 Days Ago",
        shortname: "3M",
        value: TimeRanges.PAST_QUARTER,
        getTime: function () {
            var start = new Date();
            start.setMonth(start.getMonth() - 3);
            return formatUTC(start);
        },
    },
    {
        label: "Year to Date",
        agoLabel: "Start of Year",
        shortname: "YTD",
        value: TimeRanges.YEAR_TO_DATE,
        getTime: function () {
            var start = new Date();
            start.setHours(0);
            start.setDate(1);
            start.setMonth(0);
            return formatUTC(start);
        },
    },
    {
        label: "Past Year",
        agoLabel: "1 Year Ago",
        shortname: "1Y",
        value: TimeRanges.PAST_YEAR,
        getTime: function () {
            var start = new Date();
            start.setFullYear(start.getFullYear() - 1);
            return formatUTC(start);
        },
    },
    {
        label: "Custom",
        agoLabel: "Custom",
        shortname: "Custom",
        value: TimeRanges.CUSTOM,
        getTime: function (value) {
            var now = new Date();
            if (!value)
                return formatUTC(new Date(now.getTime() - 2 * 24 * 60 * 60 * 1000));
            var start = new Date(now.getTime() - Number(value) * 24 * 60 * 60 * 1000);
            return formatUTC(start);
        },
    },
];
/**
 * Different time presets the user can choose, including now
 */
export var timerangeOptionsWithNow = __spreadArray([
    {
        label: "Now",
        agoLabel: "Now",
        shortname: "Now",
        value: TimeRanges.NOW,
        getTime: function () { return nowUTC(); },
    }
], __read(timerangeOptions.filter(function (item) { return item.label !== "Custom"; })), false);

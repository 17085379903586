import { AqiScaleRanges } from "../constants";
/**
 * Helpful color manipulation utility functions
 */
/**
 * Convenience function to parse hex value and pad with 0s of necessary
 */
var hex = function (x) {
    x = x.toString(16);
    return x.length === 1 ? "0" + x : x;
};
/**
 * Utility function to blend colors / find a color between two colors, given the ratio between them
 *
 * From https://stackoverflow.com/a/16360660/12010984
 * @param color1 First color
 * @param color2 Second color
 * @param ratio ratio between colors, must be between 0 and 1, non inclusive
 */
export var interpolateHex = function (color1, color2, ratio) {
    // Remove hash value, if there is one
    color1 = color1.replace("#", "");
    color2 = color2.replace("#", "");
    var r = Math.ceil(parseInt(color1.substring(0, 2), 16) * ratio +
        parseInt(color2.substring(0, 2), 16) * (1 - ratio));
    var g = Math.ceil(parseInt(color1.substring(2, 4), 16) * ratio +
        parseInt(color2.substring(2, 4), 16) * (1 - ratio));
    var b = Math.ceil(parseInt(color1.substring(4, 6), 16) * ratio +
        parseInt(color2.substring(4, 6), 16) * (1 - ratio));
    return "#" + hex(r) + hex(g) + hex(b);
};
/**
 * Function to lighten or darken a hex color
 * from https://stackoverflow.com/a/13532993/12010984
 *
 * @example
 * // Lighten:
 * shadeColor("#63C6FF",40);
 *
 * @example
 * // Darken:
 * shadeColor("#63C6FF",-40);
 */
export function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);
    R = parseInt(((R * (100 + percent)) / 100).toString());
    G = parseInt(((G * (100 + percent)) / 100).toString());
    B = parseInt(((B * (100 + percent)) / 100).toString());
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);
    return "#" + RR + GG + BB;
}
/**
 * Function to generate a css linear interpolation string based on a series of colored brackets
 */
export var bracketsToLinearGradient = function (brackets) {
    var bl = brackets.length - 1;
    var totalSpread = brackets[bl].max - brackets[0].min;
    var result = "linear-gradient(to right,";
    for (var i = 0; i < bl; i++) {
        var bracketSpread = brackets[i + 1].min - brackets[i].min;
        var percent = i * (bracketSpread / totalSpread) * 100;
        var color = " ".concat(brackets[i].color, " ").concat(percent, "%,");
        result = result + color;
    }
    result = result + " ".concat(brackets[bl].color, " 100%");
    result = result + ")";
    return result;
};
/**
 * How transparent to make the hex color.  Tacked on to the
 * end of a hex color string to affect its transparency
 * See more: https://stackoverflow.com/a/8254129/12010984
 */
var hexOpacity = "4C";
// const hexOpacity = "";
/**
 * Utility function to automatically generate plot band colors for
 * highcharts graphs, based on the color brackets used for the
 * different AQI indexes, and scaled to the different pollutant types
 * within that aqi index schema.
 */
export var plotBand = function (activePollutant, aqi_type) {
    var AqiSpec = AqiScaleRanges[aqi_type];
    var range = AqiSpec.pollutantIntervals[activePollutant];
    var brackets = AqiSpec.brackets;
    var bands = [];
    var firstInterpolatedColor = interpolateHex(brackets[0].color, brackets[1].color, 0.5);
    bands.push({
        from: range[0],
        to: range[1],
        color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
                [0, firstInterpolatedColor + hexOpacity],
                [0.8, brackets[0].color + hexOpacity],
                [1, brackets[0].color + hexOpacity], // primary color (start)
            ],
        },
    });
    /**
     * Dynamically generate bands from aqi spec
     */
    for (var i = 1; i < brackets.length - 1; i++) {
        /**
         * First color to interpolate to.  If creating first band, there is no first intermediate,
         * we just use the same color again
         */
        var intermediateColor1 = interpolateHex(brackets[i - 1].color, brackets[i].color, 0.5);
        /**
         * Second color to interpolate to.  If creating last band, there is no second intermediate,
         * we just use the last color again
         */
        var intermediateColor2 = interpolateHex(brackets[i].color, brackets[i + 1].color, 0.5);
        var band = {
            from: range[i],
            to: range[i + 1],
            color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, intermediateColor2 + hexOpacity],
                    [0.2, brackets[i].color + hexOpacity],
                    [0.8, brackets[i].color + hexOpacity],
                    [1, intermediateColor1 + hexOpacity], // intermediate color
                ],
            },
        };
        bands.push(band);
    }
    var lastInterpolatedColor = interpolateHex(brackets[brackets.length - 2].color, brackets[brackets.length - 1].color, 0.5);
    bands.push({
        from: range[range.length - 2],
        to: range[range.length - 1],
        color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
                [0, brackets[brackets.length - 1].color + hexOpacity],
                [0.8, brackets[brackets.length - 1].color + hexOpacity],
                [1, lastInterpolatedColor + hexOpacity], // intermediate color
            ],
        },
    });
    bands.push({
        from: range[range.length - 1],
        to: 100000000,
        color: brackets[brackets.length - 1].color + hexOpacity,
    });
    return bands;
};
export var backGround = function (activePollutant) {
    return activePollutant === "AQI" ||
        activePollutant === "O3" ||
        activePollutant === "NO2" ||
        activePollutant === "SO2" ||
        activePollutant === "PM2.5" ||
        activePollutant === "PM10";
};

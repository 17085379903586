var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Column, ErrorPrompt, Modal, TextAreaAutosize, } from "components/atoms";
import styled from "styled-components";
import { BiCheck } from "react-icons/bi";
import { COLORS } from "theme";
import { AiOutlinePlus } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { ActionCreators } from "store";
import { getFileExtension, toBase64 } from "utils";
var TextInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  border-radius: 15px;\n  border: none;\n  resize: none;\n  padding: 10px;\n  display: flex;\n  border: 1px solid ", ";\n  align-items: center;\n  margin-bottom: 10px;\n\n  &:focus {\n    outline: none;\n  }\n"], ["\n  width: 100%;\n  border-radius: 15px;\n  border: none;\n  resize: none;\n  padding: 10px;\n  display: flex;\n  border: 1px solid ", ";\n  align-items: center;\n  margin-bottom: 10px;\n\n  &:focus {\n    outline: none;\n  }\n"])), COLORS.GREY);
var UploadButton = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  font-size: 14px;\n  cursor: ", ";\n  margin-bottom: 10px;\n"], ["\n  display: flex;\n  align-items: center;\n  color: ", ";\n  font-size: 14px;\n  cursor: ", ";\n  margin-bottom: 10px;\n"])), function (props) { return (props.disabled ? "#8f96a0" : "#00a7e1"); }, function (props) { return (props.disabled ? "auto" : "pointer"); });
var ImageGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n"])));
var ThumbNail = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 22%;\n  height: 70px;\n  background-image: ", ";\n  background-size: cover;\n  margin: 0px 7px 14px 0;\n  position: relative;\n  border: 0.5px solid ", ";\n  border-radius: 5px;\n  cursor: pointer;\n"], ["\n  width: 22%;\n  height: 70px;\n  background-image: ", ";\n  background-size: cover;\n  margin: 0px 7px 14px 0;\n  position: relative;\n  border: 0.5px solid ", ";\n  border-radius: 5px;\n  cursor: pointer;\n"])), function (props) { return "url(".concat(props.imageUrl, ")"); }, COLORS.GREY);
var Preview = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 20px;\n  max-height: 75vh;\n  max-width: 80vw;\n  border: 1px solid ", ";\n  border-radius: 5px;\n"], ["\n  margin-top: 20px;\n  max-height: 75vh;\n  max-width: 80vw;\n  border: 1px solid ", ";\n  border-radius: 5px;\n"])), COLORS.GREY);
var DeleteButton = styled(IoCloseOutline)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 18px;\n  width: 18px;\n  position: absolute;\n  top: -9px;\n  right: -5px;\n  cursor: pointer;\n  border-radius: 50%;\n  display: flex;\n  background-color: ", ";\n  align-items: center;\n  justify-content: center;\n  border: 0.5px solid ", ";\n"], ["\n  height: 18px;\n  width: 18px;\n  position: absolute;\n  top: -9px;\n  right: -5px;\n  cursor: pointer;\n  border-radius: 50%;\n  display: flex;\n  background-color: ", ";\n  align-items: center;\n  justify-content: center;\n  border: 0.5px solid ", ";\n"])), COLORS.WHITE, COLORS.GREY);
var CloseButton = styled(IoCloseOutline)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 36px;\n  width: 36px;\n  cursor: pointer;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  height: 36px;\n  width: 36px;\n  cursor: pointer;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var FallthroughColumn = styled(Column)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  pointer-events: none;\n  & * {\n    pointer-events: auto;\n  }\n"], ["\n  pointer-events: none;\n  & * {\n    pointer-events: auto;\n  }\n"])));
var ALLOWED_FILETYPES = ["jpg", "jpeg", "png", "gif"];
export var ContactForm = function (props) {
    var dispatch = useDispatch();
    var loading = useSelector(function (state) { var _a; return (_a = state.general.contact) === null || _a === void 0 ? void 0 : _a.loading; });
    var success = useSelector(function (state) { var _a; return (_a = state.general.contact) === null || _a === void 0 ? void 0 : _a.success; });
    var error = useSelector(function (state) { var _a; return (_a = state.general.contact) === null || _a === void 0 ? void 0 : _a.error; });
    var userName = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.user) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.name; });
    var userEmail = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.user) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.email; });
    var _a = __read(useState(userName !== null && userName !== void 0 ? userName : ""), 2), name = _a[0], setName = _a[1];
    var _b = __read(useState(userEmail !== null && userEmail !== void 0 ? userEmail : ""), 2), email = _b[0], setEmail = _b[1];
    var _c = __read(useState(""), 2), content = _c[0], setContent = _c[1];
    var _d = __read(useState([]), 2), imageFiles = _d[0], setImageFiles = _d[1];
    var _e = __read(useState(), 2), fileError = _e[0], setFileError = _e[1];
    var _f = __read(useState(false), 2), isPreviewOpen = _f[0], setIsPreviewOpen = _f[1];
    var _g = __read(useState(), 2), filePreview = _g[0], setFilePreview = _g[1];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var encodedImages, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all(imageFiles.map(function (image) { return toBase64(image); }))];
                case 1:
                    encodedImages = _a.sent();
                    payload = {
                        name: name,
                        email: email,
                        content: content,
                        images: encodedImages,
                    };
                    dispatch(ActionCreators.requestContactUsForm(payload));
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Function to clear form responses
     */
    var clear = function () {
        dispatch(ActionCreators.clearContactUs());
        setName("");
        setEmail("");
        setContent("");
        setImageFiles([]);
        setFileError(undefined);
        setIsPreviewOpen(false);
        setFilePreview(undefined);
    };
    /**
     * Function to check that required form elements are complete
     */
    var formIsValid = function () {
        return name && email && content;
    };
    var contents = function () {
        if (success) {
            return (_jsxs(Column, __assign({ alignItems: "center" }, { children: [_jsx(BiCheck, { color: COLORS.BLUE, size: 40, style: { marginTop: "20px" } }), _jsx("h4", __assign({ style: { textAlign: "center", margin: "10px 10px 60px 10px" } }, { children: "Your inquiry has been sent. Someone from Persium customer support will get back to you shortly." })), _jsx(Button, __assign({ plump: true, outline: "filled", style: { alignSelf: "stretch" }, onClick: function () { return props.setIsOpen(false); } }, { children: "Back to Login" }))] })));
        }
        return (_jsxs(_Fragment, { children: [_jsxs(Column, __assign({ justifyContent: "space-between", alignItems: "stretch", style: { flexGrow: 1 } }, { children: [_jsxs(Column, { children: [_jsx(TextInput, { placeholder: "Name", value: name, onChange: function (e) { return setName(e.target.value); } }), _jsx(TextInput, { placeholder: "Email", value: email, onChange: function (e) { return setEmail(e.target.value); } }), _jsx(TextAreaAutosize, { minRows: 4, maxRows: 1000, placeholder: "How can we help?", value: content, onChange: function (e) { return setContent(e.target.value); } }), _jsxs(UploadButton, __assign({ htmlFor: "file-upload", className: "custom-file-upload", disabled: imageFiles.length >= 5, "aria-disabled": imageFiles.length >= 5 }, { children: [_jsx(AiOutlinePlus, { size: 24, style: { margin: "0 5px 0 0" } }), "Upload Photo ", imageFiles.length >= 5 && "(Maximum of 5)", _jsx("input", { disabled: imageFiles.length >= 5, id: "file-upload", type: "file", style: { display: "none" }, onChange: function (e) {
                                                var _a = e.target.files[0], name = _a.name, size = _a.size;
                                                if (!ALLOWED_FILETYPES.includes(getFileExtension(name))) {
                                                    setFileError(_jsxs("span", { children: ["The specific file ", name, " could not be uploaded. ", _jsx("br", {}), "Only files with the following extensions are allowed:", " ", ALLOWED_FILETYPES.join(", ")] }));
                                                }
                                                else if (size > 20 * 1024 * 1024) {
                                                    setFileError(_jsx("span", { children: "The file is too large. Allowed maximum file size is 20MB." }));
                                                }
                                                else {
                                                    setImageFiles(function (files) { return __spreadArray(__spreadArray([], __read(files), false), __read(Array.from(e.target.files)), false); });
                                                }
                                            } })] })), _jsx(ImageGroup, { children: imageFiles.map(function (file) {
                                        var imageUri = URL.createObjectURL(file);
                                        return (_jsx(ThumbNail, __assign({ imageUrl: imageUri, onClick: function () {
                                                setFilePreview(file);
                                                setIsPreviewOpen(true);
                                            } }, { children: _jsx(DeleteButton, { onClick: function (e) {
                                                    e.stopPropagation();
                                                    var remaining = imageFiles.filter(function (f) { return f.lastModified !== file.lastModified; });
                                                    setImageFiles(remaining);
                                                } }) }), file.lastModified));
                                    }) })] }), _jsx(ErrorPrompt, __assign({ visible: !!fileError }, { children: fileError })), _jsx(ErrorPrompt, __assign({ visible: !!error }, { children: error })), _jsx(Button, __assign({ outline: "filled", plump: true, disabled: !formIsValid(), onClick: handleSubmit, loading: loading }, { children: "Send" }))] })), filePreview && (_jsx(Modal, __assign({ wrapper: false, isOpen: isPreviewOpen, setIsOpen: setIsPreviewOpen, afterClose: function () { return setFilePreview(undefined); } }, { children: _jsxs(FallthroughColumn, __assign({ alignItems: "center" }, { children: [_jsx(CloseButton, { onClick: function () { return setFilePreview(undefined); }, color: COLORS.WHITE }), _jsx(Preview, { src: URL.createObjectURL(filePreview) })] })) })))] }));
    };
    return (_jsx(Modal, __assign({}, props, { afterClose: function () {
            if (props.afterClose) {
                props.afterClose();
            }
            clear();
        }, title: "Contact Us", wrapperStyle: { width: "540px" } }, { children: contents() })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import L from "leaflet";
import styled from "styled-components";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import { Row, IconButton, Tooltip } from "components";
import { COLORS } from "theme";
var Wrapper = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 24px;\n  // for right side of map positioning:\n  flex-direction: row-reverse;\n"], ["\n  height: 24px;\n  // for right side of map positioning:\n  flex-direction: row-reverse;\n"])));
var ButtonWrapper = styled(IconButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 2px;\n  padding: 2px;\n  background-color: ", ";\n"], ["\n  border-radius: 2px;\n  padding: 2px;\n  background-color: ", ";\n"])), COLORS.BLACK);
/**
 * Control button that fits the map to the user's current pod selection.  Must be wrapped
 * in a <LeafletControls /> component
 *
 * Not actually an instance of L.Control, just a vanilla react component which
 * uses react-leaflet methods.  Must be a child of a `MapView` or `MapContainer`
 */
export var FitMapToSelection = function (_a) {
    var style = _a.style, id = _a.id;
    var map = useMap();
    var selectedPods = useSelector(function (state) { return state.dashboard.filters.selected; });
    var handleClick = function () {
        if (selectedPods.length) {
            var group = L.featureGroup(selectedPods.map(function (pod) {
                return L.marker({
                    lat: pod.location.latitude,
                    lng: pod.location.longitude,
                });
            }));
            map.fitBounds(group.getBounds().pad(0.2));
        }
    };
    if (!selectedPods.length)
        return null;
    return (_jsx(Tooltip, __assign({ contents: "Fit map to sensor selection", id: "fit-map-to-select-tooltip", place: "left" }, { children: _jsx(Wrapper, __assign({ className: "react-leaflet-control leaflet-control-fit-map-to-select", style: style }, { children: _jsx(ButtonWrapper, __assign({ id: id, size: 24, className: "react-leaflet-control-button", onClick: handleClick }, { children: _jsx(AiOutlineFullscreenExit, { color: COLORS.WHITE }) })) })) })));
};
var templateObject_1, templateObject_2;

/**
 * Common colors used throughout the app's color palette
 */
export var COLORS = {
    WHITE: "#FFFFFF",
    WHITEISH: "#F4F7FA",
    BLACK: "#000000",
    BLACKISH: "#1c1e24",
    BLUE: "#00a7e1",
    LIGHTBLUE: "#C7F0FF",
    DARKBLUE: "#154C72",
    GREY: "#8f96a0",
    DARKGREY: "#393D48",
    LIGHTGREY: "#d8dce1",
    GREY1: "#A1A5B6",
    GREEN3: "#CAD404",
    GREEN: "#31e077",
    GREEN2: "#7DB36C",
    YELLOW: "#ffbc0f",
    ORANGE: "#FF9604",
    PURPLE: "#d547ec",
    RED: "#ff5b5b",
    BURGUNDY: "#B70063",
    BG: "#eaf0f5",
};
/**
 * Colors used for pod comparison UI.  `primary` colors represent the color of the ring
 * around the pod marker when selected, as well as the color in the units, percent, and
 * graph UI of the rows, bars, and lines representing that pod.  `contrast` colors are
 * useful for choosing colors that contrast well with a pod primary color, but still maintain
 * color uniformity in app.  Available as hex colors or CSS filters.  Read more about how and
 * why to use filters here: https://codepen.io/sosuke/pen/Pjoqqp
 */
export var POD_COMPARISON_COLORS = [
    {
        primary: "#00A7E1",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#88B053",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#1D4C69",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#A07AE7",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#668FA8",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#FF8686",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#D0EA01",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#F31F01",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#8003B2",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
    {
        primary: "#244BFF",
        contrast: {
            hex: "#FFFFFF",
            filter: "brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7490%) hue-rotate(300deg) brightness(122%) contrast(112%)",
        },
    },
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { POD_COMPARISON_COLORS } from "theme";
/**
 * PodHaloCSS adds a <style> tag to the DOM containing dynamically generated CSS rules.  The need for this arises
 * from a bug with react-leaflet-markercluster, discussed [here](
 * https://stackoverflow.com/questions/74254769/change-in-maps-parent-state-automatically-unspiderfies-cluster-in-react-leaflet).
 *
 * In short, any time a `<PodMarker />` component received any updates from the redux store, the underlying
 * `<Marker />` component rerenders.  This causes a bad UX effect that if a user has a cluster open (spiderfied),
 * and they click a marker (which either focuses on the marker or adds it to the comparison group), the markers'
 * state updates, the cluster rerenders, and the cluster automatically snaps shut.
 *
 * The only solution to this (for now) is to memoized the markers.  To still have a marker update its appearance
 * based on whether or not it is currently selected, a CSS workaround is used.  For every pod in the store,
 * and for every possible color, a css class is created.  And for every pod, for every color, a pod halo is rendered
 * using SVG markdown (see SvgMarkerIcon.tsx).  All color halos are rendered for every marker, but their appearance
 * is controlled through CSS.  A div that wraps the central `<MapContainer />` updates its classnames based on
 * the current pods in the store and which pods are currently being viewed/compared.  The combination of the
 * css classes applied to the map wrapper, the classes applied to the SVG halos, and the rules below, enable
 * the pod halos to render correctly.
 */
export var PodHaloCSS = function () {
    var podIds = useSelector(function (state) {
        return Object.keys(state.pods.byId);
    });
    return (_jsx("style", __assign({ id: "dynamic-pod-halo-css" }, { children: podIds
            .map(function (id) { return "\n\t\t\t\t ".concat(POD_COMPARISON_COLORS.map(function (colorSpec) {
            var color = colorSpec.primary.replace("#", "");
            return "\n\t\t\t\t\t\t\t.pod-marker-halo-".concat(id, "-").concat(color.toUpperCase(), " {\n\t\t\t\t\t\t\t\topacity: 0;\n\t\t\t\t\t\t\t\ttransition: opacity 200ms;\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t.pod-halo-").concat(id, "-").concat(color.toUpperCase(), " .pod-marker-halo-").concat(id, "-").concat(color.toUpperCase(), " {\n\t\t\t\t\t\t\t\topacity: 1;\n\t\t\t\t\t\t\t\ttransition: opacity 200ms;\n\t\t\t\t\t\t\t}\n\t\t\t\t\t ");
        }).join(""), "\n\t\t\t\t"); })
            .join("") })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Button, PillButton, Row } from "components/atoms";
import { HAWidgetTypes } from "models";
import { speciesLabels } from "../../../../../../constants";
import { BuildMapSteps } from ".";
import { SpeciesMap } from "../SpeciesMap";
/**
 * Select pollutant step of the create map widget modal for H&A
 */
export var SelectPollutant = function (_a) {
    var mapType = _a.mapType, setStep = _a.setStep, species = _a.species, setSpecies = _a.setSpecies, onSubmit = _a.onSubmit, editMode = _a.editMode;
    var pollutants = useSelector(function (state) { return state.general.allFilters.pollutants; });
    return (_jsxs(_Fragment, { children: [_jsx("h3", { children: "What data would you like to map?" }), _jsx(Row, __assign({ justifyContent: "flex-start" }, { children: __spreadArray(["aqi"], __read(pollutants.map(function (p) { return p.code; })), false).map(function (pollutant) { return (_jsx(PillButton, __assign({ selected: pollutant === species, onClick: function () { return setSpecies(pollutant); } }, { children: speciesLabels(pollutant) }), pollutant)); }) })), mapType === HAWidgetTypes.SPECIES_MAP ? (_jsx("div", __assign({ style: { width: "70vw", height: "65vh", margin: "12px 0 20px 0" } }, { children: _jsx(SpeciesMap, { id: "preview2", species: species }) }))) : (_jsx("div", { style: { height: "20px" } })), _jsxs(Row, __assign({ justifyContent: "center" }, { children: [_jsx(Button, __assign({ style: { width: "50%", borderRadius: "18px", marginRight: "30px" }, plump: true, onClick: function () { return setStep(BuildMapSteps.SELECT_MAP_TYPE); } }, { children: "Back" })), _jsx(Button, __assign({ style: { width: "50%", borderRadius: "18px" }, plump: true, outline: "filled", disabled: !species, onClick: function () {
                            if (mapType === HAWidgetTypes.SPECIES_MAP) {
                                onSubmit();
                            }
                            else {
                                setStep(BuildMapSteps.SELECT_TIMEFRAME);
                            }
                        } }, { children: mapType === HAWidgetTypes.SPECIES_MAP
                            ? "".concat(editMode ? "Save Changes" : "Create Map")
                            : "Continue" }))] }))] }));
};

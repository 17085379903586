var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
var Title = function (_a) {
    var station = _a.station;
    return (_jsx(Stack, __assign({ flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between" }, { children: _jsx(Stack, __assign({ flexDirection: "row", width: "100%", className: "station-summery-name-header", justifyContent: "space-between", maxWidth: "376px", p: "4px", style: {
                cursor: "pointer",
            } }, { children: _jsx(Typography, __assign({ fontFamily: "RobotoMonoRegular, monospace", style: {
                    height: "24px",
                    maxWidth: "316px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }, variant: "body2" }, { children: station.name || station.address })) })) })));
};
export default Title;

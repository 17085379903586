import { interpolateHex } from "utils";
import { AqiScaleRanges } from "../../../../constants";
/**
 * Min and Max opacity define the range of opacity for heatmap layers.  However, these are
 * not the end-result opacities, as all the heatmap shapes are flattenned into one canvas
 * (for performance and blurring effects), and another opacity is seth on that canvas,
 * further tempering the opacity of all heatmap shapes
 */
var MIN_OPACITY = 0.1;
var MAX_OPACITY = 1;
var OPACITY_SPREAD = MAX_OPACITY - MIN_OPACITY;
/**
 * Utility function for deriving color of a hetamap / isobar based on its
 * AQI value.  Will use the AqiScaleRange color brackets for a given aqi index,
 * and interpolate the hex colors within the brackets
 */
export var contourColor = function (value, aqi_type) {
    var _a = AqiScaleRanges[aqi_type], brackets = _a.brackets, min = _a.min, max = _a.max;
    /**
     * Fill opacity logic - higher AQI colors should have higher opacity in heatmaps.
     * fillOpacity is scaled according to the value relative to the min and max of the
     * aqi scale
     */
    var valueAsRatio = value / (max - min);
    var fillOpacity = OPACITY_SPREAD * valueAsRatio + MIN_OPACITY;
    /** Find bracket that value falls into */
    var bracket = brackets.find(function (bracket) { return value >= bracket.range[0] && value <= bracket.range[1]; });
    var bracketInd = brackets.findIndex(function (bracket) { return value >= bracket.range[0] && value <= bracket.range[1]; });
    var color;
    if (value === bracket.range[0]) {
        /**
         * If value is exactly the start value of the bracket, use its plain color
         */
        color = bracket.color;
    }
    else if (bracketInd !== brackets.length - 1) {
        /**
         * If we're not in the last bracket, interpolate colors between values
         */
        var spread = brackets[bracketInd + 1].range[0] - bracket.range[0];
        var ratio = (spread - (value - bracket.range[0])) / spread;
        color = interpolateHex(bracket.color, brackets[bracketInd + 1].color, ratio);
    }
    else {
        /**
         * If we're in the last bracket, simply pick the color of the last bracket (for very high range values)
         */
        color = brackets[brackets.length - 1].color;
    }
    return { color: color, fillOpacity: fillOpacity };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from "axios";
import { ActionTypes } from "store";
var initialState = {
    auth: {
        authenticated: false,
        jwt: null,
        loading: false,
    },
    locale: "en",
    info: {
        loading: true,
        data: null,
    },
    payment: {},
};
/**
 * Reducer for all user-related state in the application
 */
export function userReducer(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionTypes.SET_LOCALE:
            return __assign(__assign({}, state), { locale: action.payload });
        case ActionTypes.REQ_LOGIN:
            return __assign(__assign({}, state), { auth: __assign(__assign({}, state.auth), { loading: true }) });
        case ActionTypes.SET_AUTHENTICATED:
            return __assign(__assign({}, state), { auth: __assign(__assign({}, state.auth), { authenticated: action.payload.authenticated, jwt: action.payload.jwt, loading: false }) });
        case ActionTypes.SET_AUTH_ERROR:
            return __assign(__assign({}, state), { auth: __assign(__assign({}, state.auth), { authenticated: false, loading: false, error: action.payload }) });
        case ActionTypes.LOG_OUT:
            localStorage.removeItem("token");
            axios.defaults.headers.common.Authorization = false;
            return initialState;
        case ActionTypes.UPDATE_SETTINGS:
            return __assign(__assign({}, state), { info: __assign(__assign({}, state.info), { data: __assign(__assign({}, state.info.data), { settings: __assign(__assign({}, ((_a = state.info.data) !== null && _a !== void 0 ? _a : {}).settings), { loading: true }) }) }) });
        case ActionTypes.REQ_USER_INFO:
            return __assign(__assign({}, state), { info: __assign({ loading: true }, state.info) });
        case ActionTypes.SET_USER_INFO:
            return __assign(__assign({}, state), { info: {
                    loading: false,
                    data: __assign(__assign({}, state.info.data), action.payload),
                } });
        case ActionTypes.REQ_SUBMIT_PAYMENT_FORM:
            return __assign(__assign({}, state), { payment: __assign(__assign({}, state.payment), { status: {
                        loading: true,
                    } }) });
        case ActionTypes.PAYMENT_FORM_SUCCESS:
            return __assign(__assign({}, state), { payment: __assign(__assign({}, state.payment), { status: {
                        success: action.payload,
                        loading: false,
                    } }) });
        case ActionTypes.PAYMENT_FORM_ERROR:
            return __assign(__assign({}, state), { payment: __assign(__assign({}, state.payment), { status: {
                        error: action.payload,
                        loading: false,
                    } }) });
        case ActionTypes.CLEAR_PAYMENT_FORM:
            return __assign(__assign({}, state), { payment: __assign(__assign({}, state.payment), { status: undefined }) });
        case ActionTypes.REQ_RESET_PASSWORD_INIT:
            return __assign(__assign({}, state), { resetPasswordInit: {
                    loading: true,
                } });
        case ActionTypes.RESET_PASSWORD_INIT_SUCCESS:
            return __assign(__assign({}, state), { resetPasswordInit: {
                    error: undefined,
                    success: true,
                    loading: false,
                } });
        case ActionTypes.RESET_PASSWORD_INIT_ERROR:
            return __assign(__assign({}, state), { resetPasswordInit: {
                    error: action.payload,
                    loading: false,
                } });
        case ActionTypes.REQ_RESET_PASSWORD_FINISH:
        case ActionTypes.REQ_FORGOT_RESET_PASSWORD_FINISH:
            return __assign(__assign({}, state), { resetPasswordFinish: {
                    loading: true,
                } });
        case ActionTypes.RESET_PASSWORD_FINISH_SUCCESS:
            return __assign(__assign({}, state), { resetPasswordFinish: {
                    success: true,
                } });
        case ActionTypes.RESET_PASSWORD_FINISH_ERROR:
            return __assign(__assign({}, state), { resetPasswordFinish: {
                    error: true,
                } });
        case ActionTypes.REQ_UPDATE_CONTACT_INIT:
            return __assign(__assign({}, state), { updateContactInit: {
                    loading: true,
                } });
        case ActionTypes.UPDATE_CONTACT_INIT_SUCCESS:
            return __assign(__assign({}, state), { updateContactInit: {
                    loading: false,
                    success: action.payload,
                } });
        case ActionTypes.UPDATE_CONTACT_INIT_ERROR:
            return __assign(__assign({}, state), { updateContactInit: {
                    loading: false,
                    success: undefined,
                    error: action.payload,
                } });
        case ActionTypes.CLEAR_UPDATE_CONTACT_INIT:
            return __assign(__assign({}, state), { updateContactInit: undefined });
        case ActionTypes.REQ_UPDATE_CONTACT_VERIFY:
            return __assign(__assign({}, state), { updateContactVerify: {
                    loading: true,
                } });
        case ActionTypes.UPDATE_CONTACT_VERIFY_SUCCESS:
            return __assign(__assign({}, state), { updateContactVerify: {
                    loading: false,
                    success: action.payload,
                } });
        case ActionTypes.UPDATE_CONTACT_VERIFY_ERROR:
            return __assign(__assign({}, state), { updateContactVerify: {
                    loading: false,
                    success: undefined,
                    error: action.payload,
                } });
        case ActionTypes.CLEAR_UPDATE_CONTACT_VERIFY:
            return __assign(__assign({}, state), { updateContactVerify: undefined });
        case ActionTypes.RESET_PASSWORD_CLEAR:
            return __assign(__assign({}, state), { resetPasswordInit: null, resetPasswordFinish: null });
        default:
            return state;
    }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "store/types";
import { ActionCreators, Maps } from "store";
import { API_BASE } from "../../constants";
/**
 * Saga to update user settings - POSTS new settings to back end
 */
function handleUpdateSettings(action) {
    var _a, _b, method, settings, response, e_1;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = action.payload, _b = _a.method, method = _b === void 0 ? "put" : _b, settings = __rest(_a, ["method"]);
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 6]);
                return [4 /*yield*/, call(axios[method], "".concat(API_BASE, "/user/settings"), {
                        settings: settings,
                    })];
            case 2:
                response = (_c.sent());
                /**
                 * Merges the returned object of form `{ settings: <settings> }` into
                 * the existing user info object
                 */
                return [4 /*yield*/, put(ActionCreators.setUserInfo({
                        settings: __assign(__assign({}, response.data.data.settings), { loading: false }),
                    }))];
            case 3:
                /**
                 * Merges the returned object of form `{ settings: <settings> }` into
                 * the existing user info object
                 */
                _c.sent();
                /**
                 * Register the user's pollutant filter selection in the main dashboard map
                 */
                return [4 /*yield*/, put(ActionCreators.setMapView({
                        filters: response.data.data.settings.filters,
                    }, { path: Maps.Dashboard }))];
            case 4:
                /**
                 * Register the user's pollutant filter selection in the main dashboard map
                 */
                _c.sent();
                return [3 /*break*/, 6];
            case 5:
                e_1 = _c.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function watchHandleUpdateSettings() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.UPDATE_SETTINGS, handleUpdateSettings)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function settingsSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([fork(watchHandleUpdateSettings)])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

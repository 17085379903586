var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import * as L from "leaflet";
import "leaflet-side-by-side";
import { createControlComponent } from "@react-leaflet/core";
/**
 * This code is added to replace / augment the leaflet-side-by-side _updateClip function, as there is
 * a bug when trying to use the setLeftLayer and setRightLayer functions on an L.LayerGroup or
 * L.FeatureGroup (https://github.com/digidem/leaflet-side-by-side/issues/27).  This method borrowed
 * and adapted from leaflet-splitmap (https://github.com/QuantStack/leaflet-splitmap), which is itself
 * a poorly written fork of leaflet-side-by-side, but offers some crucial code to help to get
 * the setLeftLayer and setRightLayer functions working properly
 *
 * If you are not sure what is going on here, please read the leaflet docs on extending leaflet,
 * which is the foundation of most plugins: https://leafletjs.com/examples/extending/extending-1-classes.html
 */
L.Control.SideBySide.include({
    _updateClip: function () {
        if (!this._map) {
            return this;
        }
        var map = this._map;
        var nw = map.containerPointToLayerPoint([0, 0]);
        var se = map.containerPointToLayerPoint(map.getSize());
        var clipX = nw.x + this.getPosition();
        var dividerX = this.getPosition();
        this._divider.style.left = dividerX + "px";
        this.fire("dividermove", { x: dividerX });
        var clipLeft = "rect(" + [nw.y, clipX, se.y, nw.x].join("px,") + "px)";
        var clipRight = "rect(" + [nw.y, se.x, se.y, clipX].join("px,") + "px)";
        /**
         * Following code expect that layers are actually instances of L.FeatureGroup or L.LayerGroup,
         * and that they have panes that can be gotten.  If any other types of layers are passed,
         * this code will need to be udpated.  Putting in a try catch block for now to avoid crashes
         */
        try {
            this._leftLayers.forEach(function (left_layer) {
                left_layer.getLayers().forEach(function (layer) {
                    layer.getPane().style.clip = clipLeft;
                });
            });
            this._rightLayers.forEach(function (right_layer) {
                right_layer.getLayers().forEach(function (layer) {
                    layer.getPane().style.clip = clipRight;
                });
            });
        }
        catch (e) {
            if (process.env.NODE_ENV === "development") {
            }
        }
    },
});
var createControl = function (_a) {
    var leftLayers = _a.leftLayers, rightLayers = _a.rightLayers;
    return L.control.sideBySide(leftLayers, rightLayers);
};
/**
 * Swipe widget that shows certain layers on the left side of the map, and certain
 * layers on the right, with a swipe handle in the middle to compare.  Based on
 * [leaflet-side-by-side](https://www.npmjs.com/package/leaflet-side-by-side)
 *
 * SwipeBase is the very simple react-leaflet wrapper around the vanilla L.control.sideBySide,
 * which does not provide any automatic ways to update the layers on each side of the swipe.  This acts
 * as the base for the below `Swipe`, which adds additional logic to do that
 */
export var SwipeBase = createControlComponent(createControl);
/**
 * Swipe widget that shows certain layers on the left side of the map, and certain
 * layers on the right, with a swipe handle in the middle to compare.  Based on
 * [leaflet-side-by-side](https://www.npmjs.com/package/leaflet-side-by-side)
 */
export var Swipe = function (props) {
    var ref = useRef();
    var leftLayers = props.leftLayers, rightLayers = props.rightLayers;
    useEffect(function () {
        if (ref.current) {
            ref.current.setLeftLayers(leftLayers);
        }
    }, [leftLayers]);
    useEffect(function () {
        if (ref.current) {
            ref.current.setRightLayers(rightLayers);
        }
    }, [rightLayers]);
    return _jsx(SwipeBase, __assign({}, props, { ref: ref }));
};

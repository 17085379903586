/**
 * Util functions for working with arrays, objects, and other native javascript
 * data structures
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * Takes in an array, and returns same array with first item missing
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function shift(array) {
    var newArray = __spreadArray([], __read(array), false);
    newArray.shift();
    return newArray;
}
/**
 * Function to merge arrays - will merge array of objects. If two objects have
 * the same `key`, the item from the second array will replace the item in the first
 * array.  Otherwise, the item from the second array will be added to the final array
 */
export var mergeArrays = function (firstArray, secondArray, key) {
    var mergedArray = __spreadArray([], __read(firstArray), false);
    secondArray.forEach(function (newItem) {
        var matchIndex = mergedArray.findIndex(function (prevItem) { return prevItem[key] === newItem[key]; });
        if (matchIndex === -1) {
            mergedArray.push(newItem);
        }
        else {
            mergedArray[matchIndex] = newItem;
        }
    });
    return mergedArray;
};
/**
 * Takes in array of objects, removes all duplicates based on key of object
 */
export var getUniqueListBy = function (arr, key) {
    return __spreadArray([], __read(new Map(arr.map(function (item) { return [item[key], item]; })).values()), false);
};
/**
 * Function to paginate an array, i.e. return the subset of an array that starts on `page_number`, given
 * a certain `page_size`
 */
export function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}
/**
 * Util function to process map bounds data, and normalize pods according to their uuids
 */
export var normalizeBoundsData = function (data) {
    var normalized = {};
    data.data.forEach(function (podSummary) {
        normalized[podSummary.uuid] = podSummary;
    });
    var rest = __rest(data, []);
    return __assign(__assign({}, rest), { pods: normalized });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, useTheme } from "@mui/material";
var Points = function (_a) {
    var aqiMax = _a.aqiMax, AQIType = _a.AQIType, station = _a.station, statusColor = _a.statusColor;
    var theme = useTheme();
    var stationAQI = station[AQIType];
    return (_jsxs(Stack, __assign({ flexDirection: "row", gap: "8px" }, { children: [_jsx(Typography, __assign({ color: statusColor, fontFamily: "RobotoMonoRegular, monospace", fontSize: "64px", lineHeight: "64px" }, { children: stationAQI.value })), _jsxs(Stack, __assign({ gap: "4px" }, { children: [_jsx("span", { children: _jsxs(Typography, __assign({ fontFamily: "RobotoMonoRegular, monospace", variant: "body1" }, { children: [_jsx("span", __assign({ style: {
                                        color: statusColor,
                                    } }, { children: stationAQI.value > 1 ? "points" : "point" })), _jsxs("span", { children: [" of ", aqiMax] })] })) }), _jsx(Typography, __assign({ color: theme.palette.secondary[300], variant: "caption" }, { children: stationAQI.base_on }))] }))] })));
};
export default Points;

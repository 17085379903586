var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import List from "../list";
import { Stack, Typography } from "@mui/material";
var Atmospherics = function (_a) {
    var atmospherics = _a.atmospherics;
    if (!atmospherics.length)
        return null;
    var temperature = atmospherics.find(function (item) { return (item.code = "temperature"); });
    var isWindItemFn = function (item) {
        return ["windu", "windv", "windw"].includes(item.code);
    };
    var windu = atmospherics.find(function (item) { return item.code === "windu"; });
    var windv = atmospherics.find(function (item) { return item.code === "windv"; });
    var windw = atmospherics.find(function (item) { return item.code === "windw"; });
    //TODO: MOVE TO BE?
    var finalAtmospherics = atmospherics
        .map(function (item) {
        var isHeatIndex = item.code === "heatIndex";
        var isWindChill = item.code === "windChill";
        var tempValue = temperature.value;
        if (isWindChill && tempValue && tempValue > 17)
            return __assign(__assign({}, item), { value: "N/A" });
        if (isHeatIndex && tempValue && tempValue < 28)
            return __assign(__assign({}, item), { value: "N/A" });
        if (item.code === "windHoriz") {
            return __assign(__assign({}, item), { subValue: "[u ".concat(windu === null || windu === void 0 ? void 0 : windu.value, ", v ").concat(windv === null || windv === void 0 ? void 0 : windv.value, ", w ").concat(windw === null || windw === void 0 ? void 0 : windw.value, "]") });
        }
        if (isWindItemFn(item))
            return undefined;
        return item;
    })
        .filter(Boolean);
    return (_jsxs(Stack, __assign({ width: "100%" }, { children: [_jsx(Typography, __assign({ variant: "body1" }, { children: "Atmospherics" })), _jsx(List, { items: finalAtmospherics })] })));
};
export default Atmospherics;

var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Marker, useMap } from "react-leaflet";
import { SvgMarkerIcon } from "./SvgMarkerIcon";
/**
 * Component to render a single pod marker on the map, with all relevant behavior and event handlers
 */
export var PodMarker = function (_a) {
    var _b;
    var summary = _a.summary, eventHandlers = _a.eventHandlers, getHaloColor = _a.getHaloColor, aqi_type = _a.aqi_type;
    var uuid = summary.uuid, latitude = summary.latitude, longitude = summary.longitude, source = summary.source;
    var aqi = summary[aqi_type];
    var _c = __read(useState(), 2), ref = _c[0], setRef = _c[1];
    var map = useMap();
    var zoom = (_b = map.getZoom()) !== null && _b !== void 0 ? _b : 10;
    var eventHandlerTransformer = function () {
        var handlers = {};
        Object.entries(eventHandlers).forEach(function (_a) {
            var _b = __read(_a, 2), name = _b[0], handler = _b[1];
            handlers[name] = function (e) {
                handler(e, summary);
            };
        });
        return handlers;
    };
    /**
     * Because each `<Marker />` must be memoized (see notes below), we must assign the event
     * handler to the ref of the marker.  Every time `handleClick` is recreated (when the properties
     * in the above `useCallback` dep array update), we must remove the click handler and assign
     * the updated one, which we do here:
     */
    useEffect(function () {
        var handlers = eventHandlerTransformer();
        if (ref) {
            Object.entries(handlers).forEach(function (_a) {
                var _b = __read(_a, 2), event = _b[0], handler = _b[1];
                ref.off(event);
                ref.on(event, handler);
            });
        }
    }, [ref]);
    /**
     * Each marker must be memoized.  If they are not, and the marker rerenders as a result of a react
     * props/state update, the parent `<MarkerClusterGroup />` (used in the parent `<PodMarkers />` component)
     * will cause a rerender of children. The effect of this is that when clicking on a pod that is a spiderfied
     * cluster, state updates, and the cluster automatically closes, causing a poor UX moment.  When the marker
     * is memoized, we avoid this unwanted effect.  However, because the marker is memoized, it requires
     * many other workarounds.  I.e. we cannot assign event handlers in the react-leaflet `<Marker />`,
     * so we assign them to the ref in a useEffect (above).  Also, to change the appearance of the marker,
     * we create dynamic CSS classes.  More notes on that can be found in `PodHaloCss.tsx`.
     */
    var memoizedMarker = React.useMemo(function () { return (_jsx(Marker, { position: [latitude, longitude], ref: function (r) { return setRef(r); }, icon: SvgMarkerIcon({
            uuid: uuid,
            zoom: zoom,
            aqi: aqi,
            activeColor: getHaloColor ? getHaloColor(summary) : undefined,
            pod: source === "PERSIUM",
        }) }, uuid)); }, []);
    return zoom < 12 ? (_jsx(Marker, { position: [latitude, longitude], ref: function (r) { return setRef(r); }, icon: SvgMarkerIcon({
            uuid: uuid,
            zoom: zoom,
            aqi: aqi,
            activeColor: getHaloColor ? getHaloColor(summary) : undefined,
            pod: source === "PERSIUM",
        }), eventHandlers: eventHandlerTransformer() }, uuid)) : (memoizedMarker);
};

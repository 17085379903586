var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, useTheme } from "@mui/material";
import AqiScaleRange from "./aqiScaleRange";
import { AQI_SCALE_RANGES } from "utils";
var LevelBar = function (_a) {
    var _b;
    var aqi = _a.aqi, statusColor = _a.statusColor, AQIType = _a.AQIType;
    var theme = useTheme();
    var rangeSpec = AQI_SCALE_RANGES[AQIType];
    var levelText = (_b = rangeSpec.brackets.find(function (bracket) {
        return (aqi || 0) >= bracket.range[0] && (aqi || 0) <= bracket.range[1];
    })) === null || _b === void 0 ? void 0 : _b.name;
    return (_jsxs(Stack, __assign({ flexDirection: "row", width: "100%", alignItems: "flex-end", justifyContent: "space-between" }, { children: [_jsx(Typography, __assign({ style: {
                    backgroundColor: statusColor,
                    borderRadius: "2px",
                    color: theme.palette.secondary["800"],
                    lineHeight: "20px",
                    padding: "4px 6px",
                    textAlign: "center",
                    width: "112px",
                }, variant: "subtitle2" }, { children: levelText })), _jsx(AqiScaleRange, { aqi: aqi, color: statusColor })] })));
};
export default LevelBar;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTheme } from "@mui/material";
import StockChart from "./stockChart";
var Divider = function (props) {
    var theme = useTheme();
    return (_jsx("div", __assign({}, props, { style: __assign({ border: "none", borderBottom: "1px solid ".concat(theme.palette.secondary["500"]), margin: "6px 0 16px 0", width: "100%" }, props.style) })));
};
var GraphWrapper = function (props) {
    return (_jsx("div", __assign({}, props, { style: __assign({ height: (props.height || 0) + 24, margin: "16px 16px 16px 6px", position: "relative", width: "calc(100% - 32px)" }, props.style) })));
};
var GraphLines = function (props) {
    var theme = useTheme();
    return (_jsx("div", __assign({}, props, { style: __assign({ border: "solid ".concat(theme.palette.secondary["500"]), borderWidth: "0 0 1px 1px", bottom: "30px", left: "30px", pointerEvents: "none", position: "absolute", right: 0, top: "4px" }, props.style) })));
};
var Arrow = function (props) {
    var theme = useTheme();
    return (_jsx("div", __assign({}, props, { style: __assign({ border: "solid ".concat(theme.palette.secondary["500"]), borderWidth: "0 1px 1px 0", display: "inline-block", padding: "3px", position: "absolute" }, props.style) })));
};
var ArrowUp = function (props) {
    return (_jsx(Arrow, { style: __assign({ left: "-4px", top: 0, transform: "rotate(-135deg)" }, props.style) }));
};
var ArrowRight = function (props) {
    return (_jsx(Arrow, { style: __assign({ bottom: "-4px", right: 0, transform: "rotate(-45deg)" }, props.style) }));
};
var XLabels = function (props) {
    var theme = useTheme();
    return (_jsx("div", __assign({}, props, { style: __assign({ color: "".concat(theme.palette.secondary["500"]), display: "flex", 
            // fontFamily: `${MAP_VIEW_BASE_THEME.FONTS.ROBOTO}`,
            fontFamily: "roboto", fontSize: "10px", fontStyle: "normal", fontWeight: "normal", justifyContent: "space-between", lineHeight: "12px", margin: "-3px 5px 0px 30px" }, props.style) })));
};
var NoDataMessage = function (props) {
    var theme = useTheme();
    return (_jsx("div", __assign({}, props, { style: __assign({ color: "".concat(theme.palette.secondary["500"]), fontSize: "12px", margin: "auto", position: "absolute", textAlign: "center", top: "45%", width: "100%", zIndex: "10" }, props.style) })));
};
var NavigatorBorder = function (props) {
    return (_jsx(Divider, { style: __assign({ position: "absolute", left: "30px", right: "10px", width: "auto" }, props.style) }));
};
var LineChart = function (_a) {
    var _b, _c;
    var graphOptions = _a.graphOptions, isLoading = _a.isLoading, navigator = _a.navigator, noDataMessage = _a.noDataMessage, xLabels = _a.xLabels;
    var height = (_c = (_b = graphOptions === null || graphOptions === void 0 ? void 0 : graphOptions.chart) === null || _b === void 0 ? void 0 : _b.height) !== null && _c !== void 0 ? _c : 230;
    return (_jsxs(GraphWrapper, __assign({ height: height }, { children: [_jsx(StockChart, { graphOptions: graphOptions, navigator: navigator }), _jsxs(GraphLines, { children: [_jsx(ArrowUp, {}), _jsx(ArrowRight, {}), noDataMessage && !isLoading && (_jsx(NoDataMessage, { children: noDataMessage }))] }), _jsx(XLabels, { children: xLabels.map(function (_a) {
                    var text = _a.text, style = _a.style;
                    return (_jsx("span", __assign({ style: style }, { children: text }), text));
                }) }), (navigator === null || navigator === void 0 ? void 0 : navigator.xLabels) && (_jsxs(_Fragment, { children: [_jsx(NavigatorBorder, { style: {
                            bottom: "22px",
                        } }), _jsx(NavigatorBorder, { style: {
                            bottom: "74px",
                        } }), _jsx(XLabels, { children: navigator.xLabels.map(function (_a) {
                            var text = _a.text, style = _a.style;
                            return (_jsx("span", __assign({ style: style }, { children: text }), text));
                        }) })] }))] })));
};
export * from "./stockChart";
export default LineChart;

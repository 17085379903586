var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import styled from "styled-components";
import { COLORS } from "theme";
import { TextInput } from "./TextInput";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n"], ["\n  position: relative;\n  width: 100%;\n"])));
export var PasswordInput = function (_a) {
    var _b = _a.enableVisiblePassword, enableVisiblePassword = _b === void 0 ? false : _b, rest = __rest(_a, ["enableVisiblePassword"]);
    var _c = __read(useState(false), 2), visible = _c[0], setVisible = _c[1];
    var Icon = visible ? BsEyeFill : BsEyeSlashFill;
    return (_jsxs(Wrapper, { children: [_jsx(TextInput, __assign({}, rest, { type: visible ? "text" : "password" })), enableVisiblePassword && (_jsx(Icon, { onClick: function () { return setVisible(!visible); }, size: 20, color: COLORS.GREY1, style: {
                    position: "absolute",
                    top: "6px",
                    right: "10px",
                    cursor: "pointer",
                } }))] }));
};
var templateObject_1;

import { ActionTypes } from "store/types";
import { createAction } from "typesafe-actions";
export var GeneralActions = {
    /**
     * Redux action to toggle menu open or closed
     */
    toggleMenuOpen: createAction(ActionTypes.TOGGLE_MENU_OPEN, function () { return undefined; })(),
    /**
     * Redux action to request the settings of what pollutants / data are in the settings
     */
    requestAllFilters: createAction(ActionTypes.REQ_ALL_FILTERS, function () { return undefined; })(),
    /**
     * Redux action to set the potential filters of what data / pollutants can be shown
     * @param {{name: string, code: string, description: string }[]} payload Filter response from API call
     */
    setAllFilters: createAction(ActionTypes.SET_ALL_FILTERS, function (payload) { return payload; })(),
    /**
     * Redux action to request unit types available from the api
     */
    requestPreferenceOptions: createAction(ActionTypes.REQ_PREFERENCE_OPTIONS, function () { return undefined; })(),
    /**
     * Redux action to set unit types available from the api
     */
    setPreferenceOptions: createAction(ActionTypes.SET_PREFERENCE_OPTIONS, function (payload) { return payload; })(),
    /**
     * Redux action to request contact form be sent via POST request
     */
    requestContactUsForm: createAction(ActionTypes.REQ_CONTACT_US, function (payload) { return payload; })(),
    /**
     * Redux action to register successfully sending contact form
     */
    contactUsSuccess: createAction(ActionTypes.CONTACT_US_SUCCESS, function () { return undefined; })(),
    /**
     * Redux action to register error sending contact form
     */
    contactUsError: createAction(ActionTypes.CONTACT_US_ERROR, function (payload) { return payload; })(),
    /**
     * Redux action to clear contact form response state
     */
    clearContactUs: createAction(ActionTypes.CONTACT_US_CLEAR, function () { return undefined; })(),
};

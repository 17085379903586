var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ToggleButtonGroup as MuiToggleButtonGroup, } from "@mui/material";
import { ToggleButton } from "./toggle";
export var ToggleButtonGroup = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "primary" : _b, _c = _a.exclusive, exclusive = _c === void 0 ? true : _c, _d = _a.fullWidth, fullWidth = _d === void 0 ? true : _d, onChange = _a.onChange, _e = _a.shouldRoundSides, shouldRoundSides = _e === void 0 ? true : _e, _f = _a.size, size = _f === void 0 ? "small" : _f, value = _a.value, items = _a.items, rest = __rest(_a, ["color", "exclusive", "fullWidth", "onChange", "shouldRoundSides", "size", "value", "items"]);
    return (_jsx(MuiToggleButtonGroup, __assign({}, rest, { color: color, exclusive: exclusive, fullWidth: fullWidth, size: size, value: value, onChange: onChange }, { children: items.map(function (item, index) {
            var _a;
            var radiusCollection = (_a = {
                    0: "20px 0px 0px 20px"
                },
                _a[items.length - 1] = "0px 20px 20px 0px",
                _a);
            var borderRadius = shouldRoundSides
                ? radiusCollection[index]
                : undefined;
            return (_jsx(ToggleButton, __assign({ style: __assign({ borderRadius: borderRadius }, item.style), value: item.value }, { children: item.text }), item.value + index));
        }) })));
};

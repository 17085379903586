var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { ActionCreators } from "store";
/**
 * Custom hook used for handling the state of authentication when the user loads / reloads
 * the page
 */
export var useAuthentication = function () {
    var dispatch = useDispatch();
    var accessToken = localStorage.getItem("token");
    var _a = __read(useState(false), 2), done = _a[0], setDone = _a[1];
    useEffect(function () {
        if (accessToken) {
            axios.defaults.headers.common.Authorization = "Bearer ".concat(accessToken);
            var jwtDecoded = jwtDecode(accessToken);
            var expiration = jwtDecoded.exp * 1000;
            var now = new Date();
            var yesterday = new Date();
            yesterday.setHours(yesterday.getHours() - 24);
            /**
             * If token is expired, user will not be authenticated.  Destroy token
             */
            var expired = now.getTime() > expiration;
            if (expired) {
                localStorage.removeItem("token");
                axios.defaults.headers.common.Authorization = false;
                return;
            }
            /**
             * If token is within 24 hours of expiring, call refresh token route to replace
             * with fresh token
             */
            var expiresIn24Hours = yesterday.getTime() < expiration && now.getTime() < expiration;
            if (expiresIn24Hours) {
                dispatch(ActionCreators.requestRefreshToken());
            }
            dispatch(ActionCreators.setAuthenticated({
                authenticated: true,
                jwt: accessToken,
            }));
            dispatch(ActionCreators.requestUserInfo());
            // TODO - implement this
            // dispatch(ActionCreators.requestFavPods());
        }
        setDone(true);
    }, []);
    return done;
};

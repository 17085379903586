var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Select from "react-select";
import { Button, Row, Prompt } from "components/atoms";
import { avgOptions } from "../../../constants";
import { BuildGraphSteps } from "../historics_analytics/widgets";
export var SelectAvg = function (_a) {
    var selectedAvg = _a.selectedAvg, setSelectedAvg = _a.setSelectedAvg, setStep = _a.setStep, onSubmit = _a.onSubmit;
    var selectedAvgOption = avgOptions.find(function (item) { return item.value === selectedAvg; }) || { label: "1 Hours", value: 60 };
    return (_jsxs(_Fragment, { children: [_jsx(Prompt, { children: "Select the period resolution for the data you want to export" }), _jsx(Row, __assign({ style: { marginTop: "20px" } }, { children: _jsxs("div", __assign({ style: { display: "flex", gap: "8px", alignItems: "center" } }, { children: [_jsx("span", { children: "Period Resolution:" }), _jsx(Select, { menuPortalTarget: document.body, styles: {
                                menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 99999 })); },
                            }, onChange: function (option) { return setSelectedAvg(option.value); }, value: selectedAvgOption, options: avgOptions })] })) })), _jsx("div", { style: { flex: 1 } }), _jsxs(Row, __assign({ justifyContent: "center", style: { margin: "40px 0 0 0" } }, { children: [_jsx(Button, __assign({ id: "back-button", style: { width: "50%", borderRadius: "18px", marginRight: "30px" }, plump: true, onClick: function () { return setStep(BuildGraphSteps.SelectDate); } }, { children: "Back" })), _jsx(Button, __assign({ id: "continue-button", style: { width: "50%", borderRadius: "18px" }, plump: true, outline: "filled", disabled: !selectedAvgOption, onClick: onSubmit }, { children: "Export" }))] }))] }));
};

import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCStockChart from "highcharts/highstock";
import lodash from "lodash";
import React from "react";
var getDefaultOptions = function (theme) { return ({
    chart: {
        backgroundColor: "none",
        height: 230,
        margin: [6, 0, 8, 30],
        type: "spline",
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        spline: {
            color: theme.palette.primary.main,
            lineWidth: 2.5,
            marker: {
                enabled: false,
            },
            showInLegend: false,
        },
    },
    title: {
        text: "",
    },
    tooltip: {
        shared: true,
    },
    xAxis: {
        labels: {
            enabled: false,
        },
        lineWidth: 0,
        tickLength: 0,
        type: "datetime",
    },
    yAxis: {
        gridLineWidth: 0,
        labels: {
            style: { color: theme.palette.secondary["500"] },
            enabled: true,
            x: -5,
        },
        softMax: 1,
        title: {
            text: "",
        },
    },
}); };
var Graph = function (_a) {
    var graphOptionsFromProps = _a.graphOptions, navigator = _a.navigator;
    var theme = useTheme();
    var graphOptions = lodash.merge({}, getDefaultOptions(theme), graphOptionsFromProps, {
        navigator: navigator,
    });
    var highcharts = navigator ? HCStockChart : Highcharts;
    return (_jsx(HighchartsReact, { constructorType: navigator ? "stockChart" : undefined, highcharts: highcharts, options: graphOptions }));
};
var StockChart = React.memo(Graph, function (prevProps, nextProps) {
    return lodash.isEqual(prevProps, nextProps);
});
export default StockChart;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { COLORS } from "theme";
import PuffLoader from "react-spinners/PuffLoader";
import { Column } from "./Column";
export var CardWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border-radius: 16px;\n  padding: 20px;\n  display: ", ";\n  flex: ", ";\n  flex-direction: ", ";\n  align-items: ", ";\n  justify-content: ", ";\n  margin: ", ";\n  padding: ", ";\n  box-shadow: ", ";\n\n  .no-box-shadows & {\n    box-shadow: none important!;\n\n    &:not(.inner-card-wrapper) {\n      border: 1px solid ", ";\n    }\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border-radius: 16px;\n  padding: 20px;\n  display: ", ";\n  flex: ", ";\n  flex-direction: ", ";\n  align-items: ", ";\n  justify-content: ", ";\n  margin: ", ";\n  padding: ", ";\n  box-shadow: ", ";\n\n  .no-box-shadows & {\n    box-shadow: none important!;\n\n    &:not(.inner-card-wrapper) {\n      border: 1px solid ", ";\n    }\n  }\n"])), function (props) {
    return props.variant === "blue" ? COLORS.BLUE : COLORS.WHITE;
}, function (props) { return props.variant === "blue" && COLORS.WHITE; }, function (props) { return (props.row || props.column ? "flex" : undefined); }, function (props) { return props.flex; }, function (props) { return (props.column ? "column" : undefined); }, function (props) { var _a; return (_a = props.alignItems) !== null && _a !== void 0 ? _a : "center"; }, function (props) { var _a; return (_a = props.justifyContent) !== null && _a !== void 0 ? _a : "space-between"; }, function (props) { return props.margin; }, function (props) { return props.padding; }, function (props) {
    return props.elevation && "0 0 ".concat(props.elevation, "px 0 ").concat(COLORS.GREY);
}, COLORS.GREY);
/**
 * Generic Card wrapper
 */
export var Card = React.forwardRef(function (props, ref) {
    var loading = props.loading, children = props.children, loadingProps = props.loadingProps, rest = __rest(props, ["loading", "children", "loadingProps"]);
    return (_jsx(CardWrapper, __assign({}, rest, { ref: ref }, { children: loading ? (_jsx(Column, __assign({ style: { flex: 1 }, alignItems: "center", justifyContent: "center" }, { children: _jsx(PuffLoader, __assign({ color: COLORS.WHITE, size: 120 }, loadingProps)) }))) : (children) })));
});
Card.displayName = "Card";
var templateObject_1;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import List from "../list";
import SubHeading from "../list/subHeading";
import { Stack, Typography } from "@mui/material";
// TODO: Add union type
var healthSensorNames = [
    "T, P & H Sensors",
    "Gas Sensors",
    "CO2 Sensors",
    "PM Sensors",
    "Pitch",
    "Roll - Heading",
    "Wind Sensors",
];
var Engineering = function (_a) {
    var _b = _a.engineerings, engineerings = _b === void 0 ? [] : _b, _c = _a.orientations, orientations = _c === void 0 ? [] : _c;
    var sensorHealth = {
        title: "Sensor Health",
        items: healthSensorNames
            .map(function (name) { return engineerings.find(function (item) { return item && item.name === name; }); })
            .filter(function (item) { return !!item; }),
    };
    var filteredItems = engineerings.filter(function (item) {
        return item && !healthSensorNames.includes(item.name);
    });
    return (_jsxs(Stack, __assign({ width: "100%" }, { children: [_jsx(Typography, __assign({ variant: "body1" }, { children: "Engineering" })), _jsx(SubHeading, { items: sensorHealth.items, title: sensorHealth.title }), _jsx(SubHeading, { items: orientations, title: "Orientation" }), _jsx(List, { items: filteredItems })] })));
};
export default Engineering;

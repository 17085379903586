import { jsx as _jsx } from "react/jsx-runtime";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import merge from "lodash.merge";
var pieColors = (function () {
    var colors = [];
    var base = Highcharts.getOptions().colors[0];
    for (var i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(Highcharts.color(base)
            .brighten((i - 3) / 7)
            .get());
    }
    return colors;
})();
/**
 * Default graphing options used as a base to branch off from for all
 * pie charts.  `graphOptions` props gets spread into this object, allowing
 * for customization on a per-use basis of `PieChart`, but also providing
 * a consistent starting point across all instances of `PieChart`
 */
var defaultOptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        margin: [0, 0, 0, 0],
    },
    exporting: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
    title: {
        text: null,
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: "pointer",
            colors: pieColors,
            dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b> <span style=\"font-weight: normal;\">{point.percentage:.1f}%</span>",
                distance: 25,
            },
        },
    },
};
/**
 * Pie chart, based on highchartsjs
 */
export var PieChart = function (_a) {
    var optionsFromProps = _a.options;
    var options = merge({}, defaultOptions, optionsFromProps);
    return _jsx(HighchartsReact, { highcharts: Highcharts, options: options });
};

/**
 * Helpful math utility functions
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/**
 * Normalizes value on range
 * @param val The value to normalize
 * @param max The min value of the range to normalize on
 * @param min The max value of the range to normalize on
 * @returns
 */
export var normalize = function (val, min, max) {
    return (val - min) / (max - min);
};
/**
 * Util to generate moving average from [x,y] dataset.  Omits leading and trailing
 * spaces for which averages cannot be computed
 */
export var simpleMovingAverage = function (data, period) {
    var results = [];
    if (period === 1) {
        return data;
    }
    for (var i = period; i < data.length; i++) {
        var subset = data.slice(i - period, i);
        var sum = subset.map(function (_a) {
            var _b = __read(_a, 2), _ = _b[0], value = _b[1];
            return value;
        }).reduce(function (a, b) { return a + b; }, 0);
        var avg = sum / subset.length || 0;
        results.push([data[i][0], avg]);
    }
    return results;
};
/**
 * Util to get R² for linear regression
 * Borrowed from https://stackoverflow.com/a/65987107/12010984
 */
function rSquared(x, y, m, b) {
    var sumSquareResiduals = 0;
    var sumAllSquares = 0;
    var yMean = y.reduce(function (a, b) { return a + b; }) / y.length;
    for (var i = 0; i < x.length; i++) {
        var ylocal = m * x[i] + b;
        sumSquareResiduals += Math.pow(y[i] - ylocal, 2);
        sumAllSquares += Math.pow(y[i] - yMean, 2);
    }
    return 1 - sumSquareResiduals / sumAllSquares;
}
/**
 * Least squares fit function use to calculate trend lines of graphs
 * From https://dracoblue.net/dev/linear-least-squares-in-javascript/
 */
export function leastSquaresFit(data) {
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var count = 0;
    var values_x = data.map(function (_d, i) { return i; });
    var values_y = data.map(function (d) { return d[1]; });
    /*
     * We'll use those variables for faster read/write access.
     */
    var x = 0;
    var y = 0;
    var values_length = values_x.length;
    /*
     * Nothing to do.
     */
    if (values_length === 0) {
        return {
            results: [
                [0, 0],
                [0, 0],
            ],
            R2: 0,
            m: 0,
        };
    }
    /*
     * Calculate the sum for each of the parts necessary.
     */
    for (var v = 0; v < values_length; v++) {
        x = values_x[v];
        y = values_y[v];
        sum_x += x;
        sum_y += y;
        sum_xx += x * x;
        sum_xy += x * y;
        count++;
    }
    /*
     * Calculate m and b for the formular:
     * y = x * m + b
     */
    var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
    var b = sum_y / count - (m * sum_x) / count;
    /*
     * We will make the x and y result line now
     */
    var results = [];
    for (var v = 0; v < values_length; v++) {
        x = values_x[v];
        y = x * m + b;
        results.push([data[v][0], y]);
    }
    var R2 = rSquared(values_x, values_y, m, b);
    return { results: results, R2: R2, m: m };
}

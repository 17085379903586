var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "store/types";
import { ActionCreators } from "store";
import { API_BASE, CC_SUBSCRIPTION_URL } from "../../../constants";
/**
 * Sagas related to user account details
 */
/**
 * Saga to send update payment information
 */
function handleUpdatePaymentMethod(action) {
    var e_1;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 4, , 6]);
                /* Call to PUT new credit card form info */
                return [4 /*yield*/, call(axios.put, CC_SUBSCRIPTION_URL, action.payload)];
            case 1:
                /* Call to PUT new credit card form info */
                _d.sent();
                /* Register payment form success in store */
                return [4 /*yield*/, put(ActionCreators.paymentFormSuccess("Payment information has been successfully updated."))];
            case 2:
                /* Register payment form success in store */
                _d.sent();
                /* Now that user has paid, their info should be updated, so request again */
                return [4 /*yield*/, put(ActionCreators.requestUserInfo())];
            case 3:
                /* Now that user has paid, their info should be updated, so request again */
                _d.sent();
                return [3 /*break*/, 6];
            case 4:
                e_1 = _d.sent();
                return [4 /*yield*/, put(ActionCreators.paymentFormError((_c = (_b = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : "Something went wrong while we were processing your information.  Contact customer support if your issue persists."))];
            case 5:
                _d.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request password reset - will send call to POST route to send link to user email
 */
function handleResetPasswordInit(action) {
    var e_2;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 3, , 5]);
                /* Call to post email to forgot route */
                return [4 /*yield*/, call(axios.post, "".concat(API_BASE, "/user/password/forgot"), __assign(__assign({}, action.payload), { callback_url: "".concat(window.location.origin, "/password/verify") }))];
            case 1:
                /* Call to post email to forgot route */
                _d.sent();
                /* Register success in store */
                return [4 /*yield*/, put(ActionCreators.resetPasswordInitSuccess())];
            case 2:
                /* Register success in store */
                _d.sent();
                return [3 /*break*/, 5];
            case 3:
                e_2 = _d.sent();
                return [4 /*yield*/, put(ActionCreators.resetPasswordInitFailure((_c = (_b = (_a = e_2 === null || e_2 === void 0 ? void 0 : e_2.resoonse) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : "There was an error attempting to reset your password."))];
            case 4:
                _d.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request password reset - will send call to POST route to send new password
 */
function handleResetPasswordFinish(action) {
    var restoreToken, data, response, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                restoreToken = localStorage.getItem("restoreToken");
                data = {
                    token: restoreToken,
                    new_password: action.payload,
                };
                return [4 /*yield*/, call(axios.post, "".concat(API_BASE, "/user/password/reset"), data)];
            case 1:
                response = _a.sent();
                if (!(response.data.message === "The token is invalid.")) return [3 /*break*/, 3];
                return [4 /*yield*/, put(ActionCreators.resetPasswordFinishFailure())];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: 
            /* Register success in store */
            return [4 /*yield*/, put(ActionCreators.resetPasswordFinishSuccess())];
            case 4:
                /* Register success in store */
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                e_3 = _a.sent();
                return [4 /*yield*/, put(ActionCreators.resetPasswordFinishFailure())];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request phone number / email update - will send call to PUT route to send link to user email
 */
function handleUpdateContactInit(action) {
    var _a, contactType, password, payloads, response, e_4;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = action.payload, contactType = _a.contactType, password = _a.password;
                payloads = {
                    phone: {
                        new_phone: action.payload.phone,
                        password: password,
                        callback_url: "".concat(window.location.origin, "/settings/phone/verify"),
                    },
                    email: {
                        new_email: action.payload.email,
                        password: password,
                        callback_url: "".concat(window.location.origin, "/settings/email/verify"),
                    },
                    password: {
                        old_password: password,
                        callback_url: "".concat(window.location.origin, "/settings/password/verify"),
                    },
                };
                _e.label = 1;
            case 1:
                _e.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(axios.put, "".concat(API_BASE, "/user/").concat(contactType, "/change"), payloads[contactType])];
            case 2:
                response = (_e.sent());
                /* Register success in store */
                return [4 /*yield*/, put(ActionCreators.updateContactInitializeSuccess(response.data.message))];
            case 3:
                /* Register success in store */
                _e.sent();
                return [3 /*break*/, 6];
            case 4:
                e_4 = _e.sent();
                return [4 /*yield*/, put(ActionCreators.updateContactInitializeError({
                        code: (_b = e_4 === null || e_4 === void 0 ? void 0 : e_4.response) === null || _b === void 0 ? void 0 : _b.status,
                        message: (_d = (_c = e_4 === null || e_4 === void 0 ? void 0 : e_4.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error,
                    }))];
            case 5:
                _e.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
/**
 * Saga to verify new phone number / email update
 */
function handleUpdateContactVerify(action) {
    var _a, api, hash_key, otp, new_password, body, response, e_5;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = action.payload, api = _a.api, hash_key = _a.hash_key, otp = _a.otp, new_password = _a.new_password;
                body = new_password ? { hash_key: hash_key, new_password: new_password } : { hash_key: hash_key, otp: otp };
                _e.label = 1;
            case 1:
                _e.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(axios.put, "".concat(API_BASE, "/").concat(api), body)];
            case 2:
                response = (_e.sent());
                /* Register success in store */
                return [4 /*yield*/, put(ActionCreators.updateContactVerifySuccess(response.data.message))];
            case 3:
                /* Register success in store */
                _e.sent();
                return [4 /*yield*/, put(ActionCreators.requestUserInfo())];
            case 4:
                _e.sent();
                return [3 /*break*/, 7];
            case 5:
                e_5 = _e.sent();
                return [4 /*yield*/, put(ActionCreators.updateContactVerifyError((_d = (_c = (_b = e_5 === null || e_5 === void 0 ? void 0 : e_5.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) !== null && _d !== void 0 ? _d : "Something went wrong"))];
            case 6:
                _e.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request cancellation of subscription plan
 */
function handleRequestCancelSubscription(action) {
    var response, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(axios.delete, "".concat(API_BASE, "/payment/subscribe"), {
                        data: {
                            reason: action.payload,
                        },
                    })];
            case 1:
                response = (_a.sent());
                /* Register response in store */
                return [4 /*yield*/, put(ActionCreators.cancelSubscriptionResponse(response.data))];
            case 2:
                /* Register response in store */
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                e_6 = _a.sent();
                return [4 /*yield*/, put(ActionCreators.cancelSubscriptionResponse(e_6.response.data))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function watchHandleUpdatePaymentMethod() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_UPDATE_PAYMENT_METHOD, handleUpdatePaymentMethod)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleResetPasswordInit() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_RESET_PASSWORD_INIT, handleResetPasswordInit)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleResetPasswordFinish() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_RESET_PASSWORD_FINISH, handleResetPasswordFinish)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleUpdateContactInit() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_UPDATE_CONTACT_INIT, handleUpdateContactInit)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleUpdateContactVerify() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_UPDATE_CONTACT_VERIFY, handleUpdateContactVerify)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleRequestCancelSubscription() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_CANCEL_SUBSCRIPTION, handleRequestCancelSubscription)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function userAccountSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    fork(watchHandleUpdatePaymentMethod),
                    fork(watchHandleResetPasswordInit),
                    fork(watchHandleResetPasswordFinish),
                    fork(watchHandleUpdateContactInit),
                    fork(watchHandleUpdateContactVerify),
                    fork(watchHandleRequestCancelSubscription),
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

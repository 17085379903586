var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Maps } from "store/map";
import { ActionTypes } from "store/types";
import merge from "lodash.merge";
var iniitialState = {
    byId: {},
    detail: {},
    favorites: [],
    owned: [],
    subscribed: [],
    widgetErrors: {}
};
/**
 * Central pod reducer, handles all raw pod data usually displayed in maps
 */
export function podsReducer(state, action) {
    var _a, _b, _c;
    var _d, _e, _f;
    if (state === void 0) { state = iniitialState; }
    switch (action.type) {
        case ActionTypes.SET_ALL_STATIONS_IN_BOUNDS: {
            /**
             * If in an H&A widget, we do not fold new entries into the main `pods.byId` slice, as
             * the H&A entries may not be current
             */
            if (action.meta.path === Maps.HASwipeMapWidget) {
                return state;
            }
            var oldNormalizedPodData = __assign({}, state.byId);
            var newNormalizedPodData = action.payload.pods;
            var pods = merge({}, oldNormalizedPodData, newNormalizedPodData);
            return __assign(__assign({}, state), { byId: pods });
        }
        case ActionTypes.SET_POD_SUMMARIES: {
            var pods = merge({}, state.byId, action.payload);
            return __assign(__assign({}, state), { byId: pods });
        }
        case ActionTypes.SET_CURRENT_POD_SUMMARY:
            return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), { summary: action.payload }) });
        case ActionTypes.SET_FAV_PODS:
            return __assign(__assign({}, state), { favorites: action.payload });
        case ActionTypes.SET_OWNED_PODS:
            return __assign(__assign({}, state), { owned: action.payload });
        case ActionTypes.SET_SUBSCRIBED_PODS:
            return __assign(__assign({}, state), { subscribed: action.payload });
        case ActionTypes.SET_POD_LATEST_DATA:
            return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), { latest: action.payload.data }) });
        case ActionTypes.REQ_POD_PARAM_DATA:
            return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), { parameterData: __assign(__assign({}, ((_d = state.detail.parameterData) !== null && _d !== void 0 ? _d : {})), (_a = {}, _a[action.payload.speciesGroup] = __assign(__assign({}, ((_e = state.detail.parameterData) !== null && _e !== void 0 ? _e : {})[action.payload.speciesGroup]), { loading: true }), _a)) }) });
        case ActionTypes.SET_POD_PARAM_DATA:
            return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), { parameterData: __assign(__assign({}, ((_f = state.detail.parameterData) !== null && _f !== void 0 ? _f : {})), (_b = {}, _b[action.payload.speciesGroup] = {
                        species_code: action.payload.species_code,
                        loading: false,
                        data: action.payload.data,
                    }, _b)) }) });
        case ActionTypes.CLEAR_CURRENT_POD:
            return __assign(__assign({}, state), { detail: {} });
        case ActionTypes.UPDATE_ERROR:
            var _g = action.payload, uuid = _g.uuid, errorMessage = _g.errorMessage;
            return __assign(__assign({}, state), { widgetErrors: __assign(__assign({}, state.widgetErrors), (_c = {}, _c[uuid] = errorMessage, _c)) });
        default:
            return state;
    }
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSettings } from "hooks";
import { COLORS } from "theme";
import { Card } from "./Card";
import { PodStatusCircle } from "./PodStatusCircle";
var Wrapper = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 12px;\n  left: 12px;\n  z-index: 1000;\n  opacity: 0;\n  transition: all 250ms;\n  font-size: 14px;\n  pointer-events: none;\n\n  &.visible {\n    opacity: 1;\n    transition: all 250ms;\n  }\n\n  & span {\n    margin-right: 16px;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n"], ["\n  position: absolute;\n  bottom: 12px;\n  left: 12px;\n  z-index: 1000;\n  opacity: 0;\n  transition: all 250ms;\n  font-size: 14px;\n  pointer-events: none;\n\n  &.visible {\n    opacity: 1;\n    transition: all 250ms;\n  }\n\n  & span {\n    margin-right: 16px;\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n"])));
var IDText = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), COLORS.GREY);
var AQIReading = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 3px;\n  padding: 6px;\n  color: ", ";\n"], ["\n  border-radius: 3px;\n  padding: 6px;\n  color: ", ";\n"])), COLORS.WHITE);
var timeout;
/**
 * Pod preview that shows up in the map
 */
export var PodPreviewBase = function (_a) {
    var _b, _c, _d, _e;
    var pod = _a.pod, CardProps = _a.CardProps;
    var aqi_type = useSettings().aqi_type;
    var _f = __read(useState(pod), 2), currentPod = _f[0], setCurrentPod = _f[1];
    useEffect(function () {
        if (pod) {
            setCurrentPod(pod);
            clearTimeout(timeout);
        }
        else {
            timeout = setTimeout(function () {
                setCurrentPod(null);
            }, 250);
        }
    }, [pod]);
    return (_jsxs(Wrapper, __assign({ row: true }, CardProps, { className: !!pod && "visible", id: "pod-preview-".concat(currentPod === null || currentPod === void 0 ? void 0 : currentPod.uuid) }, { children: [_jsx(IDText, { children: currentPod === null || currentPod === void 0 ? void 0 : currentPod.source_id }), _jsx("span", { children: currentPod === null || currentPod === void 0 ? void 0 : currentPod.name }), ((_b = currentPod === null || currentPod === void 0 ? void 0 : currentPod[aqi_type]) === null || _b === void 0 ? void 0 : _b.value) !== -1 && (_jsxs(AQIReading, __assign({ style: { backgroundColor: (_c = currentPod === null || currentPod === void 0 ? void 0 : currentPod[aqi_type]) === null || _c === void 0 ? void 0 : _c.color } }, { children: [(_d = currentPod === null || currentPod === void 0 ? void 0 : currentPod[aqi_type]) === null || _d === void 0 ? void 0 : _d.value, " ", aqi_type.toUpperCase()] }))), _jsx(PodStatusCircle, { status: currentPod === null || currentPod === void 0 ? void 0 : currentPod.status }), _jsx("span", __assign({ style: {
                    textTransform: "capitalize",
                    marginLeft: "10px",
                    color: (currentPod === null || currentPod === void 0 ? void 0 : currentPod.status) === "online" ? COLORS.BLUE : COLORS.GREY1,
                } }, { children: (_e = currentPod === null || currentPod === void 0 ? void 0 : currentPod.status) !== null && _e !== void 0 ? _e : "offline" }))] })));
};
export var PodPreview = React.memo(PodPreviewBase);
var templateObject_1, templateObject_2, templateObject_3;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown } from "components/atoms";
import { BiCalendar } from "react-icons/bi";
import styled from "styled-components";
import { timerangeOptions } from "../../constants";
var CalendarIcon = styled(BiCalendar)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tmargin-right: 10px;\n\tfill: hsl(0, 0%, 80%);\n\ttransition: all 200ms;\n\n\t.date-select:hover & {\n\t\tfill: hsl(0, 0%, 60%);\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\tmargin-right: 10px;\n\tfill: hsl(0, 0%, 80%);\n\ttransition: all 200ms;\n\n\t.date-select:hover & {\n\t\tfill: hsl(0, 0%, 60%);\n\t\ttransition: all 200ms;\n\t}\n"])));
/**
 * Reusable timeframe dropdown option.  Comes with all preset timeframes
 */
export var SelectTimeframeDropdown = function (_a) {
    var value = _a.value, onChange = _a.onChange, label = _a.label, options = _a.options;
    return (_jsx(Dropdown, { placeholder: value[label !== null && label !== void 0 ? label : "label"], options: options
            ? options.map(function (option) { return (__assign(__assign({}, option), { label: option[label !== null && label !== void 0 ? label : "label"] })); })
            : timerangeOptions
                .filter(function (item) { return item.label !== "Custom"; })
                .map(function (option) { return (__assign(__assign({}, option), { label: option[label !== null && label !== void 0 ? label : "label"] })); }), value: value, isSearchable: false, onChange: function (e) { return onChange(e); }, closeMenuOnSelect: true, components: {
            DropdownIndicator: function () { return _jsx(CalendarIcon, {}); },
        } }));
};
var templateObject_1;

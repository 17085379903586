var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from "styled-components";
import { COLORS } from "theme";
import { Button } from "./Button";
/**
 * Specialized button for rendering circular icons within a button. Adopts size of
 * child icon if size is not constrainzed by the `size` prop
 */
export var IconButton = styled(Button).attrs(function (props) { return (__assign(__assign({}, props), { centerRipple: true })); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  padding: ", ";\n  border: none;\n  border-radius: 50%;\n  color: black;\n  background-color: ", ";\n  opacity: ", ";\n  cursor: ", ";\n\n  & svg {\n    pointer-events: none;\n    height: 100%;\n    width: 100%;\n    color: ", ";\n  }\n"], ["\n  height: ", ";\n  width: ", ";\n  padding: ", ";\n  border: none;\n  border-radius: 50%;\n  color: black;\n  background-color: ", ";\n  opacity: ", ";\n  cursor: ", ";\n\n  & svg {\n    pointer-events: none;\n    height: 100%;\n    width: 100%;\n    color: ", ";\n  }\n"])), function (props) { return (props.size ? "".concat(props.size, "px") : "unset"); }, function (props) { return (props.size ? "".concat(props.size, "px") : "unset"); }, function (props) { var _a; return (_a = props.padding) !== null && _a !== void 0 ? _a : "8px"; }, function (props) {
    return props.outline === "filled" ? COLORS.BLUE : undefined;
}, function (props) { return (props.disabled ? 0.5 : 1); }, function (props) { return (props.disabled ? "auto" : "pointer"); }, function (props) {
    return props.outline === "filled" ? COLORS.WHITE : COLORS.BLACK;
});
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MapContainer, TileLayer } from "react-leaflet";
import { Card } from "components/atoms";
import styled from "styled-components";
import { COLORS } from "theme";
import { PeLogo } from "assets/images";
import { MAPBOX_TOKEN, MAPBOX_BASELAYER } from "../../constants";
var Background = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n"], ["\n  position: relative;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  width: 100vw;\n"])), COLORS.DARKGREY);
var CardWrapper = styled(Card)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 650px;\n  width: 60%;\n  z-index: 1000;\n"], ["\n  max-width: 650px;\n  width: 60%;\n  z-index: 1000;\n"])));
export var Inner = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  align-items: stretch;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  align-items: stretch;\n"])));
var Logo = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 85px;\n  align-self: flex-start;\n"], ["\n  width: 85px;\n  align-self: flex-start;\n"])));
/**
 * Full screen wrapper component, contains background leaflet map with no labels and minimal
 * interactivity, with central white card, to render UI within card (i.e. login screen or error screen)
 */
export var SimpleMapWrapper = function (_a) {
    var children = _a.children;
    return (_jsxs(Background, { children: [_jsx(MapContainer, __assign({ center: [51.505, -0.09], zoom: 14, style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: "default",
                }, zoomControl: false, doubleClickZoom: false, scrollWheelZoom: false, attributionControl: false }, { children: _jsx(TileLayer, { url: "".concat(MAPBOX_BASELAYER, "?access_token=").concat(MAPBOX_TOKEN) }) })), _jsxs(CardWrapper, __assign({ column: true, alignItems: "center", style: { position: "relative" } }, { children: [_jsx(Logo, { src: PeLogo }), children] }))] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

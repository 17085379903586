var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox, } from "react-icons/md";
var Container = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  cursor: pointer;\n  user-select: none;\n  & input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  cursor: pointer;\n  user-select: none;\n  & input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n  }\n"])));
/**
 * Reusable checkbox component, persium branded.  Can manage internal state, or be
 * state controlled, depending on presence of `checked` and `onChange` props
 */
export var Checkbox = function (_a) {
    var checkedFromProps = _a.checked, onChangeFromProps = _a.onChange, name = _a.name, size = _a.size, style = _a.style, className = _a.className, id = _a.id;
    var _b = __read(useState(false), 2), internalChecked = _b[0], setInternalChecked = _b[1];
    var checked = checkedFromProps !== null && checkedFromProps !== void 0 ? checkedFromProps : internalChecked;
    var onChange = onChangeFromProps !== null && onChangeFromProps !== void 0 ? onChangeFromProps : (function () {
        setInternalChecked(function (c) { return !c; });
    });
    var Renderable = checked
        ? MdOutlineCheckBox
        : MdOutlineCheckBoxOutlineBlank;
    return (_jsxs(Container, __assign({ className: "container ".concat(className), style: style, id: id }, { children: [_jsx("input", { type: "checkbox", name: name, checked: checked, onChange: onChange }), _jsx(Renderable, { size: size })] })));
};
var templateObject_1;

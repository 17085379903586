var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal, Prompt, Row } from "components/atoms";
/**
 * Reusable modal component to confirm a certain action
 */
export var ConfirmationModal = function (_a) {
    var isOpen = _a.isOpen, setIsOpen = _a.setIsOpen, title = _a.title, message = _a.message, actionButtonText = _a.actionButtonText, onConfirm = _a.onConfirm, id = _a.id, rest = __rest(_a, ["isOpen", "setIsOpen", "title", "message", "actionButtonText", "onConfirm", "id"]);
    return (_jsxs(Modal, __assign({ isOpen: isOpen, setIsOpen: setIsOpen }, rest, { wrapperStyle: __assign({ maxWidth: "360px" }, rest.wrapperStyle), id: id }, { children: [_jsx("h5", { children: title }), _jsx(Prompt, __assign({ strong: true, margin: "32px 0" }, { children: message })), _jsxs(Row, { children: [_jsx(Button, __assign({ plump: true, onClick: function () { return setIsOpen(false); }, style: {
                            flex: 1,
                            marginRight: "20px",
                        }, id: "cancel-button" }, { children: "Cancel" })), _jsx(Button, __assign({ plump: true, outline: "filled", style: {
                            flex: 1,
                        }, onClick: onConfirm, id: "continue-button" }, { children: actionButtonText !== null && actionButtonText !== void 0 ? actionButtonText : "Continue" }))] })] })));
};

/**
 * Geographic calculation related utilities
 */
import L from "leaflet";
/**
 * Function to convert L.LatLngBounds or L.LatLngBoundsLiteral from leaflet
 * into MapCornersParams required by API
 */
export var boundsToParams = function (boundsArg) {
    var bounds = boundsArg instanceof L.LatLngBounds ? boundsArg : L.latLngBounds(boundsArg);
    var lat1 = bounds.getNorthEast().lat;
    var lng1 = bounds.getNorthEast().lng;
    var lat2 = bounds.getSouthWest().lat;
    var lng2 = bounds.getNorthEast().lng;
    var lat3 = bounds.getSouthWest().lat;
    var lng3 = bounds.getSouthWest().lng;
    var lat4 = bounds.getNorthEast().lat;
    var lng4 = bounds.getSouthWest().lng;
    return {
        lat1: lat1,
        lng1: lng1,
        lat2: lat2,
        lng2: lng2,
        lat3: lat3,
        lng3: lng3,
        lat4: lat4,
        lng4: lng4,
    };
};
/**
 * Util function to check if point is inside polygon
 *
 * Adapted from https://stackoverflow.com/a/31813714/12010984
 */
export function isPointInPolygon(point, poly) {
    var polyPoints = poly.getLatLngs()[0];
    var x = point.lat;
    var y = point.lng;
    var inside = false;
    for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
        var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
        var xj = polyPoints[j].lat, yj = polyPoints[j].lng;
        var intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect)
            inside = !inside;
    }
    return inside;
}
/**
 * Function to test whether or not a point is within an L.Circle instance
 */
export function isPointInCircle(point, circle) {
    var radius = circle.getRadius();
    var center = circle.getLatLng();
    var latlng = L.latLng(point);
    return latlng.distanceTo(center) <= radius;
}
/**
 * Utility function to derive map center and zoom level from a given latLngBounds.  Because bounds
 * are dependent on the map's real HTML container size, the arguments must contain either a real,
 * sized HTML element that is the container for a map, or height and width values of an imaginary
 * container to create on the fly
 */
export var deriveCenterAndZoomFromBounds = function (_a) {
    var bounds = _a.bounds, height = _a.height, width = _a.width;
    // First, create dummy element with specific size for off-screen map to live in
    var element = document.createElement("div");
    // Create offscreen map
    var map = L.map(element);
    map.getSize = function () {
        return L.point({ x: width, y: height });
    };
    var center = bounds.getCenter();
    var zoom = 11 || map.getBoundsZoom(bounds);
    return { center: center, zoom: zoom };
};
/**
 * Utility function to derive map center, zoom, and bounds from a group of points
 */
export var deriveBoundsFromPoints = function (points, padding) {
    if (padding === void 0) { padding = 0; }
    var group = L.featureGroup(points.map(function (_a) {
        var lat = _a.lat, lng = _a.lng;
        return L.marker({ lat: lat, lng: lng });
    }));
    return group.getBounds().pad(padding);
};

import { jsx as _jsx } from "react/jsx-runtime";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import merge from "lodash.merge";
highchartsMore(Highcharts);
/**
 * Default graphing options used as a base to branch off from for all
 * gauge charts.  `graphOptions` props gets spread into this object, allowing
 * for customization on a per-use basis of `Gauge`, but also providing
 * a consistent starting point across all instances of `Gauge`
 */
var defaultOptions = {
    chart: {
        type: "gauge",
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: "80%",
        marginTop: 0,
    },
    exporting: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
    title: {
        text: null,
    },
    pane: {
        startAngle: -110,
        endAngle: 110,
        background: null,
        center: ["50%", "75%"],
        size: "110%",
    },
    // the value axis
    yAxis: {
        min: 0,
        max: 200,
        tickPixelInterval: 72,
        tickLength: 0,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
            distance: 20,
            style: {
                fontSize: "14px",
            },
        },
    },
    series: [
        {
            dataLabels: {
                borderWidth: 0,
                color: "#333333",
                style: {
                    fontSize: "16px",
                    fontFamily: "Inter",
                },
            },
            // @ts-expect-error
            dial: {
                radius: "80%",
                backgroundColor: "gray",
                baseWidth: 12,
                baseLength: "0%",
                rearLength: "0%",
            },
            // @ts-expect-error
            pivot: {
                backgroundColor: "gray",
                radius: 6,
            },
        },
    ],
};
/**
 * Gauge type indicator, based on highchartsjs
 */
export var Gauge = function (_a) {
    var optionsFromProps = _a.options;
    var options = merge({}, defaultOptions, optionsFromProps);
    return _jsx(HighchartsReact, { highcharts: Highcharts, options: options });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";
var BarChart = function (_a) {
    var station = _a.station, pollutants = _a.pollutants;
    var filteredPollutants = pollutants.filter(function (item) { return item.percent !== null && item.percent !== undefined; });
    return (_jsxs(Stack, __assign({ flexDirection: "row", style: {
            justifyContent: "stretch",
            marginTop: "16px",
        } }, { children: [_jsx(LeftSide, { pollutants: filteredPollutants }), _jsx(RightSide, { pollutants: filteredPollutants, selectedStation: station })] })));
};
export default BarChart;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MdNotificationsActive } from "react-icons/md";
import { Button, Column, Divider, Prompt, Modal } from "components";
import { ActionCreators } from "store";
import { COLORS } from "theme";
/**
 * Default AQI to be set when user subscribes to their first pod, for various
 * AQI unit types
 */
var defaultAqiThreshhold = {
    daqi: 4,
    caqi: 51,
    usaqi: 100,
};
/**
 * Confirmation modal that pops up when user clicks to subscribe to pod alerts
 */
export var GetAlertsConfirmation = function (_a) {
    var _b;
    var pod = _a.pod, rest = __rest(_a, ["pod"]);
    var navigate = useNavigate();
    var gotoSettings = function () { return navigate("/settings"); };
    var dispatch = useDispatch();
    var subscribedPods = useSelector(function (state) { return state.pods.subscribed; });
    var userSettings = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.user) === null || _a === void 0 ? void 0 : _a.info) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.settings; });
    var unit = (_b = userSettings === null || userSettings === void 0 ? void 0 : userSettings.aqi_type) !== null && _b !== void 0 ? _b : "daqi";
    var notificationsDisabled = !(userSettings === null || userSettings === void 0 ? void 0 : userSettings.email_notification) && !(userSettings === null || userSettings === void 0 ? void 0 : userSettings.phone_notification);
    var thresholds = userSettings === null || userSettings === void 0 ? void 0 : userSettings.thresholds;
    var thresholdsList = Object.values(thresholds).flat();
    var availableSpecies = Object.values(pod.available_parameters)
        .flat()
        .map(function (s) { return s.code; });
    /** Have to add bc AQI is not actually listed in available_parameters */
    availableSpecies.push("AQI");
    var relevantThressholds = thresholdsList.filter(function (threshhold) {
        return availableSpecies.includes(threshhold.code);
    });
    var onlyCurrentThreshholdIsAqi = relevantThressholds.length === 1 && relevantThressholds[0].code === "AQI";
    var onlyNonAqiThreshholds = !!relevantThressholds.length && !onlyCurrentThreshholdIsAqi;
    /**
     * Handle what happens when the user clicks "Get Alerts"
     */
    var handleSubscribe = useCallback(function () {
        /* Call route to subscribe to a pod */
        dispatch(ActionCreators.requestAddSubscribedPod({
            uuid: pod.uuid,
            body: { parameters: [] }, // TODO - how do we determine what parameters to send here?
        }));
        /**
         * If user does not currently have any pods subscribed, they de-facto will also have no threshholds
         * set.  So when we are going from 0 subscribed pods to > 0 subscribed pods, we always set an initial
         * threshhold on AQI
         */
        if (!subscribedPods.length) {
            dispatch(ActionCreators.updateSettings({
                thresholds: {
                    aqi: [
                        {
                            code: "AQI",
                            value: defaultAqiThreshhold[unit],
                            operator: ">",
                            unit: unit,
                        },
                    ],
                    pollutants: [],
                    atmospherics: [],
                    engineerings: [],
                },
            }));
        }
        /**
         * If user is subscribed to some pods, and tries to subscribe to another one, but the new one
         * does not have any parameters the user is currently listening for in their threshhold list,
         * we automatically add AQI as a threshhold to listen for.  For example - I am only subscribed to
         * a persium pod, and I only have a threshhold set for Battery Voltage.  I then try
         * to subscribe to regular station, that does not have battery voltage as an available param.
         * In this case, we add AQI back to the list of threshholds, so the newly subscribed pods will generate
         * alerts, and adding it as a subscribed pod has meaning.  This is reflected in the confirmation
         * warning the user sees when they click the bell.
         */
        if (!relevantThressholds.length) {
            dispatch(ActionCreators.updateSettings({
                thresholds: __assign(__assign({}, thresholds), { aqi: [
                        {
                            code: "AQI",
                            value: defaultAqiThreshhold[unit],
                            operator: ">",
                            unit: unit,
                        },
                    ] }),
            }));
        }
        /* Close the subscription modal / popup */
        rest.setIsOpen(false);
    }, [pod]);
    return (_jsx(Modal, __assign({ id: "confirm-subscribe-to-pod-dialog", backProps: { text: "Later" }, wrapperStyle: { width: "340px" }, nextProps: {
            text: notificationsDisabled ? "Go to Settings" : "Get Alerts",
            onClick: notificationsDisabled ? gotoSettings : handleSubscribe,
        }, title: _jsxs("span", { children: [_jsx(MdNotificationsActive, { color: COLORS.BLUE, style: { marginBottom: "-2px" } }), " ", "Get air quality alerts"] }) }, rest, { children: notificationsDisabled ? (_jsx(Prompt, { children: "You have disabled all notifications. You must enable notifications in your settings to receive alerts from stations." })) : (_jsxs(Column, { children: [!relevantThressholds.length && (_jsxs(Prompt, __assign({ strong: true }, { children: ["If the AQI at this station exceeds ", defaultAqiThreshhold[unit], ", you will receive a notification."] }))), onlyCurrentThreshholdIsAqi && (_jsxs(Prompt, __assign({ strong: true }, { children: ["If the AQI at this station exceeds ", relevantThressholds[0].value, ", you will receive a notification."] }))), onlyNonAqiThreshholds && (_jsx(Prompt, __assign({ strong: true }, { children: "If the pollutants at this station exceed your specified threshholds, you will receive a notification." }))), _jsx(Divider, { style: { marginTop: "20px" } }), _jsxs(Prompt, { children: ["You can customize your notification settings in the", " ", _jsx(Button, __assign({ outline: "bare", onClick: gotoSettings, style: {
                                margin: 0,
                                display: "inline",
                                fontSize: "14px",
                                padding: 0,
                                marginRight: "4px",
                            } }, { children: "Settings" })), "section."] })] })) })));
};

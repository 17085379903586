var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { parseDateString } from "utils";
var StationLatestUpdateDate = function (_a) {
    var stationLatest = _a.stationLatest;
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var _b = parseDateString(stationLatest.date, userInfo.settings.timezone), date = _b.date, hourWithTimezone = _b.hourWithTimezone;
    return (_jsxs(Stack, __assign({ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Typography, __assign({ variant: "caption" }, { children: date })), _jsx(Typography, __assign({ variant: "caption" }, { children: hourWithTimezone }))] })));
};
export default StationLatestUpdateDate;

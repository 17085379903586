var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
/**
 * Generic column component.  Based on flexbox, aligns content in a column.  Can be customized
 * with CSS properties
 */
export var Column = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: ", ";\n\tjustify-content: ", ";\n\tmargin: ", ";\n\tpadding: ", ";\n\tflex: ", ";\n\tgap: ", ";\n"], ["\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: ", ";\n\tjustify-content: ", ";\n\tmargin: ", ";\n\tpadding: ", ";\n\tflex: ", ";\n\tgap: ", ";\n"])), function (props) { var _a; return (_a = props.alignItems) !== null && _a !== void 0 ? _a : "flex-start"; }, function (props) { var _a; return (_a = props.justifyContent) !== null && _a !== void 0 ? _a : "flex-start"; }, function (props) { return props.margin; }, function (props) { return props.padding; }, function (props) { return props.flex; }, function (props) { return props.gap; });
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { COLORS } from "theme";
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  cursor: pointer;\n  opacity: ", ";\n  pointer-events: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  cursor: pointer;\n  opacity: ", ";\n  pointer-events: ", ";\n"])), function (props) { return (props.disabled ? 0.5 : 1); }, function (props) { return props.disabled && "none"; });
var Switch = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 32px;\n  height: 14px;\n  background: ", ";\n  border-radius: 32px;\n  transition: 300ms all;\n\n  /* Toggle 'handle' */\n  &:before {\n    transition: 300ms all;\n    content: \"\";\n    position: absolute;\n    width: 20px;\n    height: 20px;\n    border-radius: 35px;\n    top: 50%;\n    left: -6px;\n    background: #ffffff;\n    transform: translate(0, -50%);\n    box-shadow: 0 0 5px 2px ", ";\n  }\n"], ["\n  position: relative;\n  width: 32px;\n  height: 14px;\n  background: ", ";\n  border-radius: 32px;\n  transition: 300ms all;\n\n  /* Toggle 'handle' */\n  &:before {\n    transition: 300ms all;\n    content: \"\";\n    position: absolute;\n    width: 20px;\n    height: 20px;\n    border-radius: 35px;\n    top: 50%;\n    left: -6px;\n    background: #ffffff;\n    transform: translate(0, -50%);\n    box-shadow: 0 0 5px 2px ", ";\n  }\n"])), COLORS.GREY, COLORS.GREY);
var Input = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n\n  &:checked + ", " {\n    background: #00a7e1;\n\n    &:before {\n      transform: translate(24px, -50%);\n    }\n  }\n"], ["\n  display: none;\n\n  &:checked + ", " {\n    background: #00a7e1;\n\n    &:before {\n      transform: translate(24px, -50%);\n    }\n  }\n"])), Switch);
/**
 * Stylized toggle switch
 */
export var ToggleSwitch = function (props) {
    var checkedFromProps = props.checked, onChangeFromProps = props.onChange, label = props.label, id = props.id;
    var _a = __read(useState(false), 2), internalChecked = _a[0], setInternalChecked = _a[1];
    var checked = checkedFromProps !== null && checkedFromProps !== void 0 ? checkedFromProps : internalChecked;
    var onChange = onChangeFromProps !== null && onChangeFromProps !== void 0 ? onChangeFromProps : (function () {
        setInternalChecked(!checked);
    });
    return (_jsxs(Label, __assign({ disabled: props.disabled }, { children: [label && _jsx("span", { children: label }), _jsx(Input, { type: "checkbox", checked: checked, onChange: onChange, id: id }), _jsx(Switch, {})] })));
};
var templateObject_1, templateObject_2, templateObject_3;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { BsCheckLg } from "react-icons/bs";
import { Button, Column, ErrorPrompt, Prompt, TextInput, } from "components/atoms";
import { ActionCreators } from "store";
import { COLORS } from "theme";
import { ContactForm } from "components/molecules";
import { Inner } from ".";
/**
 * Forgot password content for login screen
 */
export var ForgotPassword = function () {
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var _a = __read(useState(""), 2), email = _a[0], setEmail = _a[1];
    var _b = __read(useState(false), 2), contactFormOpen = _b[0], setContactFormOpen = _b[1];
    var resetPassword = useSelector(function (state) { return state.user.resetPasswordInit; });
    var loading = resetPassword === null || resetPassword === void 0 ? void 0 : resetPassword.loading;
    var success = resetPassword === null || resetPassword === void 0 ? void 0 : resetPassword.success;
    var error = resetPassword === null || resetPassword === void 0 ? void 0 : resetPassword.error;
    useEffect(function () {
        return function () {
            dispatch(ActionCreators.clearResetPasswordInit());
        };
    }, []);
    return (_jsxs(_Fragment, { children: [success ? (_jsxs(Column, __assign({ margin: "80px 0 120px", alignItems: "center" }, { children: [_jsx(BsCheckLg, { color: COLORS.BLUE, size: 40 }), _jsx("h2", __assign({ style: { textAlign: "center", marginTop: "40px" } }, { children: "A link was sent to your email to reset your password" }))] }))) : (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ style: { textAlign: "center", width: "60%" } }, { children: "Reset your password" })), _jsx(Prompt, __assign({ strong: true, style: {
                            maxWidth: "65%",
                            margin: "12px 0 20px 0",
                            textAlign: "center",
                        } }, { children: "Tell us your email address or username and we\u2019ll send you link to reset your password." })), _jsxs(Inner, { children: [_jsx(TextInput, { id: "email-input", type: "email", placeholder: "User name or Email", value: email, onChange: function (e) { return setEmail(e.target.value); }, error: !!error }), _jsx(ErrorPrompt, __assign({ visible: !!error }, { children: error })), _jsx(Button, __assign({ plump: true, outline: "filled", loading: loading, onClick: function (e) {
                                    e.preventDefault();
                                    dispatch(ActionCreators.requestResetPasswordInit({ email: email }));
                                }, disabled: !email }, { children: "Continue" })), _jsxs(Column, __assign({ alignItems: "center", margin: "20px 0" }, { children: [_jsx(Prompt, __assign({ style: { textAlign: "center" } }, { children: "Still having trouble?" })), _jsx(Button, __assign({ plump: true, outline: "bare", onClick: function (e) {
                                            e.preventDefault();
                                            setContactFormOpen(true);
                                        } }, { children: "Contact Persium Support" }))] }))] })] })), _jsx(Button, __assign({ plump: true, outline: "bare", loading: loading, onClick: function (e) {
                    e.preventDefault();
                    navigate("/");
                }, style: { position: "absolute", left: "10px", bottom: "10px" } }, { children: "Back" })), _jsx(ContactForm, { isOpen: contactFormOpen, setIsOpen: setContactFormOpen })] }));
};

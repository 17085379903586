var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createLayerComponent, } from "@react-leaflet/core";
import { spline } from "leaflet-spline";
/**
 * Function to create the bezier spline layer
 */
var createLayer = function (props, context) {
    var positions = props.positions, pathOptions = props.pathOptions;
    var instance = spline(positions, __assign(__assign({}, pathOptions), { noClip: true }));
    return { instance: instance, context: context };
};
/**
 * Function to update the layer
 * @param {*} instance The underlying L.polyline instance
 * @param {*} props Component properties that may be updated via react
 * @param {*} prevProps Previous component properties before updating
 */
var updateLayer = function (instance, props, prevProps) {
    /**
     * Will update line weight, which is needed to respond to map zoom level
     */
    if (prevProps.weight !== props.weight) {
        instance.setStyle({ weight: props.weight });
    }
};
/**
 * Custom react-leaflet component for transforming polylines/polygons into bezier-splines
 * Based on turfjs
 */
export var BezierSpline = createLayerComponent(createLayer, updateLayer);

/**
 * All actions related to fetching data for pods
 */
import { ActionTypes } from "store/types";
import { createAction } from "typesafe-actions";
/**
 * Meta value representing used to help inform where pod graph data
 * should be saved in the redux store - consumed by reducers
 */
export var GraphType;
(function (GraphType) {
    GraphType["Dashboard"] = "Dashboard";
    GraphType["HA"] = "HA";
    GraphType["PodDetail"] = "PodDetail";
})(GraphType || (GraphType = {}));
/**
 * All actions related to getting data for specific pods and specific parameters
 */
export var PodActions = {
    /**
     * Redux action to request multiple pod summaries at once
     */
    requestPodSummaries: createAction(ActionTypes.REQ_POD_SUMMARIES, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to set multiple pod summaries in store at once
     */
    setPodSummaries: createAction(ActionTypes.SET_POD_SUMMARIES, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to set pod summary as the current pod detail summary
     */
    setCurrentSummary: createAction(ActionTypes.SET_CURRENT_POD_SUMMARY, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to clear the current pod detail data
     */
    clearCurrentPod: createAction(ActionTypes.CLEAR_CURRENT_POD, function () { return undefined; }, function () { return "undefined"; })(),
    /**
     * Redux action to request latest pod data from api
     */
    requestLatestPodData: createAction(ActionTypes.REQ_POD_LATEST_DATA, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to set latest pod data as returned from api
     */
    setLatestPodData: createAction(ActionTypes.SET_POD_LATEST_DATA, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request user's favorite station/pod from API
     */
    requestFavPods: createAction(ActionTypes.REQ_FAV_PODS, function () { return undefined; }, function () { return "undefined"; })(),
    /**
     * Redux action to set user's favorite station/pod from API
     */
    setFavPods: createAction(ActionTypes.SET_FAV_PODS, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request (POST) to add pod to user's favorites
     */
    requestAddFavPod: createAction(ActionTypes.REQ_ADD_FAV_POD, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request (DELETE) to delete pod from user's favorites
     */
    requestDeleteFavPod: createAction(ActionTypes.REQ_DELETE_FAV_POD, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request user's owned station/pod from API
     */
    requestOwnedPods: createAction(ActionTypes.REQ_OWNED_PODS, function () { return undefined; }, function () { return "undefined"; })(),
    /**
     * Redux action to set user's owned station/pod from API
     */
    setOwnedPods: createAction(ActionTypes.SET_OWNED_PODS, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request the user's subscribed pods from the API
     */
    requestSubscribedPods: createAction(ActionTypes.REQ_SUBSCRIBED_PODS, function () { return undefined; }, function () { return "undefined"; })(),
    /**
     * Redux action to request the user's subscribed pods from the API
     */
    updateError: createAction(ActionTypes.UPDATE_ERROR, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to set the user's subscribed pods in the store
     * @param {PodSummary} payload User's subscribed pods as defined by API response
     */
    setSubscribedPods: createAction(ActionTypes.SET_SUBSCRIBED_PODS, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request (POST) to add pod to user's subscribed list
     * @param {{ uuid: string, body: object }} payload UUID of pod to add to subscribed, and any req body
     */
    requestAddSubscribedPod: createAction(ActionTypes.REQ_ADD_SUBSCRIBED_POD, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request (DELETE) to delete pod from user's subscribed pods
     */
    requestDeleteSubscribedPod: createAction(ActionTypes.REQ_DELETE_SUBSCRIBED_POD, function (payload) { return payload; }, function () { return "undefined"; })(),
    /**
     * Redux action to request parameter data for a single parameter for a single pod
     */
    requestPodParameterData: function (payload, meta) { return ({
        type: ActionTypes.REQ_POD_PARAM_DATA,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to set pod parameter data as returned from api
     */
    setPodParameterData: function (payload, meta) { return ({
        type: ActionTypes.SET_POD_PARAM_DATA,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to request parameter data for a single parameter for multiple pods
     */
    requestMultiplePodsParameterData: function (payload, meta) { return ({
        type: ActionTypes.REQ_POD_PARAM_DATA_MULTI,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to set parameter data for a single parameter for a single pod
     */
    setMultiplePodsParameterData: function (payload, meta) { return ({
        type: ActionTypes.SET_POD_PARAM_DATA_MULTI,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to request parameter data for multiple parameters for multiple pods
     */
    request2DParameterData: function (payload, meta) { return ({
        type: ActionTypes.REQ_POD_PARAM_DATA_2D,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to set parameter data for multiple parameters for multiple pods in store
     */
    set2DParameterData: function (payload, meta) { return ({
        type: ActionTypes.SET_POD_PARAM_DATA_2D,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to request average parameter data for a single parameter for multiple pods
     */
    requestAveragePodParameterData: function (payload, meta) { return ({
        type: ActionTypes.REQ_POD_PARAM_DATA_AVERAGE,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to set average parameter data for a single parameter for multiple pods
     */
    setAveragePodParameterData: function (payload, meta) { return ({
        type: ActionTypes.SET_POD_PARAM_DATA_AVERAGE,
        payload: payload,
        meta: meta,
    }); },
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { SimpleMapWrapper } from "components/molecules";
import { Button, Column, Prompt } from "components/atoms";
import { ActionCreators } from "store";
/**
 * Global error boundary fallback component that will show if any runtime JS errors occur
 */
export var ErrorBoundary = function (_a) {
    var resetErrorBoundary = _a.resetErrorBoundary;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    return (_jsx(SimpleMapWrapper, { children: _jsxs(Column, __assign({ style: { width: "80%", margin: "20px 0" }, id: "global-error-message" }, { children: [_jsx("h1", __assign({ className: "blue" }, { children: "Oops!" })), _jsx(Prompt, __assign({ strong: true }, { children: "An unexpected error has ocurred. If this error persists or reocurrs, you can report the error to tech support." })), _jsx(Button, __assign({ onClick: function () {
                        resetErrorBoundary();
                        navigate("/");
                    }, plump: true, outline: "filled", style: { alignSelf: "center", marginTop: "40px", width: "100%" }, id: "back-to-dashboard-button" }, { children: "Back to Dashboard" })), _jsx(Button, __assign({ onClick: function () {
                        dispatch(ActionCreators.logOut());
                        navigate("/");
                        resetErrorBoundary();
                    }, plump: true, style: { alignSelf: "center", marginTop: "10px", width: "100%" }, id: "log-out-button" }, { children: "Log Out" }))] })) }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { FeatureGroup, Pane, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { getMapSlice } from "store";
import { v4 as uuid } from "uuid";
import isEqual from "lodash.isequal";
import groupBy from "lodash.groupby";
import { BezierSpline } from "../components/BezierSpline";
import { contourColor } from "./ContourColor";
/**
 * Map layer to show heatmaps
 */
export var HeatmapLayer = React.forwardRef(function (_a, ref) {
    var reduxLocation = _a.reduxLocation, pane = _a.pane;
    var heatmapData = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).data.heatmap;
    });
    var aqi_type = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).data.meta.aqi_type;
    });
    var map = useMap();
    /**
     * Need to use useEffect to get reference to pane, because react-leaflet v3 does not yet have
     * support for refs in a `<Pane />` component (solved in react-leaflet v4).  Then we can
     * get the canvas being rendered as a child of the pane to apply a blur effect
     *
     * // TODO - this hack is carried over from MapView - this codebase is using RLV4, so we can switcg
     * to using a ref now instead of querySelecting after the fact
     */
    useEffect(function () {
        var canvas = document.querySelector(".leaflet-".concat(pane !== null && pane !== void 0 ? pane : "heatmap", "-pane canvas"));
        if (canvas) {
            canvas.style.filter = "blur(".concat(map.getZoom() / 3, "px)");
        }
    }, [heatmapData]);
    /** Heatmap objects grouped by value, for proper pane order */
    var valueGroups = groupBy(heatmapData, "value");
    return (_jsx(Pane, __assign({ name: pane !== null && pane !== void 0 ? pane : "heatmap", style: { opacity: 0.8 } }, { children: _jsx(FeatureGroup, __assign({ ref: ref }, { children: Object.entries(valueGroups).map(function (_a) {
                var _b = __read(_a, 2), _value = _b[0], heatmapData = _b[1];
                return (_jsx(React.Fragment, { children: heatmapData.map(function (shape) {
                        var value = shape.value, points = shape.points;
                        var latLngs = points.map(function (point) {
                            return point.map(function (number) { return Number(number.toFixed(4)); });
                        });
                        var isClosedShape = isEqual(points[0], points[points.length - 1]);
                        /**
                         * If shape is not already closed as defined from API, copy first point
                         * to also be last point to create a closed polygon spline
                         */
                        if (!isClosedShape) {
                            latLngs.push(latLngs[0]);
                        }
                        return (_jsx(BezierSpline, { positions: latLngs, pathOptions: {
                                pane: pane !== null && pane !== void 0 ? pane : "heatmap",
                                fillColor: contourColor(value, aqi_type).color,
                                color: contourColor(value, aqi_type).color,
                                fill: true,
                                fillOpacity: contourColor(value, aqi_type).fillOpacity,
                                stroke: false,
                            } }, uuid()));
                    }) }, uuid()));
            }) })) })));
});
HeatmapLayer.displayName = "HeatmapLayer";

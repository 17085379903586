var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, useTheme } from "@mui/material";
import { speciesLabels } from "../../../../../constants";
var levelColor = function (lvl, theme) {
    return ({
        1: theme.palette.success.main,
        2: theme.palette.warning["400"],
        3: theme.palette.error.main,
        4: theme.palette.error["600"],
    }[lvl] || theme.palette.secondary["50"]);
};
var Unit = function (_a) {
    var rest = __rest(_a, []);
    return (_jsx(Stack, __assign({ width: "100%", flexDirection: "row", justifyContent: "space-between" }, rest, { sx: {
            flex: 1,
            ":last-child": {
                marginBottom: 0,
            },
        } })));
};
var AirQualityUnit = function (_a) {
    var rest = __rest(_a, []);
    return (_jsx(Stack, __assign({}, rest, { style: {
            fontSize: "14px",
            left: "16px",
            opacity: "10",
        } })));
};
var AirQualityValue = function (_a) {
    var level = _a.level, style = _a.style, rest = __rest(_a, ["level", "style"]);
    var theme = useTheme();
    return (_jsx(Stack, __assign({ flexDirection: "row" }, rest, { style: {
            color: (style === null || style === void 0 ? void 0 : style.color) ||
                (level ? levelColor(level, theme) : theme.palette.secondary["50"]),
            fontSize: "14px",
            lineHeight: "26px",
            minWidth: "64px",
            textAlign: "end",
            width: "64px",
        } })));
};
var LeftSide = function (_a) {
    var pollutants = _a.pollutants;
    return (_jsx(Stack
    // flexDirection="row"
    , __assign({ 
        // flexDirection="row"
        width: "100%", alignItems: "flex-end", justifyContent: "space-between", style: {
            marginTop: "4px",
            minWidth: "200px",
            height: "".concat(pollutants.length * 32, "px"),
        } }, { children: pollutants.map(function (item, index) {
            var percent = item.percent;
            if (typeof percent !== "number")
                return null;
            var hasDecimal = percent.toString().split(".").length > 1;
            return (_jsxs(Unit, { children: [_jsx(AirQualityUnit, __assign({ style: {
                            flexGrow: 1,
                            textAlign: "start",
                        } }, { children: speciesLabels(item.name) })), _jsxs(AirQualityValue, __assign({ level: hasDecimal ? Number(percent.toFixed(2)) : percent, style: {
                            color: item.color,
                        } }, { children: [hasDecimal ? Number(percent).toFixed(2) : percent, _jsx("span", __assign({ style: {
                                    marginLeft: "3px",
                                } }, { children: "%" }))] }))] }, item.name + index));
        }) })));
};
export default LeftSide;

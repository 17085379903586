var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Column } from "components";
import { COLORS, FONTS } from "theme";
var MIN_WIDTH = "36px";
var MAX_WIDTH = "308px";
export var Wrapper = styled(Column)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: 12px;\n  top: 12px;\n  z-index: 401;\n"], ["\n  position: absolute;\n  right: 12px;\n  top: 12px;\n  z-index: 401;\n"])));
export var SearchBar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  background: #000000;\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);\n  border-radius: 34px;\n  transition: all 250ms;\n\n  .expanded & {\n    width: ", ";\n    transition: all 250ms;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: ", ";\n  height: ", ";\n  background: #000000;\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);\n  border-radius: 34px;\n  transition: all 250ms;\n\n  .expanded & {\n    width: ", ";\n    transition: all 250ms;\n  }\n"])), MIN_WIDTH, MIN_WIDTH, MAX_WIDTH);
export var InputWrapperForm = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n"], ["\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n"])));
export var Input = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  width: 100%;\n  height: 36px;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 20px;\n  padding: 8px 5px 8px 34px;\n  ::placeholder,\n  ::-webkit-input-placeholder {\n    color: #f5f6fa;\n    opacity: 0.5;\n  }\n  :-ms-input-placeholder {\n    color: #f5f6fa;\n    opacity: 0.5;\n  }\n\n  :focus,\n  textarea:focus,\n  select:focus {\n    outline: none;\n  }\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);\n  border-radius: 34px;\n  display: flex;\n  align-items: center;\n  background-color: #000000;\n  color: #f5f6fa;\n  border: none;\n"], ["\n  position: absolute;\n  left: 0;\n  width: 100%;\n  height: 36px;\n  font-family: ", ";\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 20px;\n  padding: 8px 5px 8px 34px;\n  ::placeholder,\n  ::-webkit-input-placeholder {\n    color: #f5f6fa;\n    opacity: 0.5;\n  }\n  :-ms-input-placeholder {\n    color: #f5f6fa;\n    opacity: 0.5;\n  }\n\n  :focus,\n  textarea:focus,\n  select:focus {\n    outline: none;\n  }\n  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);\n  border-radius: 34px;\n  display: flex;\n  align-items: center;\n  background-color: #000000;\n  color: #f5f6fa;\n  border: none;\n"])), FONTS.INTER);
export var Select = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  width: 308px;\n  max-height: calc(380px);\n  background: #000000;\n  border-radius: 0 0 16px 16px;\n  margin-top: -16px;\n  padding: 26px 6px 10px 0;\n\n  @media (max-width: 448px) {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  width: 308px;\n  max-height: calc(380px);\n  background: #000000;\n  border-radius: 0 0 16px 16px;\n  margin-top: -16px;\n  padding: 26px 6px 10px 0;\n\n  @media (max-width: 448px) {\n    width: 100%;\n  }\n"])));
export var Line = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  width: calc(100% - 16px);\n  height: 1px;\n  left: 8px;\n  top: 37px;\n  z-index: 402;\n  background: #393d48;\n"], ["\n  position: absolute;\n  width: calc(100% - 16px);\n  height: 1px;\n  left: 8px;\n  top: 37px;\n  z-index: 402;\n  background: #393d48;\n"])));
export var PodsNear = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 11px;\n  padding-bottom: 5px;\n  padding-left: 10px;\n  line-height: 12px;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 11px;\n  padding-bottom: 5px;\n  padding-left: 10px;\n  line-height: 12px;\n"])), COLORS.GREY, FONTS.INTER);
export var FoundPods = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  overflow: auto;\n  flex-direction: column;\n  justify-content: flex-start;\n  width: 100%;\n  background: #000000;\n  overflow-x: hidden;\n  ::-webkit-scrollbar {\n    background: transparent;\n    width: 8px;\n    border: 4px solid rgba(0, 0, 0, 0);\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: ", ";\n    border-radius: 1ex;\n    -webkit-border-radius: 1ex;\n    padding: 2px;\n    width: 5px;\n  }\n"], ["\n  display: flex;\n  overflow: auto;\n  flex-direction: column;\n  justify-content: flex-start;\n  width: 100%;\n  background: #000000;\n  overflow-x: hidden;\n  ::-webkit-scrollbar {\n    background: transparent;\n    width: 8px;\n    border: 4px solid rgba(0, 0, 0, 0);\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: ", ";\n    border-radius: 1ex;\n    -webkit-border-radius: 1ex;\n    padding: 2px;\n    width: 5px;\n  }\n"])), COLORS.GREY);
export var FoundPod = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  cursor: pointer;\n  width: 100%;\n  font-family: ", ";\n  font-size: 14px;\n  color: #f5f6fa;\n  display: flex;\n  align-items: center;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &:first-child {\n    margin-top: 0;\n  }\n"], ["\n  cursor: pointer;\n  width: 100%;\n  font-family: ", ";\n  font-size: 14px;\n  color: #f5f6fa;\n  display: flex;\n  align-items: center;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n  }\n\n  &:focus {\n    outline: none;\n  }\n\n  &:first-child {\n    margin-top: 0;\n  }\n"])), FONTS.INTER, COLORS.DARKGREY);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeatmapLayer, IsobarsLayer, MapView, } from "components/organisms";
import { ActionCreators, getMapSlice, Maps, } from "store";
/**
 * Map to be used in an H&A species map widget, comes with batteries included redux logic
 */
export var SpeciesMap = function (_a) {
    var id = _a.id, species = _a.species, MapViewProps = _a.MapViewProps;
    var dispatch = useDispatch();
    var mapRef = useRef();
    var reduxLocation = {
        path: Maps.HASpeciesMapWidget,
        widget: { id: id },
    };
    /**
     * After widget loads, and the entry animation is done, invalidate the size to make
     * sure map components are rendering properly
     */
    useEffect(function () {
        setTimeout(function () {
            var _a;
            (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.invalidateSize();
        }, 500);
    }, []);
    useEffect(function () {
        dispatch(ActionCreators.setMapView({
            showHeatmap: true,
            showIsobar: true,
            filters: {
                pollutants: [species],
            },
        }, reduxLocation));
    }, [species]);
    var mapState = useSelector(function (state) {
        return getMapSlice(state, reduxLocation);
    });
    if (!mapState)
        return null;
    return (_jsxs(MapView, __assign({ ref: mapRef, reduxLocation: reduxLocation, style: { width: "100%", height: "100%" }, search: false, zoomControl: false }, MapViewProps, { children: [_jsx(HeatmapLayer, {}), _jsx(IsobarsLayer, {})] })));
};

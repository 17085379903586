var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ActionCreators, Maps, ActionTypes, } from "store";
import { boundsToParams } from "utils";
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { getMapDataInBounds } from "../map";
/**
 * Special saga to handle getting map data in the specific instance of a split/swipe map widget
 */
function handleGetSplitMapData(action) {
    var _a, left, right, id_1, view, corners, showHeatmap, showIsobar, heatmap, _b, leftResults, rightResults, e_1;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 5, , 6]);
                _a = action.payload, left = _a.left, right = _a.right;
                id_1 = action.meta.widget.id;
                return [4 /*yield*/, select(function (state) { return state.analytics.maps.swipeMaps[id_1].left.view; })];
            case 1:
                view = _c.sent();
                corners = boundsToParams(view.bounds);
                showHeatmap = view.showHeatmap, showIsobar = view.showIsobar;
                heatmap = showHeatmap || showIsobar;
                return [4 /*yield*/, all([
                        call(getMapDataInBounds, {
                            heatmap: heatmap,
                            params: __assign(__assign({}, corners), left),
                        }),
                        call(getMapDataInBounds, {
                            heatmap: heatmap,
                            params: __assign(__assign({}, corners), right),
                        }),
                    ])];
            case 2:
                _b = __read.apply(void 0, [_c.sent(), 2]), leftResults = _b[0], rightResults = _b[1];
                return [4 /*yield*/, put(ActionCreators.setHeatmapInBounds(leftResults.data, {
                        path: Maps.HASwipeMapWidget,
                        widget: {
                            id: id_1,
                            side: "left",
                        },
                    }))];
            case 3:
                _c.sent();
                return [4 /*yield*/, put(ActionCreators.setHeatmapInBounds(rightResults.data, {
                        path: Maps.HASwipeMapWidget,
                        widget: {
                            id: id_1,
                            side: "right",
                        },
                    }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 6];
            case 5:
                e_1 = _c.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function watchHandleGetSplitMapData() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_SPLIT_MAP_DATA, handleGetSplitMapData)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function analyticsSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([fork(watchHandleGetSplitMapData)])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useDispatch, useSelector } from "react-redux";
import isEqual from "lodash.isequal";
import uniqBy from "lodash.uniqby";
import { ActionCreators } from "store";
/**
 * Utility custom hook to use when removing a pod from the list of subscribed pods.  Returns a function that
 * will execute the following:  In addition to making the api call to remove the pod, this hook also checks
 * any existing notification thresholds.  If there are thresholds for data species that are only available
 * on the pod being deleted, we make a call to remove those thresholds from the user settings as well
 */
export var useUnsubscribeFromPod = function (uuid) {
    var dispatch = useDispatch();
    var subscribedPods = useSelector(function (state) { return state.pods.subscribed; });
    var thresholds = useSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state.user.info) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.settings) === null || _c === void 0 ? void 0 : _c.thresholds; });
    var remainingViableThreshholds = __assign({}, thresholds);
    return function () {
        /**
         * What pods are remaining in subscription list
         */
        var remainingSubscribedPods = subscribedPods.filter(function (subscribedPod) { return subscribedPod.uuid !== uuid; });
        Object.keys(thresholds).forEach(function (group) {
            /**
             * Find available species for all subscribed pods, within the dataspecies group
             * AQI artificially injected because it is not present on the `available_parameters` obj
             */
            var availableSpeciesInGroup = group === "aqi"
                ? [{ name: "AQI", code: "AQI" }]
                : uniqBy(remainingSubscribedPods
                    .map(function (pod) { return pod.available_parameters[group]; })
                    .flat(), "code");
            /**
             * Find what thresholds are available among the available parameters for the currently selected pods,
             * for the current data species group
             */
            var remainingThresholdsInGroup = thresholds[group].filter(function (threshold) {
                return availableSpeciesInGroup
                    .map(function (species) { return species.code; })
                    .includes(threshold.code);
            });
            remainingViableThreshholds[group] = remainingThresholdsInGroup;
        });
        /**
         * Make call to remove pod from subscribed list
         */
        dispatch(ActionCreators.requestDeleteSubscribedPod(uuid));
        /**
         * If viable thresholds were only available on the removed pod, and no other pods,
         * functionality remove them from the list by updating the settings
         */
        if (!isEqual(thresholds, remainingViableThreshholds)) {
            dispatch(ActionCreators.updateSettings({
                thresholds: remainingViableThreshholds,
            }));
        }
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ActionTypes, ActionCreators } from "store";
import { API_BASE, SIGNIN_URL } from "../../../constants";
/**
 * Sagas related to user authentication
 */
/**
 * Saga to request logging the user in
 */
function handleRequestLogin(action) {
    var authResponse, e_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call(axios.post, SIGNIN_URL, action.payload)];
            case 1:
                authResponse = (_b.sent());
                if (!authResponse.data.error) return [3 /*break*/, 3];
                /* If auth error, register it in the store */
                return [4 /*yield*/, put(ActionCreators.setAuthError(authResponse.data.error))];
            case 2:
                /* If auth error, register it in the store */
                _b.sent();
                return [3 /*break*/, 6];
            case 3: 
            /* If auth is successful, register JWT in store */
            return [4 /*yield*/, put(ActionCreators.setAuthenticated({
                    authenticated: true,
                    jwt: authResponse.data.access_token,
                }))];
            case 4:
                /* If auth is successful, register JWT in store */
                _b.sent();
                /* Save JWT to localstorage as as well as to axios headers to be used in all future calls */
                localStorage.setItem("token", authResponse.data.access_token);
                axios.defaults.headers.common.Authorization = "Bearer ".concat(authResponse.data.access_token);
                /* Call next action to request user info */
                return [4 /*yield*/, put(ActionCreators.requestUserInfo())];
            case 5:
                /* Call next action to request user info */
                _b.sent();
                _b.label = 6;
            case 6: return [3 /*break*/, 9];
            case 7:
                e_1 = _b.sent();
                return [4 /*yield*/, put(ActionCreators.setAuthError((_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.data.error))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
/**
 * Saga to call to get refresh JWT token
 */
function handleRequestRefreshToken() {
    var authResponse, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 6]);
                return [4 /*yield*/, call(axios.put, "".concat(API_BASE, "/user/auth/refresh"))];
            case 1:
                authResponse = (_a.sent());
                if (!authResponse.data.error) return [3 /*break*/, 3];
                /* If auth error, register it in the store */
                return [4 /*yield*/, put(ActionCreators.setAuthError(authResponse.data.error))];
            case 2:
                /* If auth error, register it in the store */
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                /* If call to get refresh token is successful */
                /* Save JWT to localstorage as as well as to axios headers to be used in all future calls */
                localStorage.setItem("token", authResponse.data.access_token);
                axios.defaults.headers.common.Authorization = "Bearer ".concat(authResponse.data.access_token);
                _a.label = 4;
            case 4: return [3 /*break*/, 6];
            case 5:
                e_2 = _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request password reset - will send call to POST route to send link to user email
 */
function handleResetPasswordInit(action) {
    var e_3;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 3, , 5]);
                /* Call to post email to forgot route */
                return [4 /*yield*/, call(axios.post, "".concat(API_BASE, "/user/password/forgot"), __assign(__assign({}, action.payload), { callback_url: "".concat(window.location.origin, "/reset-password/") }))];
            case 1:
                /* Call to post email to forgot route */
                _d.sent();
                /* Register success in store */
                return [4 /*yield*/, put(ActionCreators.resetPasswordInitSuccess())];
            case 2:
                /* Register success in store */
                _d.sent();
                return [3 /*break*/, 5];
            case 3:
                e_3 = _d.sent();
                return [4 /*yield*/, put(ActionCreators.resetPasswordInitFailure((_c = (_b = (_a = e_3 === null || e_3 === void 0 ? void 0 : e_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : "There was an error trying to submit your request."))];
            case 4:
                _d.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request password reset - will send call to POST route to send new password
 */
function handleResetPasswordFinish(action) {
    var data, response, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                data = {
                    token: action.payload.restoreToken,
                    new_password: action.payload.password,
                };
                return [4 /*yield*/, call(axios.post, "".concat(API_BASE, "/user/password/reset"), data)];
            case 1:
                response = _a.sent();
                if (!(response.data.message === "The token is invalid.")) return [3 /*break*/, 3];
                return [4 /*yield*/, put(ActionCreators.resetPasswordFinishFailure())];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: 
            /* Register success in store */
            return [4 /*yield*/, put(ActionCreators.resetPasswordFinishSuccess())];
            case 4:
                /* Register success in store */
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                e_4 = _a.sent();
                return [4 /*yield*/, put(ActionCreators.resetPasswordFinishFailure())];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function watchHandleRequestLogin() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_LOGIN, handleRequestLogin)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleRequestRefreshToken() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_REFRESH_TOKEN, handleRequestRefreshToken)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleResetPasswordInit() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_RESET_PASSWORD_INIT, handleResetPasswordInit)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleResetPasswordFinish() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_FORGOT_RESET_PASSWORD_FINISH, handleResetPasswordFinish)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function userAuthSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    fork(watchHandleRequestLogin),
                    fork(watchHandleRequestRefreshToken),
                    fork(watchHandleResetPasswordInit),
                    fork(watchHandleResetPasswordFinish),
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { Button, Row, Prompt, PillButton } from "components/atoms";
import { useDashboardSettings } from "hooks";
import styled from "styled-components";
import { BuildGraphSteps } from "../historics_analytics/widgets";
var PodNamePill = styled(PillButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\twidth: 48%;\n"], ["\n\twidth: 48%;\n"])));
/**
 * The select pods step of the build graph modal for the H&A section
 */
export var SelectPods = function (_a) {
    var _b, _c, _d, _e;
    var setStep = _a.setStep, selectedPods = _a.selectedPods, setSelectedPods = _a.setSelectedPods;
    var Dashboard = useDashboardSettings();
    var podsById = useSelector(function (state) { return state.pods.byId; });
    var pods = (_c = (_b = Dashboard.dashboard) === null || _b === void 0 ? void 0 : _b.sample) === null || _c === void 0 ? void 0 : _c.map(function (pod) { return podsById === null || podsById === void 0 ? void 0 : podsById[pod === null || pod === void 0 ? void 0 : pod.uuid]; }).filter(Boolean);
    return (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsx("h2", __assign({ className: "blue", style: { margin: "0 0 12px 0" }, id: "build-graph-select-pods-title" }, { children: "Select Stations" })) }), ((_e = (_d = Dashboard.dashboard) === null || _d === void 0 ? void 0 : _d.sample) === null || _e === void 0 ? void 0 : _e.length) ? (_jsx(Prompt, { children: "Select the stations whose data you want to export" })) : (_jsxs(Prompt, __assign({ strong: true, style: { textAlign: "center" }, id: "no-sample-message" }, { children: ["You do not have any stations added to your selection. ", _jsx("br", {}), "Please return to the dashboard page and add stations to your selection."] }))), _jsx(Row, __assign({ style: { flexWrap: "wrap", marginTop: "20px" } }, { children: pods.map(function (pod) { return (_jsx(PodNamePill, __assign({ id: "select-pod-".concat(pod.uuid, "-button"), className: selectedPods.map(function (p) { return p.uuid; }).includes(pod.uuid) && "selected", onClick: function () {
                        if (selectedPods.map(function (p) { return p.uuid; }).includes(pod.uuid)) {
                            setSelectedPods(selectedPods.filter(function (p) { return p.uuid !== pod.uuid; }));
                        }
                        else {
                            setSelectedPods(function (p) { return __spreadArray(__spreadArray([], __read(p), false), [pod], false); });
                        }
                    } }, { children: pod.name }), "select-pod-".concat(pod.uuid))); }) })), _jsx(Row, __assign({ justifyContent: "center", style: { margin: "40px 0 0 0" } }, { children: _jsx(Button, __assign({ style: { width: "50%", borderRadius: "18px" }, plump: true, outline: "filled", disabled: !selectedPods.length, onClick: function () { return setStep(BuildGraphSteps.SelectParams); }, id: "continue-button" }, { children: "Continue" })) }))] }));
};
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { checkPasswordSafety } from "utils";
export var PasswordIndicatorandTipsBoxMinMargin = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: -12px;\n  transition: all 0.3s ease-in-out;\n  height: 92px;\n\n  &.hidden {\n    margin: 0;\n    opacity: 0;\n    height: 0;\n    transition: all 0.3s ease-in-out;\n    pointer-events: none;\n  }\n"], ["\n  margin-top: -12px;\n  transition: all 0.3s ease-in-out;\n  height: 92px;\n\n  &.hidden {\n    margin: 0;\n    opacity: 0;\n    height: 0;\n    transition: all 0.3s ease-in-out;\n    pointer-events: none;\n  }\n"])));
export var IndicatorBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 9px 16px 11px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  color: #ffffff;\n  font-size: 10px;\n  line-height: 12px;\n"], ["\n  margin: 9px 16px 11px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  color: #ffffff;\n  font-size: 10px;\n  line-height: 12px;\n"])));
export var PasswordIndicator = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 4px;\n  width: 100%;\n  background: #393d48;\n  display: flex;\n"], ["\n  height: 4px;\n  width: 100%;\n  background: #393d48;\n  display: flex;\n"])));
export var PasswordIndicatorConstuction = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 0;\n  background: transparent;\n  &.weak {\n    width: 25%;\n    background: #ff5b5b;\n  }\n  &.light {\n    width: 50%;\n    background: #ffbc0f;\n  }\n  &.normal {\n    width: 75%;\n    background: #ffbc0f;\n  }\n  &.safe {\n    width: 100%;\n    background: #31e077;\n  }\n  transition: all 0.5s ease-in-out;\n"], ["\n  width: 0;\n  background: transparent;\n  &.weak {\n    width: 25%;\n    background: #ff5b5b;\n  }\n  &.light {\n    width: 50%;\n    background: #ffbc0f;\n  }\n  &.normal {\n    width: 75%;\n    background: #ffbc0f;\n  }\n  &.safe {\n    width: 100%;\n    background: #31e077;\n  }\n  transition: all 0.5s ease-in-out;\n"])));
export var PasswordTips = styled.ul(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0 16px 12px;\n  font-size: 12px;\n  line-height: 16px;\n  color: #8f96a0;\n  padding-left: 0;\n"], ["\n  margin: 0 16px 12px;\n  font-size: 12px;\n  line-height: 16px;\n  color: #8f96a0;\n  padding-left: 0;\n"])));
export var PasswordSafetyBox = function (_a) {
    var password = _a.password, style = _a.style;
    var passwordSafety = checkPasswordSafety(password);
    return (_jsxs(PasswordIndicatorandTipsBoxMinMargin, __assign({ className: !password && "hidden", style: style }, { children: [_jsxs(IndicatorBox, { children: [_jsx(PasswordIndicator, { children: _jsx(PasswordIndicatorConstuction, { className: passwordSafety }) }), "\u00A0\u00A0", passwordSafety] }), _jsxs(PasswordTips, { children: [_jsx("li", { children: "Enter at least 6 characters" }), _jsx("li", { children: "Use letters together with spaces, numbers, or symbols (!@#$%^&*)." })] })] })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { DashboardActionCreators, dashboardReducer, dashboardSagas, } from "./dashboard";
import { MapActionCreators, mapSagas } from "./map";
import { userReducer, userSagas, UserActionCreators } from "./user";
import { SettingsActionCreators, settingsSagas } from "./settings";
import { PodActions, podSagas, podsReducer } from "./pods";
import { AnalyticsActionCreators, analyticsReducer, analyticsSagas, } from "./analytics";
import { GeneralActions, generalReducer, generalSagas, } from "./general";
import { ReportsActionCreators, reportsReducer, reportsSagas, } from "./reports";
import { AtmosphereActionCreators, atmosphereReducer, atmosphereSagas, } from "./atmosphere";
export * from "./map";
export * from "./types";
/**
 * Central, combined reducer for whole application
 */
var rootReducer = combineReducers({
    user: userReducer,
    dashboard: dashboardReducer,
    reports: reportsReducer,
    analytics: analyticsReducer,
    pods: podsReducer,
    general: generalReducer,
    atmosphere: atmosphereReducer,
});
/**
 * Central, combined sagas for whole application
 */
function rootSaga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    fork(dashboardSagas),
                    fork(reportsSagas),
                    fork(mapSagas),
                    fork(userSagas),
                    fork(settingsSagas),
                    fork(podSagas),
                    fork(generalSagas),
                    fork(analyticsSagas),
                    fork(atmosphereSagas),
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
var sagaMiddleware = createSagaMiddleware();
/**
 * Compose middleware through dev tools if in development mode, otherwise use redux's simple `compose`
 */
var middleware = process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(sagaMiddleware))
    : compose(applyMiddleware(sagaMiddleware));
/**
 * Central redux store for whole application
 */
export var store = createStore(rootReducer, middleware);
// @ts-expect-error For cypress debugging purposes
if (window.Cypress) {
    // @ts-expect-error For cypress debugging purposes
    window.store = store;
}
sagaMiddleware.run(rootSaga);
/**
 * All redux action creators in one convenience object
 */
export var ActionCreators = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, MapActionCreators), DashboardActionCreators), UserActionCreators), SettingsActionCreators), PodActions), GeneralActions), ReportsActionCreators), AnalyticsActionCreators), AtmosphereActionCreators);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
var HealthAdvice = function (_a) {
    var statusColor = _a.statusColor, healthAdvice = _a.healthAdvice;
    return (_jsx(Stack, { children: _jsx(Typography, __assign({ color: statusColor, fontFamily: "InterSemiBold, sans-serif", variant: "caption" }, { children: healthAdvice })) }));
};
export default HealthAdvice;

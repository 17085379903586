var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { useMap } from "react-leaflet";
import { FaStar } from "react-icons/fa";
import { MdLocationPin, MdOutlineClose } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import L from "leaflet";
import { ActionCreators, getMapSlice, } from "store";
import { Column, IconButton, LoadingSpinner, Prompt, Row } from "components";
import { COLORS } from "theme";
import { SearchBar, InputWrapperForm, Input, Select, Line, FoundPod, FoundPods, Wrapper, PodsNear, } from "./styles";
/**
 * Searchbar UI component in the top right of the screen.  Acts as both a search as well
 * as a geocoder when the user pans the map (if search is empty)
 */
export var SearchBase = function (_a) {
    var _b, _c, _d;
    var reduxLocation = _a.reduxLocation;
    var map = useMap();
    var _e = __read(useState(""), 2), value = _e[0], setValue = _e[1];
    var _f = __read(useState(true), 2), expanded = _f[0], setExpanded = _f[1];
    var _g = __read(useState(true), 2), isActiveSearch = _g[0], setIsActiveSearch = _g[1];
    var dispatch = useDispatch();
    var loading = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).search.loading;
    });
    var searchResults = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).search.results;
    });
    var foundPods = useSelector(function (state) { return getMapSlice(state, reduxLocation).search.pods; });
    var favPods = useSelector(function (state) { return state.pods.favorites; });
    /**
     * Sort function to sort found pods in order of proximity to the primary
     * search result location
     */
    var sortByProximityToSearchResult = useCallback(function (a, b) {
        var aDistance = a.distance;
        var bDistance = b.distance;
        return aDistance < bDistance ? -1 : 1;
    }, [searchResults[0]]);
    /**
     * Function to calculate distance from a pod to the primary search result,
     * then append that value to the pod data
     */
    var appendDistanceToSearchResult = useCallback(function (pod) {
        var _a = searchResults[0], lat = _a.lat, lon = _a.lon;
        var pLat = pod.latitude, pLng = pod.longitude;
        var refLatLng = L.latLng(Number(lat), Number(lon));
        var pLatLng = L.latLng(pLat, pLng);
        var distance = refLatLng.distanceTo(pLatLng);
        return { distance: distance, pod: pod };
    }, [searchResults[0]]);
    var handleClick = function (pod) {
        setIsActiveSearch(false);
        dispatch(ActionCreators.setPodPreview(pod, reduxLocation));
        map.setView({ lat: pod.latitude, lng: pod.longitude }, 12);
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        dispatch(ActionCreators.setSearch(value, reduxLocation));
        dispatch(ActionCreators.requestGeocode(value, reduxLocation));
        if (searchResults.length && !loading) {
            var boundingbox = searchResults[0].boundingbox;
            var _a = __read(boundingbox, 4), sLat = _a[0], nLat = _a[1], wLng = _a[2], eLng = _a[3];
            var bounds = L.latLngBounds([
                [sLat, wLng],
                [nLat, eLng],
            ]);
            map.fitBounds(bounds, {
            // paddingBottomRight: TODO - accomodate for any open menus
            });
        }
    };
    /**
     * If search term value is at least 3 characters, request to search for pods near the place name
     * This effect will fire `requestSearchForPods`, which first searches the placename in nominatim.
     * If there are results, the latitude and longitude of the first result is used to create a bounding
     * box 1 degree lat x 1 degree lng large, and another call is made to find pods in those bounds. The
     * results are shown in a dropdown from the search menu
     */
    useEffect(function () {
        if (value.length > 2) {
            dispatch(ActionCreators.requestSearchForPods(value, reduxLocation));
        }
    }, [value]);
    return (_jsxs(Wrapper, __assign({ className: expanded && "expanded", id: "search-bar-".concat(reduxLocation.path) }, { children: [_jsx(SearchBar, { children: _jsxs(InputWrapperForm, __assign({ onSubmit: handleSubmit }, { children: [_jsx(IconButton, __assign({ size: 36, style: {
                                position: "absolute",
                                left: 0,
                                padding: "9px",
                                zIndex: 10,
                            }, onClick: function (e) {
                                e.stopPropagation();
                                e.preventDefault();
                                setExpanded(true);
                            } }, { children: _jsx(BsSearch, { color: COLORS.WHITE, size: 16 }) })), _jsx(Input, { disabled: !expanded, onClick: function () { return setIsActiveSearch(true); }, onChange: function (e) {
                                setValue(e.target.value);
                                setIsActiveSearch(true);
                            }, type: "text", value: value, placeholder: "Search for pods" }), expanded && (_jsx(IconButton, __assign({ size: 36, style: {
                                position: "absolute",
                                right: 0,
                                padding: "9px",
                            }, onClick: function (e) {
                                e.stopPropagation();
                                e.preventDefault();
                                setExpanded(false);
                            } }, { children: _jsx(MdOutlineClose, { color: COLORS.GREY, size: 16 }) })))] })) }), isActiveSearch && value && (_jsx(OutsideClickHandler, __assign({ onOutsideClick: function () { return setIsActiveSearch(false); } }, { children: _jsxs(Select, __assign({ className: "search-select-menu" }, { children: [_jsx(Line, {}), loading ? (_jsx(PodsNear, { children: _jsxs(Row, __assign({ justifyContent: "flex-start" }, { children: [_jsx(LoadingSpinner, { style: { height: "20px", width: "20px" } }), " ", "Searching . . ."] })) })) : foundPods.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs(PodsNear, { children: ["Showing pods near", " ", _jsx("em", __assign({ style: { fontStyle: "italic" } }, { children: (_b = searchResults === null || searchResults === void 0 ? void 0 : searchResults[0]) === null || _b === void 0 ? void 0 : _b.display_name }))] }), _jsx(FoundPods, { children: foundPods
                                        .map(appendDistanceToSearchResult)
                                        .sort(sortByProximityToSearchResult)
                                        .map(function (item) {
                                        var PodIcon = favPods
                                            .map(function (p) { return p.uuid; })
                                            .includes(item.pod.uuid)
                                            ? FaStar
                                            : MdLocationPin;
                                        return (_jsx(FoundPod, __assign({ id: "search-item-".concat(item.pod.uuid), onClick: function () { return handleClick(item.pod); }, onKeyPress: function (e) {
                                                if (e.key === "Enter") {
                                                    handleClick(item.pod);
                                                }
                                            }, tabIndex: 0 }, { children: _jsxs(Column, { children: [_jsxs(Row, __assign({ justifyContent: "flex-start" }, { children: [_jsx(PodIcon, { style: { color: COLORS.GREY, margin: "0 8px" } }), item.pod.name] })), _jsxs(Prompt, __assign({ style: {
                                                            fontSize: "11px",
                                                            paddingLeft: "30px",
                                                            lineHeight: "unset",
                                                            marginBottom: "5px",
                                                        } }, { children: [(item.distance / 1000).toFixed(1), " km away"] }))] }) }), item.pod.uuid));
                                    }) })] })) : (_jsxs(PodsNear, __assign({ className: "no-pods-found" }, { children: ["No pods found near", " ", _jsx("em", __assign({ style: { fontStyle: "italic" } }, { children: (_d = (_c = searchResults === null || searchResults === void 0 ? void 0 : searchResults[0]) === null || _c === void 0 ? void 0 : _c.display_name) !== null && _d !== void 0 ? _d : "\"".concat(value, "\"") }))] })))] })) })))] })));
};
export var Search = React.memo(SearchBase);

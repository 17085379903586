/**
 * Utility functions related to manipulating files
 */
/**
 * Encodes a file to Base64 Array Buffer
 */
export var toBase64 = function (file, mime) {
    if (mime === void 0) { mime = false; }
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            var result = reader.result;
            var noMime = result.replace(/^data:image\/[a-z]+;base64,/, "");
            return resolve(mime ? result : noMime);
        };
        reader.onerror = function (error) { return reject(error); };
    });
};
/**
 * Function to extract file extention from filename string
 */
export var getFileExtension = function (filename) {
    if (!filename.includes("."))
        return undefined;
    return filename.split(".").pop();
};

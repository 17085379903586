var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AqiValueText } from "./aqiValueText";
import { AqiValueTick } from "./aqiValueTick";
import { BarChunk } from "./barChunk";
import { Column } from "./column";
import { NumberIndicatorLine } from "./numberIndicatorLane";
import { Row } from "./row";
import { useSelector } from "react-redux";
import { AQI_SCALE_RANGES, normalize } from "utils";
var hide = {
    opacity: 0,
};
var AqiScaleRange = function (_a) {
    var aqi = _a.aqi, color = _a.color;
    var userInfo = useSelector(function (state) { return state.user.info.data; });
    var aqiType = userInfo.settings.aqi_type;
    var rangeSpec = AQI_SCALE_RANGES[aqiType];
    var excessAqi = aqi - rangeSpec.max;
    var aqiOffTheCharts = excessAqi > 0;
    var aqiAsPercent = aqiOffTheCharts
        ? 100
        : normalize(aqi, rangeSpec.min, rangeSpec.max) * 100;
    return (_jsxs(Column, __assign({ style: { flex: 1 } }, { children: [_jsxs(NumberIndicatorLine, { children: [_jsx("span", __assign({ style: aqi < rangeSpec.minHide ? hide : undefined }, { children: rangeSpec.min })), _jsx("span", __assign({ style: aqi > rangeSpec.maxHide ? hide : undefined }, { children: rangeSpec.max }))] }), _jsxs(Row, __assign({ style: {
                    height: "8px",
                    position: "relative",
                    width: "calc(100% - 2px)",
                } }, { children: [_jsx(AqiValueTick, { style: {
                            backgroundColor: color,
                            left: "".concat(aqiAsPercent, "%"),
                        } }), _jsx(AqiValueText, __assign({ style: {
                            left: "".concat(aqiAsPercent + 4, "%"),
                        } }, { children: aqi }))] })), _jsx(Row, { children: rangeSpec.brackets.map(function (bracket, i) {
                    var last = i === rangeSpec.brackets.length - 1;
                    return (_jsx(BarChunk, { style: {
                            flex: last && aqiOffTheCharts ? excessAqi : bracket.flexGrow,
                            backgroundColor: bracket.color,
                        } }, bracket.name));
                }) })] })));
};
export default AqiScaleRange;

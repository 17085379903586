var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, Typography, useTheme } from "@mui/material";
var Recommendation = function (_a) {
    var aqi = _a.aqi, recommendation = _a.recommendation;
    var theme = useTheme();
    return (_jsx(Stack, { children: _jsx(Typography, __assign({ color: aqi > -1 ? undefined : theme.palette.secondary["500"], variant: "caption" }, { children: recommendation })) }));
};
export default Recommendation;

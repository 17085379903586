var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, EmailShareButton, EmailIcon, TelegramShareButton, TelegramIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon, } from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsBellFill, BsBell, BsShareFill } from "react-icons/bs";
import styled, { css } from "styled-components";
import { ActionCreators } from "store";
import { useUnsubscribeFromPod } from "hooks";
import { Modal, Row, IconButton } from "components/atoms";
import { GetAlertsConfirmation } from "./GetAlertsConfirmation";
export var Share = styled(FacebookShareButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  background: transparent;\n  border: none;\n  width: 24px;\n  padding: 0;\n  margin-top: -2px;\n"], ["\n  cursor: pointer;\n  background: transparent;\n  border: none;\n  width: 24px;\n  padding: 0;\n  margin-top: -2px;\n"])));
export var Icon = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 22px;\n  color: #ffffff;\n"], ["\n  height: 22px;\n  color: #ffffff;\n"])));
var IconCard = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 0;\n    padding-bottom: 0;\n    border: none;\n    outline: none;\n    margin-right: 10px;\n    color: none;\n    border-bottom: none;\n    overflow: hidden;\n    &:last-child {\n      margin-right: 0;\n    }\n  "], ["\n    padding: 0;\n    padding-bottom: 0;\n    border: none;\n    outline: none;\n    margin-right: 10px;\n    color: none;\n    border-bottom: none;\n    overflow: hidden;\n    &:last-child {\n      margin-right: 0;\n    }\n  "]))));
/**
 * Reusable component showing social media buttons for sharing, subscribing to,
 * and favoriting a pod.  Reused on pod-info screen, as well as favorites screen
 */
export var SocialMediaButtons = function (_a) {
    var pod = _a.pod;
    var uuid = pod.uuid, name = pod.name;
    var _b = __read(useState(false), 2), shareOpen = _b[0], setShareOpen = _b[1];
    var _c = __read(useState(false), 2), subscribeConfirm = _c[0], setSubscribeConfirm = _c[1];
    var token = useSelector(function (state) { var _a; return (_a = state.user.auth) === null || _a === void 0 ? void 0 : _a.jwt; });
    var subscribedPods = useSelector(function (state) { return state.pods.subscribed; });
    var favoritePods = useSelector(function (state) { return state.pods.favorites; });
    var unsubscribe = useUnsubscribeFromPod(uuid);
    var isFavorite = favoritePods.some(function (elem) { return elem.uuid === uuid; });
    var isSubscribed = subscribedPods.some(function (elem) { return elem.uuid === uuid; });
    var dispatch = useDispatch();
    var defaultShareProps = {
        url: "https://app.persium.co.uk/#pods/detail/".concat(uuid),
        title: "Persium Air Monitoring System: ".concat(name),
    };
    /**
     * Function to handle when user clicks on subscribe social media button.
     * If not authenticated or already at limit, calls parent callback to show
     * subscribe warning in parent component.  If authenticated, either opens
     * subscribe modal, or ubsubscribes
     */
    var handleNotification = useCallback(function () {
        if (isSubscribed) {
            unsubscribe();
        }
        else {
            setSubscribeConfirm(true);
        }
    }, [subscribedPods]);
    /**
     * Function to handle when user clicks the favorite (star) icon.
     * If already favorited, will call route to unfavorite.  If not a fav,
     * will call route to favorite it
     */
    var handleFavourite = useCallback(function () {
        if (isFavorite) {
            dispatch(ActionCreators.requestDeleteFavPod(uuid));
        }
        else {
            dispatch(ActionCreators.requestAddFavPod(uuid));
        }
    }, [token, uuid, isFavorite]);
    var BellIcon = isSubscribed ? BsBellFill : BsBell;
    var StarIcon = isFavorite ? AiFillStar : AiOutlineStar;
    return (_jsxs(_Fragment, { children: [_jsxs(Row, __assign({ style: { width: "auto" } }, { children: [_jsx(IconButton, __assign({ size: 32, onClick: function () { return setShareOpen(true); } }, { children: _jsx(BsShareFill, {}) })), _jsx(IconButton, __assign({ size: 32, padding: "6px", onClick: handleNotification }, { children: _jsx(BellIcon, { id: "pod-subscribed-icon-".concat(uuid), className: isSubscribed ? "filled" : "empty" }) })), _jsx(IconButton, __assign({ size: 32, padding: "4px", onClick: handleFavourite }, { children: _jsx(StarIcon, { id: "pod-liked-icon-".concat(uuid), className: isFavorite ? "filled" : "empty" }) }))] })), _jsx(Modal, __assign({ id: "share-dialog", isOpen: shareOpen, setIsOpen: setShareOpen, title: "Share This Station", backProps: {
                    text: "Close",
                    onClick: function () { return setShareOpen(false); },
                    style: {
                        maxWidth: "100%",
                        flex: "1 1 0%",
                        marginRight: 0,
                    },
                } }, { children: _jsxs(Row, __assign({ justifyContent: "center" }, { children: [_jsx(IconCard, { children: _jsx(FacebookShareButton, __assign({}, defaultShareProps, { children: _jsx(FacebookIcon, { size: 40 }) })) }), _jsx(IconCard, { children: _jsx(TwitterShareButton, __assign({}, defaultShareProps, { children: _jsx(TwitterIcon, { size: 40 }) })) }), _jsx(IconCard, { children: _jsx(WhatsappShareButton, __assign({}, defaultShareProps, { children: _jsx(WhatsappIcon, { size: 40 }) })) }), _jsx(IconCard, { children: _jsx(TelegramShareButton, __assign({}, defaultShareProps, { children: _jsx(TelegramIcon, { size: 40 }) })) }), _jsx(IconCard, { children: _jsx(LinkedinShareButton, __assign({}, defaultShareProps, { children: _jsx(LinkedinIcon, { size: 40 }) })) }), _jsx(IconCard, { children: _jsx(EmailShareButton, __assign({}, defaultShareProps, { children: _jsx(EmailIcon, { size: 40 }) })) })] })) })), _jsx(GetAlertsConfirmation, { pod: pod, isOpen: subscribeConfirm, setIsOpen: setSubscribeConfirm })] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useDidMountEffect, usePrevious } from "hooks";
import { COLORS } from "theme";
import { bracketsToLinearGradient } from "utils";
var RangeSliderWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n  height: 18px;\n  box-sizing: border-box;\n  & * {\n    box-sizing: border-box;\n  }\n"], ["\n  width: 100%;\n  position: relative;\n  height: 18px;\n  box-sizing: border-box;\n  & * {\n    box-sizing: border-box;\n  }\n"])));
var Input = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  margin: 0;\n  appearance: none;\n  background-color: transparent;\n  cursor: pointer;\n\n  &::-webkit-slider-runnable-track {\n    background-color: \"rgba(0, 0, 0, 0)\";\n    -webkit-appearance: none;\n  }\n\n  &::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    appearance: none;\n    background: #fff;\n    border-radius: 50%;\n    width: 18px;\n    height: 18px;\n    background: ", ";\n  }\n"], ["\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  margin: 0;\n  appearance: none;\n  background-color: transparent;\n  cursor: pointer;\n\n  &::-webkit-slider-runnable-track {\n    background-color: \"rgba(0, 0, 0, 0)\";\n    -webkit-appearance: none;\n  }\n\n  &::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    appearance: none;\n    background: #fff;\n    border-radius: 50%;\n    width: 18px;\n    height: 18px;\n    background: ", ";\n  }\n"])), COLORS.BLUE);
var Colorbar = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  position: absolute;\n  top: 50%;\n  transform: translate(0, -50%);\n  left: 0;\n  right: 0;\n  height: 5px;\n  border-radius: 2px;\n"], ["\n  background: ", ";\n  position: absolute;\n  top: 50%;\n  transform: translate(0, -50%);\n  left: 0;\n  right: 0;\n  height: 5px;\n  border-radius: 2px;\n"])), function (props) { return props.background; });
var Dimmer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 100%;\n  position: absolute;\n  right: 0;\n  background-color: ", ";\n  opacity: 0.7;\n"], ["\n  height: 100%;\n  position: absolute;\n  right: 0;\n  background-color: ", ";\n  opacity: 0.7;\n"])), COLORS.WHITE);
/**
 * Persium-branded range slider with "rainbow-line" capabilities
 */
export var RangeSlider = function (_a) {
    var value = _a.value, setValue = _a.setValue, min = _a.min, max = _a.max, _b = _a.step, step = _b === void 0 ? 1 : _b, _c = _a.operator, operator = _c === void 0 ? "<" : _c, brackets = _a.brackets, savePlace = _a.savePlace;
    var valueAsPercent = ((Number(value) - min) / (max - min)) * 100;
    var lessThan = operator === "<";
    var prevMin = usePrevious(min);
    var prevMax = usePrevious(max);
    /**
     * Cute util to account for if the units the slider uses changes, we 'save the place' of the current
     * value, on the new scale
     */
    useDidMountEffect(function () {
        if (savePlace) {
            var oldPercent = ((Number(value) - prevMin) / (prevMax - prevMin)) * 100;
            var newValue = (oldPercent * (max - min)) / 100;
            setValue(Math.round(newValue));
        }
    }, [min, max, savePlace]);
    return (_jsxs(RangeSliderWrapper, { children: [_jsx(Colorbar, { background: (brackets === null || brackets === void 0 ? void 0 : brackets.length) > 1
                    ? bracketsToLinearGradient(brackets)
                    : COLORS.GREY }), _jsx(Dimmer, { style: lessThan
                    ? { right: 0, left: "".concat(valueAsPercent, "%") }
                    : { left: 0, right: "".concat(100 - valueAsPercent, "%") } }), _jsx(Input, { type: "range", value: value, min: min, max: max, step: step, onChange: function (e) {
                    setValue(e.target.value);
                } })] }));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;

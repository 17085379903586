var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { BsLayersFill } from "react-icons/bs";
import { Column as ColumnBase, Row } from "components/atoms";
import styled from "styled-components";
import { ActionCreators, getMapSlice, } from "store";
import { COLORS } from "theme";
import { ToggleButton } from "./ToggleButton";
var Wrapper = styled(Row)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 56px;\n  right: 12px;\n  z-index: 400;\n  align-items: flex-start;\n  justify-content: flex-end;\n  pointer-events: none;\n  width: 308px;\n\n  @media (max-width: 448px) {\n    justify-content: center;\n  }\n"], ["\n  position: absolute;\n  top: 56px;\n  right: 12px;\n  z-index: 400;\n  align-items: flex-start;\n  justify-content: flex-end;\n  pointer-events: none;\n  width: 308px;\n\n  @media (max-width: 448px) {\n    justify-content: center;\n  }\n"])));
var Column = styled(ColumnBase)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: stretch;\n  flex-basis: 164px;\n  pointer-events: none;\n\n  & * {\n    pointer-events: auto;\n  }\n\n  @media (max-width: 448px) {\n    flex: 1;\n  }\n"], ["\n  align-items: stretch;\n  flex-basis: 164px;\n  pointer-events: none;\n\n  & * {\n    pointer-events: auto;\n  }\n\n  @media (max-width: 448px) {\n    flex: 1;\n  }\n"])));
/**
 * UI component in top right of map to select which layers are visible in the map
 */
export var LayerSelector = function (_a) {
    var reduxLocation = _a.reduxLocation, style = _a.style, setFiltersModalOpen = _a.setFiltersModalOpen;
    var dispatch = useDispatch();
    var showHeatmap = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).view.showHeatmap;
    });
    var showIsobar = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).view.showIsobar;
    });
    var showWind = useSelector(function (state) { return getMapSlice(state, reduxLocation).view.showWind; });
    return (_jsxs(Wrapper, __assign({ style: style }, { children: [_jsx(Column, __assign({ style: { marginRight: "10px" } }, { children: _jsx(ToggleButton, { layerName: "Filters", visible: showHeatmap, onClick: function () { return setFiltersModalOpen(true); }, text: "Pollutant Filters", icon: _jsx(BsLayersFill, { color: COLORS.GREY, size: 18 }) }) })), _jsxs(Column, { children: [_jsx(ToggleButton, { layerName: "Heatmap", visible: showHeatmap, onClick: function () { return dispatch(ActionCreators.setHeatmap(reduxLocation)); } }), _jsx(ToggleButton, { layerName: "Isobars", visible: showIsobar, onClick: function () { return dispatch(ActionCreators.setIsobar(reduxLocation)); } }), _jsx(ToggleButton, { layerName: "Wind", visible: showWind, onClick: function () { return dispatch(ActionCreators.setWind(reduxLocation)); } })] })] })));
};
var templateObject_1, templateObject_2;

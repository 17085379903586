var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMap, useMapEvents } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators, getMapSlice, } from "store";
/**
 * Util function to combine default ahdn custom event handlers for leaflet map.
 * Will fire the default, unless the custom handler species to override the default behavior.
 * Will fire the custom handler if there is one
 */
var combineHandlers = function (_a) {
    var e = _a.e, defaultHandler = _a.defaultHandler, customHandler = _a.customHandler;
    if (customHandler === null || customHandler === void 0 ? void 0 : customHandler.override) {
        customHandler.fn(e);
        return;
    }
    if (customHandler) {
        customHandler.fn(e);
    }
    defaultHandler(e);
};
/**
 * Non-renderable component to capture all map events for a `<MapView />`
 */
export var MapEvents = function (_a) {
    var reduxLocation = _a.reduxLocation, _b = _a.events, events = _b === void 0 ? {} : _b;
    var dispatch = useDispatch();
    var podPreview = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).view.podPreview;
    });
    var moveend = events.moveend, click = events.click, load = events.load, otherCustomEvents = __rest(events, ["moveend", "click", "load"]);
    /**
     * De-shelled events from their instructions, pure LeafletEventHandlerFnMap
     */
    var eventsWithoutDefaults = Object.keys(otherCustomEvents).reduce(function (acc, elem) {
        acc[elem] = otherCustomEvents[elem].fn;
        return acc;
    }, {});
    var map = useMap();
    var setBounds = function () {
        return dispatch(ActionCreators.setMapView({
            bounds: map.getBounds(),
            center: map.getCenter(),
            zoom: map.getZoom(),
        }, reduxLocation));
    };
    var clearPodPreview = function () {
        if (podPreview) {
            dispatch(ActionCreators.setPodPreview(null, reduxLocation));
        }
    };
    useMapEvents(__assign({ load: function (e) {
            combineHandlers({
                e: e,
                defaultHandler: setBounds,
                customHandler: load,
            });
        }, click: function (e) {
            combineHandlers({
                e: e,
                defaultHandler: clearPodPreview,
                customHandler: click,
            });
        }, moveend: function (e) {
            combineHandlers({
                e: e,
                defaultHandler: setBounds,
                customHandler: moveend,
            });
        } }, eventsWithoutDefaults));
    return null;
};

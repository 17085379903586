var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, Routes } from "react-router-dom";
import { SimpleMapWrapper } from "components";
import styled from "styled-components";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";
export * from "./ResetPassword";
export var Inner = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  align-items: stretch;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 50%;\n  align-items: stretch;\n"])));
/**
 * Primary login screen
 */
export var LoginPage = function () {
    return (_jsx(SimpleMapWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Login, {}) }), _jsx(Route, { path: "/forgot", element: _jsx(ForgotPassword, {}) }), _jsx(Route, { path: "/reset-password", element: _jsx(ResetPassword, {}) })] }) }));
};
var templateObject_1;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
/**
 * Generic row component.  Based on flexbox, aligns content in a row.  Can be customized
 * with CSS properties
 */
export var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: ", ";\n  justify-content: ", ";\n  margin: ", ";\n  padding: ", ";\n  flex: ", ";\n  flex-wrap: ", ";\n  gap: ", ";\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: ", ";\n  justify-content: ", ";\n  margin: ", ";\n  padding: ", ";\n  flex: ", ";\n  flex-wrap: ", ";\n  gap: ", ";\n"])), function (props) { var _a; return (_a = props.alignItems) !== null && _a !== void 0 ? _a : "center"; }, function (props) { var _a; return (_a = props.justifyContent) !== null && _a !== void 0 ? _a : "space-between"; }, function (props) { return props.margin; }, function (props) { return props.padding; }, function (props) { return props.flex; }, function (props) { return props.flexWrap; }, function (props) { return props.gap; });
var templateObject_1;

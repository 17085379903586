var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PersiumLoading as Loading } from "assets/images";
export var PersiumLoading = function () {
    return (_jsxs("div", __assign({ style: {
            width: "100%",
            height: "100%",
            maxHeight: "500px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
        } }, { children: [_jsxs("div", __assign({ style: {
                    position: "relative",
                } }, { children: [_jsx("div", { style: {
                            height: " 100px",
                            width: " 100px",
                            borderRadius: " 50%",
                            border: " 3px solid grey",
                        } }), _jsx("div", { className: "persium-spinner", style: { position: "absolute", top: 0, left: 0 } })] })), _jsx("img", { src: Loading, style: {
                    height: "100px",
                    padding: "10px",
                    position: "absolute",
                    width: "100px",
                } })] })));
};

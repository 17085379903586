import { useEffect, useRef } from "react";
/**
 * Custom hook that runs exactly like useEffect, except it does not run on first mount
 */
export var useDidMountEffect = function (func, deps) {
    var didMount = useRef(false);
    useEffect(function () {
        if (didMount.current)
            func();
        else
            didMount.current = true;
    }, deps);
};

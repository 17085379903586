/**
 * Types used in the app
 */
export var ActionTypes;
(function (ActionTypes) {
    /* Action types for user actions related to login and auth (`state.general` reducers) */
    ActionTypes["SET_LOCALE"] = "user/SET_LOCALE";
    ActionTypes["REQ_LOGIN"] = "user/REQ_LOGIN";
    ActionTypes["SET_AUTHENTICATED"] = "user/SET_AUTHENTICATED";
    ActionTypes["LOG_OUT"] = "user/LOG_OUT";
    ActionTypes["SET_AUTH_ERROR"] = "user/SET_AUTH_ERROR";
    ActionTypes["REQ_REFRESH_TOKEN"] = "user/REQ_REFRESH_TOKEN";
    /* Actiontypes related to getting user information */
    ActionTypes["REQ_USER_INFO"] = "user/REQ_USER_INFO";
    ActionTypes["SET_USER_INFO"] = "user/SET_USER_INFO";
    /* Actiontypes related to cancelling account */
    ActionTypes["REQ_CANCEL_SUBSCRIPTION"] = "user/REQ_CANCEL_SUBSCRIPTION";
    ActionTypes["CANCEL_SUBSCRIPTION_RESPONSE"] = "user/CANCEL_SUBSCRIPTION_RESPONSE";
    ActionTypes["CLEAR_CANCEL_SUBSCRIPTION"] = "user/CLEAR_CANCEL_SUBSCRIPTION";
    /* Action types for user actions related to payment  */
    ActionTypes["REQ_SUBMIT_PAYMENT_FORM"] = "user/REQ_SUBMIT_PAYMENT_FORM";
    ActionTypes["PAYMENT_FORM_SUCCESS"] = "user/PAYMENT_FORM_SUCCESS";
    ActionTypes["PAYMENT_FORM_ERROR"] = "user/PAYMENT_FORM_ERROR";
    ActionTypes["CLEAR_PAYMENT_FORM"] = "user/CLEAR_PAYMENT_FORM";
    ActionTypes["REQ_UPDATE_PAYMENT_METHOD"] = "user/REQ_UPDATE_PAYMENT_METHOD";
    /* Action types for user actions related to password  */
    ActionTypes["REQ_RESET_PASSWORD_INIT"] = "user/REQ_RESET_PASSWORD_INIT";
    ActionTypes["RESET_PASSWORD_INIT_SUCCESS"] = "user/RESET_PASSWORD_INIT_SUCCESS";
    ActionTypes["RESET_PASSWORD_INIT_ERROR"] = "user/RESET_PASSWORD_INIT_ERROR";
    ActionTypes["RESET_PASSWORD_CLEAR"] = "user/RESET_PASSWORD_CLEAR";
    ActionTypes["REQ_RESET_PASSWORD_FINISH"] = "user/REQ_RESET_PASSWORD_FINISH";
    ActionTypes["REQ_FORGOT_RESET_PASSWORD_FINISH"] = "user/REQ_FORGOT_RESET_PASSWORD_FINISH";
    ActionTypes["RESET_PASSWORD_FINISH_SUCCESS"] = "user/RESET_PASSWORD_FINISH_SUCCESS";
    ActionTypes["RESET_PASSWORD_FINISH_ERROR"] = "user/RESET_PASSWORD_FINISH_ERROR";
    /* Action types related to updating phone or email */
    ActionTypes["REQ_UPDATE_CONTACT_INIT"] = "user/REQ_UPDATE_CONTACT_INIT";
    ActionTypes["UPDATE_CONTACT_INIT_SUCCESS"] = "user/UPDATE_CONTACT_INIT_SUCCESS";
    ActionTypes["UPDATE_CONTACT_INIT_ERROR"] = "user/UPDATE_CONTACT_INIT_ERROR";
    ActionTypes["CLEAR_UPDATE_CONTACT_INIT"] = "user/CLEAR_UPDATE_CONTACT_INIT";
    ActionTypes["REQ_UPDATE_CONTACT_VERIFY"] = "user/REQ_UPDATE_CONTACT_VERIFY";
    ActionTypes["UPDATE_CONTACT_VERIFY_SUCCESS"] = "user/UPDATE_CONTACT_VERIFY_SUCCESS";
    ActionTypes["UPDATE_CONTACT_VERIFY_ERROR"] = "user/UPDATE_CONTACT_VERIFY_ERROR";
    ActionTypes["CLEAR_UPDATE_CONTACT_VERIFY"] = "user/CLEAR_UPDATE_CONTACT_VERIFY";
    /* Dashboard action types */
    ActionTypes["SELECT_PODS"] = "dashboard/SELECT_PODS";
    ActionTypes["TOGGLE_SELECT_POD"] = "dashboard/TOGGLE_SELECT_POD";
    ActionTypes["CLEAR_SELECTED_PODS"] = "dashboard/CLEAR_SELECTED_PODS";
    ActionTypes["REQ_LOCATION_LIST"] = "dashboard/REQ_LOCATION_LIST";
    ActionTypes["SET_LOCATION_LIST"] = "dashboard/SET_LOCATION_LIST";
    ActionTypes["REQ_POD_LIST"] = "dashboard/REQ_POD_LIST";
    ActionTypes["SET_POD_LIST"] = "dashboard/SET_POD_LIST";
    ActionTypes["SET_TIME_FRAME"] = "dashboard/SET_TIME_FRAME";
    ActionTypes["REQ_TIMEFRAME_STATS"] = "dashboard/REQ_TIMEFRAME_STATS";
    ActionTypes["SET_TIMEFRAME_STATS"] = "dashboard/SET_TIMEFRAME_STATS";
    /* Map action types */
    ActionTypes["SET_VIEW"] = "map/SET_VIEW";
    ActionTypes["SET_MAP_BOUNDS"] = "map/SET_MAP_BOUNDS";
    ActionTypes["SET_HEATMAP"] = "map/SET_HEATMAP";
    ActionTypes["SET_ISOBAR"] = "map/SET_ISOBAR";
    ActionTypes["SET_WIND"] = "map/SET_WIND";
    ActionTypes["REQ_ALL_STATIONS_IN_BOUNDS"] = "map/REQ_ALL_STATIONS_IN_BOUNDS";
    ActionTypes["CREATE_SPLITMAP_SLICE"] = "map/CREATE_SPLITMAP_SLICE";
    ActionTypes["REQ_SPLIT_MAP_DATA"] = "map/REQ_SPLIT_MAP_DATA";
    ActionTypes["SET_ALL_STATIONS_IN_BOUNDS"] = "map/SET_ALL_STATIONS_IN_BOUNDS";
    ActionTypes["SET_HEATMAPS_IN_BOUNDS"] = "map/SET_HEATMAPS_IN_BOUNDS";
    ActionTypes["REQ_WIND_IN_BOUNDS"] = "map/REQ_WIND_IN_BOUNDS";
    ActionTypes["SET_WIND_IN_BOUNDS"] = "map/SET_WIND_IN_BOUNDS";
    ActionTypes["SET_POD_PREVIEW"] = "map/SET_POD_PREVIEW";
    /* Action types for map search related actions */
    ActionTypes["SET_SEARCH"] = "map/SET_SEARCH";
    ActionTypes["REQ_SEARCH_FOR_PODS"] = "map/REQ_SEARCH_FOR_PODS";
    ActionTypes["REQ_GEOCODE"] = "map/REQ_GEOCODE";
    ActionTypes["SET_FOUND_PODS"] = "map/SET_FOUND_PODS";
    ActionTypes["SET_GEOCODE_RESULTS"] = "map/SET_GEOCODE_RESULTS";
    ActionTypes["REQ_GEOCODE_REVERSE"] = "map/REQ_GEOCODE_REVERSE";
    /* Action types for general pod and pod-data related actions */
    ActionTypes["REQ_POD_SUMMARIES"] = "pods/REQ_POD_SUMMARIES";
    ActionTypes["SET_POD_SUMMARIES"] = "pods/SET_POD_SUMMARIES";
    ActionTypes["REQ_FAV_PODS"] = "pods/REQ_FAV_PODS";
    ActionTypes["SET_FAV_PODS"] = "pods/SET_FAV_PODS";
    ActionTypes["REQ_ADD_FAV_POD"] = "pods/REQ_ADD_FAV_POD";
    ActionTypes["REQ_DELETE_FAV_POD"] = "pods/REQ_DELETE_FAV_POD";
    ActionTypes["REQ_OWNED_PODS"] = "pods/REQ_OWNED_PODS";
    ActionTypes["SET_OWNED_PODS"] = "pods/SET_OWNED_PODS";
    ActionTypes["REQ_SUBSCRIBED_PODS"] = "pods/REQ_SUBSCRIBED_PODS";
    ActionTypes["UPDATE_ERROR"] = "pods/UPDATE_ERROR";
    ActionTypes["SET_SUBSCRIBED_PODS"] = "pods/SET_SUBSCRIBE_PODS";
    ActionTypes["REQ_ADD_SUBSCRIBED_POD"] = "pods/REQ_ADD_SUBSCRIBED_POD";
    ActionTypes["REQ_DELETE_SUBSCRIBED_POD"] = "pods/REQ_DELETE_SUBSCRIBED_POD";
    ActionTypes["SET_CURRENT_POD_SUMMARY"] = "pods/SET_CURRENT_POD_SUMMARY";
    ActionTypes["REQ_POD_LATEST_DATA"] = "pods/REQ_POD_LATEST_DATA";
    ActionTypes["SET_POD_LATEST_DATA"] = "pods/SET_POD_LATEST_DATA";
    ActionTypes["CLEAR_CURRENT_POD"] = "pods/CLEAR_CURRENT_POD";
    /* Action types for pod parameter related actions */
    ActionTypes["REQ_POD_PARAM_DATA"] = "pods/REQ_POD_PARAM_DATA";
    ActionTypes["SET_POD_PARAM_DATA"] = "pods/SET_POD_PARAM_DATA";
    ActionTypes["REQ_POD_PARAM_DATA_MULTI"] = "pods/REQ_POD_PARAM_DATA_MULTI";
    ActionTypes["SET_POD_PARAM_DATA_MULTI"] = "pods/SET_POD_PARAM_DATA_MULTI";
    ActionTypes["REQ_POD_PARAM_DATA_2D"] = "pods/REQ_POD_PARAM_DATA_2D";
    ActionTypes["SET_POD_PARAM_DATA_2D"] = "pods/SET_POD_PARAM_DATA_2D";
    ActionTypes["REQ_POD_PARAM_DATA_AVERAGE"] = "pods/REQ_POD_PARAM_DATA_AVERAGE";
    ActionTypes["SET_POD_PARAM_DATA_AVERAGE"] = "pods/SET_POD_PARAM_DATA_AVERAGE";
    /* Action types for reports related actions */
    ActionTypes["REQ_PDF_REPORT"] = "reports/REQ_PDF_REPORT";
    ActionTypes["PDF_REPORT_SUCESS"] = "reports/PDF_REPORT_SUCESS";
    ActionTypes["PDF_REPORT_ERROR"] = "reports/PDF_REPORT_ERROR";
    ActionTypes["EXPORT_DATA"] = "reports/EXPORT_DATA";
    ActionTypes["CLEAR_PDF_REPORT"] = "reports/CLEAR_PDF_REPORT";
    /* Action types for general related actions */
    ActionTypes["TOGGLE_MENU_OPEN"] = "general/TOGGLE_MENU_OPEN";
    ActionTypes["REQ_ALL_FILTERS"] = "general/REQ_ALL_FILTERS";
    ActionTypes["SET_ALL_FILTERS"] = "general/SET_ALL_FILTERS";
    /* Action types for settings related actions */
    ActionTypes["REQ_PREFERENCE_OPTIONS"] = "settings/REQ_PREFERENCE_OPTIONS";
    ActionTypes["SET_PREFERENCE_OPTIONS"] = "settings/SET_PREFERENCE_OPTIONS";
    ActionTypes["REQ_CONTACT_US"] = "settings/REQ_PREFREQ_CONTACT_USERENCE_OPTIONS";
    ActionTypes["CONTACT_US_SUCCESS"] = "settings/CONTACT_US_SUCCESS";
    ActionTypes["CONTACT_US_ERROR"] = "settings/CONTACT_US_ERROR";
    ActionTypes["CONTACT_US_CLEAR"] = "settings/CONTACT_US_CLEAR";
    ActionTypes["UPDATE_SETTINGS"] = "settings/UPDATE_SETTINGS";
    /* Action types for atmospheric data related actions */
    ActionTypes["ATMOSPHERE_FETCH_AVAILABLE_STATIONS"] = "atmosphere/FETCH_AVAILABLE_STATIONS";
    ActionTypes["ATMOSPHERE_SET_AVAILABLE_STATIONS"] = "atmosphere/SET_AVAILABLE_STATIONS";
    ActionTypes["ATMOSPHERE_SET_SELECTED_STATION"] = "atmosphere/SET_SELECTED_STATION";
    ActionTypes["ATMOSPHERE_SET_START_DATE"] = "atmosphere/SET_START_DATE";
    ActionTypes["ATMOSPHERE_SET_END_DATE"] = "atmosphere/SET_END_DATE";
    ActionTypes["ATMOSPHERE_FETCH_STATISTICS"] = "atmosphere/FETCH_STATISTICS";
    ActionTypes["ATMOSPHERE_SET_STATISTICS"] = "atmosphere/SET_STATISTICS";
})(ActionTypes || (ActionTypes = {}));

import { useMemo } from "react";
import { generateTimeFrame, nowUTC } from "utils";
import { timerangeOptions } from "../constants";
var getDefaultTimeframe = function () {
    var defaultDateRange = generateTimeFrame(2);
    return {
        endFrame: defaultDateRange.to,
        startFrame: defaultDateRange.from,
    };
};
var getByTimeframe = function (fixedTimeframe, customFrame) {
    var startFrame = timerangeOptions
        .find(function (t) { return t.value === fixedTimeframe; })
        .getTime(customFrame);
    var endFrame = nowUTC();
    return {
        startFrame: startFrame,
        endFrame: endFrame,
    };
};
// TODO: Remove
export var useGetTimeframe = function (config) {
    var _a = useMemo(function () {
        var fixedTimeframe = typeof config.timeframe === "string" ? config.timeframe : undefined;
        var fixedDateRange = config.dateRange ||
            (typeof config.timeframe === "object"
                ? config.timeframe
                : undefined);
        if (config.dateFormat === "frame" && fixedTimeframe) {
            return getByTimeframe(fixedTimeframe, config.customFrame);
        }
        if (config.dateFormat === "range" && fixedDateRange) {
            return {
                endFrame: fixedDateRange.to,
                startFrame: fixedDateRange.from,
            };
        }
        if (fixedTimeframe) {
            return getByTimeframe(fixedTimeframe, config.customFrame);
        }
        if (fixedDateRange) {
            return {
                endFrame: fixedDateRange.to,
                startFrame: fixedDateRange.from,
            };
        }
        return getDefaultTimeframe();
    }, [config.dateFormat, config.dateRange, config.timeframe]), endFrame = _a.endFrame, startFrame = _a.startFrame;
    return {
        endFrame: endFrame,
        startFrame: startFrame,
    };
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton, Tooltip } from "components/atoms";
import { FcFilledFilter } from "react-icons/fc";
import { RiErrorWarningLine } from "react-icons/ri";
import styled from "styled-components";
import { COLORS } from "theme";
import clsx from "clsx";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 10000;\n  background-color: ", ";\n  height: 0px;\n  width: 34px;\n  margin-top: 0;\n  border-radius: 50%;\n  opacity: 0;\n  pointer-events: none;\n  transition: all 200ms;\n  overflow: hidden;\n\n  &.visible {\n    height: 34px;\n    opacity: 1;\n    margin-top: 10px;\n    pointer-events: auto;\n    transition: all 200ms;\n  }\n"], ["\n  z-index: 10000;\n  background-color: ", ";\n  height: 0px;\n  width: 34px;\n  margin-top: 0;\n  border-radius: 50%;\n  opacity: 0;\n  pointer-events: none;\n  transition: all 200ms;\n  overflow: hidden;\n\n  &.visible {\n    height: 34px;\n    opacity: 1;\n    margin-top: 10px;\n    pointer-events: auto;\n    transition: all 200ms;\n  }\n"])), COLORS.BLACK);
/**
 * Simple informational map control telling the user that there are currently
 * filters applied, and why the map might be empty
 */
export var FilterIndicator = function (_a) {
    var visible = _a.visible, emptyMessage = _a.emptyMessage, style = _a.style;
    var contents = "You currently have pollutant filters applied.";
    if (emptyMessage) {
        contents =
            contents +
                " The map currently has no data to display, which may be a result of your filters.";
    }
    return (_jsx(Tooltip, __assign({ contents: contents, id: "filter-indicator-tooltip" }, { children: _jsxs(Wrapper, __assign({ style: __assign({}, style), className: clsx("filter-indicator", { visible: visible }) }, { children: [_jsx(IconButton, __assign({ style: { backgroundColor: COLORS.BLACK } }, { children: _jsx(FcFilledFilter, { size: 18 }) })), emptyMessage && (_jsx("div", __assign({ style: {
                        position: "absolute",
                        bottom: "20px",
                        right: "-5px",
                        padding: 0,
                        backgroundColor: COLORS.BLACK,
                        borderRadius: "50%",
                        height: "20px",
                        width: "20px",
                    } }, { children: _jsx(RiErrorWarningLine, { size: 20, color: COLORS.WHITE }) })))] })) })));
};
var templateObject_1;

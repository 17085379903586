var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { Card, LineGraph, ControlledGraphCSS, HorizontalLegend, } from "components";
import { createTimeframeStrings, formatMultiTooltip, } from "utils";
import { useSettings } from "hooks";
import { GraphHandlePath } from "assets/images";
import styled from "styled-components";
import clsx from "clsx";
import { COLORS } from "theme";
import { DashArrayMap } from "components/organisms";
import { speciesDashStyleMap } from "store/pods";
import { useGetTimeframe } from "hooks/useGetTimeframe";
import { useSeries } from "./useSeries";
var labelStyles = {
    fontSize: "16px",
    height: "30px",
    marginTop: "10px",
};
/**
 * How wide we assume a y axis on the graph to be, in pixels,
 * used to help calculate margins and offsets
 */
var Y_AXIS_WIDTH = 64;
var Wrapper = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t", "\n\n\twidth: 100%;\n\theight: 480px;\n\tbox-shadow: 0 0 2px 0 ", ";\n\ttransition: all 200ms;\n\toverflow: hidden;\n\n\t.HALF & {\n\t\theight: 360px;\n\t\tbox-shadow: 0 0 0px 0 ", ";\n\t\tpadding: 0px;\n\t\ttransition: all 200ms;\n\t}\n\n\t.line-graph-wrapper {\n\t\tmargin-right: 10px;\n\t\ttransition: all 200ms;\n\t}\n\n\t.line-graph-frame-container {\n\t\tright: ", ";\n\t\tleft: 64px;\n\t}\n\n\t.HALF & .line-graph-wrapper {\n\t\tmargin-right: 0;\n\t\ttransition: all 200ms;\n\t}\n"], ["\n\t", "\n\n\twidth: 100%;\n\theight: 480px;\n\tbox-shadow: 0 0 2px 0 ", ";\n\ttransition: all 200ms;\n\toverflow: hidden;\n\n\t.HALF & {\n\t\theight: 360px;\n\t\tbox-shadow: 0 0 0px 0 ", ";\n\t\tpadding: 0px;\n\t\ttransition: all 200ms;\n\t}\n\n\t.line-graph-wrapper {\n\t\tmargin-right: 10px;\n\t\ttransition: all 200ms;\n\t}\n\n\t.line-graph-frame-container {\n\t\tright: ", ";\n\t\tleft: 64px;\n\t}\n\n\t.HALF & .line-graph-wrapper {\n\t\tmargin-right: 0;\n\t\ttransition: all 200ms;\n\t}\n"])), ControlledGraphCSS, COLORS.GREY, COLORS.GREY, function (props) { return "".concat(20 + props.noOfYAxesOnRightSide * Y_AXIS_WIDTH, "px"); });
/**
 * Central graph component of an H&A graph widget
 */
export var Graph = function (_a) {
    var config = _a.config, species = _a.config.species, movingAverageInterval = _a.movingAverageInterval, setChartRef = _a.setChartRef, showAqiColors = _a.showAqiColors, showAsAverage = _a.showAsAverage, showMinMaxAnnotations = _a.showMinMaxAnnotations, showMinMaxHighlights = _a.showMinMaxHighlights, showMovingAverage = _a.showMovingAverage, showTrendLines = _a.showTrendLines, graphState = _a.graphState;
    var timezone = useSettings().timezone;
    var useUTC = timezone === "UTC";
    var localtz = timezone === "local";
    var tzOffset = new Date().getTimezoneOffset() * 60 * 1000;
    var _b = useGetTimeframe(config), endFrame = _b.endFrame, startFrame = _b.startFrame;
    var yAxisDynamicCss = "\n    ".concat(species
        .map(function (s) { return "\n          .highcharts-custom-y-axis-".concat(s.species_code, " svg path {\n            stroke-dasharray: ").concat(DashArrayMap[speciesDashStyleMap[s.species_code]], ";\n          }\n        "); })
        .join(""), "\n  ");
    var timerange = [startFrame, endFrame];
    /**
     * Local state variable for the timeframe shown in the main (larger) graph.
     * State variable initialized to the same timeFrame from the store
     */
    var _c = __read(useState(timerange), 2), zoomedTimeframe = _c[0], setZoomedTimeframe = _c[1];
    useEffect(function () {
        setZoomedTimeframe([startFrame, endFrame]);
    }, [startFrame, endFrame]);
    /**
     * The timeframe labels for the entire timeframe data in the store,
     * shown under the navigator to represent the full scope of all available data
     */
    var fullTimeframeStrings = createTimeframeStrings(timerange, useUTC);
    /**
     * The timeframe currently shown in the main, larger graph labels
     */
    var zoomedTimeframeStrings = createTimeframeStrings(zoomedTimeframe, useUTC);
    var _d = useSeries({
        species: species,
        graphState: graphState,
        showAsAverage: showAsAverage,
        showMovingAverage: showMovingAverage,
        movingAverageInterval: movingAverageInterval,
        showMinMaxHighlights: showMinMaxHighlights,
        showTrendLines: showTrendLines,
        showMinMaxAnnotations: showMinMaxAnnotations,
        // zoomedTimeframe
    }), series = _d.series, annotations = _d.annotations, trendLineLegendItems = _d.trendLineLegendItems, yAxis = _d.yAxis;
    var noOfYAxesOnRightSide = yAxis.filter(function (axis) { return axis.opposite; }).length;
    var graphOptions = useMemo(function () {
        // let min = +new Date(zoomedTimeframe[0] || timerange[0]);
        // let max = +new Date(zoomedTimeframe[1] || timerange[1]);
        var min = +new Date(timerange[0]);
        var max = +new Date(timerange[1]);
        min = min + tzOffset;
        max = max + tzOffset;
        return {
            chart: {
                height: 440,
                margin: [40, 10 + Y_AXIS_WIDTH * noOfYAxesOnRightSide, 8, 64],
                animation: false,
                events: {
                    /**
                     * On chart initial load, grab reference to chart and save to state
                     * for imperative use in certain useEffects, helps to reduce rerenders
                     * of entire chart
                     */
                    load: function () {
                        setChartRef(this);
                        this.options.exporting.filename = "Persium Analysis Multigraph";
                    },
                },
            },
            annotations: annotations,
            time: {
                useUTC: true,
                timezoneOffset: new Date().getTimezoneOffset(),
            },
            xAxis: {
                min: min,
                max: max,
                crosshair: false,
                events: {
                    setExtremes: function (e) {
                        var chart = this.chart;
                        var xMin = e.min;
                        var xMax = e.max;
                        // Loop through each Y-axis to set new extremes
                        chart.yAxis.forEach(function (yAxis) {
                            var newMaxY = -Infinity;
                            var newMinY = Infinity;
                            // Filter data for each series associated with this Y-axis
                            chart.series
                                .filter(function (series) { return series.yAxis === yAxis; })
                                .forEach(function (series) {
                                var filteredData = series.data.filter(function (point) { return point.x >= xMin && point.x <= xMax; });
                                filteredData.forEach(function (point) {
                                    if (point.y > newMaxY)
                                        newMaxY = point.y;
                                    if (point.y < newMinY)
                                        newMinY = point.y;
                                });
                            });
                            // Set new extremes for this Y-axis
                            if (newMaxY !== -Infinity && newMinY !== Infinity) {
                                yAxis.setExtremes(newMinY, newMaxY);
                            }
                        });
                    },
                },
            },
            yAxis: yAxis,
            // yAxis: {
            // 	// Leave yAxis dynamic and let the afterSetExtremes recalculate it
            // 	min: null,
            // 	max: null,
            // },
            scrollbar: {
                enabled: false,
            },
            tooltip: {
                split: false,
                shared: true,
                useHTML: true,
                formatter: function () {
                    return formatMultiTooltip(this, localtz);
                },
            },
            rangeSelector: {
                enabled: false,
            },
            // @ts-expect-error - Highcharts TS defs are not super great
            series: series,
        };
    }, [series, annotations, timerange]);
    var navigatorOptions = useMemo(function () {
        return {
            margin: 50,
            height: 52,
            maskFill: "#00A7E133",
            outlineWidth: 0,
            xAxis: {
                gridLineWidth: 0,
                labels: {
                    enabled: false,
                },
            },
            xLabels: [
                {
                    text: fullTimeframeStrings.start,
                },
                {
                    text: fullTimeframeStrings.end,
                },
            ],
            handles: {
                height: 24,
                width: 14,
                symbols: [GraphHandlePath, GraphHandlePath],
            },
            series: series
                .map(function (line) { return (__assign(__assign({}, line), { lineWidth: (line === null || line === void 0 ? void 0 : line.className.includes("sma-line")) ? 2.5 : undefined, enableMouseTracking: false })); })
                .filter(function (series) { return series.className !== "dot-marker"; }),
        };
    }, [series, fullTimeframeStrings]);
    return (_jsxs(Wrapper, __assign({ className: clsx({
            hideAqiColors: !showAqiColors,
            showAverageLines: showAsAverage,
        }), noOfYAxesOnRightSide: noOfYAxesOnRightSide }, { children: [_jsx(HorizontalLegend, { lines: trendLineLegendItems, colWidth: 220 }), _jsx(LineGraph, { loading: graphState === null || graphState === void 0 ? void 0 : graphState.loading, graphOptions: graphOptions, xLabels: [
                    { text: zoomedTimeframeStrings.start, style: labelStyles },
                    { text: zoomedTimeframeStrings.end, style: labelStyles },
                ], navigator: navigatorOptions, rangeSelector: true }), _jsx("style", __assign({ id: "dynamic-y-axis-css" }, { children: yAxisDynamicCss }))] })));
};
var templateObject_1;

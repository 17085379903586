var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillStar, AiFillHome } from "react-icons/ai";
import { Dropdown, ALL, Row, Checkbox } from "components";
import { ActionCreators } from "store";
import styled from "styled-components";
import { MultiValueLabel, MultiValueRemove, } from "./SelectHeader";
/**
 * Mimic regular group headings by copying over styles from existing dropdown
 */
var DefaultGroupHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #00a7e1;\n  cursor: default;\n  display: block;\n  font-size: 75%;\n  font-weight: bold;\n  margin-bottom: 0.5em;\n  padding-left: 12px;\n  padding-right: 12px;\n  text-transform: uppercase;\n  box-sizing: border-box;\n"], ["\n  color: #00a7e1;\n  cursor: default;\n  display: block;\n  font-size: 75%;\n  font-weight: bold;\n  margin-bottom: 0.5em;\n  padding-left: 12px;\n  padding-right: 12px;\n  text-transform: uppercase;\n  box-sizing: border-box;\n"])));
/**
 * Custom group heading component used to inject filters UI to allow
 * user to filter the list based on favorites and owned pods
 *
 * Not using much TS here because react-selet TS defs are being strange
 */
var GroupHeading = function (props) {
    var innerRef = props.innerRef, innerProps = props.innerProps, data = props.data, id = props.id, filters = props.filters, setFilters = props.setFilters;
    if (data.label === "Select All")
        return null;
    /**
     * If we're in the first heading after "select all":
     */
    if (id.includes("group-1-heading") || data.label === "Sensors") {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: { margin: "-10px 0 20px 12px" } }, { children: [_jsx(DefaultGroupHeader, __assign({ style: { paddingLeft: 0 } }, { children: "Filter By:" })), _jsxs(Row, __assign({ justifyContent: "flex-start" }, { children: [_jsx(Checkbox, { id: "filter-by-owned-checkbox", checked: filters === null || filters === void 0 ? void 0 : filters.includes("owned"), onChange: function () {
                                        if (filters === null || filters === void 0 ? void 0 : filters.includes("owned")) {
                                            setFilters(filters === null || filters === void 0 ? void 0 : filters.filter(function (f) { return f !== "owned"; }));
                                        }
                                        else {
                                            setFilters(__spreadArray(__spreadArray([], __read(filters), false), ["owned"], false));
                                        }
                                    } }), _jsx(Row, __assign({ justifyContent: "center", style: { width: "30px" } }, { children: _jsx(AiFillHome, {}) })), _jsx("span", { children: "My Pods" })] })), _jsxs(Row, __assign({ justifyContent: "flex-start" }, { children: [_jsx(Checkbox, { id: "filter-by-fav-checkbox", checked: filters === null || filters === void 0 ? void 0 : filters.includes("favorites"), onChange: function () {
                                        if (filters === null || filters === void 0 ? void 0 : filters.includes("favorites")) {
                                            setFilters(filters === null || filters === void 0 ? void 0 : filters.filter(function (f) { return f !== "favorites"; }));
                                        }
                                        else {
                                            setFilters(__spreadArray(__spreadArray([], __read(filters), false), ["favorites"], false));
                                        }
                                    } }), _jsx(Row, __assign({ justifyContent: "center", style: { width: "30px" } }, { children: _jsx(AiFillStar, { style: { marginBottom: "-2px" } }) })), _jsx("span", { children: "My Favorites" })] }))] })), _jsx(DefaultGroupHeader, __assign({ ref: innerRef }, innerProps, { children: data.label }))] }));
    }
    return (_jsx(DefaultGroupHeader, __assign({ ref: innerRef }, innerProps, { children: data.label })));
};
/**
 * Dropdown to select pods within the top selection header
 */
export var PodSelectDropdown = function (_a) {
    var _b;
    var availablePods = _a.availablePods, selectedPods = _a.selectedPods;
    var dispatch = useDispatch();
    var favorites = useSelector(function (state) { return state.pods.favorites; });
    var owned = useSelector(function (state) { return state.pods.owned; });
    var loading = useSelector(function (state) { var _a; return (_a = state.dashboard) === null || _a === void 0 ? void 0 : _a.filters.available.pods.loading; });
    var _c = __read(useState([]), 2), filters = _c[0], setFilters = _c[1];
    /**
     * Pod options, transformed to show favorite and ownership icons
     */
    var podOptions = availablePods === null || availablePods === void 0 ? void 0 : availablePods.map(function (podGroup, i) {
        var _a;
        return (__assign(__assign({}, podGroup), { options: (_a = podGroup.options) === null || _a === void 0 ? void 0 : _a.map(function (option) {
                var isFav = favorites === null || favorites === void 0 ? void 0 : favorites.map(function (p) { return p.uuid; }).includes(option.value);
                var isOwned = owned === null || owned === void 0 ? void 0 : owned.map(function (p) { return p.uuid; }).includes(option.value);
                return __assign(__assign({}, option), { id: "id-something-" + i, label: (_jsxs(Row, __assign({ alignItems: "center", justifyContent: "space-between", flex: 1, id: "option-".concat(option.value.replaceAll(" ", "-")) }, { children: [_jsx("span", { children: option.label }), _jsxs(Row, __assign({ alignItems: "center", margin: "0 0 0 16px" }, { children: [isFav && (_jsx(AiFillStar, { style: { marginBottom: "-2px" }, className: "fav-station-icon" })), isOwned && (_jsx(AiFillHome, { style: { marginLeft: "3px" }, className: "owned-station-icon" }))] }))] }))) });
            }) }));
    });
    var options = (_b = podOptions === null || podOptions === void 0 ? void 0 : podOptions.map(function (optionGroup) {
        var _a;
        var options = (_a = optionGroup.options) === null || _a === void 0 ? void 0 : _a.filter(function (option) {
            var isFav = favorites === null || favorites === void 0 ? void 0 : favorites.map(function (p) { return p.uuid; }).includes(option.value);
            var isOwned = owned === null || owned === void 0 ? void 0 : owned.map(function (p) { return p.uuid; }).includes(option.value);
            if (!(filters === null || filters === void 0 ? void 0 : filters.length))
                return true;
            return ((filters.includes("favorites") && isFav) ||
                (filters.includes("owned") && isOwned) ||
                (selectedPods === null || selectedPods === void 0 ? void 0 : selectedPods.map(function (pod) { return pod.uuid; }).includes(option.pod.uuid)));
        });
        return __assign(__assign({}, optionGroup), { options: options });
    })) === null || _b === void 0 ? void 0 : _b.filter(function (optionGroup) { var _a; return !!((_a = optionGroup.options) === null || _a === void 0 ? void 0 : _a.length); });
    return (_jsx(Dropdown, { placeholder: "Sensors", id: "select-station-dropdown", classNamePrefix: "select-station", isMulti: true, selectAll: true, isDisabled: !(availablePods === null || availablePods === void 0 ? void 0 : availablePods.length), loading: loading, options: (options === null || options === void 0 ? void 0 : options.length)
            ? options
            : [
                {
                    label: "Sensors",
                    options: [
                        {
                            label: "No available sensors based on filters",
                            disabled: true,
                            omitCheckbox: true,
                        },
                    ],
                },
            ], components: {
            MultiValueLabel: MultiValueLabel,
            MultiValueRemove: MultiValueRemove,
            GroupHeading: function (props) { return (_jsx(GroupHeading, __assign({}, props, { filters: filters, setFilters: setFilters }))); },
        }, value: selectedPods === null || selectedPods === void 0 ? void 0 : selectedPods.map(function (pod) { return ({
            label: pod.name,
            value: pod.uuid,
            pod: pod,
        }); }), onChange: function (e, actionMeta) {
            if (actionMeta.option.value === ALL) {
                var flattenedOptions = options === null || options === void 0 ? void 0 : options.map(function (podGroup) { var _a; return (_a = podGroup.options) === null || _a === void 0 ? void 0 : _a.map(function (podOptionItem) { return podOptionItem.pod; }); }).flat();
                if ((flattenedOptions === null || flattenedOptions === void 0 ? void 0 : flattenedOptions.length) === (selectedPods === null || selectedPods === void 0 ? void 0 : selectedPods.length)) {
                    dispatch(ActionCreators.clearSelectedPods());
                }
                else {
                    dispatch(ActionCreators.selectPods(flattenedOptions));
                }
            }
            else {
                dispatch(ActionCreators.togglePodSelect(actionMeta.option.pod));
            }
        } }));
};
var templateObject_1;

/**
 * Data models specific to the dashboard page
 */
export * from "./analytics";
/**
 * The various time range options a user can choose for the pods' data they want to see
 */
export var TimeRanges;
(function (TimeRanges) {
    TimeRanges["NOW"] = "NOW";
    TimeRanges["PAST_DAY"] = "PAST_DAY";
    TimeRanges["PAST_WEEK"] = "PAST_WEEK";
    TimeRanges["PAST_MONTH"] = "PAST_MONTH";
    TimeRanges["PAST_QUARTER"] = "PAST_QUARTER";
    TimeRanges["PAST_YEAR"] = "PAST_YEAR";
    TimeRanges["PAST_X_DAYS"] = "PAST_X_DAYS";
    TimeRanges["YEAR_TO_DATE"] = "YEAR_TO_DATE";
    TimeRanges["FROM_BEGINNING"] = "FROM_BEGINNING";
    TimeRanges["CUSTOM"] = "CUSTOM";
})(TimeRanges || (TimeRanges = {}));

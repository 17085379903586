/**
 * Simple email validation function (https://stackoverflow.com/a/46181/12010984)
 */
export var validateEmail = function (email) {
    return String(email)
        .toLowerCase()
        .match(
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};
// eslint-disable-next-line max-len
export var SAFE_PASS = /^((?=.*[\d])(?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w\d\s])|(?=.*[\d])(?=.*[A-Z])(?=.*[^\w\d\s])|(?=.*[\d])(?=.*[a-z])(?=.*[^\w\d\s])).{9,}$/;
export var LIGHT_PASS = /^[a-zA-Z0-9\s]{6,}$/;
export var NORMAL_PASS = /^((?=.*[\s])(?=.*[a-zA-Z0-9\s])|(?=.*[\d])(?=.*[a-zA-Z0-9\s])).{6,}$/;
/**
 * Function to check strength of password.  Returns `safe`, `normal`, `light`, and `weak`
 * strings based on password value
 */
export var checkPasswordSafety = function (password) {
    if (password.match(SAFE_PASS)) {
        return "safe";
    }
    else if (password.match(NORMAL_PASS)) {
        return "normal";
    }
    else if (password.match(LIGHT_PASS)) {
        return "light";
    }
    else {
        return "weak";
    }
};

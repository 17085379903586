/**
 * Redux actions related to the leaflet map and its presentation
 */
import { ActionTypes } from "store/types";
export var MapActionCreators = {
    /**
     * Generic action to set as many propers in the map.view slice as you want in a single function call
     */
    setMapView: function (payload, meta) { return ({
        type: ActionTypes.SET_VIEW,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to set map bounds in store
     */
    setMapBounds: function (payload, meta) { return ({
        type: ActionTypes.SET_MAP_BOUNDS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to toggle display of heatmap layer on Map component
     */
    setHeatmap: function (meta) { return ({
        type: ActionTypes.SET_HEATMAP,
        payload: undefined,
        meta: meta,
    }); },
    /**
     * Redux action to toggle display of isobar layer on Map component
     */
    setIsobar: function (meta) { return ({
        type: ActionTypes.SET_ISOBAR,
        payload: undefined,
        meta: meta,
    }); },
    /**
     * Redux action to toggle display of wind layer on Map component
     */
    setWind: function (meta) { return ({
        type: ActionTypes.SET_WIND,
        payload: undefined,
        meta: meta,
    }); },
    /**
     * Redux action to set or clear the pod being previewed on the map
     */
    setPodPreview: function (payload, meta) { return ({
        type: ActionTypes.SET_POD_PREVIEW,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action -> saga to call API to request stations data within current map bounds
     */
    requestStationsInBounds: function (payload, meta) { return ({
        type: ActionTypes.REQ_ALL_STATIONS_IN_BOUNDS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to store returned data in store from call to api/stations
     */
    setAllStationsInBounds: function (payload, meta) { return ({
        type: ActionTypes.SET_ALL_STATIONS_IN_BOUNDS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to store returned data in store from call to api/heatmap
     */
    setHeatmapInBounds: function (payload, meta) { return ({
        type: ActionTypes.SET_HEATMAPS_IN_BOUNDS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action -> saga to call API to request wind data for map bounds
     */
    requestWindInBounds: function (payload, meta) { return ({
        type: ActionTypes.REQ_WIND_IN_BOUNDS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action -> saga to call API to request wind data for map bounds
     */
    setWindInBounds: function (payload, meta) { return ({
        type: ActionTypes.SET_WIND_IN_BOUNDS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to search for pods near a given place name.  First calls
     * nominatim api to get placenames based on search term, then uses first
     * returned result from nominatim to search for pods within a 0.5 degree
     * latlng box from that first nominatim result
     */
    requestSearchForPods: function (payload, meta) { return ({
        type: ActionTypes.REQ_SEARCH_FOR_PODS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to request geocode from Nominatim
     */
    requestGeocode: function (payload, meta) { return ({
        type: ActionTypes.REQ_GEOCODE,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to save nominatim geocode results in store
     */
    setGeocodeResults: function (payload, meta) { return ({
        type: ActionTypes.SET_GEOCODE_RESULTS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to save pods found from search in store
     */
    setFoundPods: function (payload, meta) { return ({
        type: ActionTypes.SET_FOUND_PODS,
        payload: payload,
        meta: meta,
    }); },
    /**
     * Redux action to set the search term being used in the search widget
     */
    setSearch: function (payload, meta) { return ({
        type: ActionTypes.SET_SEARCH,
        payload: payload,
        meta: meta,
    }); },
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FeatureGroup, Pane, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { getMapSlice } from "store";
import { v4 as uuid } from "uuid";
import { BezierSpline } from "../components/BezierSpline";
import { contourColor } from "./ContourColor";
/**
 * Map layer containing isobars
 */
export var IsobarsLayer = React.forwardRef(function (_a, ref) {
    var reduxLocation = _a.reduxLocation, pane = _a.pane;
    var isobarData = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).data.heatmap;
    });
    var aqi_type = useSelector(function (state) {
        return getMapSlice(state, reduxLocation).data.meta.aqi_type;
    });
    var map = useMap();
    return (_jsx(Pane, __assign({ name: pane !== null && pane !== void 0 ? pane : "isobars" }, { children: _jsx(FeatureGroup, __assign({ ref: ref }, { children: !isobarData || !(isobarData === null || isobarData === void 0 ? void 0 : isobarData.length)
                ? null
                : isobarData.map(function (isobar) {
                    var points = isobar.points, value = isobar.value;
                    var latLngs = points.map(function (point) {
                        return point.map(function (number) { return Number(number.toFixed(4)); });
                    });
                    latLngs.push(latLngs[0]);
                    return (_jsx(BezierSpline, { positions: latLngs, pathOptions: {
                            color: contourColor(value, aqi_type).color,
                            weight: map.getZoom() * 0.15,
                            pane: pane !== null && pane !== void 0 ? pane : "isobars",
                        } }, uuid()));
                }) })) })));
});
IsobarsLayer.displayName = "IsobarsLayer";

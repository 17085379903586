import { jsx as _jsx } from "react/jsx-runtime";
import * as ReactDOM from "react-dom/client";
import { App } from "./components/App";
import "./theme/index.scss";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
if (module.hot) {
    module.hot.accept();
}
var domContainer = document.querySelector("#root");
var root = ReactDOM.createRoot(domContainer);
root.render(_jsx(App, {}));

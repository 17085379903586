var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ActionCreators, ActionTypes } from "store";
import { API_BASE, FILTER_URL } from "../../constants";
/**
 * Saga to request all available species filters from back end
 */
function handleGetFilters() {
    var response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(axios.get, FILTER_URL)];
            case 1:
                response = (_a.sent());
                return [4 /*yield*/, put(ActionCreators.setAllFilters(response.data.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_1 = _a.sent();
                // Need proper error handling in this application
                if (process.env.NODE_ENV === "development") {
                }
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to request all available unit/language preference options from backend
 */
function handleGetPreferenceOptions() {
    var response, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, call(axios.get, "".concat(API_BASE, "/user/settings/options"))];
            case 1:
                response = (_a.sent());
                return [4 /*yield*/, put(ActionCreators.setPreferenceOptions(response.data.data))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                e_2 = _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
/**
 * Saga to send user contact form
 */
function handleContactForm(action) {
    var e_3;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(axios.post, "".concat(API_BASE, "/contact-us"), action.payload)];
            case 1:
                _d.sent();
                return [4 /*yield*/, put(ActionCreators.contactUsSuccess())];
            case 2:
                _d.sent();
                return [3 /*break*/, 5];
            case 3:
                e_3 = _d.sent();
                return [4 /*yield*/, put(ActionCreators.contactUsError((_c = (_b = (_a = e_3 === null || e_3 === void 0 ? void 0 : e_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : "There was an error submitting your request.  Please try again."))];
            case 4:
                _d.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function watchHandleGetFilters() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_ALL_FILTERS, handleGetFilters)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleGetPreferenceOptions() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_PREFERENCE_OPTIONS, handleGetPreferenceOptions)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function watchHandleContactForm() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(ActionTypes.REQ_CONTACT_US, handleContactForm)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function generalSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    fork(watchHandleGetFilters),
                    fork(watchHandleGetPreferenceOptions),
                    fork(watchHandleContactForm),
                ])];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

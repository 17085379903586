var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { initialMapState, Maps } from "store/map";
import generateMapReducer from "store/map/reducer";
import { ActionTypes } from "store/types";
/**
 * Default view for most H&A map widgets
 */
var defaultView = __assign(__assign({}, initialMapState.view), { showHeatmap: true, showIsobar: true });
var initialState = {
    graphs: {},
    maps: {
        speciesMaps: {
            preview: __assign(__assign({}, initialMapState), { view: defaultView }),
        },
        swipeMaps: {
            preview: {
                left: __assign(__assign({}, initialMapState), { view: defaultView }),
                right: __assign(__assign({}, initialMapState), { view: defaultView }),
            },
        },
    },
};
var haSpeciesMapReducer = generateMapReducer(Maps.HASpeciesMapWidget);
var haSwipeMapReducer = generateMapReducer(Maps.HASwipeMapWidget);
export function analyticsReducer(state, action) {
    var _a, _b, _c, _d, _e, _f;
    var _g, _h, _j, _k, _l, _m, _o;
    if (state === void 0) { state = initialState; }
    switch ((_g = action.meta) === null || _g === void 0 ? void 0 : _g.path) {
        case Maps.HASpeciesMapWidget: {
            var widget = (_h = action.meta) === null || _h === void 0 ? void 0 : _h.widget;
            var id = widget === null || widget === void 0 ? void 0 : widget.id;
            return __assign(__assign({}, state), { maps: __assign(__assign({}, state.maps), { speciesMaps: __assign(__assign({}, state.maps.speciesMaps), (_a = {}, _a[id] = haSpeciesMapReducer(state.maps.speciesMaps[id], action), _a)) }) });
        }
        case Maps.HASwipeMapWidget: {
            switch (action.type) {
                case ActionTypes.CREATE_SPLITMAP_SLICE:
                    return __assign(__assign({}, state), { maps: __assign(__assign({}, state.maps), { swipeMaps: __assign(__assign({}, state.maps.swipeMaps), (_b = {}, _b[action.payload] = {
                                left: __assign(__assign({}, initialMapState), { view: defaultView }),
                                right: __assign(__assign({}, initialMapState), { view: defaultView }),
                            }, _b)) }) });
                default: {
                    var widget = (_j = action.meta) === null || _j === void 0 ? void 0 : _j.widget;
                    var id = widget === null || widget === void 0 ? void 0 : widget.id;
                    var side = widget === null || widget === void 0 ? void 0 : widget.side;
                    return __assign(__assign({}, state), { maps: __assign(__assign({}, state.maps), { swipeMaps: __assign(__assign({}, state.maps.swipeMaps), (_c = {}, _c[id] = __assign(__assign({}, state.maps.swipeMaps[id]), (side
                                ? (_d = {},
                                    _d[side] = haSwipeMapReducer(state.maps.swipeMaps[id][side], action),
                                    _d) : {})), _c)) }) });
                }
            }
        }
        default:
            switch (action.type) {
                case ActionTypes.REQ_POD_PARAM_DATA_2D: {
                    return __assign(__assign({}, state), { graphs: __assign(__assign({}, state.graphs), (_e = {}, _e[action.payload.widgetId] = __assign(__assign({}, ((_l = (_k = state.graphs) === null || _k === void 0 ? void 0 : _k[action.payload.widgetId]) !== null && _l !== void 0 ? _l : {})), { loading: true }), _e)) });
                }
                case ActionTypes.SET_POD_PARAM_DATA_2D: {
                    return __assign(__assign({}, state), { graphs: __assign(__assign({}, state.graphs), (_f = {}, _f[action.payload.widgetId] = __assign(__assign({}, ((_o = (_m = state.graphs) === null || _m === void 0 ? void 0 : _m[action.payload.widgetId]) !== null && _o !== void 0 ? _o : {})), { loading: false, lines: action.payload.lines }), _f)) });
                }
                default:
                    return state;
            }
    }
}

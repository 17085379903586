import React from "react";
import { DownloadData, Help } from "components/pages";
import { Changelog } from "components/App/frame";
var Dashboard = React.lazy(function () { return import("components/pages/dashboard"); });
var HistoricsAndAnalytics = React.lazy(function () { return import("components/pages/historics_analytics"); });
var ImpactTracker = React.lazy(function () { return import("components/pages/impact_tracker"); });
var ReportGenerator = React.lazy(function () { return import("components/pages/report_generator"); });
var Settings = React.lazy(function () { return import("components/pages/settings"); });
var Atmosphere = React.lazy(function () { return import("components/pages/atmosphere"); });
var Map = React.lazy(function () { return import("components/pages/map"); });
/**
 * Central route definitions for app's primary menu navigation
 */
export var privateRoutes = [
    {
        path: "",
        component: Dashboard,
    },
    {
        path: "historics",
        component: HistoricsAndAnalytics,
    },
    {
        path: "reports",
        component: ReportGenerator,
    },
    {
        path: "impact",
        component: ImpactTracker,
    },
    {
        path: "downloadData",
        component: DownloadData,
    },
    {
        path: "map",
        component: Map,
    },
    {
        path: "settings/*",
        component: Settings,
    },
    {
        path: "help",
        component: Help,
    },
    {
        path: "changelog",
        component: Changelog,
    },
];
export var telecomRoutes = [
    {
        path: "",
        component: Atmosphere,
    },
];

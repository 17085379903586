var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from "styled-components";
import { COLORS } from "theme";
export var Prompt = styled.div.attrs(function (props) { return (__assign(__assign({}, props), { className: "prompt" })); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 20px;\n  font-size: 14px;\n  color: ", ";\n  margin: ", ";\n  padding: ", ";\n"], ["\n  line-height: 20px;\n  font-size: 14px;\n  color: ", ";\n  margin: ", ";\n  padding: ", ";\n"])), function (props) { return (props.strong ? COLORS.BLACK : COLORS.GREY); }, function (props) { return props.margin; }, function (props) { return props.padding; });
var templateObject_1;

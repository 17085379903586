export var AQI_SCALE_RANGES = {
    caqi: {
        brackets: [
            {
                color: "#31E077",
                flexGrow: 25,
                name: "Very Low",
                range: [0, 25],
                text: "0 - 25",
            },
            {
                color: "#7DB36C",
                flexGrow: 25,
                name: "Low",
                range: [25, 50],
                text: "25 - 50",
            },
            {
                color: "#FFBC0F",
                flexGrow: 25,
                name: "Medium",
                range: [50, 75],
                text: "50 - 75",
            },
            {
                color: "#FF9604",
                flexGrow: 25,
                name: "High",
                range: [75, 100],
                text: "75 - 100",
            },
            {
                color: "#D547EC",
                flexGrow: 2,
                name: "Very High",
                range: [100, 1000],
                text: "100+",
            },
        ],
        max: 100,
        maxHide: 90,
        min: 0,
        minHide: 5,
        pillWidth: "64px",
        pollutantIntervals: {
            AQI: [0, 25, 50, 75, 100, 1000],
            NO2: [0, 50, 100, 200, 400, 2000],
            O3: [0, 60, 120, 180, 240, 2000],
            "PM2.5": [0, 15, 30, 55, 110, 300],
            PM10: [0, 25, 50, 90, 180, 300],
            SO2: [0, 50, 100, 350, 500, 5000],
        },
    },
    daqi: {
        brackets: [
            {
                color: "#00EC6C",
                flexGrow: 3,
                name: "Low",
                range: [1, 3],
                text: "1 - 3",
            },
            {
                color: "#FFBA00",
                flexGrow: 3,
                name: "Moderate",
                range: [4, 6],
                text: "4 - 6",
            },
            {
                color: "#FF5B5B",
                flexGrow: 3,
                name: "High",
                range: [7, 9],
                text: "7 - 9",
            },
            {
                color: "#E100F3",
                flexGrow: 1,
                name: "Very High",
                range: [10, 100000],
                text: "10",
            },
        ],
        max: 10,
        maxHide: 9,
        min: 1,
        minHide: 2,
        pillWidth: "54px",
        pollutantIntervals: {
            AQI: [0, 3, 6, 9, 10],
            NO2: [0, 200, 400, 600, 2000],
            O3: [0, 100, 160, 240, 1000],
            "PM2.5": [0, 35, 53, 70, 500],
            PM10: [0, 50, 75, 100, 500],
            SO2: [0, 266, 532, 1064, 2000],
        },
    },
    usaqi: {
        brackets: [
            {
                color: "#00E400",
                flexGrow: 50,
                name: "Good",
                range: [0, 50],
                text: "0 - 50",
            },
            {
                color: "#FFBC0F",
                flexGrow: 50,
                name: "Moderate",
                range: [51, 100],
                text: "50 - 100",
            },
            {
                color: "#FF7E00",
                flexGrow: 50,
                name: "Caution",
                range: [101, 150],
                text: "100 - 150",
            },
            {
                color: "#FF0000",
                flexGrow: 50,
                name: "Unhealthy",
                range: [151, 200],
                text: "150 - 200",
            },
            {
                color: "#8F3F97",
                flexGrow: 100,
                name: "Very Unhealthy",
                range: [201, 300],
                text: "200 - 300",
            },
            {
                color: "#7E0019",
                flexGrow: 200,
                name: "Hazardous",
                range: [301, 1000],
                text: "300 - 500",
            },
        ],
        max: 500,
        maxHide: 440,
        min: 0,
        minHide: 30,
        pillWidth: "84px",
        pollutantIntervals: {
            AQI: [0, 50, 100, 150, 200, 300, 300, 99999],
            NO2: [0, 53, 100, 360, 650, 1250, 1700, 2050],
            O3: [0, 54, 70, 85, 105, 200, 1000, 99999],
            "PM2.5": [0, 12, 35.5, 55.5, 150.5, 250.5, 350.5, 99999],
            PM10: [0, 55, 155, 255, 355, 425, 505, 99999],
            SO2: [0, 35, 76, 186, 305, 605, 805, 99999],
        },
    },
};

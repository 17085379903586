import { format, parse, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useSettings } from "hooks";
/**
 * Util function for formatting a date in UTC, in the format required by persium API calls
 */
export var formatUTC = function (date) {
    var d = date instanceof Date ? date : new Date(date);
    return format(utcToZonedTime(d, "UTC"), "yyyy-MM-dd' 'HH:mm:ss");
};
var defaultFormatShape = "yyyy-MM-dd' 'HH:mm:ss";
export var generateTimeFrame = function (timeFrame) {
    var now = new Date();
    var start = new Date(now.getTime() - timeFrame * 24 * 60 * 60 * 1000);
    var formatShape = defaultFormatShape;
    return {
        to: format(now, formatShape),
        from: format(start, formatShape),
    };
};
export var convertDateToTimezone = function (date, timezone) {
    return timezone === "utc"
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};
var getCurrentTime = function () {
    return format(new Date(), defaultFormatShape);
};
export var convertDateToTime = function (date) {
    return format(date, defaultFormatShape);
};
export var parseTimeToDate = function (time) {
    var fixedTime = time || getCurrentTime();
    return parse(fixedTime, defaultFormatShape, new Date());
};
export var nowUTC = function () {
    return formatUTC(new Date());
};
/**
 * Universal solution to convert UTC date to local date
 */
export function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    return newDate;
}
/**
 * Function to format date based on user's settings (Local time vs UTC)
 */
export var useFormattedDateString = function (datestring) {
    var timezone = useSettings().timezone;
    if (!datestring)
        return "";
    var localTz = timezone === "local";
    var date = localTz
        ? convertUTCDateToLocalDate(new Date(datestring))
        : parseISO(datestring);
    var result = localTz
        ? format(date, "MMMM do, yyyy' 'hh:mm:ss aa") + " Local"
        : format(date, "MMMM do, yyyy' 'HH:mm:ss") + " UTC";
    return result;
};
/**
 * Checks whether 2 dates are the same day, regardless of hour and minute
 */
export var datesAreOnSameDay = function (first, second) {
    return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
};
/**
 * Function to format a highchartsjs line chart tooltip, according to Persium UX
 * and user timezone settings.  Used for any instance of a LineGraph that will
 * have multiple datasets in it.
 */
export var formatMultiTooltip = function (tooltipFormatterContextObject, localtz) {
    var date = localtz
        ? convertUTCDateToLocalDate(new Date(tooltipFormatterContextObject.x))
        : new Date(tooltipFormatterContextObject.x);
    var formattedDate = "\n\t<span class=\"grey\">\n\t\t".concat(date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }), ",\n\t</span>\n\t<span class=\"grey\">\n\t\t").concat(date.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        second: localtz ? undefined : "2-digit",
        hour12: localtz,
    })).concat(localtz ? "" : " UTC", "\n\t</span>\n");
    return (formattedDate +
        tooltipFormatterContextObject.points.reduce(function (s, point) {
            return (s +
                "<br />" +
                "<span \n\t\t\t\tstyle=\"\n\t\t\t\t\tbackground-color: ".concat(point.color, "; \n\t\t\t\t\theight: 8px;\n\t\t\t\t\twidth: 8px;\n\t\t\t\t\tmargin-right: 3px;\n\t\t\t\t\tdisplay: inline-block;\n\t\t\t\t\tbox-shadow: 0px 0px 3px black;\n\t\t\t\t\tborder-radius: 10px;\"\n\t\t\t\t></span>") +
                point.series.name +
                ": " +
                point.y);
        }, ""));
};
/**
 * Utility function to format time range strings in a sensible manner based on the total
 * spread of the time interval
 */
export var formatDateRange = function (timerange, useUTC) {
    var transformTz = useUTC
        ? function (date) {
            var now = +date;
            var tzOffset = new Date().getTimezoneOffset();
            now = now + tzOffset * 60 * 1000;
            return new Date(now);
        }
        : function (date) { return date; };
    var start = new Date(timerange[0]);
    var end = new Date(timerange[timerange.length - 1]);
    var spread = Math.abs(end.getTime() - start.getTime());
    if (spread - 1000 * 60 * 60 * 24 * 2) {
        // range < 2 days
        return timerange.map(function (timestring) {
            return format(transformTz(new Date(timestring)), useUTC ? "MMM d, HH:mm" : "MMM d, ha");
        });
    }
    else if (spread < 1000 * 60 * 60 * 24 * 30) {
        // if range between 2 and 30 days
        return timerange.map(function (timestring) {
            return format(transformTz(new Date(timestring)), "MMM dd");
        });
    }
    else {
        // if range > 30 days
        return timerange.map(function (timestring) {
            return format(transformTz(new Date(timestring)), "MMM dd yyyy");
        });
    }
};
/**
 * Util function for transforming date ranges into formatted start, middle,
 * and end foramtted date strings
 */
export var createTimeframeStrings = function (datestrings, useUTC) {
    var startTimestamp = new Date(datestrings[0]).getTime();
    var endTimestamp = new Date(datestrings[datestrings.length - 1]).getTime();
    var lesserTime = Math.min(startTimestamp, endTimestamp);
    var spread = Math.abs(startTimestamp - endTimestamp);
    var midPoint = new Date(lesserTime + spread / 2).toISOString();
    var withInterpolated = [
        datestrings[0],
        midPoint,
        datestrings[datestrings.length - 1],
    ];
    var formattedDates = formatDateRange(withInterpolated, useUTC);
    var start = formattedDates[0];
    var middle = formattedDates[Math.floor(formattedDates.length / 2)];
    var end = formattedDates[formattedDates.length - 1];
    return { start: start, middle: middle, end: end };
};
/**
 * Convenience function to format a given number of minutes to a sensible
 * time reading
 *
 * @example
 * formatMinutes(60) // returns '60 minutes'
 *
 * @example
 * formatMinutes(600) // returns '10 hours'
 *
 * @example
 * formatMinutes(6000) // returns '4.1 days'
 *
 * @example
 * formatMinutes(600000) // returns '13.7 months'
 */
export var formatMinutes = function (minutes) {
    if (minutes < 60)
        return "".concat(minutes, " minutes");
    var result = minutes / 60;
    if (result <= 72)
        return "~".concat(result.toFixed(1), " hours");
    result = result / 24;
    if (result <= 90)
        return "~".concat(result.toFixed(1), " days");
    result = result / 30;
    return "~".concat(result.toFixed(1), " months");
};
/**
 * Calculates the difference in minutes between two dates
 */
export var daysDiff = function (start, end) {
    var diff = Math.abs(+new Date(end) - +new Date(start));
    return Math.floor(diff / (24 * 60 * 60 * 1000));
};
/**
 * Calculates a sensible time interval to call for data, given the desired date range.  Returns interval
 * in minutes.
 *
 * - Less than or equal to 7 days, request data in intervals of 60 minutes
 * - Between 7 and 90 days, request data in intervals of 8 hours
 * - 90 days or more, request data in intervals of 1 day
 */
export var sensibleInterval = function (start, end) {
    var diff = daysDiff(start, end);
    if (diff <= 7)
        return 60;
    if (diff < 90)
        return 480;
    return 1440;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import { useSearchParams } from "react-router-dom";
import { Button, Column, ErrorPrompt, LoadingSpinner, Modal, Prompt, } from "components/atoms";
import { BsCheckLg } from "react-icons/bs";
import { COLORS } from "theme";
/**
 * Two factor authentication verification form modal.  This component renders
 * a 6-digit react-verification-code-input, and relies on props to fill in the
 * rest of the messages / behaviors
 */
export var Verification = function (_a) {
    var id = _a.id, onSubmit = _a.onSubmit, title = _a.title, loading = _a.loading, success = _a.success, error = _a.error, rest = __rest(_a, ["id", "onSubmit", "title", "loading", "success", "error"]);
    var _b = __read(useState(""), 2), value = _b[0], setValue = _b[1];
    var _c = __read(useSearchParams(), 1), searchParams = _c[0];
    var hash_key = searchParams.get("hash_key");
    if (!hash_key) {
        return (_jsx(Modal, __assign({ id: "".concat(id, "-verification-modal"), nextProps: {
                text: "Back to Settings",
                onClick: function () { return rest.setIsOpen(false); },
            } }, rest, { children: _jsxs(Column, __assign({ style: { width: "100%" } }, { children: [_jsx("h2", __assign({ style: { textAlign: "center", width: "100%" }, id: "oops-message" }, { children: "Oops!" })), _jsx(Prompt, __assign({ style: { margin: "32px 0" }, id: "".concat(id, "-verification-no-hash-key-error") }, { children: "Something went wrong. You may have been directed to this page by mistake." }))] })) })));
    }
    if (success) {
        return (_jsx(Modal, __assign({ id: "".concat(id, "-verification-success-modal") }, rest, { children: _jsxs(Column, { children: [_jsx(BsCheckLg, { color: COLORS.BLUE, size: 40 }), _jsx("h3", __assign({ style: { margin: "20px 0" } }, { children: success })), _jsx(Button, __assign({ onClick: function () { return rest.setIsOpen(false); }, plump: true, outline: "filled", style: { width: "100%" } }, { children: "Done" }))] }) })));
    }
    return (_jsx(Modal, __assign({ id: "".concat(id, "-verification-modal"), backProps: { text: "Cancel", onClick: function () { return rest.setIsOpen(false); } }, nextProps: {
            text: loading ? (_jsx(LoadingSpinner, { style: { height: "15px" } })) : ("Verify"),
            disabled: value.length < 6,
            onClick: function () {
                onSubmit({ hash_key: hash_key, otp: value });
            },
        } }, rest, { children: _jsxs(Column, { children: [_jsx(Prompt, __assign({ style: { marginBottom: "16px" } }, { children: title !== null && title !== void 0 ? title : "Enter the verification code that was sent to your email:" })), _jsx(ReactCodeInput, { type: "number", fields: 6, className: "verification-input ".concat(error && "error"), onChange: function (value) { return setValue(value); } }), _jsx(ErrorPrompt, __assign({ visible: !!error, style: { margin: "8px 0 0" }, id: "verification-error" }, { children: error !== null && error !== void 0 ? error : "Something is wrong" }))] }) })));
};
